<template>
  <div class="sign-in-form">
    <div class="form-title">Sign In to your account</div>
    <div class="form-elements">
      <div class="form-element">
        <input-email v-model="signInForm.email" placeholder="example@example.com" autocomplete="both" />
        <!--          :rules="rules.requiredEmail"-->
        <!--          @error="-->
        <!--            (err) => {-->
        <!--              regError('email', err);-->
        <!--            }-->
        <!--          "-->
      </div>
      <div class="form-element">
        <input-password v-model="signInForm.password" placeholder="Password" />
        <!--          :rules="rules.password"-->
        <!--          @error="-->
        <!--            (err) => {-->
        <!--              regError('password', err);-->
        <!--            }"-->
      </div>
    </div>
    <div class="form-elements row space-between">
      <div class="form-element">
        <input-checkbox v-model="signInForm.remember_me" label="Remember me" />
      </div>
      <div class="form-element">
        <button class="button-link" @click="$router.push({ name: 'password_recover' })">
          Recover password
        </button>
      </div>
    </div>
    <button class="button" @click="onSignIn" :disabled="!isStepValid">
      sign in
    </button>
    
  </div>
  <div class="check-account">
    <span>Don't have an account?</span>
    <router-link :to="{ name: 'sign_up' }" class="button-link">Sign Up
    </router-link>
    <!--        @click="onSignUp()"-->
  </div>

  <div class="social-auth">
    <div class="social-auth-alternative">
      <span>or</span>
    </div>
    <button-google-auth />
    <button-linkedin-auth />
   
  </div>
</template>

<script>
import Spinner from "@/components/helpers/Spinner.vue";
import InputEmail from "@/components/form-elements/InputEmail.vue";
import InputPassword from "@/components/form-elements/InputPassword.vue";
import InputCheckbox from "@/components/form-elements/InputCheckbox.vue";
import ButtonGoogleAuth from "@/components/authorization/ButtonGoogleAuth.vue";
import ButtonLinkedinAuth from "@/components/authorization/ButtonLinkedinAuth.vue";
import ValidateMixin from "@/mixins/ValidateMixin";
export default {
  name: "SignInForm",
  components: {
    InputEmail,
    InputCheckbox,
    InputPassword,
    ButtonGoogleAuth,
    ButtonLinkedinAuth,
    Spinner,

  },
  mixins: [ValidateMixin],
  data() {
    return {
      isLoading: false,
      token: "",

      signInForm: {
        email: null,
        password: null,
        remember_me: false,
      },
      // errors: {
      //   email: false,
      //   password: false,
      //   remember_me: false,
      // },
    };
  },
  emits: ["actionSignIn"],
  computed: {
    isStepValid() {
      // const existError = Object.keys(this.errors).find((el) => this.errors[el]);
      // const isEmptyField = Object.entries(this.signInForm)
      //   .filter(([key, value]) => key !== "remember_me")
      //   .some(([key, value]) => !value);
      // return !existError && !isEmptyField;
      return true;
    },
  },
  methods: {
   
    onSignIn() {
      const data = { ...this.signInForm };
      this.$store.dispatch("actionSignIn", data);
    },
 
    // onRecoverPassword() {
    //   this.$emit("updateRecoverPassword", true);
    // },
    // onSignUp() {
    //   this.$emit("updateSignUp", true);
    // },
  },

};
</script>
