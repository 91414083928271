<template>
  <div id="equipment-form" :class="['equipment-form', 'equipment-form-type-' + user_role]">
    <div class="eq-bg"></div>
 
    <div
        class="equipment-form__inner"
        @click.prevent="eventBackgroundClick"
    > 

      <div class="ef__wrapper">
        <button @click="closeEquipmentWindow" class="close-eqipment">&#x2715</button>
        <div class="ef__window">
          <div class="ef-form-title">Create Vehicle Combination</div>

          <div class="ef-container">
            <div class="ef-form-tabs">
              <div
              class='ef-form-tab active'
              >Power Units
              </div>
            </div>
            <div class="ef-form">
              <div
                  :class="['ef-form__inner', 'ef-form__pos-' + form_step]">
                 <equipment-form-one :mode="mode" 
                 :selectedEquipment="selectedEquipment"
                 :selectedAxles="selectedAxles"
                  @update-table="onUpdateTable"/>
              </div>
            </div>


          </div>

        </div>
      </div>
    </div>


  </div>
</template>

<script>


import EquipmentFormOne from "@/components/equipment/form-steps/EquipmentFormOne.vue";

export default {
  name: "EquipmentForm",
  components: {

    EquipmentFormOne
  },
  props: {
   
   selectedEquipment: {
     type: Object,
     
   },
   selectedAxles: {
     type: Object,
     
   },
   mode: {
      type: String,
      default: "new",
    },
 },
 computed: {
    user_role() {
      return this.$store.getters.userRole;
    },
  },
  data() {
    return {
      form_step: 1,
    }
  },
emits: [ 'close','update-table'],
  methods: {
    /**
     *
     * @param e
     */
    eventBackgroundClick(e) {
      const click_on_bg = e.target.closest('.ef__window') === null;
      if (click_on_bg) {
        // this.closeEquipmentWindow(e);
      }
    },
    /**
     *
     * @param e
     */
    closeEquipmentWindow(e) {
      this.$emit('close');
    },
    onUpdateTable(eq_id) {
      this.$emit('update-table', eq_id);
    }

  },
  



}

</script>

<style scoped>

</style>