<template>
  <spinner :loading="loading" />
  <sign-up-page v-if="($route.name === 'sign_up') || ($route.name === 'sign_up_token')" />
  <password-reset v-else-if="$route.name === 'password_reset' || $route.name === 'password_recover'" />
  <invite-page v-else-if="$route.name === 'invite_user'" />
  <login-form v-else-if="show_login_form" />

  <settings-template v-else-if="first_login" />
  <manager-template v-else-if="logged && user_role === 'manager'" />
  <owner-template v-else-if="logged && user_role === 'owner'" />
  <driver-template v-else-if="logged && user_role === 'driver'" />
  <super-admin-template v-else-if="logged && user_role === 'admin'" />
  <alert-notification />

</template>

<script>
import LoginForm from "@/components/authorization/LoginForm";
import PasswordReset from "@/pages/passwordReset/PasswordReset";
import InvitePage from "@/pages/invitePage/InvitePage.vue";
import ManagerTemplate from "@/templates/ManagerTemplate";
import OwnerTemplate from "@/templates/OwnerTemplate";
import SuperAdminTemplate from "@/templates/SuperAdminTemplate";
import SettingsTemplate from "@/templates/SettingsTemplate";
import AlertNotification from "@/components/helpers/AlertNotification.vue";
// import AddDataPopupNotification from "@/components/helpers/AddDataPopupNotification.vue";
import Spinner from "@/components/helpers/Spinner.vue";
import SignUpPage from "@/pages/signUpPage/SignUpPage.vue";
import DriverTemplate from "@/templates/DriverTemplate.vue";

/*
todo Add server authorization on the system

 */

export default {
  name: "Accounting",
  components: {
    DriverTemplate,
    LoginForm,
    SignUpPage,
    InvitePage,
    PasswordReset,
    ManagerTemplate,
    OwnerTemplate,
    SuperAdminTemplate,
    SettingsTemplate,
    AlertNotification,
    // AddDataPopupNotification,
    Spinner
  },
  data() {
    return {
      loading: true,
      logged: false,
      // display: false,
      first_login: false,
      // routes where login form (and Google prompt) doesn't work
      list_not_login_routes: [
        'sign_up',
        'sign_up_token',
        'password_reset',
        'password_recover',
        'invite_user'
      ],
    };
  },

  computed: {
    show_login_form() {
      const list_not_login = this.list_not_login_routes;
      const route_name = this.$route.name;
      if (!route_name) {
        return false;
      }
      const found = list_not_login.find(el => el === route_name);
      return (found === undefined) && !this.logged;
    },
    // is_authorized() {
    //   return this.$store.getters.isAuthorized;
    // },
    user_data() {
      return this.$store.getters.userData;
    },
    user_role() {
      return this.$store.getters.userRole;
    },
  },
  mounted() {
    this.$store.dispatch('actionGetUserByToken')
      .then(user => {
        if (!!user) {
          this.logged = true;
        }
      });
    setTimeout(() => {
      this.loading = false;
    }, 500);
  },

  watch: {

    user_data(user) {
      // if (!user?.password_exist) {
      //   this.display = true;
      // }
      if (user?.first_login == `0` && user.role === 'owner' || user?.first_login == `0` && user.role === 'driver') {
        this.first_login = true;
      }
      if (!!user) {
        this.logged = true;
        if (this.$route.name === "home") {
          if (user.role === 'manager') {

            this.$router.push({ name: "admin_order" });
          }
          else if (user.role === 'admin') {

            this.$router.push({ name: "users" });
          }
          else {
            this.$router.push({ name: "new_order" });

          }
        }
      } else {
        this.logged = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./assets/css/app.scss";
</style>
