<template>
  <div v-if="user_data.role == 'manager'" id="clients-page">
    <div class="table-wrapper">
      <div class="table-search">
        <div class="table-search__title">Clients {{ total }}</div>
        <div class="table-search__actions">
          <div class="table-search__input__wrapper">
            <input v-model="queryParameters.searchValue" type="text" placeholder="Search" @input="searchEventInput" />
            <button @click="getClientList" class="button-search"></button>
          </div>
          <button class="button small button-icon reset-icon" @click="resetRequest">
            Reset
          </button>
        </div>
      </div>
      <div v-if="!!client_items" class="table-clients-wrapper">
        <slot-table class="table-clients" :data="client_items">
          <template #header>
            <tr>
              <!-- <th>
                <div class="input-wrapper">
                  <input type="checkbox" name="" id="" />
                </div>
              </th> -->
              <th v-for="column in columns" :class="{ 'has-sort': column.sort }" :key="column.name"
                @click="sort(column)">
                {{ column.label }}
                <span v-if="column.name === queryParameters.orderBy" :class="`sorting-icon-${queryParameters.order == 'ASC' ? 'ASC' : 'DESC'
                  }`">
                </span>
                <template v-else>
                  <span v-if="column.sort" class="sorting-icon"> </span>
                </template>
              </th>
            </tr>
          </template>
          <template v-slot:body="{ item }">
          
            <tr :key="item.user_id">
              <td>
                <div class="clients-name">
                  <img :src="item.picture_name ? this.$store.getters.apiUrl + '/user_images/' + item.picture_name : profile_photo2" alt="photo" />
                  <span>{{ item.name ? item.name : "Unnamed" }}</span>
                </div>
              </td>
              <td class="quote-route">
                <a :href="'mailto:' + item.email">{{ item.email }}</a>
              </td>
              <td> <div class="company-show-popup" @click="showCompanyPopup(item.company_id)">{{ item.company?.company_name ? item.company.company_name : 'Add company name in profile' }}</div></td>
              <td class="no-wrap">
                <a :href="'tel:' + item.phone">{{ item.phone }}</a>
              </td>
              <td class="no-wrap">{{ item.created_at }}</td>
              <td class="no-wrap"><router-link :to="'/client-activities/' + item.user_id">Show</router-link></td>
            </tr>
          </template>
        </slot-table>
      </div>
      <p v-else>Not found</p>
      <button v-if="!!client_items && client_items.length < total" @click="loadMore"
        class="button small table-clients-loadmore">
        Load more
      </button>
    </div>
  </div>
  <company-view ref="companyViewRef" :show-popup="showCompanyPopupWindow" :company_id="activeCompanyId" @close-popup="closeCompanyPopup"  />
</template>

<script>
import profile_photo2 from "@/assets/images/profile.png";
import SlotTable from "@/components/SlotTable.vue";
import { nextTick } from "vue";
import { ref } from "vue";
import CompanyView from "@/components/company/CompanyView.vue";
export default {
  name: "ClientsPage",
  components: { SlotTable, CompanyView },

  data() {
    return {
      showCompanyPopupWindow: false,
      activeCompanyId: '',
      profile_photo2,
      queryParameters: {
        searchValue: null,
        offset: 0,
        limit: 12,
        orderBy: "name",
        order: "DESC",
        // manager_id: this.$store.getters.userData.user_id
      },
      total: null,

      columns: [
        { name: "name", label: "Name", sort: true },
        { name: "email", label: "Email", sort: true },
        { name: "company", label: "Company", sort: false },
        { name: "phone", label: "Phone", sort: true },
        { name: "date", label: "Date", sort: false },
        { name: "action_Log", label: "", sort: false },
      ],
    };
  },
  async mounted() {
    // const ls_ClientListParameters = localStorage.getItem(
    //   "clientListParameters"
    // );
    // if (!!ls_ClientListParameters) {
    //   const clientListParameters = JSON.parse(ls_ClientListParameters);
    //   const queryParameters = this.queryParameters;
    //   Object.keys(this.queryParameters).forEach((key) => {
    //     queryParameters[key] = clientListParameters[key];
    //   });
    // }
    // await this.$nextTick();
    this.getClientList();
  },
  computed: {
    user_data() {
      return this.$store.getters.userData;
    },
    client_items() {
      return this.$store.getters.clientList;
    },
    picture_url() {
      if (!this.user_data) {
        return this.profile_photo2;
      }
      if (!this.user_data.picture_name) {
        return this.profile_photo2;
      }
      const api_url = this.$store.getters.apiUrl;
      return api_url + '/user_images/' + this.user_data.picture_name;
    }
  },
  setup() {
    const companyViewRef = ref(null);
    return {
      companyViewRef,
    };
  },
  methods: {
    showCompanyPopup(company_id) {
      this.activeCompanyId = company_id;
      this.companyViewRef.getCompaniesList()
      this.showCompanyPopupWindow = true;
    },
    closeCompanyPopup(company_id) {
      this.showCompanyPopupWindow = false;
    },
    getClientList() {
      const parameters = this.queryParameters;
      this.$store
        .dispatch("actionGetClientList", { parameters })
        .then((response) => {
          this.total = response.total;
        })
        .catch((err) => { });
    },
    async searchEventInput(e) {
      this.queryParameters.offset = 0;
      this.queryParameters.limit = 12;
      this.queryParameters.orderBy = "name";
      this.queryParameters.order = "ASC";
      await nextTick();
      this.getClientList();
    },
    async sort(column) {
      if (!column.sort) return;

      if (this.queryParameters.orderBy === column.name) {
        this.queryParameters.order =
          this.queryParameters.order === "ASC" ? "DESC" : "ASC";
      } else {
        this.queryParameters.orderBy = column.name;
        this.queryParameters.order = "ASC";
      }

      await nextTick();
      const parameters = { ...this.queryParameters };
      parameters.limit += parameters.offset;
      parameters.offset = 0;

      this.$store
        .dispatch("actionGetClientList", { parameters })
        .then((response) => {
          this.total = response.total;
        })
        .catch((err) => { });
    },
    loadMore() {
      const newOffset = this.client_items.length;
      this.queryParameters.offset = newOffset;
      const parameters = this.queryParameters;

      this.$store
        .dispatch("actionGetClientList", { parameters, event: "pagination" })
        .then((response) => {
          this.total = response.total;
        })
        .catch((err) => { });
    },
    async resetRequest(e) {
      this.queryParameters.searchValue = null;
      this.queryParameters.offset = 0;
      this.queryParameters.limit = 12;
      this.queryParameters.orderBy = "name";
      this.queryParameters.order = "ASC";
      localStorage.removeItem("clientListParameters");
      await nextTick();
      this.getClientList();
    },
  },
};
</script>
