<template>

  <div v-if="user_data.role === 'manager' || user_data.role === 'admin'" class="table-wrap table-margin-right">
    <div class="quote-single__row">
      <div class="quote-single__col">
        <div>
          <google-map-component ref="googleMapRef" :origin="originValue" :destination="destinationValue" status="show"
            :order_id="order.order_id" />
        </div>
      </div>
      <div class="quote-single__col calculator">
        <calculator mode="display_only" />
      </div>
    </div>
    <template v-if="order.order_status === 'deposit_paid' && order.manager_id === user_data.user_id">
      <hr />
      <div class="permits-quote-table-wrapper">
        <slot-table class="permits-quote-table" :data="orderData">
          <template #header>
            <tr>
              <th v-for="column in columns" :key="column.name">
                {{ column.label }}
              </th>
            </tr>
          </template>
          <template v-slot:body="{ item, index }">
            <tr :key="item.id" :class="[{ active: item.state.value === '' }]">
              <td>
                <div class="quotes-states">
                  <select v-if="item.state.edit" v-model="item.state.value" @change="onSelectSelected(item.state.value)"
                    @blur="toggleSelectVisibility(item.state)">
                    <option value="">Select a state</option>
                    <option v-for="state in state_list" :key="state" :value="state">
                      {{ state }}
                    </option>
                  </select>
                  <div v-else @click="toggleSelectVisibility(item.state)">
                    {{ item.state.value }}
                  </div>
                </div>
              </td>
              <td>
                <div class="quotes-oversize">
                  <money-input v-if="item.oversize_data.edit" v-model="item.oversize_data.value"
                    :obj_data="item.oversize_data" placeholder="$25.00" @update:model-value="calculateTotals" />
                  <div v-else @click="editColumn(item.oversize_data)" class="quotes-oversize-value">
                    {{ formatPrice(item.oversize_data.value) }}
                  </div>
                </div>
              </td>
              <td>
                <div class="quotes-overwieght">
                  <money-input v-if="item.overweight_data.edit" v-model="item.overweight_data.value"
                    :obj_data="item.overweight_data" placeholder="$0.0" @update:model-value="calculateTotals" />
                  <div v-else @click="editColumn(item.overweight_data)" class="quotes-overwieght-value">
                    {{ formatPrice(item.overweight_data.value) }}
                  </div>
                </div>
              </td>

              <td>
                <div class="quotes-superload">
                  <div class="quotes-superload-value">
                    <money-input v-if="item.superload.data.edit" v-model="item.superload.data.value"
                      :obj_data="item.superload.data" placeholder="$0.0" @update:model-value="calculateTotals" />
                    <div v-else @click="editColumn(item.superload.data)" class="quotes-escort-value">
                      {{ formatPrice(item.superload.data.value) }}
                    </div>
                  </div>

                  <div class="select-superload">
                    <div class="select-superload-checking" v-if="!item.superload.selectData.edit"
                      @click="toggleSelectVisibility(item.superload.selectData)">
                      {{ item.superload.selectData.value ? item.superload.selectData.value : 'NO' }}
                    </div>

                    <select v-else v-model="item.superload.selectData.value"
                      @change="onSelectSelected(item.superload.selectData.value)"
                      @blur="toggleSelectVisibility(item.superload.selectData)">
                      <option v-for="item in select_data" :key="item.value" :value="item.text">
                        {{ item.text }}
                      </option>
                    </select>
                  </div>
                </div>
              </td>
              <td>
                <div class="quotes-oversize">
                  <money-input v-if="item.other_data.edit" v-model="item.other_data.value" :obj_data="item.other_data"
                    placeholder="$25.00" @update:model-value="calculateTotals" />
                  <div v-else @click="editColumn(item.other_data)" class="quotes-oversize-value">
                    {{ formatPrice(item.other_data.value) }}
                  </div>
                </div>
              </td>
              <td>
                <div class="quotes-oversize">
                  <money-input v-if="item.service_data.edit" v-model="item.service_data.value"
                    :obj_data="item.service_data" placeholder="$25.00" @update:model-value="calculateTotals" />
                  <div v-else @click="editColumn(item.service_data)" class="quotes-service_data-value">
                    {{ formatPrice(item.service_data.value) }}
                  </div>
                </div>
              </td>
              <td>${{ item.total.toFixed(2) }}</td>
              <td>
                <button class="quotes-button-remove" @click="showConfirmationDialog(index)"></button>
                <button class="quotes-button-add" @click="addNewState(index)"></button>
              </td>
            </tr>
          </template>
          <template #footer>
            <tr class="bg-transparent">
              <td class="bg-blue"><strong>Total</strong></td>
              <td class="bg-blue"><strong>${{ total_oversize }}</strong></td>
              <td class="bg-blue"><strong>${{ total_overweight }}</strong></td>
              <td class="bg-blue"><strong>${{ total_superload }}</strong></td>
              <td class="bg-blue"><strong>${{ total_other }}</strong></td>
              <td class="bg-blue"><strong>${{ total_service_fee }}</strong></td>
              <td class="bg-blue"><strong>${{ calculateTotalOfTotals().toFixed(2) }}</strong></td>
              <td class="bg-blue"></td>
            </tr>


            <tr class="bg-transparent">

              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="bg-white">Transaction fee 3.5%</td>
              <td class="bg-white">${{ calculate3_5Percent().toFixed(2) }}</td>
              <td class="bg-white"></td>
            </tr>
            <tr class="bg-transparent">

              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="bg-white">Deposit</td>
              <td class="bg-white">-${{ Number(order.deposit_amount).toFixed(2) }}</td>
              <td class="bg-white"></td>
            </tr>
            <!-- <tr v-if="additionalDeposit > 0" class="bg-transparent">

              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="bg-white">Additional payment for states</td>
              <td class="bg-white">${{ additionalDeposit }}</td>
              <td class="bg-white"></td>
            </tr> -->

            <tr class="bg-transparent">

              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="bg-blue"><strong>Due Amount</strong></td>
              <td class="bg-blue">
                <strong>${{ calculateAll() }}</strong>
              </td>
              <td class="bg-blue"></td>
            </tr>
          </template>
        </slot-table>
      </div>

      <button v-if="!orderData.length" class="button small add-state" @click="addNewState(-1)">
        Add State
      </button>
    </template>

    <admin-order-page-statuses :order="order" :total="calculateAll()" :order_meta="orderData"
      @update-data="getOrderMeta" :check-valid="allEditsFalse" />
      <!-- :additionalDeposit="additionalDeposit" -->
    <admin-edit-permit v-if="order.order_status == 'payment_completed'
    " :tableData="orderData" :order_id="order.order_id" @update-table="onOrderDataUpdated" />
  </div>
  <div v-else>Permission issue.</div>
  <confirmation-dialog v-if="showConfirmDialog" content="Are you sure you want to remove this state/country?"
    @cancel="cancelRemoveRow" @confirm="confirmRemoveRow" />
</template>

<script>
import { ref } from "vue";
import Calculator from "@/components/calculator/Calculator.vue";
import Spinner from "@/components/helpers/Spinner.vue";
import GoogleMapComponent from "@/components/googleMapComponent/GoogleMapComponent.vue";
import SlotTable from "@/components/SlotTable.vue";
import AdminEditPermit from "@/components/orders/admin/AdminEditPermit.vue";
import ConfirmationDialog from "@/components/helpers/ConfirmationDialog.vue";
import MoneyInput from "@/components/form-elements/MoneyInput.vue";
import AdminOrderPageStatuses from "@/components/orders/admin/AdminOrderPageStatuses.vue";
import { DEPOSIT_RATE } from '@/config';
export default {
  name: "AdminOrderPageId",

  components: {
    AdminOrderPageStatuses,
    SlotTable,
    AdminEditPermit,
    ConfirmationDialog,
    MoneyInput,
    GoogleMapComponent,
    Spinner,
    Calculator,
  },
  setup() {
    const googleMapRef = ref(null);

    return {
      googleMapRef,
    };
  },
  data() {
    return {
      columns: [
        { name: "state", label: "State" },
        { name: "oversize", label: "Oversize" },
        { name: "overweight", label: "Overweight" },
        { name: "superload", label: "Superload" },
        { name: "other", label: "Other" },
        { name: "service_fee", label: "Service fee" },
        { name: "total", label: "Total" },
        { name: "empty", label: "" },
      ],
      loading: false,
      order_id: this.$route.params.order_id,
      originValue: "",
      destinationValue: "",
      roadTypeValue: "interstate",
      map: null,
      showPopup: false,
      showConfirmDialog: false,
      rowIndex: null,

      select_data: [
        { text: "NO", value: "no" },
        { text: "YES", value: "yes" },
      ],
      orderData: [],
      selectedState: "",

      stateData: {
        state: {
          value: "",
          edit: true,
        },
        oversize_data: {
          value: null,
          edit: true,
        },
        overweight_data: {
          value: null,
          edit: true,
        },

        superload: {
          selectData: {
            value: "NO",
            edit: false,
          },
          data: {
            value: null,
            edit: true,
          },
        },
        other_data: {
          value: null,
          edit: true,
        },
        service_data: {
          value: null,
          edit: true,
        },
        total: 0,
        status: { value: "not_requested" },
        state_permit: {
          img_url: "",
          name: "",
          file_id: "",
        },
        state_permit_link: "",
      },
    };
  },
  mounted() {
    this.getStatesList();
    this.getOrderMeta();

  },
  computed: {
    // additionalDeposit() {
    //   const depositDifference = (+this.orderData.length * DEPOSIT_RATE) - +this.order.deposit_amount;
    //   return depositDifference > 0 ? depositDifference.toFixed(2) : 0;
    // },
    state_list() {
      const usStates =
        this.$store.getters.state_list
          .filter(state => state.country_id === "US")
          .map(state => state.name);

      return usStates;
    },
    order_meta_data() {
      const data = JSON.parse(this.$store.getters.order_meta.data.meta_value);
      return data;
    },
    order() {
      return this.$store.getters.order_by_id;
    },
    user_data() {
      return this.$store.getters.userData;
    },

    total_oversize() {
      return this.orderData.reduce(
        (acc, item) => acc + Number(item.oversize_data.value),
        0
      ).toFixed(2);
    },
    total_overweight() {
      return this.orderData.reduce(
        (acc, item) => acc + Number(item.overweight_data.value),
        0
      ).toFixed(2);
    },
    total_other() {
      return this.orderData.reduce(
        (acc, item) => acc + Number(item.other_data.value),
        0
      ).toFixed(2);
    },
    total_service_fee() {
      return this.orderData.reduce(
        (acc, item) => acc + Number(item.service_data.value),
        0
      ).toFixed(2);
    },
    total_superload() {
      return this.orderData.reduce(
        (acc, item) => acc + Number(item.superload.data.value),
        0
      ).toFixed(2);
    },

    allEditsFalse() {
      return this.orderData.every((item) =>
        this.areAllEditsFalseDeep(item)
      );
    }


  },
  methods: {
    getStatesList() {
      this.$store.dispatch("actionGetStates").then(() => { });
    },
    getOrderData() {
      const order_id = this.order_id;
      this.$store
        .dispatch("actionGetOrderbyOrderId", { order_id })
        .then((response) => {
          const order = response.order;
          this.originValue = order.origin;
          this.destinationValue = order.destination;
          this.roadTypeValue = order.road_type;

          if (this.orderData.length === 0) {
            const states = JSON.parse(response.order.choosed_states);
            states.forEach(state => {
              this.orderData.push({
                state: {
                  value: state.state,
                  edit: false,
                },
                oversize_data: {
                  value: null,
                  edit: true,
                },
                overweight_data: {
                  value: null,
                  edit: true,
                },

                superload: {
                  selectData: {
                    value: "NO",
                    edit: true,
                  },
                  data: {
                    value: null,
                    edit: true,
                  },
                },
                service_data: {
                  value: null,
                  edit: true,
                },
                other_data: {
                  value: null,
                  edit: true,
                },
                total: 0,
                status: { value: "not_requested" },
                state_permit: {
                  img_url: "",
                  name: "",
                  file_id: "",
                },
                state_permit_link: "",
              });
            });
          }
          this.$nextTick(() => {
            this.googleMapRef.calcRoute(); //turn on if map
          });
        });
    },
    getOrderMeta() {
      const order_id = this.order_id;
      const meta_key = "_data";
      this.$store
        .dispatch("actionGetOrderMeta", { order_id, meta_key })
        .then((response) => {
          this.orderData = JSON.parse(response.data.meta_value);
          this.getOrderData();
        })
        .catch((err) => { });
    },

    showConfirmationDialog(index) {
      this.rowIndex = index;
      this.showConfirmDialog = true;
    },
    cancelRemoveRow() {
      this.rowIndex = null;
      this.showConfirmDialog = false;
    },
    confirmRemoveRow() {
      this.removeRow(this.rowIndex);
      this.rowIndex = null;
      this.showConfirmDialog = false;

      if (!this.orderData.length) {
        const order_id = this.order.order_id;
        this.$store
          .dispatch("actionUdpateOrderMeta", { order_id, data: [] })
          .then((response) => { })
          .catch((err) => { });
      }
    },
    formatPrice(price) {
      if (price === "") {
        return "-";
      } else {
        return "$" + price;
      }
    },

    toggleSelectVisibility(state) {
      if (!state.edit) {
        // Show the select dropdown
        state.edit = true;
      } else {
        state.edit = false;
        this.calculateTotals();
      }
    },
    addNewState(index) {
      this.$store.dispatch("actionGenerateId", 32).then((id) => {
        const generateId = id.toLowerCase();

        this.stateData.id = generateId;
        let newStateCopy = JSON.parse(JSON.stringify(this.stateData)); //for copy - spread dosent works

        if (index === -1) {
          // Add new row at the end
          this.orderData.push(newStateCopy);
        } else {
          // Add new row after the specified index
          this.orderData.splice(index + 1, 0, newStateCopy);
        }
      });
    },
    removeRow(index) {
      this.orderData.splice(index, 1);
      this.calculateTotals();
    },
    onSelectSelected(state) {
      // Hide the select element by setting the selectedState
      this.selectedState = state;
    },
    editColumn(col, index) {
      if (!col.edit) {
        col.edit = true;
      }
    },

    calculateTotals() {
      const order_id = this.order.order_id;
      this.orderData.forEach((item) => {
        item.total = [
          Number(item.oversize_data.value),
          Number(item.overweight_data.value),
          Number(item.superload.data.value),
          Number(item.other_data.value),
          Number(item.service_data.value),
        ].reduce((acc, currentValue) => acc + Number(currentValue), 0);
      });

      const allEditsFalseDeep = this.orderData.every((item) =>
        this.areAllEditsFalseDeep(item)
      );

      if (allEditsFalseDeep && this.orderData.length) {
        console.log("All items have 'edit' set to false at a deep level.");
        const data = this.removeKeysFromArray(this.orderData);
        this.$store
          .dispatch("actionUdpateOrderMeta", { order_id, data })
          .then((response) => {
          })
          .catch((err) => { });
        const parameters = {
          cost: this.calculateAll()
        };
        this.$store
          .dispatch("actionUpdateOrderByOrderId", { order_id, parameters })
          .then((response) => {

          })
          .catch((err) => { });

      }
    },

    calculateTotalOfTotals() {
      const total = this.orderData.reduce((acc, item) => acc + item.total, 0);
      return +total.toFixed(2);
    },
    calculate3_5Percent() {
      const totalOfTotals = this.calculateTotalOfTotals();
      const result = totalOfTotals * 0.035; // 3.5% of the total
      return +result.toFixed(2);
    },

    calculateAll() {
      return (this.calculateTotalOfTotals() + this.calculate3_5Percent() - this.order.deposit_amount).toFixed(2);
      // return (this.calculateTotalOfTotals() + this.calculate3_5Percent() + Number(this.additionalDeposit) - this.order.deposit_amount).toFixed(2);
    },

    onOrderDataUpdated(updatedorderData) {
      this.orderData = updatedorderData;
      this.calculateTotals();
    },
    areAllEditsFalseDeep(obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (typeof obj[key] === "object") {
            // If the property is an object, recursively check its properties
            if (!this.areAllEditsFalseDeep(obj[key])) {
              return false;
            }
          } else if (key === "edit" && obj[key] !== false) {
            return false;
          }
        }
      }
      return true;
    },
    removeKeysFromArray(arr) {
      return arr.map((item) => {
        for (const key in item) {
          if (typeof item[key] === "object") {
            // Recursively remove keys from nested objects
            item[key] = this.removeKeysFromObject(item[key]);
          }
        }
        return item;
      });
    },
    removeKeysFromObject(obj) {
      for (const key in obj) {
        if (key === "edit" || key === "message" || key === "err") {
          // Remove the key from the object
          delete obj[key];
        } else if (typeof obj[key] === "object") {
          // Recursively remove keys from nested objects
          obj[key] = this.removeKeysFromObject(obj[key]);
        }
      }
      return obj;
    },
  },

};
</script>
