<template>
  <div class="status-select">
    <div class="select-box" @click="toggleDropdown">
      <div :class="['selected-item', selectedItem.value]">
        {{ selectedItem.text }}
      </div>
      <div class="dropdown-icon">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.49915 7.83182C4.77577 7.55519 5.22427 7.55519 5.50089 7.83182L10 12.3309L14.4992 7.83182C14.7758 7.55519 15.2243 7.55519 15.5009 7.83182C15.7775 8.10844 15.7775 8.55693 15.5009 8.83355L10.5009 13.8335C10.2243 14.1102 9.77577 14.1102 9.49915 13.8335L4.49915 8.83355C4.22253 8.55693 4.22253 8.10844 4.49915 7.83182Z"
            fill="#6B7280"
          />
        </svg>
      </div>
    </div>
    <div
      class="dropdown"
      v-if="isDropdownOpen"
      ref="dropdown"
      @click="closePopupOutside"
    >
      <div class="dropdown-content">
        <button class="dropdown-close" @click="closeSelect">
          <svg
            width="20"
            height="20"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            viewBox="0 0 100 100"
            xml:space="preserve"
          >
            <path
              fill="none"
              stroke="#e15b64"
              stroke-width="16"
              stroke-miterlimit="10"
              d="M22 78l56-56"
            />
            <path
              fill="none"
              stroke="#e15b64"
              stroke-width="16"
              stroke-miterlimit="10"
              d="M78 78L22 22"
            />
          </svg>
        </button>
        <h3>{{ item_details?.name_field }} {{ item_details?.lastname_field }} {{ item_details?.order_name }}</h3>
        <p>Select status for order.</p>
        <div
          :class="['dropdown-item', option.value]"
          v-for="option in options"
          :key="option.value"
          @click="selectItem(option)"
        >
          {{ option.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatusSelect",
  props: {
    options: {
      type: Array,
      required: true,
    },
    item_details: {
      type: Object,
    },
    value: {
      type: String,
      required: true,
    },
  },
  emits: ["input"],
  data() {
    return {
      isDropdownOpen: false,
      selectedItem: this.getSelectedOption(),
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    closeSelect() {
      this.isDropdownOpen = false;
    },
    selectItem(option) {
      this.selectedItem = option;
      this.$emit("input", option.value);
      this.isDropdownOpen = false;
    },
    getSelectedOption() {
      return this.options.find((option) => option.value === this.value) || {};
    },

    closePopupOutside(event) {
      const dropdown = this.$refs.dropdown;
      if (event.target === dropdown) {
        this.isDropdownOpen = false;
      }
    },
  },
};
</script>
