export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {

    async checkInvitedEmails({dispatch}, email_arr) {
      try {
        const request = await dispatch('actionRequest', {
          uri: '/acc-check-invited-emails',
          method: 'POST',
          post_data: {emails: email_arr}
        });
        const result = request.result;
        return Promise.resolve(result);
      } catch (err) {
        console.error(err);
      }
    },

    async inviteNewMembers({dispatch}, email_arr) {
      try {
        const request = await dispatch('actionRequest', {
          uri: '/acc-invite-new-members',
          method: 'POST',
          post_data: {emails: email_arr}
        });
        const result = request.result;
        return Promise.resolve(result);
      } catch (err) {
        console.error(err);
      }
    },

    async inviteNoPrivateOneMember({dispatch}, data) {
      try {
        const request = await dispatch('actionRequest', {
          uri: '/acc-invite-no-private-one-member',
          method: 'POST',
          authorization: false,
          post_data: data
        });
        const result = request.result;
        return Promise.resolve(result);
      } catch (err) {
        console.error(err);
      }

    },

    async actionRegistrationOneInvitedMember({dispatch}, data) {
      try {
        const request = await dispatch('actionRequest', {
          uri: '/acc-registration-one-invited-member',
          method: 'POST',
          authorization: false,
          post_data: data
        });
        const result = request.result;
        localStorage.setItem('token', result.token);
        dispatch('actionGetUserByToken');
        return Promise.resolve();
      } catch (err) {
        console.error(err);
      }
    },

    async actionCreateInviteLink({dispatch}) {
      try {
        const request = await dispatch('actionRequest', {
          uri: '/acc-create-invite-link',
        });
        const result = request.result;
        const link = result.link;
        return Promise.resolve(link);
      } catch (err) {
        console.error(err);
      }
    },

    async actionGetDataByInvitedToken({dispatch}, token) {
      try {
        const request = await dispatch('actionRequest', {
          uri: '/acc-get-data-by-invited-link/' + token,
          authorization: false
        });
        const response = request.result;
        return Promise.resolve(response);
      } catch (err) {
        console.error(err);
      }
    },

    async actionAddGoogleUserByInviteToken({dispatch, commit}, data) {
      try {
        const request = await dispatch('actionRequest', {
          uri: '/acc-sign-up-invite-google-user',
          authorization: false,
          method: 'POST',
          post_data: data
        });
        const result = request.result;
        localStorage.setItem('token', result.token);
        dispatch('actionGetUserByToken');
        return Promise.resolve();

      } catch (err) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        return Promise.reject(err)
      }
    },
    async actionAddLinkedinUserByInviteToken({dispatch, commit}, data) {
      try {
        const request = await dispatch('actionRequest', {
          uri: '/acc-sign-up-invite-linkedin-user',
          authorization: false,
          method: 'POST',
          post_data: data
        });
        const result = request.result;
        localStorage.setItem('token', result.token);
        dispatch('actionGetUserByToken');
        return Promise.resolve();

      } catch (err) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        return Promise.reject(err)
      }
    }


  }
};