<template>
  <div class="quotes-input">
    <input 
        type="text" 
        inputmode="decimal" 
        v-model="inputValue" 
        @blur="onBlur" 
        @keyup.enter="onBlur" 
        @input="onInput"
        :class="{ 'not-valid-data': obj_data?.err }" 
        :placeholder="placeholder"
        :maxlength="maxInputLength"
    />
    <span v-if="obj_data?.err" class="err">{{ obj_data?.message }}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inputValue: this.modelValue,
    };
  },
  props: {
    modelValue: {
      type: Number,
      default: null,
    },
    obj_data: {
      type: Object,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    maxInputLength: {
      type: Number,
      default: 7,
    },
  },
  emits: ["update:modelValue", "blur"],
  methods: {
    onInput(event) {
      let value = event.target.value;

      // Limit the length of input
      if (value.length > this.maxInputLength) {
        value = value.slice(0, this.maxInputLength);
      }

      // Allow only numbers and a single dot
      const regex = /^(\d+\.?\d{0,2})$/;
      if (!regex.test(value)) {
        value = this.inputValue;
      }

      this.inputValue = value;
    },

    onBlur(event) {
      const value = event.target.value;
      const numericValue = parseFloat(value);

      if (value === "" || value === null) {
        this.obj_data.err = true;
        this.obj_data.message = "Value empty.";
      } else if (isNaN(numericValue) || numericValue < 0) {
        this.obj_data.err = true;
        this.obj_data.message = "Negative or invalid value.";
      } else {
        this.obj_data.edit = false;
        this.obj_data.err = false;
        this.obj_data.message = "";

        // Emit the numeric value with two decimal places
        const formattedValue = numericValue.toFixed(2);
        this.$emit("update:modelValue", formattedValue);
        this.$emit("blur", formattedValue);
      }
    },
  },
  watch: {
    modelValue(new_val) {
      this.inputValue = new_val;
    },
  },
};
</script>

<style>
.not-valid-data {
  border-color: red;
}
.err {
  color: red;
}
</style>
