export default {

  state: {
    show_equipment_form: false,
    equipment_list: [],
    equipment_by_id: [],
    common_equipment_list: [],
    equipment_tractor_list: [],
    equipment_sme_list: [],
    equipment_trailer_list: [],
    equipment_all_parts_list: [],
    selected_checkbox_items: [],
    selected_company_id: [],
  },
  getters: {
    getShowEquipmentForm(state) {
      return state.show_equipment_form;
    },
    getSelectedCheckboxItems(state) {
      return state.selected_checkbox_items;
    },
    getSelectedCompanyId(state) {
      return state.selected_company_id;
    },
    equipmentList(state) {
      return state.equipment_list;
    },
    equipment_by_id(state) {
      return state.equipment_by_id;
    },
    commonEquipmentList(state) {
      return state.common_equipment_list;
    },
    equipmentPartsTractorList(state) {
      return state.equipment_tractor_list;
    },
    equipmentPartsSMEList(state) {
      return state.equipment_sme_list;
    },
    equipmentPartsTrailerList(state) {
      return state.equipment_trailer_list;
    },
    equipmentPartsHelperList(state) {
      return state.equipment_helper_list;
    },
    equipmentAllPartsList(state) {

      const parts = {};
      
      parts.eq_parts_all = state.equipment_all_parts_list;
      parts.eq_parts_tractor = state.equipment_all_parts_list.filter(part => part.type === 'tractor');
      parts.eq_parts_trailer = state.equipment_all_parts_list.filter(part => part.type === 'trailer');
      parts.eq_parts_helper = state.equipment_all_parts_list.filter(part => part.type === 'helper');
      parts.eq_parts_sme = state.equipment_all_parts_list.filter(part => part.type === 'sme');
      parts.eq_parts_tractor_list = [{ value: '', title: "Copy Data" }].concat(parts.eq_parts_tractor.map(item => ({
        title: item.name,
        value: item.part_id,
      })));
      parts.eq_parts_sme_list = [{ value: '', title: "Copy Data" }].concat(parts.eq_parts_sme.map(item => ({
        title: item.name,
        value: item.part_id,
      })));
      parts.eq_parts_trailer_list = [{ value: '', title: "Copy Data" }].concat(parts.eq_parts_trailer.map(item => ({
        title: item.name,
        value: item.part_id,
      })));
   
   
  
  // // Concatenate "---" with the filtered list
  // return ["---"].concat(filteredList);


      return parts;
    },
  },
  mutations: {
    mutationShowEquipmentForm(state) {
      state.show_equipment_form = !state.show_equipment_form;
    },
    mutation_get_eq_list(state, payload) {
      state.equipment_list = payload;
    },
    mutation_get_equipment_by_id(state, payload) {
      state.equipment_by_id = payload;
    },
    mutation_selected_checkbox_items(state, payload) {
      state.selected_checkbox_items = payload;
    },
    mutation_selected_company_id(state, payload) {
      state.selected_company_id = payload;
    },
    mutation_get_common_eq_list(state, payload) {
      state.common_equipment_list = payload;
    },
    mutation_add_new_eq_item_to_list(state, payload) {
      state.equipment_list = state.equipment_list.concat(payload);
    },
    mutation_pagination_get_eq_list(state, payload) {
      state.equipment_list = state.equipment_list.concat(payload);
    },
    mutation_get_eq_tractor_list(state, payload) {
      state.equipment_tractor_list = payload;
    },
    mutation_get_eq_all_list(state, payload) {
      state.equipment_all_parts_list = payload;
    },
    mutation_pagination_get_eq_tractor_list(state, payload) {
      state.equipment_tractor_list = state.equipment_tractor_list.concat(payload);
    },
    mutation_get_eq_trailer_list(state, payload) {
      state.equipment_trailer_list = payload;
    },
    mutation_pagination_get_eq_trailer_list(state, payload) {
      state.equipment_trailer_list = state.equipment_trailer_list.concat(payload);
    },
    mutation_get_eq_sme_list(state, payload) {
      state.equipment_sme_list = payload;
    },
    mutation_pagination_get_eq_sme_list(state, payload) {
      state.equipment_sme_list = state.equipment_sme_list.concat(payload);
    },
  

  },
  actions: {

    actionToggleShowEquipmentForm({ commit }) {
      commit('mutationShowEquipmentForm');
    },
    actionSelectedCheckboxItems({ commit }, ids) {
      commit('mutation_selected_checkbox_items', ids);
    },
    actionAddSelectedCompanyId({ commit }, id) {
      commit('mutation_selected_company_id', id);
    },

    
    

    /**
     *
     * @param dispatch
     * @param commit
     * @param rootGetters
     * @param equipment
     * @returns {any}
     */
    async actionAddNewEquipment({ dispatch, commit, rootGetters }, { equipment }) {
      const user_equipment = rootGetters.user_equipment;
      user_equipment.push(equipment);
      localStorage.setItem('user_equipment', JSON.stringify(user_equipment));
      dispatch('actionCheckUserEquipment');
      return Promise.resolve(user_equipment);
    },


    async actionAddNewOwnerEquipment({ dispatch, commit }, { equipment }) {
      try {
        const request = await dispatch('actionRequest', {
          uri: "/add-new-owner-equipment",
          method: 'POST',
          post_data: { equipment }
        });
        const result = request.result;
   
        return Promise.resolve(result);
      } catch (err) {
        // return Promise.reject(err);
      }
    },
    async actionAddNewPartEquipment({ dispatch, commit }, { formData }) {
      try {
        const request = await dispatch('actionRequest', {
          uri: "/add-new-part-equipment",
          method: 'POST',
          post_data: { formData }
        });
        const result = request.result;
   
        return Promise.resolve(result);
      } catch (err) {
        // return Promise.reject(err);
      }
    },
    async actionUpdatePartEquipment({ dispatch, commit }, { formData }) {
      try {
        const request = await dispatch('actionRequest', {
          uri: "/update-part-equipment",
          method: 'POST',
          post_data: { formData }
        });
        const result = request.result;
   
        return Promise.resolve(result);
      } catch (err) {
        // return Promise.reject(err);
      }
    },
  

    async actionGetOwnerEquipmentPartsByEqId({ dispatch, commit }, { eq_id }) {
      try {
        const request = await dispatch('actionRequest', {
          uri: "/get-owner-equipment-parts-by-eq-id",
          method: 'POST',
          post_data: { eq_id }
        });
        const result = request.result;
   
        return Promise.resolve(result);
      } catch (err) {
        // return Promise.reject(err);
      }
    },
    async actionGetEquipmentByEqId({ dispatch, commit }, { equipment_id }) {
      try {
        const request = await dispatch('actionRequest', {
          uri: "/get-equipment-by-eq-id",
          method: 'POST',
          post_data: { equipment_id }
        });
        const result = request.result;
        commit('mutation_get_equipment_by_id', result);
        return Promise.resolve(result);
      } catch (err) {
        // return Promise.reject(err);
      }
    },
    async actionUpdateOwnerEquipment({ dispatch, commit }, { equipment }) {
      try {
        const request = await dispatch('actionRequest', {
          uri: "/update-owner-equipment",
          method: 'POST',
          post_data: { equipment }
        });
        const result = request.result;
   
        return Promise.resolve(result);
      } catch (err) {
        // return Promise.reject(err);
      }
    },
    async actionDeleteOwnerEquipment({ dispatch, commit }, { eq_id }) {
      try {
        const request = await dispatch('actionRequest', {
          uri: "/delete-owner-equipment",
          method: 'POST',
          post_data: { eq_id }
        });
        const result = request.result;
        return Promise.resolve(result);
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async actionDeletePartEquipment({ dispatch, commit }, { part_id }) {
      try {
        const request = await dispatch('actionRequest', {
          uri: "/delete-part-equipment",
          method: 'POST',
          post_data: { part_id }
        });
        const result = request.result;
        return Promise.resolve(result);
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async actionMultiDeleteOwnerEquipment({ dispatch, commit }, { ids }) {
      try {
        const request = await dispatch('actionRequest', {
          uri: "/multi-delete-owner-equipment",
          method: 'POST',
          post_data: { ids }
        });
        const result = request.result;
        return Promise.resolve(result);
      } catch (err) {
        return Promise.reject(err);
      }
    },


    async actionGetCommonEquipmentList({ dispatch, commit }) {
      try {
        const request = await dispatch('actionRequest', {
          uri: "/get-common-equipment-list",
        });
        const result = request.result;
        const eq_list = result.equipment_common_list;
        commit('mutation_get_common_eq_list', eq_list);
        // if (event === 'change_parameters') {
        // } else if (event === 'pagination') {
        //   commit('mutation_pagination_get_eq_list', eq_list);

        // }
        return Promise.resolve(result);
      } catch (err) {
        return Promise.reject(err);
      }

    },
    async actionGetOwnerEquipmentList({ dispatch, commit }, { parameters, event = 'change_parameters' }) {
      try {
        const request = await dispatch('actionRequest', {
          uri: "/get-owner-equipment-list",
          method: 'POST',
          post_data: { parameters }
        });
        const result = request.result;
        const eq_list = result.equipment_list;

        if (event === 'change_parameters') {
          commit('mutation_get_eq_list', eq_list);
        } else if (event === 'pagination') {
          commit('mutation_pagination_get_eq_list', eq_list);

        }
        return Promise.resolve(result);
      } catch (err) {
        return Promise.reject(err);
      }

    },
    async actionGetOwnerEquipmentPartsList({ dispatch, commit }, { parameters, event = 'change_parameters' }) {
      try {
        const request = await dispatch('actionRequest', {
          uri: "/get-owner-equipment-parts-list",
          method: 'POST',
          post_data: { parameters }
        });
        const result = request.result;
        const eq_parts_list = result.eq_parts;
        const eq_parts_type = result.type;

        const mutations = {
          tractor: {
            change_parameters: 'mutation_get_eq_tractor_list',
            pagination: 'mutation_pagination_get_eq_tractor_list'
          },
          trailer: {
            change_parameters: 'mutation_get_eq_trailer_list',
            pagination: 'mutation_pagination_get_eq_trailer_list'
          },
          sme: {
            change_parameters: 'mutation_get_eq_sme_list',
            pagination: 'mutation_pagination_get_eq_sme_list'
          },
          all: {
            change_parameters: 'mutation_get_eq_all_list',
          },
        };

        if (mutations.hasOwnProperty(eq_parts_type) && mutations[eq_parts_type].hasOwnProperty(event)) {
          const mutationName = mutations[eq_parts_type][event];
          commit(mutationName, eq_parts_list);
        }

        return Promise.resolve(result);
      } catch (err) {
        // return Promise.reject(err);
      }

    }
  }
};