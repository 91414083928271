<template>
  <div id="accounting-panel" :class="['accounting-panel', 'accounting-panel-type-' + user_role]">
    <div class="container">
      <div class="accounting-panel__inner">
        <div class="accounting-panel__left">
          <a href="/">
            <img :src="logo" alt="logo" class="accounting-panel__logo" />
          </a>
          <div  v-if="user_data.first_login == `1`" class="accounting-panel__navigation">
            <ul v-if="user_role == 'owner' || user_role == 'driver'" class="accounting-panel__nav">
              <li>
                <router-link class="link-icon plus-circle-icon" :to="{ name: 'new_order' }">New Order
                </router-link>
              </li>
              <li>
                <router-link class="link-icon folder-icon" :to="{ name: 'order' }">Order History
                </router-link>
              </li>
              <li>
                <router-link class="link-icon eq-icon" :to="{ name: 'equipment' }">Equipment
                </router-link>
              </li>
            </ul>
            <ul v-if="user_role == 'manager'" class="accounting-panel__nav">
              <li>
                <router-link class="link-icon folder-icon" :to="{ name: 'admin_order' }">Order History
                </router-link>
              </li>
              <li>
                <router-link class="link-icon clients-icon" :to="{ name: 'clients' }">Clients
                </router-link>
              </li>
              <!-- <li>
                <router-link
                    class="link-icon eq-icon"
                    :to="{ name: 'admin-equipment' }"
                >Equipment
                </router-link
                >
              </li> -->
            </ul>
            <ul v-if="user_role == 'admin'" class="accounting-panel__nav">
              <li>
                <router-link class="link-icon folder-icon" :to="{ name: 'super_orders' }">Orders
                </router-link>
              </li>

              <li>
                <router-link class="link-icon clients-icon" :to="{ name: 'users' }">Users
                </router-link>
              </li>
              <li>
                <router-link class="link-icon clients-icon" :to="{ name: 'companies' }">Companies
                </router-link>
              </li>
              <li>
                <router-link
                    class="link-icon eq-icon"
                    :to="{ name: 'admin-equipment' }"
                >Equipment
                </router-link
                >
              </li>
            </ul>
            <ul class="accounting-panel__nav notifications">
              <li><a @click="onContactUsButton()" class="link-icon message-icon only" href="#"></a></li>
              <li class="header-notifications-wrapper">

                <a :class="{
                  only: true,
                  'link-icon': true,
                  'notification-true-icon': this.user_notifications.length !== 0,
                  'notification-false-icon': !checkNotifications,
                }" href="#" @click="toggleNotifications"></a>
                <header-notifications :show-notifications="showNotifications" :user_notifications="user_notifications"
                  />
              </li>
            </ul>
          </div>
        </div>
        <div class="accounting-panel__right">
          <router-link v-if="user_data.first_login == `1`"  :to="{ name: 'profile' }" class="accounting-panel__profile" exact>
            <img class="accounting-panel__picture" :src="picture_url" alt="picture" />
            <span class="accounting-panel__name">
              {{ user_data.name }} {{ user_data.lastname }}
            </span>
          </router-link>
          <a href="#" @click="onLogOut" class="link-icon sign-out-icon only"></a>
        </div>
      </div>
    </div>
    <popup-contact-us :show-popup="showPopup" @close-popup="closePopup" /> 
  </div>
</template>

<script>

import logo from "@/assets/images/logo.svg";
import profile_photo2 from "@/assets/images/profile.png";
import HeaderNotifications from "@/components/helpers/HeaderNotifications.vue";
import PopupContactUs from "@/components/header/PopupContactUs.vue";
export default {
  name: "AccountingPanel",
  components: {
    HeaderNotifications,
    PopupContactUs
  },
  data() {
    return {
      showPopup: false,
      logo,
      profile_photo2,
      showNotifications: false,
      existNotifications: false,
      user_notifications: []
    };
  },

  mounted() {
    this.startNotificationInterval();
  },
  computed: {
    user_data() {
      return this.$store.getters.userData;
    },
    user_role() {
      return this.$store.getters.userRole;
    },
    picture_url() {
      if (!this.user_data) {
        return this.profile_photo2;
      }
      if (!this.user_data.picture_name) {
        return this.profile_photo2;
      }
      const api_url = this.$store.getters.apiUrl;
      return api_url + '/user_images/' + this.user_data.picture_name;
    },
    checkNotifications() {
      let showIcon = true;
      const unreadExists = Object.values(this.user_notifications).some(notification => !notification.readed);
      if (!unreadExists) {
        showIcon = false;
      }
      return showIcon;
    }
  },
  methods: {
    onContactUsButton() {
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
    },
    onLogOut() {
      this.$store.dispatch("actionLogOut")
        .then(() => {
          setTimeout(() => {
            window.location.reload();
            window.location.href = '/';
          }, 1000);
        });
    },

    parseNotifications() {
      this.$store.dispatch('actionGetUserNotifications')
        .then(data => {
          this.user_notifications = JSON.parse(data.notifications) || [];
          // this.user_notifications.reverse();
        });

    },
    startNotificationInterval() {
      this.parseNotifications();

      this.notificationInterval = setInterval(() => {
        this.parseNotifications();
      }, 20000); // 20 seconds in milliseconds
    },
    stopNotificationInterval() {
      clearInterval(this.notificationInterval);
    },

    toggleNotifications() {
      this.showNotifications = !this.showNotifications;
      if (this.showNotifications) {
        document.addEventListener('click', this.closeNotificationsOutside);
      } else {
        document.removeEventListener('click', this.closeNotificationsOutside);
      }
    },
    closeNotificationsOutside(event) {
      const notificationsWrapper = this.$el.querySelector('.header-notifications-wrapper');
      if (!notificationsWrapper.contains(event.target)) {
        this.showNotifications = false;
      }
    },
    addBodyOverflowHidden() {
      document.body.classList.add("popup-open");
    },
    removeBodyOverflowHidden() {
      document.body.classList.remove("popup-open");
    },
  },
  beforeDestroy() {
    this.stopNotificationInterval();
  },
  watch: {

    showNotifications(newVal) {
      if (newVal) {
        this.addBodyOverflowHidden();
      } else {
        this.removeBodyOverflowHidden();
      }
    },
    showPopup(newVal) {
      if (newVal) {
        this.addBodyOverflowHidden();
      } else {
        this.removeBodyOverflowHidden();
      }
    },
  },

};
</script>
