<template>
  <div class="input-wrap">
    <div :class="['input-box', 'input-sizes', { 'not-valid-data-box': !!errorText }, { 'disabled': !!disabled }]">
      <span v-if="!!label" class="label">{{ label }}</span>
      <input v-model="inputData" type="text" :class="[inputClasses, { 'not-valid-data': !!errorText }]"
        :placeholder="placeholder" :maxlength="maxLength" :disabled="disabled" @blur="eventBlur" @input="eventInput"
        @keyup.enter="eventKeyUp" />
      <span v-if="!!errorText" class="err">{{ errorText }}</span>
      <button v-if="copy && inputData" class="copy-link" @click="copyLink">
        <svg width="64px" height="64px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" stroke-width="3"
          stroke="#6B7280" fill="none">
          <rect x="11.13" y="17.72" width="33.92" height="36.85" rx="2.5" />
          <path
            d="M19.35,14.23V13.09a3.51,3.51,0,0,1,3.33-3.66H49.54a3.51,3.51,0,0,1,3.33,3.66V42.62a3.51,3.51,0,0,1-3.33,3.66H48.39" />
        </svg>
      </button>
    </div>
    <span v-if="!!showCopied" class="show-copied">Copied!</span>
  </div>
</template>

<script>
export default {
  name: "InputSize",
  data() {

    return {

      inputData: this.modelValue,
      formattedSize: "",
      errorText: null,
      showCopied: false
    };
  },
  props: {
    modelValue: {
      type: [Number, String],
      default: null
    },
    validSw: {
      type: [Boolean, String],
    },
    label: {
      type: String,
      default: null
    },
    rules: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: null
    },
    inputClasses: {
      type: String,
      default: null
    },
    maxLength: {
      type: Number,
      default: 5
    },
    disabled: {
      type: Boolean,
      default: false
    },
    step: {
      type: Number,
      default: 1
    },
    copy: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'error', 'update'],
  methods: {
    copyLink() {
      const input = document.createElement("input");
      input.value = this.inputData;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);

      this.showCopied = true;
      setTimeout(() => {
        this.showCopied = false;
      }, 3000);
    },
    eventKeyUp(e) {
      this.eventBlur(e);
    },
    eventInput(e) {
      let v = e.target.value;
      v = v.replace(/[^0-9\-\'\"\.]/ig, '');
      this.inputData = v;
    },
    eventBlur(e) {
      let v = e.target.value;
      if (/^0+$/g.test(v) || v.length === 0) {
        e.target.value = '';
        this.inputData = '';
        this.$emit('update:modelValue', this.inputData);
        this.$emit('update', this.inputData);
        return null;
      }
      this.formatInput();
      this.validateData(this.inputData);
    },
    formatInput() {
      if (!this.inputData) {
        this.inputData = '';
      } else {
        // Remove any characters that are not digits, apostrophes, hyphens, or periods
        let input = this.inputData.replace(/[^0-9-'.]/g, '');

        // Handle decimal inputs by splitting and taking the integer part
        const decimalParts = input.split('.');
        input = decimalParts[0];

        // Split input by hyphen to check for feet and inches
        const partsWithInches = input.split('-').filter(Boolean);

        if (partsWithInches.length === 2) {
          const feet = parseInt(partsWithInches[0], 10) || 0;
          const inches = parseInt(partsWithInches[1], 10) || 0;
          this.inputData = `${feet}'${inches}"`;
        } else {
          // Split input by apostrophe to check for feet
          const parts = input.split("'").filter(Boolean);

          if (parts.length === 1) {
            // If the input is a single part, treat it as feet
            let value = parseInt(parts[0], 10) || 0;
            this.inputData = `${Math.abs(value)}'`;
          } else if (parts.length === 2) {
            // If the input is in feet and inches format
            const feet = parseInt(parts[0], 10) || 0;
            const inchesPart = parts[1].split('"').filter(Boolean);
            const inches = parseInt(inchesPart[0], 10) || 0;
            this.inputData = `${feet}'${inches}"`;
          } else {
            this.inputData = "";
          }
        }
      }
      this.$emit('update:modelValue', this.inputData);
      this.$emit('update', this.inputData);
    }

    ,
    validateData(v) {
      if (!!this.rules && this.rules.length) {
        const err_txt = this.rules
          .map(f => f(v))
          .find(itm => itm !== true);

        if (!!err_txt) {
          this.errorText = err_txt;
          this.$emit('error', true);
          return;
        } else {
          this.errorText = null;
          this.$emit('error', false);
          return;
        }
      }
      this.$emit('error', false);
    },


  },
  watch: {
    validSw() {
      this.validateData(this.inputData);
    },
    modelValue(new_data) {
      this.inputData = new_data;
    }
  }
};
</script>
