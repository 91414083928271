<template>
  <div class="edit-equipment-popup tractor-popup" ref="popupContainer" @click="closePopupOutside">
    <div id="equipment-form" class="equipment-form-edit">
      <div class="ef__wrapper">
        <button @click="closePopup" class="close-eqipment">&#x2715</button>
        <div class="ef__window">
          <div class="ef-container">
            <div class="ef-form-title">Edit Power Unit</div>
            <div class="ef-form">
              <div class="ef-form-box">
                <div class="ef-form-box ef-form-type3">
                  <div class="part-form ef-form__simple-window form-elements">
                    <div class="form-line part-form__add-fields">
                      <div class="input-box acceptable-box">
                        <span class="like-link" @click="additional = !additional">{{
                          additional
                            ? "Hide additional fields"
                            : "Show additional fields"
                        }}</span>
                      </div>
                    </div>
                    <div class="form-line form-grid r-2">
                      <div class="additional-info">Unit number/Name
                        <div class="additional-info-message">
                          The nickname/company specific number/internal tracking number
                        </div>
                      </div>
                      <input-text v-model="formData.unit_number" placeholder="W335" :valid-sw="validSw"
                        :rules="rules.requiredText" maxlength="32" @error="regError('unit_number', $event)" />
                    </div>
                    <div class="form-line form-grid r-2">
                      <div>Type*</div>
                      <input-select v-model="formData.trailer_type" :options="type_items" :valid-sw="validSw"
                        :rules="rules.requiredOnly" @error="regError('axle_quantity', $event)" />
                    </div>
                    <div class="form-line form-grid r-2">
                      <div class="additional-info make">Make*
                        <div class="additional-info-message">
                          Kenworth,
                          Peterbilt, etc.
                        </div>
                      </div>
                      <input-text v-model="formData.make" placeholder="Peterbilt" :valid-sw="validSw"
                        :rules="rules.requiredText" maxlength="32" @error="regError('make', $event)" />
                    </div>
                    <div class="form-line form-grid r-2">
                      <div>Year Model*</div>
                      <input-text v-model="formData.year_model" placeholder="2014" :valid-sw="validSw"
                        :rules="rules.year" maxlength="4" @error="regError('year_model', $event)" />
                    </div>
                    <div class="form-line form-grid r-2 vin-code">
                      <div class="vin-code">VIN Number*</div>
                      <input-text v-model="formData.vin" placeholder="VHR151EKNFE2223" :valid-sw="validSw"
                        :rules="rules.vin_code" maxlength="17" @error="regError('vin', $event)" />
                    </div>
                    <div class="form-line form-grid r-2">
                      <div>License Plate Number*</div>
                      <input-text class="license-plate" v-model="formData.license" placeholder="P56231"
                        :valid-sw="validSw" :rules="rules.license_plate" maxlength="20"
                        @error="regError('license', $event)" />
                    </div>
                    <div class="form-line form-grid r-2">
                      <div>State Registered*</div>
                      <input-select-group v-model="formData.registration_place"
                        :grouped-states="{ 'US States': usStates, 'Mexican States': mxStates, 'Canadian Provinces': caProvinces }"
                        :valid-sw="validSw" :rules="rules.requiredOnly"
                        @error="regError('registration_place', $event)" />
                    </div>
                    <div class="form-line form-grid r-2">
                      <div>Power Unit Registered Weight*</div>
                      <input-weight v-model="formData.registered_weight" placeholder="45,000 lbs"
                        :rules="rules.requiredText" @error="regError('registered_weight', $event)" :valid-sw="validSw"
                        maxlength="8" />
                    </div>
                    <div class="form-line form-grid r-2">
                      <div># Axles*</div>
                      <input-select v-model="formData.axle_quantity" :options="axles_options" :valid-sw="validSw"
                        :rules="rules.requiredOnly" @error="regError('axle_quantity', $event)" />
                    </div>
                    <div v-show="additional" class="form-line form-grid r-2">
                      <div>Axle Width</div>
                      <input-size v-model="formData.axle_width" placeholder="8’6”" maxlength="8" />
                    </div>
                    <div v-show="additional" class="form-line form-grid r-2">
                      <div>Power Unit Length</div>
                      <input-size v-model="formData.length" placeholder="25’" maxlength="8" />
                    </div>
                    <div v-show="additional" class="form-line form-grid r-2">
                      <div>Empty weight</div>
                      <input-weight v-model="formData.empty_weight" placeholder="45,000 lbs" maxlength="8" />
                    </div>
                  </div>
                </div>
                <button @click="eventFormSubmit" class="button small update-eq-button">
                  Update Power Unit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputSize from "@/components/form-elements/InputSize.vue";
import InputText from "@/components/form-elements/InputText.vue";
import InputSelect from "@/components/form-elements/InputSelect.vue";
import InputSelectGroup from "@/components/form-elements/InputSelectGroup.vue";
import InputWeight from "@/components/form-elements/InputWeight.vue";
import AxlesTable from "@/components/equipment/parts/AxlesTable.vue";
import ValidateMixin from "@/mixins/ValidateMixin";
import { FeetMathMixin } from "@/mixins/FeetMathMixin";
export default {
  name: "EditTractorForm",
  components: {
    InputWeight,
    InputSelect,
    InputSelectGroup,
    InputText,
    InputSize,
    AxlesTable,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ["update-table", "close-popup"],
  mixins: [FeetMathMixin, ValidateMixin],
  data() {
    return {
      validSw: false,
      additional: false,
      formData: {
        type: "tractor",
        unit_number: null,
        trailer_type: null,
        part_id: null,
        length: null,
        make: null,
        year_model: null,
        vin: null,
        license: null,
        registration_place: null,
        empty_weight: null,
        registered_weight: null,
        axle_width: null,
        axle_quantity: 3,
      },

      errors: {
        unit_number: null,
        make: null,
        year_model: null,
        vin: null,
        license: null,
        registered_weight: null,
        registration_place: null,
        axle_quantity: null,
      },
      type_items: [
        "Please select",
        "Automobile",
        "MH Toter",
        "Truck",
        "Truck-Tractor"
      ],
    };
  },

  computed: {
    axles_options() {
      const min = 1;
      const max = 30;
      let out = ["---"];
      for (let i = min; i <= max; i++) {
        out.push(i);
      }
      return out;
    },
    state_list() {
      let out = ["---"];
      const state_list = this.$store.getters.state_list;
      return out.concat(state_list);
    },
    usStates() {
      return this.state_list.filter(state => state.country_id === "US");
    },
    mxStates() {
      return this.state_list.filter(state => state.country_id === "MX");
    },
    caProvinces() {
      return this.state_list.filter(state => state.country_id === "CA");
    }
  },

  methods: {
    is_form_valid_new() {
      let valid = false;
      const allErrorsValid = Object.values(this.errors).some(error => error === true || error === null);
      // console.log(allErrorsValid);
      this.$store.dispatch("actionGenerateId", 32).then((id) => {
        this.validSw = id;
      });
      valid = allErrorsValid;
      return valid;
    },
    closePopup() {
      this.$emit("close-popup");
    },
    closePopupOutside(event) {
      const popupContainer = this.$refs.popupContainer;
      if (event.target === popupContainer) {
        this.closePopup();
      }
    },
    resetForm() {
      const form = this.formData;
      form.unit_number = null;
      form.trailer_type = null;
      form.part_id = null;
      form.length = null;
      form.make = null;
      form.year_model = null;
      form.vin = null;
      form.license = null;
      form.registration_place = null;
      form.empty_weight = null;
      form.registered_weight = null;
      form.axle_width = null;
      form.axle_quantity = 3;

    },
    eventFormSubmit() {
      if (this.is_form_valid_new()) return;

      const formData = this.formData;
      this.$store
        .dispatch("actionUpdatePartEquipment", { formData })
        .then(() => {
          this.resetForm();
          this.closePopup();
          this.$store.dispatch("alert", {
            message: "Power Unit updated",
            type: "info",
          });
        });
    },
  },
  watch: {
    item(new_data) {
      this.formData.unit_number = new_data.unit_number;
      this.formData.trailer_type = new_data.trailer_type;
      this.formData.part_id = new_data.part_id;
      this.formData.length = this.dec_to_feet(new_data.length);
      this.formData.make = new_data.make;
      this.formData.year_model = new_data.year_model;
      this.formData.vin = new_data.vin;
      this.formData.license = new_data.license;
      this.formData.registration_place = new_data.registration_place;
      this.formData.axle_width = this.dec_to_feet(new_data.axle_width);
      this.formData.axle_quantity = new_data.axle_quantity;
      this.formData.empty_weight = (new_data.empty_weight && new_data.empty_weight != "0") ? new_data.empty_weight + " lbs" : '';
      this.formData.registered_weight = (new_data.registered_weight && new_data.registered_weight != "0") ? new_data.registered_weight + " lbs" : '';
      this.is_form_valid_new();
    },
  },
};
</script>
