<template>
  <div 
      :class="['mail-input-wrap', 'input-box', 'input-email', {'not-valid-data-box': !!errorText}, {'disabled': !!disabled}]">
      <span class="input-icon mail-icon"></span>
    <input
        v-model="inputValue"
        type="text"
        :class="[{'not-valid-data': !!errorText}]"
        :placeholder="placeholder"
        :minlength="minlength"
        :maxlength="maxlength"
        :disabled="disabled"
        :autocomplete="autocomplete"
        @blur="eventBlur"
        @input="eventInput"
    />
    <span v-if="!!errorText" class="err">{{ errorText }}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inputValue: this.modelValue,
      errorText: null
    };
  },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    rules: {
      type: Array,
      default: () => []
    },
    minlength: {
      type: [Number, String],
      default: null
    },
    maxlength: {
      type: [Number, String],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: 'on'
    }
  },
  emits: ["update:modelValue", 'error', 'blur'],

  methods: {
    eventInput(e) {
      const value = e.target.value;
      this.errorText = null;
      this.$emit('update:modelValue', value);
    },
    eventBlur(e) {
      const value = e.target.value;
      this.$emit('blur', value);
      this.validateData(value);
    },
    validateData(v) {
      if (!!this.rules && this.rules.length) {
        const err_txt = this.rules
            .map(f => f(v))
            .find(itm => itm !== true);

        if (!!err_txt) {
          this.errorText = err_txt;
          this.$emit('error', true);
          return;
        } else {
          this.errorText = null;
          this.$emit('error', false);
          return;
        }
      }
      this.$emit('error', false);
    },
  },
  watch: {
    modelValue(new_val) {
      this.inputValue = new_val;
    }
  }
};
</script>
