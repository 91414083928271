<template>
  <div
    :class="['input-box', 'input-text', {'not-valid-data-box': !!errorText}]">
    <span v-if="!!label" class="label">{{ label }}</span>
    <textarea
      v-model="inputValue"
      type="text"
      :class="{'not-valid-data': !!errorText}"
      :placeholder="placeholder"
      :minlength="minlength"
      :maxlength="maxlength"
      :disabled="disabled"
      @blur="eventBlur"
      @input="eventInput"
      ></textarea>
    <span v-if="!!errorText" class="err">{{ errorText }}</span>
  </div>
</template>

<script>
export default {
  name: "InputTextarea",
  data() {
    return {
      inputValue: this.modelValue,
      errorText: null
    };
  },

  emits: ["update:modelValue", 'error', 'blur'],
  props: {

    modelValue: {
      type: [String,Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    validSw: {
      type: [Boolean,String],
    },
    rules: {
      type: Array,
      default: () => []
    },
    minlength: {
      type: [Number, String],
      default: null
    },
    maxlength: {
      type: [Number, String],
      default: null
    },
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean
    },
  },
  methods: {
    eventInput(e) {
      const value = e.target.value;
      this.errorText = null;
      this.$emit('update:modelValue', value);
    },
    eventBlur(e) {
      const value = e.target.value;
      this.$emit('blur', value);
      this.validateData(value);
    },

    validateData(v) {
      if (!!this.rules && this.rules.length) {
        const err_txt = this.rules
          .map(f => f(v))
          .find(itm => itm !== true);

        if (!!err_txt) {
          this.errorText = err_txt;
          this.$emit('error', true);
          return;
        } else {
          this.errorText = null;
          this.$emit('error', false);
          return;
        }
      }
      this.$emit('error', false);
    },

  },
  watch: {
    validSw(v) {
      this.validateData(this.inputValue);
    },
    modelValue(new_val) {
        this.inputValue = new_val;
    }
  }
};
</script>