<template>
    <div id="choose-states">
        <button type="button" @click="toggleChooseStates"
            :class="{ active: this.chooseStatesOpen, 'button-choose-states': true, 'not-valid-data-box': checkStates && states.length < 1 }">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M4.99921 7.83243C5.27584 7.5558 5.72433 7.5558 6.00095 7.83243L10.5001 12.3316L14.9992 7.83243C15.2758 7.5558 15.7243 7.5558 16.0009 7.83243C16.2776 8.10905 16.2776 8.55754 16.0009 8.83416L11.0009 13.8342C10.7243 14.1108 10.2758 14.1108 9.99921 13.8342L4.99921 8.83416C4.72259 8.55754 4.72259 8.10905 4.99921 7.83243Z"
                    fill="#00101E" />
            </svg>
            <span>Choose the states you need permits for</span>
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M4.99921 7.83243C5.27584 7.5558 5.72433 7.5558 6.00095 7.83243L10.5001 12.3316L14.9992 7.83243C15.2758 7.5558 15.7243 7.5558 16.0009 7.83243C16.2776 8.10905 16.2776 8.55754 16.0009 8.83416L11.0009 13.8342C10.7243 14.1108 10.2758 14.1108 9.99921 13.8342L4.99921 8.83416C4.72259 8.55754 4.72259 8.10905 4.99921 7.83243Z"
                    fill="#00101E" />
            </svg>
        </button>
        <span class="err" v-if="checkStates && states.length < 1">Please add the states for which you need
            permits</span>
        <div v-if="chooseStatesOpen" class="route-states">
            <div class="route-states__inner">
                <div class="route-states__header">
                    <div class="route-states__header__item route-states__header__num">#</div>
                    <div class="route-states__header__item route-states__header__state">State*</div>
                    <div class="route-states__header__item route-states__header__pref__routes">Preferred routes</div>
                    <div class="route-states__header__item route-states__header__date">Date*</div>
                    <div class="route-states__header__item route-states__header__deposit">Deposit</div>
                </div>
                <div class="route-states__items">
                    <div class="route-states__item" v-for="(item, index) in states" :key="index">
                        <div class="route-states__num"><span>State #</span>{{ (index + 1 >= 10) ? (index + 1) : ("0" +
                            (index + 1)) }}</div>
                        <div class="route-states__state">
                            <input-select v-model="item.state" :options="state_list" :valid-sw="validSw"
                                :rules="rules.requiredOnly" :disabled="disabled" @error="item.error = $event" />
                        </div>
                        <div class="route-states__pref__routes"><input-text :disabled="disabled"
                                v-model="item.preferred_routes" placeholder="Preferred routes" maxlength="30" /></div>
                        <div class="route-states__date">

                            <input-date :valid-sw="validSw" :min="this.originDateValue" :max="this.destinationDateValue"
                                :disabled="disabled" v-model="item.date" :futureDate="this.destinationDate"
                                :rules="rules.dateRules" @error="(err) => {
                                    item.error_date = $event
                                }" />
                        </div>
                        <div class="route-states__deposit">
                            <div>${{ this.$DEPOSIT_RATE }}</div>
                            <button v-if="!disabled" @click="removeStateConfirmWindow(index)"
                                class="quotes-button-remove"></button>
                        </div>
                    </div>
                </div>
                <div class="route-states__footer">

                    <div class="route-states__footer__item route-states__footer__num"></div>
                    <div class="route-states__footer__item route-states__footer__state"></div>
                    <div class="route-states__footer__item route-states__footer__pref__routes"></div>
                    <div class="route-states__footer-wrap">
                        <div class="route-states__footer__item route-states__footer__date">
                            <strong>Deposit Amount</strong>
                        </div>
                        <div class="route-states__footer__item route-states__footer__deposit">
                            <strong>${{ depositValue }}</strong>
                        </div>
                    </div>
                </div>
                <button v-if="!disabled" class="button button-add-state" @click="addNewState()">Add State
                </button>
            </div>
        </div>
        <div v-else>
            <div v-if="choosedStatesShow || states.length > 1" class="choose-state-info">
                <div class="choose-state-selected">
                    States selected: {{ states.length }}
                </div>
                <div class="choose-state-all-deposit">
                    Deposit Amount: <span>${{ depositValue }}</span>
                </div>
            </div>
        </div>
    </div>
    <confirmation-dialog v-if="showConfirmDialog" content="Are you sure you want to remove this state/country?"
        @cancel="cancelRemoveState" @confirm="confirmRemoveState" />
</template>

<script>
import InputSelect from "@/components/form-elements/InputSelect.vue";
import ValidateMixin from "@/mixins/ValidateMixin";
import InputText from "@/components/form-elements/InputText.vue";
import InputDate from "@/components/form-elements/InputDate.vue";
import SlotTable from "@/components/SlotTable.vue";
import ConfirmationDialog from "@/components/helpers/ConfirmationDialog.vue";
import { DEPOSIT_RATE } from '@/config';
export default {
    name: "ChooseStates",
    mixins: [ValidateMixin],
    components: {
        SlotTable,
        InputText,
        ConfirmationDialog,
        InputDate,
        InputSelect
    },
    props: {
        validSw: {
            type: [Boolean, String],
        },
        states_list_data: {
            type: [Object, String],
        },
        originDate: {
            type: String,
        },
        checkDates: {
            type: Boolean,
        },
        checkStates: {
            type: Boolean,
        },
        destinationDate: {
            type: String,
        },
        disabled: {
            type: Boolean
        }
    },
    computed: {
        state_list() {
            const usStates = this.$store.getters.state_list
                .filter(state => state.country_id === "US")
                .map(state => state.name);

            usStates.unshift("Select State");

            return usStates;
        },
        depositValue() {
            const depositValue = DEPOSIT_RATE;
            return this.states.length * depositValue;
        },
        user_data() {
            return this.$store.getters.userData;
        },
    },

    data() {
        return {
            futureDateValue: null,
            destinationDateValue: null,
            originDateValue: null,
            showConfirmDialog: false,
            stateIndex: null,
            chooseStatesOpen: false,
            checkDatesValue: false,
            checkStatesValue: false,
            choosedStatesShow: false,
            states: this.states_list_data ? this.states_list_data : [{
                state: null,
                preferred_routes: "",
                date: null,
                error: false,
                error_date: false,
            }],

            newState: {
                state: null,
                preferred_routes: "",
                date: null,
                error: false,
                error_date: false,
            },



        }
    },
    emits: ['choosed-states', 'check-states'],
    methods: {
        getCurrentDate() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
            const day = String(today.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        toggleChooseStates() {
            if (!this.checkDatesValue) {
                this.choosedStatesShow = false;
                this.chooseStatesOpen = false;
                return;
            }
            this.choosedStatesShow = true;
            this.chooseStatesOpen = !this.chooseStatesOpen;
        },
        addNewState() {
            const template = { ...this.newState };
            this.states.push(template);
            this.$emit('choosed-states', this.states, this.depositValue);
        },
        removeStateConfirmWindow(index) {
            this.stateIndex = index;
            this.showConfirmDialog = true;
            this.$emit('check-states');
        },
        cancelRemoveState() {
            this.stateIndex = null;
            this.showConfirmDialog = false;
        },
        confirmRemoveState() {
            this.removeState(this.stateIndex);
            this.stateIndex = null;
            this.showConfirmDialog = false;
        },
        removeState(index) {
            this.states.splice(index, 1);
        },
    },
    watch: {
        checkDates(v) {
            this.checkDatesValue = v;
            if (!this.checkDatesValue) {
                this.choosedStatesShow = false;
                this.chooseStatesOpen = false;
                this.states_list_data.forEach(state => {
                    state.date = "";
                });
                return;
            }
        },
        checkStates(v) {
        },
        originDate(v) {
            this.originDateValue = v
            if (!this.originDateValue) {
                this.originDateValue = this.getCurrentDate()
            }
            const originDate = new Date(this.originDateValue)

            const formatDate = (date) => {
                if (isNaN(date.getTime())) {
                    return ''
                }
                let d = date.getDate()
                let m = date.getMonth() + 1
                let y = date.getFullYear()
                if (d < 10) d = '0' + d
                if (m < 10) m = '0' + m
                return `${y}-${m}-${d}`
            }

            this.states_list_data.forEach(state => {
                const stateDate = new Date(state.date)

                if (originDate > stateDate) {
                    state.date = formatDate(originDate)
                }
            })
        }

        ,
        destinationDate(v) {
           

            this.destinationDateValue = v
            if (!this.destinationDateValue) {
                this.destinationDateValue = ''
            }
            const destinationDate = new Date(this.destinationDateValue)
           
            const formatDate = (date) => {
                if (isNaN(date.getTime())) {
                    return ''
                }
                let d = date.getDate()
                let m = date.getMonth() + 1
                let y = date.getFullYear()
                if (d < 10) d = '0' + d
                if (m < 10) m = '0' + m
                return `${y}-${m}-${d}`
            }

            this.states_list_data.forEach(state => {
                const stateDate = new Date(state.date)
                if (destinationDate < stateDate) {
                    state.date = formatDate(stateDate)
                } 
            })
        }

        ,
        depositValue() {
            this.$emit('choosed-states', this.states, this.depositValue);
            this.$emit('check-states');
        },
        states_list_data(v) {
            this.states = v;
            this.$emit('choosed-states', this.states, this.depositValue);
            this.$emit('check-states');
        },
    }

}
</script>
