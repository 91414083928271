<template>
  <div id="calculator">
    <div class="clc">
      <div class="clc__inner">
        <div @click="onSelectCopyWindow" v-if="step === 1 && mode === 'new'" class="copy-order-button"><span><svg
              width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.00033 0.291992C6.39153 0.291992 6.70866 0.609124 6.70866 1.00033V5.29199H11.0003C11.3915 5.29199 11.7087 5.60912 11.7087 6.00033C11.7087 6.39153 11.3915 6.70866 11.0003 6.70866H6.70866V11.0003C6.70866 11.3915 6.39153 11.7087 6.00033 11.7087C5.60912 11.7087 5.29199 11.3915 5.29199 11.0003V6.70866H1.00033C0.609124 6.70866 0.291992 6.39153 0.291992 6.00033C0.291992 5.60912 0.609124 5.29199 1.00033 5.29199H5.29199V1.00033C5.29199 0.609124 5.60912 0.291992 6.00033 0.291992Z"
                fill="white" />
            </svg></span>
          Copy Order
        </div>
        <div class="testing-mode" v-if="is_test">Testing mode <div class="testing-mode-delete"
            v-if="order_by_id.order_id" @click="deleteOrder(order_by_id)">Delete</div>
        </div>
        <div v-if="step === 1 && openSelectCopyWindow && copyData" class="copyOrder-block">
          <label for="copyOrderId">Copy From:</label>
          <div class="input-box input-select">
           
            <select v-model="copyOrderId" id="copyOrderId" @change="copyDataFromOrder">
              <option value="">—</option>
              <option v-for="option in copyOrderList" :key="option.order_id" :value="option.order_id">
                {{ option.is_test == 1 ? "TEST" : '' }} {{  option.name_field }} {{  option.lastname_field }} {{ option.order_name }}
              </option>
            </select>
          </div>
        </div>
        <step-tabs :step="step" @change-step="eventChangeStep" />

        <div class="clc__step-forms step-forms">

          <div :class="['step-forms__inner', 'show-step-' + step, 'show-step-type-' + user_data.role]">

            <step-form-one class="step-form step-form-1" @form-valid="formOneValid" @next-step="eventChangeStep"
              @update-states-data="(states, deposit_amount) => {
                this.formData.choosed_states = states;
                this.formData.deposit_amount = deposit_amount;

              }" @update-step-one="formDataStepOne" @update-route="updateRoute" :mode="mode"
              :copy-order-data="copyData" :selected-new-equipment="selectedNewEquipment" />
            <step-form-two class="step-form step-form-2" :mode="mode" @updateStepTwo="formDataStepTwo" />

            <step-form-three ref="stepThreeCompoment" :incoming-data="formData" class="step-form step-form-3" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <equipment-form :mode="mode" :selectedEquipment="this.formData.vehicle_combination" :selectedAxles="selectedAxles"
    @update-table="updateEquipmentSelector" v-show="show_equipment_form" @close="closeEquipmentForm" />
  <spinner :loading="isLoading" />
  <confirmation-dialog v-if="showConfirmDialog" content="Are you sure you want to delete this test order?"
    @cancel="cancelTestDeleteOrder" @confirm="confirmDeleteTestOrder" />
</template>

<script>
import StepTabs from "@/components/calculator/parts/StepTabs.vue";
import Spinner from "@/components/helpers/Spinner.vue";
import EquipmentForm from "@/components/equipment/EquipmentForm.vue";
import StepFormOne from "@/components/calculator/parts/StepFormOne.vue";
import StepFormTwo from "@/components/calculator/parts/StepFormTwo.vue";
import StepFormThree from "@/components/calculator/parts/StepFormThree.vue";
import InputSelect from "@/components/form-elements/InputSelect.vue";
import ConfirmationDialog from "@/components/helpers/ConfirmationDialog.vue";
export default {
  name: "Calculator",
  components: { StepFormOne, StepFormTwo, StepFormThree, EquipmentForm, ConfirmationDialog, Spinner, StepTabs, InputSelect },
  props: {
    mode: {
      type: String,
      default: "new",
      required: true,
    },
    mapPositions: {
      type: String,
    },
  },
  data() {
    return {
      copyOrderList: [],
      copyData: {
      },
      showConfirmDialog: false,
      activeOrder: null,
      copyOrderId: "",
      isLoading: false,
      directionsService: null,
      order_id: this.$route.params.order_id,
      formData: {
        origin: null,
        origin_date: null,
        destination: null,
        destination_date: null,
        deposit_amount: 0,
        road_type: null,
        eq_id: null,
        serial: null,
        model: null,
        axle_quantity: null,
        axles_values: [],
        choosed_states: [],
        how_loaded: [],
        make: null,
        copy: false,
        dimensions: {
          length: null,
          width: null,
          height: null,
          weight: null,
        },
        overall_dimensions: {
          length: null,
          width: null,
          height: null,
          weight: null,
        },
        overhang_front: null,
        overhang_rear: null,
        kingpin: null,
        vehicle_combination: null,
        load_time: null,
        map_positions: this.mapPositions,
        lastname: null,
        name: null,
        phone: null,
        email: null,
        load_time: null,
        company: null,
      },
      step: 1,
      is_forms_valid: {
        1: false,
        2: false,
        3: false,
      },
      step_three_data: {
        order: this.$store.getters.order_by_id,
        order_id: this.$route.params.order_id,
      },

      queryParameters: {
        offset: 0,
        limit: 100,
        orderBy: "name",
        order: "DESC",
        company_id: this.$store.getters.userData.company_id,
      },

      selectedNewEquipment: null,
      selectedAxles: null,
      openSelectCopyWindow: false
    };
  },

  computed: {
    is_test() {
      return this.formData.description === 'test123'
    },
    user_data() {
      return this.$store.getters.userData;
    },
    show_equipment_form() {
      return this.$store.getters.getShowEquipmentForm;
    },
    order_by_id() {
      return this.$store.getters.order_by_id;
    },

  },
  created() {

    this.getOwneEquipmentList();
    this.getEquipmentPartsList();
    this.getOrdersList();

  },

  emits: ["calcRoute"],
  methods: {

    onSelectCopyWindow() {
      this.openSelectCopyWindow = !this.openSelectCopyWindow;
    },
    copyDataFromOrder(e) {
      this.formData.copy = true;
      this.copyData = this.copyOrderList.find(
        (order) => order.order_id === this.copyOrderId
      );
      if (!this.copyData) {
        this.formData.copy = false;
      }
      this.openSelectCopyWindow = false;
    },
    getOrdersList() {
      const parameters = {
        offset: 0,
        limit: 999,
        orderBy: "created_at",
        order: "DESC",
        archive: 'all',
        origin: null,
        destination: null,
        status: "",
        company_id: this.$store.getters.userData.company_id,
      };
      this.$store
        .dispatch("actionGetOrdersListByCompanyId", { parameters })
        .then((response) => {
          this.copyOrderList = response.orders_list;
        })
        .catch((err) => { });
    },
    formOneValid(is_valid) {
      this.is_forms_valid["1"] = is_valid;
    },
    updateEquipmentSelector(equipment) {

      this.selectedNewEquipment = equipment;
      this.getOwneEquipmentList();
      this.getEquipmentPartsList();
    },

    closeEquipmentForm() {
      let scrollPosition = parseInt(document.body.style.top || '0') * -1;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';

      // Restore the scroll position
      window.scrollTo(0, scrollPosition);
      this.$store.dispatch("actionToggleShowEquipmentForm");
    },
    getOwneEquipmentList() {
      const parameters = this.queryParameters;
      this.$store
        .dispatch("actionGetOwnerEquipmentList", { parameters })
        .then((response) => { })
        .catch((err) => { });
    },
    getEquipmentPartsList() {
      const parameters = {
        offset: 0,
        limit: 999,
        orderBy: "name",
        order: "DESC",
        type: "all",
        company_id: this.$store.getters.userData.company.company_id,
      };
      this.$store
        .dispatch("actionGetOwnerEquipmentPartsList", { parameters })
        .then((response) => { })
        .catch((err) => { });
    },

    updateRoute(data) {
      this.$emit("calcRoute", data);
    },

    createOrder(data) {

      this.isLoading = true;
      const parameters = {
        ...this.formData
      };
      // console.log(parameters, 'parameters');
      const equipment = { ...this.formData };

      equipment.form_type = 1;


      if (!this.formData.copy) {
        this.$store
          .dispatch("actionAddNewOwnerEquipment", { equipment });
      }

      this.$store.dispatch("actionCreateNewOrder", {
        parameters,
      })
        .then((response) => {
          const order_id = response.order_id;
          const meta_key = '_map_positions';
          const meta_value = JSON.stringify(this.mapPositions);
          if (meta_value !== null) {
            this.$store
              .dispatch("actionUpdateMetaValueOrderMeta", { order_id, meta_key, meta_value })
              .catch((err) => { });
          }

          this.isLoading = false;
          this.$router.push(`/order/${order_id}`);
        })


    },

    updateOrder(data) {
      const parameters = this.formData;
      Object.assign(parameters, data);
      // console.log(parameters);
      const order_id = this.order_id;
      this.$store
        .dispatch("actionUpdateOrderByOrderId", { order_id, parameters })
        .then((response) => { window.location.reload() })
        .catch((err) => { window.location.reload() });
    },
    eventChangeStep(stepNum) {
      if (this.order_by_id.order_status == 'deposit_needed' && stepNum === 3) return;
      const cur_step_num = +this.step;
      if (stepNum < cur_step_num) {
        this.step = stepNum;
      } else {
        const is_form_valid = { ...this.is_forms_valid };
        const cur_form_valid = is_form_valid[cur_step_num.toString()];
        if (cur_form_valid) {
          this.step = stepNum;
        }
      }
      if (stepNum.toString() === "1") {
        this.is_forms_valid["2"] = false;
        this.is_forms_valid["3"] = false;
      }
      if (stepNum.toString() === "2") {

        if (this.order_by_id.order_status === 'deposit_paid' ||
          this.order_by_id.order_status === 'deposit_reminder' ||
          this.order_by_id.order_status === 'payment_needed' ||
          this.order_by_id.order_status === 'payment_reminder' ||
          this.order_by_id.order_status === 'payment_completed'
        ) {
          //   this.order_by_id.order_status
          this.step = 2;
        }
      }
      if (stepNum.toString() === "3") {
        if (this.order_by_id.order_status === 'deposit_paid' ||
          this.order_by_id.order_status === 'deposit_reminder' ||
          this.order_by_id.order_status === 'payment_needed' ||
          this.order_by_id.order_status === 'payment_completed' ||
          this.order_by_id.order_status === 'payment_reminder'
        ) {
          this.step = 3;
        }
      }
    },

    formDataStepOne(data) {
      const fd = this.formData;

      fd.origin = data.origin;
      fd.origin_date = data.origin_date;
      fd.description = data.description;
      fd.destination = data.destination;
      fd.destination_date = data.destination_date;
      fd.road_type = data.road_type;
      fd.deposit_amount = data.deposit_amount;
      fd.eq_id = data.eq_id;
      fd.vehicle_combination = data.vehicle_combination;
      fd.choosed_states = data.choosed_states;
      fd.how_loaded = data.how_loaded;
      fd.axles_values = data.axles_values;
      fd.axle_quantity = data.axles_values.length;
      fd.make = data.make;
      fd.model = data.model;
      fd.serial = data.serial;

      fd.dimensions.length = data.dimensions.length;
      fd.dimensions.width = data.dimensions.width;
      fd.dimensions.height = data.dimensions.height;
      fd.dimensions.weight = data.dimensions.weight;

      fd.overall_dimensions.length = data.overall_dimensions.length;
      fd.overall_dimensions.width = data.overall_dimensions.width;
      fd.overall_dimensions.height = data.overall_dimensions.height;
      fd.overall_dimensions.weight = data.overall_dimensions.weight;

      fd.overhang_front = data.overhang_front;
      fd.overhang_rear = data.overhang_rear;
      fd.kingpin = data.kingpin;

      fd.name_field = data.name;
      fd.lastname_field = data.lastname;
      fd.phone_field = data.phone;
      fd.email_field = data.email;
      fd.company_field = data.company;

      this.selectedAxles = data.axles_values;
      // console.log(fd);
      fd.is_test = 0;
      if (data.description === 'test123') {
        fd.is_test = 1;
      }
    },

    formDataStepTwo(data) {

      this.formData.load_time = data.load_time;
      const form_data = { ...this.formData };
      if (!!this.order_id) {
        this.updateOrder(form_data);
        this.step = 3;

      } else {
        this.createOrder(form_data);
      }

    },

    deleteOrder(order) {
      this.activeOrder = order;
      this.showConfirmDialog = true;
    },
    cancelTestDeleteOrder() {
      this.activeOrder = null;
      this.showConfirmDialog = false;
    },
    confirmDeleteTestOrder() {
      const order_id = this.activeOrder.order_id;
      this.$store
        .dispatch("actionDeleteOrder", { order_id })
        .then((response) => {
          console.log(response);
          this.showConfirmDialog = false;
          if (this.user_data.role === 'manager') {

            this.$router.push({ name: "admin_order" });
          }
          else if (this.user_data.role === 'admin') {

            this.$router.push({ name: "users" });
          }
          else {
            this.$router.push({ name: "new_order" });

          }
        })
        .catch((err) => { });
    }

  },
  watch: {
    order_by_id: {
      handler(newValue) {
        this.formData.vehicle_combination = JSON.parse(newValue.vehicle_combination);
        this.formData.axles_values = JSON.parse(newValue.axles_values);
        this.formData.axle_quantity = newValue.axle_quantity;
        this.selectedAxles = JSON.parse(newValue.axles_values);

        if (newValue.order_status === 'deposit_needed' || newValue.order_status === 'deposit_reminder' || newValue.order_status === 'payment_needed') {
          this.step = 3;
        }

      },
      deep: true,
    },
    selectedEquipment() {

    },
    selectedAxles() {

    },
    deposit_amount(v) {
      this.deposit_amount = v;
    }
  },
};
</script>
<style>
.testing-mode {
  color: red;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.testing-mode-delete {
  cursor: pointer;
}
</style>