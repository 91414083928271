<template>
  <table>
    <thead>
      <slot name="header"></slot>
    </thead>
    <tbody>
      <slot name="body" v-for="(item, index) in data" :index="index" :item="item" :key="item.id"></slot>
      <slot name="footer">
      </slot>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'SlotTable',
  props: ['data'],
  data() {
    return {
    };
  },
 
};
</script>
