<template>
  <div class="template">
    <accounting-panel/>
    <main class="main">
      <div class="container">
        Driver Template
        <router-view/>
      </div>
    </main>
  </div>
</template>

<script>

import AccountingPanel from "@/components/header/AccountingPanel.vue";

export default {
  name: "DriverTemplate",
  components: {AccountingPanel},
  data() {
    return {}
  }


}
</script>