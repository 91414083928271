<template>

    <transition name="slide-in-from-top">
        <div v-if="showPopup" class="user-popup" @click="closePopupOutside" ref="popupContainer">
            <div class="popup-content">
                <button class="contact-withus-popup-close" @click="closePopup">
                    <svg width="20" height="20" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0"
                        viewBox="0 0 100 100" xml:space="preserve">
                        <path fill="none" stroke="#e15b64" stroke-width="16" stroke-miterlimit="10" d="M22 78l56-56" />
                        <path fill="none" stroke="#e15b64" stroke-width="16" stroke-miterlimit="10" d="M78 78L22 22" />
                    </svg>
                </button>
                <div class="settings-accounting-text">
                    <h3>User, {{ formData.name }}</h3>

                </div>
                <div class="form-element-wrap">
                    <div class="form-element phone">
                        <label>Name*</label>
                        <input-text v-model="formData.name" placeholder="Enter name" :valid-sw="validSw"
                            :rules="rules.requiredText" maxlength="32" @error="regError('name', $event)" />
                    </div>
                    <div class="form-element phone">
                        <label>Phone*</label>
                        <input-phone v-model="formData.phone" placeholder="Enter phone" :valid-sw="validSw"
                            :rules="rules.phone" maxlength="14" @error="regError('phone', $event)" />
                    </div>

                </div>
                <div class="settings-accounting-footer">
                    <button @click="updateInfo" class="button small">Update</button>
                </div>

            </div>

        </div>
    </transition>


</template>

<script>
import InputPhone from "@/components/form-elements/InputPhone.vue";
import InputPassword from "@/components/form-elements/InputPassword.vue";
import InputText from "@/components/form-elements/InputText.vue";
import InputNumber from "@/components/form-elements/InputNumber.vue";
import ValidateMixin from "@/mixins/ValidateMixin";
export default {
    components: {
        InputPhone,
        InputPassword,
        InputNumber,
        InputText,
    },
    name: "UserEdit",
    computed: {
        user_data() {
            return this.$store.getters.userData;
        },
    },
    props: {
        showPopup: {
            type: Boolean,
            default: false,
        },
        user_id: {
            type: String,
        },
    },
    mixins: [ValidateMixin],
    data() {
        return {
            validSw: false,
            formData: {
                name: null,
                phone: null,
            },
            errors: {
                name: false,
                phone: false,
            },

        }
    },
    methods: {
        getUsersList() {
            const parameters = {
                searchValue: null,
                offset: 0,
                limit: 99999,
                orderBy: "name",
                order: "DESC",
            };
            this.$store
                .dispatch("actionGetUsersList", { parameters })
                .then((response) => {
                    const foundUser = response.users_list.find(user => user.user_id === this.user_id);
                    this.formData.name = foundUser.name;
                    this.formData.phone = foundUser.phone;
                })
                .catch((err) => { });
        },
        updateInfo() {
            if (this.is_form_valid_new()) return;
            const data = { ...this.formData };
            const user_id = this.user_id;

            const parameters = { user_id, data };
            this.$store
                .dispatch("actionUpdateUser", { parameters })
                .then(user => {
                    this.getUsersList();
                    this.closePopup();
                    
                });

        },
        is_form_valid_new() {
            let valid = false;
            this.$store.dispatch("actionGenerateId", 32).then((id) => {
                this.validSw = id;
            });
            const allErrorsValid = Object.values(this.errors).some(error => error === true || error === null);
            valid = allErrorsValid;
            return valid;
        },
        closePopup() {
            this.removeBodyOverflowHidden()
            this.$emit("close-popup");
        },
        addBodyOverflowHidden() {
            document.body.classList.add("popup-open");
        },
        removeBodyOverflowHidden() {
            document.body.classList.remove("popup-open");
        },
        closePopupOutside(event) {
            const popupContainer = this.$refs.popupContainer;

            if (event.target === popupContainer) {
                this.$emit("close-popup");
            }
        },
    }
};
</script>