<template>
  <div class="input-wrap">
    <div :class="['input-box', 'input-date', { 'not-valid-data-box': !!errorText }, { 'disabled': !!disabled }]">
      <span v-if="!!label" class="label">{{ label }}</span>
      <input ref="dateInput" v-model="inputValue" type="date" :class="{ 'not-valid-data': !!errorText }"
        :placeholder="computedPlaceholder" :minlength="minlength" :maxlength="maxlength" :disabled="disabled" :min="min"
        :max="max" @blur="eventBlur" @input="eventInput" />
      <span v-if="!!errorText" class="err">{{ errorText }}</span>
      <button v-if="copy && inputValue" class="copy-link" @click="copyLink">
        <svg width="64px" height="64px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" stroke-width="3"
          stroke="#6B7280" fill="none">
          <rect x="11.13" y="17.72" width="33.92" height="36.85" rx="2.5" />
          <path
            d="M19.35,14.23V13.09a3.51,3.51,0,0,1,3.33-3.66H49.54a3.51,3.51,0,0,1,3.33,3.66V42.62a3.51,3.51,0,0,1-3.33,3.66H48.39" />
        </svg>
      </button>
    </div>
    <span v-if="!!showCopied" class="show-copied">Copied!</span>
  </div>
</template>

<script>
export default {
  name: "InputDate",
  data() {
    return {
      inputValue: this.modelValue,
      errorText: null,
      showCopied: false,
      today: new Date().toISOString().split('T')[0]
    };
  },

  emits: ["update:modelValue", 'error', 'blur', 'inputCleared'],
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    futureDate: {
      type: String,
    },
    rules: {
      type: Array,
      default: () => []
    },
    minlength: {
      type: [Number, String],
      default: null
    },
    min: {
      type: [Number, String],
    },
    max: {
      type: [Number, String],
    },
    maxlength: {
      type: [Number, String],
      default: null
    },
    label: {
      type: String,
      default: null,
    },
    validSw: {
      type: [Boolean, String],
    },
    disabled: {
      type: Boolean
    },
    copy: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedPlaceholder() {
      return this.inputValue ? '' : this.placeholder || this.today;
    }
  },
  mounted() {
    this.setDatePlaceholder();
    this.$refs.dateInput.addEventListener("change", this.handleDateChange);
  },
  beforeUnmount() {
    this.$refs.dateInput.removeEventListener("change", this.handleDateChange);
  },
  methods: {
    setDatePlaceholder() {
      const inputDate = this.$refs.dateInput;
      if (inputDate) {
        inputDate.setAttribute("placeholder", this.computedPlaceholder);
      }
    },
    handleDateChange(e) {
      const inputDate = this.$refs.dateInput;
      if (e.target.value !== "") {
        inputDate.removeAttribute("placeholder");
      } else {
        inputDate.setAttribute("placeholder", this.computedPlaceholder);
      }
    },
    copyLink() {
      const input = document.createElement("input");
      input.value = this.inputValue;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);

      this.showCopied = true;
      setTimeout(() => {
        this.showCopied = false;
      }, 3000);
    },
    eventInput(e) {
      const value = e.target.value;
      this.errorText = null;
      this.$emit('update:modelValue', value);
    },
    eventBlur(e) {
      const value = e.target.value;
      this.$emit('blur', value);
      this.validateData(value);
     
    },
    checkFutureDate(v) {
      if(this.futureDate) {

        let inputDate = new Date(v)
        let futureDate = new Date(this.futureDate)
     
        if (inputDate > futureDate) {
          this.errorText = 'Future Date is not allowed';
          return true;
        } 
      }
      return false;
    },
    validateData(v) {
      
      if(this.checkFutureDate(v)) return;

      if (!!this.rules && this.rules.length) {
        const err_txt = this.rules
          .map(f => f(v))
          .find(itm => itm !== true);

        if (!!err_txt) {
          this.errorText = err_txt;
          this.$emit('error', true);
          return;
        } else {
          this.errorText = null;
          this.$emit('error', false);
          return;
        }
      } 
        

      this.$emit('error', false);
    },

  },
  watch: {
    validSw() {
      this.validateData(this.inputValue);
    },
    modelValue(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.$emit('inputCleared');
      }
      this.inputValue = newValue;
    }
  },
};
</script>