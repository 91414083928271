export const FormatterMixin = {
  methods: {
    prettifyDimensions(dimensions) {
      const dimensionsObj = JSON.parse(dimensions);

      const width = dimensionsObj.width;
      const height = dimensionsObj.height;
      const length = dimensionsObj.length;
      const weight = dimensionsObj.weight;

      // Create the pretty formatted string
      const prettyDimensions = `${length} X ${width} X ${height} X ${weight}`;

      return prettyDimensions;
    },
    formatDateToReadableText(datetimeStr) {
      const inputDatetime = new Date(datetimeStr);
      const currentDatetime = new Date();

      const timezoneOffset = currentDatetime.getTimezoneOffset() * 60000;
      const adjustedInputDatetime = new Date( inputDatetime.getTime() - timezoneOffset );
      const timeDifference = currentDatetime - adjustedInputDatetime;
      
      const seconds = Math.floor(timeDifference / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const months = Math.floor(days / 30); // Approximating months as 30 days

      if (months >= 1) {
        return datetimeStr;
      } else if (days > 0) {
        return `${days} ${days > 1 ? "days" : "day"} ago`;
      } else if (hours > 0) {
        return `about ${hours} ${hours > 1 ? "hours" : "hour"} ago`;
      } else if (minutes > 0) {
        return `about ${minutes} ${minutes > 1 ? "minutes" : "minute"} ago`;
      } else {
        return `about ${seconds} ${seconds > 1 ? "seconds" : "second"} ago`;
      }
    },
  },
};
