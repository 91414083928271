<template>
  <div :class="['input-box', 'input-number'/*, {'not-valid-data-box': !!errorText}*/]">
    <div class="number-input-wrapper">
      <input
          ref="numinp"
          type="number"
          v-model="inputValue"
          :placeholder="placeholder"
          :minlength="minlength"
          :maxlength="maxlength"
          @input="eventInput"
          @blur="eventBlur"
          @wheel.prevent="eventWheel"
          :min="min"
          :max="max"
      />
<!--      @keydown="eventKeyDown"-->
      <div class="input-num-buttons">
        <div
            class="input-num-button input-button-plus"
            @mousedown="increase"
        >+
        </div>
        <div
            class="input-num-button input-button-minus"
            @mousedown="decrease"
        >-
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "InputNumber",
  data() {
    return {
      inputValue: this.modelValue,
      actionTo: null,
      actionInt: null
    }
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: [String, Number],
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    min: {
      type: [Number, String],
      default: 1
    },
    max: {
      type: [Number, String],
      default: 1000
    },
    step: {
      type: [Number, String],
      default: 1
    },
    minlength: {
      type: [Number, String],
      default: null
    },
    maxlength: {
      type: [Number, String],
      default: null
    },
  },
  methods: {
    eventKeyDown(keyData) {
      const step = parseInt(this.step);
      return false;
    },

    /**
     *
     */
    eventInput(event) {
      if (!event.target.value) {
        return;
      }
      let nv = parseInt(event.target.value.replace(/[^\d-]/ig, ''));
      if (isNaN(nv)) {
        nv = parseInt(this.min);
      }
      this.inputValue = nv;
    },

    eventBlur(event) {
      let nv = parseInt(event.target.value.replace(/[^\d-]/ig, ''));
      if (isNaN(nv)) {
        nv = parseInt(this.min);
      } else if (nv < +this.min) {
        nv = this.min;
      } else if (nv > +this.max) {
        nv = this.max;
      }
      this.inputValue = nv;
      this.emitValue(nv);
    },

    eventWheel(event) {
      const step = parseInt(this.step);
      let nv = event.target.value;
      nv = parseInt(nv.replace(/[^\d-]/ig, ''));
      if (event.deltaY > 0) {
        nv = nv - step;
      } else {
        nv = nv + step;
      }
      this.emitValue(nv);
    },

    /**
     * Кнопка увеличения
     */
    increase() {
      let nv = parseInt(this.inputValue) + parseInt(this.step);
      this.emitValue(nv);
    },

    decrease() {
      let nv = parseInt(this.inputValue) - parseInt(this.step);
      this.emitValue(nv);
    },

    stopActions() {
      if (this.actionTo) {
        clearTimeout(this.actionTo);
      }
      if (this.actionInt) {
        clearInterval(this.actionInt);
      }
    },


    /**
     * Emiter
     * @param value
     */
    emitValue(value) {

      let v = value;

      if (this.min !== undefined) {
        if (value < parseInt(this.min)) {
          v = parseInt(this.min);
          this.$emit("update:modelValue", v + 1);
        }
      }
      if (this.max !== undefined) {
        if (value > parseInt(this.max)) {
          v = parseInt(this.max);
          this.$emit("update:modelValue", v - 1);
          setTimeout(() => {
            this.$emit("update:modelValue", v)
          });
          return;
        }
      }
      // this.inputValue = v;
      if (isNaN(v)) {
        v = parseInt(this.min);
        this.$emit("update:modelValue", v + 1);
        setTimeout(() => {
          this.$emit("update:modelValue", v)
        });
        return;
      }
      this.$emit("update:modelValue", v);
    }

  },
  watch: {
    modelValue(newData) {
      this.inputValue = newData;
    }
  }
}
</script>
