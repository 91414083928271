<template>
  <div class="signup-form-failed">
    <div class="failed-icon">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        viewBox="0 0 100 100"
        xml:space="preserve"
      >
        <path
          fill="none"
          stroke="#e15b64"
          stroke-width="16"
          stroke-miterlimit="10"
          d="M22 78l56-56"
        />
        <path
          fill="none"
          stroke="#e15b64"
          stroke-width="16"
          stroke-miterlimit="10"
          d="M78 78L22 22"
        />
      </svg>
    </div>

    <div class="form-title">
      Your registration was failed, please try again.
    </div>
    <a href="/" class="button" >Back to sign Up</a>
  </div>
  
</template>

<script>
export default {
  name: "SignUpFailed",
};
</script>
