<template>
    <div class="bt-settings">

        <div class="email-list-container">
            <h1>Emails list for test orders</h1>
            <div class="form-group">
                <input-email v-model="newEmail" placeholder="example@example.com" :rules="rules.requiredEmail" @error="(err) => {
                    regError('email', err);
                }
                    " />
                <button class="button" @click="addEmail">Add Email</button>
            </div>
            <ul>
                <li v-for="(email, index) in emails" :key="index">
                    <span v-if="!editIndex.includes(index)">{{ email }}</span>
                    <input v-else v-model="emails[index]" type="text" />
                    <!-- <button @click="editEmail(index)">{{ editIndex.includes(index) ? 'Save' : 'Edit' }}</button> -->
                    <button @click="deleteEmail(index)">Delete</button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import ValidateMixin from "@/mixins/ValidateMixin";
import InputEmail from "@/components/form-elements/InputEmail.vue";
export default {
    components: {
        InputEmail
    },
    name: "BtPage",
    mixins: [ValidateMixin],
    data() {
        return {
            newEmail: '',
            emails: [],
            editIndex: [],
            errors: {
                email: null,
            },
        }
    },
    mounted() {
        this.getSettings()
    },
    methods: {
        addEmail() {
            if (this.newEmail.trim() !== '' && this.errors.email !== true && this.errors.email !== null) {
                this.emails.push(this.newEmail)
                this.newEmail = ''
                this.updateSettings();
            }
        },
        editEmail(index) {
            if (this.editIndex.includes(index)) {
                this.editIndex.splice(this.editIndex.indexOf(index), 1)
            } else {
                this.editIndex.push(index)
            }
        },
        deleteEmail(index) {
            this.emails.splice(index, 1);
            this.updateSettings();
        },

        getSettings() {

            this.$store.dispatch("actionGetBtSettings").then((res) => {
                let emails = res.result.qa_emails;
                if (emails) {
                    try {
                        this.emails = JSON.parse(emails);
                    } catch (e) {
                        console.error("Failed to parse qa_emails:", e);
                        this.emails = [];
                    }
                } else {
                    this.emails = [];
                }
            });
        },
        updateSettings() {
            const parameters = {
                param: 'qa_emails',
                data_value: JSON.stringify(this.emails)
            }

            this.$store
                .dispatch("actionUpdateBtSettings", { parameters })
                .then((response) => {

                });

        },
    },
}
</script>

<style scoped>
.email-list-container {
    max-width: 600px;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
    text-align: center;
    margin-bottom: 20px;
}

.form-group {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;

}

.mail-input-wrap {
    width: 450px;
}

input[type='text'] {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    padding: 10px 15px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

ul {
    list-style: none;
    padding: 0;
}

li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
}

li span {
    width: 100%;
}

li button {
    background-color: #28a745;
    margin-left: 5px;
}

li button:hover {
    background-color: #218838;
}

li button:last-child {
    background-color: #dc3545;
}

li button:last-child:hover {
    background-color: #c82333;
}
</style>