<template>

    <transition name="slide-in-from-top">
        <div v-if="showPopup" class="company-popup" @click="closePopupOutside" ref="popupContainer">
            <div class="popup-content">
                <button class="contact-withus-popup-close" @click="closePopup">
                    <svg width="20" height="20" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0"
                        viewBox="0 0 100 100" xml:space="preserve">
                        <path fill="none" stroke="#e15b64" stroke-width="16" stroke-miterlimit="10" d="M22 78l56-56" />
                        <path fill="none" stroke="#e15b64" stroke-width="16" stroke-miterlimit="10" d="M78 78L22 22" />
                    </svg>
                </button>
                <div class="settings-accounting-text">
                    <h3>Company Info, {{ formData.company_name }}</h3>

                </div>
                <div class="form-element-wrap">
                    <div class="form-element ">
                        <label>Company Name*</label>
                        <div class="not-edit-input">{{ formData.company_name }}</div>
                    </div>
                    <div class="form-element ">
                        <label>Company Phone*</label>
                        <div class="not-edit-input">{{ formData.company_phone }}</div>
                    </div>
                    <div class="form-element ">
                        <label>Doing business as</label>
                        <div class="not-edit-input">{{ formData.doing_business_as }}</div>
                    </div>

                    <div class="form-element ">
                        <label>Address*</label>
                        <div class="not-edit-input">{{ formData.address }}</div>
                    </div>
                    <div class="form-element ">
                        <label>City*</label>
                        <div class="not-edit-input">{{ formData.city }}</div>
                    </div>
                    <div class="form-element ">
                        <label>State*</label>
                        <div class="not-edit-input">{{ formData.state }}</div>
                    </div>
                    <div class="form-element ">
                        <label>Zip*</label>
                        <div class="not-edit-input">{{ formData.zip_code }}</div>
                    </div>
                    <div class="form-element ">
                        <label>FID*</label>
                        <div class="not-edit-input">{{ formData.fid }}</div>
                    </div>
                    <div class="form-element ">
                        <label>MC#</label>
                        <div class="not-edit-input">{{ formData.mc }}</div>
                    </div>
                    <div class="form-element ">
                        <label>USDOT</label>
                        <div class="not-edit-input">{{ formData.usdot }}</div>
                    </div>

                    <div class="form-element ">
                        <label>IFTA</label>
                        <div class="not-edit-input">{{ formData.ifta }}</div>
                    </div>
                    <div class="form-element ">
                        <label>IRP</label>
                        <div class="not-edit-input">{{ formData.irp }}</div>
                    </div>

                </div>

            </div>

        </div>
    </transition>


</template>

<script>
import InputPhone from "@/components/form-elements/InputPhone.vue";
import InputPassword from "@/components/form-elements/InputPassword.vue";
import InputText from "@/components/form-elements/InputText.vue";
import InputNumber from "@/components/form-elements/InputNumber.vue";
export default {
    components: {
        InputPhone,
        InputPassword,
        InputNumber,
        InputText,
    },
    name: "CompanyView",
    computed: {
        user_data() {
            return this.$store.getters.userData;
        },
    },
    props: {
        showPopup: {
            type: Boolean,
            default: false,
        },
        company_id: {
            type: String,
        },
    },

    data() {
        return {
            formData: {
                company_name: null,
                company_phone: null,
                doing_business_as: null,
                address: null,
                city: null,
                state: null,
                zip_code: null,
                usdot: null,
                mc: null,
                fid: null,
                ifta: null,
                irp: null,
                days_to_archive: 10,
                first_login: 1,
            },
            errors: {
                company_name: false,
                company_phone: false,
                doing_business_as: false,
                address: false,
                city: false,
                state: false,
                zip_code: false,
                usdot: false,
                mc: false,
                fid: false,
                ifta: false,
                irp: false,
            },

        }
    },
    methods: {
        getCompaniesList() {

            const parameters = {
                searchValue: null,
                offset: 0,
                limit: 1200,
                orderBy: "company_name",
                order: "DESC",
            };
            this.$store
                .dispatch("actionGetCompaniesList", { parameters })
                .then((response) => {
                    const foundCompany = response.companies_list.find(company => company.company_id === this.company_id);
                    console.log(foundCompany);
                    this.formData.company_name = foundCompany.company_name;
                    this.formData.company_phone = foundCompany.company_phone;
                    this.formData.doing_business_as = foundCompany.doing_business_as;
                    this.formData.address = foundCompany.address;
                    this.formData.city = foundCompany.city;
                    this.formData.state = foundCompany.state;
                    this.formData.zip_code = foundCompany.zip_code;
                    this.formData.usdot = foundCompany.usdot;
                    this.formData.mc = foundCompany.mc;
                    this.formData.ifta = foundCompany.ifta;
                    this.formData.irp = foundCompany.irp;
                    this.formData.fid = foundCompany.fid;
                })
                .catch((err) => { });
        },
        closePopup() {
            this.removeBodyOverflowHidden()
            this.$emit("close-popup");
        },
        addBodyOverflowHidden() {
            document.body.classList.add("popup-open");
        },
        removeBodyOverflowHidden() {
            document.body.classList.remove("popup-open");
        },
        closePopupOutside(event) {
            const popupContainer = this.$refs.popupContainer;

            if (event.target === popupContainer) {
                this.$emit("close-popup");
            }
        },
    }
};
</script>