<template>
  <div class="ef-form-box ef-form-type1">
    <div class="ef-form__form-boxes form-elements">
      <div>
        <part-add-vehicle-button v-if="stepForm.equipment.length === 0 && !canIfillForm" @create-new-part="(type) => {
          createNewPart({ type, idx: 0 });
        }" />
      </div>
      <div class="ef__form-container">
        <div v-for="(part, idx) in stepForm.equipment" :key="part.id">

          <tractor-form v-if="part.type === 'tractor'" ref="tractorFormRef" v-model="stepForm.equipment[idx]"
            :form-index="idx" :equipmentLength="stepForm.equipment.length"
            :additional-fields="stepForm.equipment[idx].additional_fields" :state-list="state_list"
            :tractor-list="get_tractors_list" :tractors-data="get_tractors_data" @create-new-part="createNewPart"
            @remove-part="deletePart" @valid-fields="(boolean) => { this.validForm = boolean }" />

          <s-m-e-form v-if="part.type === 'sme'" ref="smeFormRef" v-model="stepForm.equipment[idx]"
            :additional-fields="stepForm.equipment[idx].additional_fields" :state-list="state_list"
            :sme-list="get_sme_list" :sme-data="get_sme_data" @create-new-part="createNewPart" @remove-part="deletePart"
            @valid-fields="(boolean) => { this.validForm = boolean }" />

          <trailer-form v-else-if="part.type === 'trailer'" ref="trailerFormRef" v-model="stepForm.equipment[idx]"
            :additional-fields="stepForm.equipment[idx].additional_fields" :axles-options="axles_options"
            :state-list="state_list" :form-index="idx" :trailer-list="get_trailers_list"
            :trailers-data="get_trailers_data" @remove-part="deletePart" @create-new-part="createNewPart"
            @valid-fields="(boolean) => { this.validForm = boolean }" />
        </div>
      </div>
    </div>
    <axles-table v-show="stepForm.equipment.length !== 0" v-model="stepForm.axles_values" :axles="axles_data"
      ref="axlesRef" @valid-fields="(boolean) => {
        this.validForm = boolean;
      }" />
    <equipment-buttons :form-filed="is_form_filled" :p-text="button_box_p_text" @submit="eventFormSubmit" />

  </div>




</template>

<script>
import PartAddButton from "@/components/equipment/parts/PartAddButton.vue";
import PartAddVehicleButton from "@/components/equipment/parts/PartAddVehicleButton.vue";
import InputSize from "@/components/form-elements/InputSize.vue";
import InputText from "@/components/form-elements/InputText.vue";
import InputSelect from "@/components/form-elements/InputSelect.vue";
import InputWeight from "@/components/form-elements/InputWeight.vue";
import EquipmentButtons from "@/components/equipment/EquipmentButtons.vue";
import TractorForm from "@/components/equipment/parts/TractorForm.vue";
import SMEForm from "@/components/equipment/parts/SMEForm.vue";
import TrailerForm from "@/components/equipment/parts/TrailerForm.vue";
import AxlesTable from "@/components/equipment/parts/AxlesTable.vue";
import { ref } from "vue";
export default {
  name: "EquipmentFormOne",
  components: {
    TrailerForm,
    TractorForm,
    PartAddVehicleButton,
    PartAddButton,
    SMEForm,
    EquipmentButtons,
    InputWeight,
    InputSelect,
    InputText,
    InputSize,
    AxlesTable,
  },
  props: {

    selectedEquipment: {
      type: Object,
    },
    selectedAxles: {
      type: Object,
    },
    mode: {
      type: String,
      default: "new",
    },
  },
  setup() {
    const tractorFormRef = ref(null);
    const trailerFormRef = ref(null);
    const smeFormRef = ref(null);
    const axlesRef = ref(null);

    return {
      tractorFormRef,
      trailerFormRef,
      smeFormRef,
      axlesRef,
    };
  },
  // mounted() {
  //   this.createNewPart({ type: "tractor", idx: 0 });
  //   this.createNewPart({ type: "trailer", idx: 1 });
  // },

  data() {
    return {
      validForm: false,
      stepForm: {
        name: null,
        total_length: null,
        axles_values: null,
        equipment: [],
      },
      button_box_p_text:
        "<p>By default custom power unit & trailers are visible to everyone in the company</p>",

      templates: {
        tractor: {
          type: "tractor",
          additional_fields: false,
          unit_number: null,
          length: null,
          make: null,
          year_model: null,
          vin: null,
          license: null,
          registration_place: null,
          empty_weight: null,
          axle_width: null,
          axle_quantity: 3,
        },
        sme: {
          type: "sme",
          additional_fields: false,
          unit_number: null,
          length: null,
          make: null,
          year_model: null,
          vin: null,
          license: null,
          registration_place: null,
          empty_weight: null,
          axle_width: null,
          axle_quantity: 3,
        },
        trailer: {
          type: "trailer",
          additional_fields: false,
          unit_number: null,
          length: null,
          trailer_type: null,
          deck_height: null,
          make: null,
          year_model: null,
          vin: null,
          license: null,
          registration_place: null,
          empty_weight: null,
          axle_width: null,
          axle_quantity: 3,
        },
      },
    };
  },
  emits: ['update-table'],

  created() {

    // this.createNewPart({ type: "tractor", idx: 0 });
    this.getStatesList();
  },


  computed: {
    order_by_id() {
      return this.$store.getters.order_by_id;
    },

    user_role() {
      return this.$store.getters.userRole;
    },
    user_data() {
      return this.$store.getters.userData;
    },
    canIfillForm() {
      const allowedStatuses = ['deposit_paid', 'deposit_reminder', 'payment_needed', 'payment_reminder', 'payment_completed', 'cancelled'];
      if (['owner', 'driver'].includes(this.user_role) && allowedStatuses.includes(this.order_by_id.order_status)) {
        return true;
      }
      return false;
    },

    is_form_filled() {
      let filled = true;
      if (this.stepForm.equipment.length < 1) {
        filled = false;
      }
      return filled;
    },

    axles_options() {
      const min = 1;
      const max = 30;
      let out = ["---"];
      for (let i = min; i <= max; i++) {
        out.push(i);
      }
      return out;
    },

    state_list() {
      let out = ["---"];
      const state_list = this.$store.getters.state_list;
      return out.concat(state_list);
    },
    get_tractors_list() {
      return this.$store.getters.equipmentAllPartsList.eq_parts_tractor_list;
    },
    get_tractors_data() {
      return this.$store.getters.equipmentAllPartsList.eq_parts_tractor;
    },
    get_sme_list() {
      return this.$store.getters.equipmentAllPartsList.eq_parts_sme_list;
    },
    get_sme_data() {
      return this.$store.getters.equipmentAllPartsList.eq_parts_sme;
    },
    get_trailers_list() {
      return this.$store.getters.equipmentAllPartsList.eq_parts_trailer_list;
    },
    get_trailers_data() {
      return this.$store.getters.equipmentAllPartsList.eq_parts_trailer;
    },


    axles_data() {
      const all_equipment = this.stepForm.equipment;
      let id,
        eq_el,
        quantity,
        first = null;
      let data = [];

      for (let i = 0; i < all_equipment.length; i++) {
        eq_el = all_equipment[i];
        quantity = +eq_el.axle_quantity;
        for (let ii = 0; ii < quantity; ii++) {
          first = false;
          if (eq_el.id !== id) {
            first = true;
          }
          data.push({
            id: eq_el.id,
            type: eq_el.type,
            is_first: first,
          });
          id = eq_el.id;
        }
      }
      return data;
    },
  },

  methods: {

    getStatesList() {
      this.$store.dispatch("actionGetStates").then(() => { });
    },
    eventFormSubmit() {
      // if (this.stepForm.equipment[0].type === 'tractor' && this.stepForm.equipment.length == 1) {
      //   this.$store.dispatch("alert", {
      //   message: "Add trailer  information",
      //   type: "info",
      // });
      // return;
      // }

      if (this.tractorFormRef && this.tractorFormRef.length !== 0) {
        this.tractorFormRef.forEach(form => {
          form.is_form_valid_new();
        });
      }
      if (this.trailerFormRef && this.trailerFormRef.length !== 0) {
        this.trailerFormRef.forEach(form => {
          form.is_form_valid_new();
        });
      }
      if (this.smeFormRef && this.smeFormRef.length !== 0) {
        this.smeFormRef.forEach(form => {
          form.is_form_valid_new();
        });
      }

      // this.axlesRef.is_form_valid_new();

      if (!this.validForm) return;
      // ;
      const equipment = { ...this.stepForm };
      equipment.form_type = 1;
      this.$emit("update-table", equipment);
      this.$store.dispatch("actionToggleShowEquipmentForm");
      // this.$store
      //   .dispatch("actionAddNewOwnerEquipment", { equipment })
      //   .then(() => {
      //   });
    },

    resetForm() {
      this.stepForm.name = null;
      this.stepForm.total_length = null;
      this.stepForm.axles_values = null;
      this.stepForm.equipment = [];
      // this.createNewPart({ type: "tractor", idx: 0 });
    },

    createNewPart(data) {
      this.$store.dispatch("actionGenerateId", 32).then((id) => {
        const template = { ...this.templates[data.type] };
        template.id = id.toLowerCase();
        this.stepForm.equipment.splice(data.idx + 1, 0, template);
      });
    },
    deletePart(idx) {
      setTimeout(() => {
        this.stepForm.equipment.splice(idx, 1);
      }, 40);
    },
  },

  watch: {
    validForm(v) {
      this.validForm = v;
    },
    selectedAxles: {
      deep: true,
      handler(newData) {
        this.stepForm.axles_values = newData;

      },
    },
    selectedEquipment: {
      deep: true,
      handler(newData) {
        this.stepForm.equipment = newData;

      },
    },
    order_by_id(new_data) {
      // this.equipmentMode();

    },
  },

};
</script>
