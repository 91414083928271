<template>
  <button class="button-auth button-linkedin" @click="onLinkedinAuth">
    <div class="button-icon">
      <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.19995 17.9999H0.199951V5.9999H4.19995V17.9999ZM2.20075 4.3999C1.09435 4.3999 0.199951 3.5031 0.199951 2.3991C0.199951 1.2951 1.09595 0.399902 2.20075 0.399902C3.30315 0.399902 4.19995 1.2967 4.19995 2.3991C4.19995 3.5031 3.30315 4.3999 2.20075 4.3999ZM18.6 17.9999H14.7544V12.1599C14.7544 10.7671 14.728 8.9759 12.7552 8.9759C10.7528 8.9759 10.4448 10.4927 10.4448 12.0591V17.9999H6.59995V5.9911H10.2912V7.6319H10.3432C10.8568 6.6879 12.112 5.6927 13.984 5.6927C17.88 5.6927 18.6 8.1799 18.6 11.4135V17.9999Z"
          fill="#6B7280" />
      </svg>

    </div>
    <span class="button-text">Sign in with LinkedIn</span>
  </button>
</template>

<script>
export default {
  name: "ButtonLinkedinAuth",
  // async mounted() {
  //   await this.$nextTick();
  //   setTimeout(() => {
  //     this.initializeGoogleSignIn();
  //   }, 100);
  // },
  mounted() {
    const code = new URLSearchParams(window.location.search).get('code');
    const currentUrl = window.location.href;
    const parsedUrl = new URL(currentUrl);
    const domain = parsedUrl.origin;
    const callbackUrl = domain + '/';
    if (code) {
      this.sendCodeToApi(code, callbackUrl);
      const newUrl = window.location.href.split('?')[0];
      history.replaceState({}, document.title, newUrl);
    }
  },
  methods: {
    onLinkedinAuth() {
      const currentUrl = window.location.href;
      const parsedUrl = new URL(currentUrl);
      const domain = parsedUrl.origin;
      const callbackUrl = domain + '/';
      const clientId = '77djmc10uywfqs';
      const timestamp = Date.now();
      const state = `foobar_${timestamp}`;
      const scope = 'openid profile email';

      const authorizationUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${callbackUrl}&state=${state}&scope=${scope}`;
      window.location.href = authorizationUrl;

    },
    sendCodeToApi(code, callbackUrl) {
      const data = { data: code, callbackUrl: callbackUrl };
      this.$store.dispatch('actionGetTokenFromLinkedin', data)
        .then(response => {
          this.handleCredentialResponse(response);
        })
    },
    handleCredentialResponse(response) {
      const token = response.id_token;

      // ----------------- jwt parser ----------------
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      // user data
      const incoming_linkedin_user_data = JSON.parse(jsonPayload);
      const out_data = {
        linkedin_id: incoming_linkedin_user_data.sub,
        email: incoming_linkedin_user_data.email,
        email_verified: incoming_linkedin_user_data.email_verified,
        name: incoming_linkedin_user_data.name,
        given_name: incoming_linkedin_user_data.given_name,
        family_name: incoming_linkedin_user_data.family_name,
        picture_url: incoming_linkedin_user_data.picture,
        locale: incoming_linkedin_user_data.locale,
        all_json_data: jsonPayload
      };

      this.$store.dispatch('actionSignInByLinkedin', out_data);
    },

  },
};
</script>
