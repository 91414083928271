<template>
  <div :key="componentKey">
    <div id="paypal-button" style="float: left;">
    </div>
    <div id="card-button" style="float: right;" title="Credit card">
    </div>
  </div>
  <spinner :loading="loading" />
</template>

<script>
import Spinner from "@/components/helpers/Spinner.vue";
export default {
  name: "PayPalPaymentButton",
  components: { Spinner },
  props: {
    amount: {
      type: Number,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    order_id: {
      type: String,
      required: true,
    },

  },
  data() {
    return {
      loading: false,
      payment_description: 'Full price from ' + this.order.order_name,
      componentKey: 0,
    }
  },
  mounted() {
    this.loadPayPalScript();
  },
  methods: {
    loadPayPalScript() {
      let clientId;
      if (window.location.hostname === "stage.account.expresspermits.llc" || window.location.hostname === "localhost") {
        clientId = this.$PAYPAL_TEST;
      } else {
        clientId = this.order.is_test == 1 ? this.$PAYPAL_TEST : this.$PAYPAL_REAL;
      }
      const existingScript = document.querySelector('script[src^="https://www.paypal.com/sdk/js"]');
      if (existingScript) {
        existingScript.remove();
      }
      // Create new script element
      const script = document.createElement('script');
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=USD&vault=true`;
      script.onload = () => {
        this.renderPayPalButton();
      };
      document.head.appendChild(script);
      this.componentKey += 1; // Force re-render
    },
    renderPayPalButton() {
      this.createButton(paypal.FUNDING.PAYPAL);
      this.createButton(paypal.FUNDING.CARD);
     
    },
    createButton(fundingSource) {

      var config = {
        fundingSource: fundingSource,
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: this.amount,
                },
                description: this.payment_description,
              },
            ],
          });
        },
        onApprove: (data, actions) => {
          return actions.order.capture().then((details) => {
            const data_all = { data, details }
            // console.log(JSON.stringify(details));
            if (details.status === "COMPLETED") {
              this.changeOrderStatus(data_all);
            }
          });
        },
      };
      var button = paypal.Buttons(config);
      if (button.isEligible()) {
        button.render(`#${fundingSource}-button`);
      }
    },
    changeOrderStatus(details) {

      this.loading = true;
      const order_id = this.order_id;
      const parameters = {
        order_status: "payment_completed",
        details: details,
        payment_description: this.payment_description,
        payment_method: 'full',
        cost: this.amount,
        order: this.order,
        is_test: this.order.is_test,
      };
      this.$store
        .dispatch("actionUpdateOrderByOrderId", { order_id, parameters })
        .then((response) => {
          // this.loading = false;
          this.getOrderData();
          this.loading = false;
        })
        .catch((err) => { });
    },
    getOrderData() {
      const order_id = this.order_id;
      this.$store.dispatch("actionGetOrderbyOrderId", { order_id });
    },
  },
  watch: {
    order() {
      this.loadPayPalScript()
    }
  }
};
</script>
