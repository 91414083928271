<template>
  <div class="ef--axles">
    <div class="ef--axles__inner">
      <div class="ef--axles__wheels">
        <div id="quote-nout" class="new-quote__nout3">
          <p><span><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M16 5.13288C9.99854 5.13288 5.13337 9.99805 5.13337 15.9995C5.13337 22.001 9.99854 26.8662 16 26.8662C22.0015 26.8662 26.8667 22.001 26.8667 15.9995C26.8667 9.99805 22.0015 5.13288 16 5.13288ZM2.8667 15.9995C2.8667 8.74621 8.74669 2.86621 16 2.86621C23.2534 2.86621 29.1334 8.74621 29.1334 15.9995C29.1334 23.2529 23.2534 29.1329 16 29.1329C8.74669 29.1329 2.8667 23.2529 2.8667 15.9995ZM16 13.5329C16.626 13.5329 17.1334 14.0403 17.1334 14.6662V21.3329C17.1334 21.9588 16.626 22.4662 16 22.4662C15.3741 22.4662 14.8667 21.9588 14.8667 21.3329V14.6662C14.8667 14.0403 15.3741 13.5329 16 13.5329ZM16 11.9995C16.7364 11.9995 17.3334 11.4026 17.3334 10.6662C17.3334 9.92983 16.7364 9.33288 16 9.33288C15.2637 9.33288 14.6667 9.92983 14.6667 10.6662C14.6667 11.4026 15.2637 11.9995 16 11.9995Z"
                  fill="#0080EA" />
              </svg>
            </span>Axles information is required for all overweight loads.
          </p>
        </div>
        <!-- <div id="quote-nout" class="new-quote__nout2">
        <p>Axles information is required for all overweight loads.
        </p>
      </div> -->
        <div class="ef--axles__wheels--inner">
          <div v-for="(axle, idx) in axles_data.spacing" :key="idx" class="ef__wheel"
            :class="{ 'ef__wheel--space-right': !!axle.is_first && (axle.type === 'tractor') }"
            :style="{ paddingLeft: feetToPixels(axles_data.spacing[idx], 0.5) + 'px' }">
            <span class="wheel-number">{{ idx + 1 }}</span>
            <div :class="[{ 'wheel-number-text': true, 'hide': idx === 0 }]" v-if="axles_data.spacing[idx]">
              {{ axles_data.spacing[idx].spacing }}
            </div>
          </div>

        </div>
      </div>
      <div class="ef--axles__table-box">
        <div class="ef--axles__table--wrapper">

          <!--            Titles-->
          <div class="ef--axles__table--titles">

            <div class="ef--title-box">
              <span class="additional-info">Axles
                <div class="additional-info-message">
                  Axles on both tractor and trailer/trailers, including lift/pusher axles
                </div>
              </span>

            </div>
            <div class="ef--title-box">
              <span class="additional-info axle-spacing">Axle Spacing
                <div class="additional-info-message">
                  Spacing is measured from the center of an axle to the center of the next axle. Example: Measure from
                  the
                  center of the steer axle to the center of the first drive axle. Enter that number into the first
                  spacing
                  field. Follow the same process for the rest of your axles.
                </div>
              </span>
            </div>
            <div class="ef--title-box">
              <span class="additional-info axle-weight">Axle Weight
                <div class="additional-info-message">
                  Weight is measured for each axle individually. Example: Enter the weight of the steer axle into the
                  first weight field. Follow the same process for the rest of your axles.
                </div>
              </span>
            </div>
            <div class="ef--title-box">
              <span class="additional-info tires"># Tires
                <div class="additional-info-message">
                  The total number of tires on one axle. Example: The steer axle has two tires, the first drive axle has
                  four.
                </div>
              </span>
            </div>
            <div class="ef--title-box">
              <span class="additional-info tires-width">Tires Size
                <div class="additional-info-message">
                  The width in inches from one side wall of the tired to the other. Example: The tire measures eleven
                  inches in width.
                </div>
              </span>
            </div>
          </div>
          <div class="axles-inputs">
            <div class="axles-inputs-row">
              <div v-for="(axle, ind) in axles_data.type" :key="ind">
                <div class="ef--data-box data-box__axle-number">{{ ind + 1 }} - {{ getEquipmentName(axle.type) }}</div>
              </div>
            </div>
            <div class="axles-inputs-row">
              <div v-for="(axle, ind) in axles_data.spacing" :key="ind">
                <input-size :class="[{ 'hide': ind === 0 }]" v-model="axles_data.spacing[ind].spacing"
                  placeholder="1’10”" :disabled="canIfillForm" @blur="updateFormDataOnBlur" />
              </div>
            </div>
            <div class="axles-inputs-row">
              <div v-for="(axle, ind) in axles_data.axle_weight" :key="ind">
                <input-weight v-model="axles_data.axle_weight[ind].axle_weight" placeholder="10 lbs"
                  :disabled="canIfillForm" @blur="updateFormDataOnBlur" />
              </div>
            </div>
            <div class="axles-inputs-row">
              <div v-for="(axle, ind) in axles_data.axle_weight" :key="ind">
                <input-select v-model="axles_data.tires_quantity[ind].tires_quantity" placeholder="1’10”"
                  @change="updateFormDataOnBlur" :disabled="canIfillForm" :options="tires_items" />
              </div>
            </div>

            <div class="axles-inputs-row">
              <div v-for="(axle, ind) in axles_data.axle_weight" :key="ind">
                <input-select v-model="axles_data.tire_width[ind].tire_width" placeholder="1’10”"
                  :disabled="canIfillForm" @change="updateFormDataOnBlur" :options="tire_size" />
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import InputSelect from "@/components/form-elements/InputSelect.vue";
import InputSize from "@/components/form-elements/InputSize.vue";
import InputWeight from "@/components/form-elements/InputWeight.vue";
import ValidateMixin from "@/mixins/ValidateMixin";
export default {
  name: "AxlesTable",
  components: { InputSize, InputWeight, InputSelect },
  computed: {
    order_by_id() {
      return this.$store.getters.order_by_id;
    },
    user_role() {
      return this.$store.getters.userRole;
    },
    user_data() {
      return this.$store.getters.userData;
    },
    canIfillForm() {
      if (this.user_role === 'manager' && this.order_by_id.manager_id !== this.user_data.user_id) {
        return true;
      }
      const allowedStatuses = ['deposit_paid', 'deposit_reminder', 'payment_needed', 'payment_reminder', 'payment_completed', 'cancelled'];
      if (['owner', 'driver'].includes(this.user_role) && allowedStatuses.includes(this.order_by_id.order_status)) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {

      validSw: false,
      axles_data: [],
      tires_items: [
        '—',
        2,
        4, 6, 8, 10
      ],
      tire_size: [
        '—',
        '8.25R15 | 8.25',
        '305/70R19.5 | 12.0',
        '12R22.5 | 12.0',
        '279/80R22.5 | 10.99',
        '10R20 10',
        '16R25 | 16',
        '385/95R24 | 15.16',
        '565/80R/25 | 22.24',
        '245/70R18.5 | 9.6',
        '580/80R34 | 22.8',
        '255/70R22.5 | 10.03',
        '520/85R42 | 20.5',
        '205/75R17.5 | 8.07',
        '215/75R17.5 | 8.47',
        '225/70R19.5 | 8.86',
        '235/80R22.5 | 9.26',
        '245/70R19.5 | 9.65',
        '255/80R22.5 | 10.04',
        '265/80R19.5 | 10.44',
        '275/80R22.5 | 10.83',
        '11R24.5 | 11.0',
        '11R22.5 | 11.0',
        '285/75R24.5 | 11.2',
        '295/75R22.5 | 11.62',
        '12R24.5 | 12.0',
        '315/80R22.5 | 12.41',
        '355/80R22.5 13.98',
        '14R24 | 14',
        '385/65R22.5 | 15.16',
        '425/65R22.5 | 16.74',
        '445/95R25 | 17.52',
        '445/65R22.5 | 17.52',
        '455/65R22.5 | 17.92',
        '495/65R19.5 | 19.5'
      ]
    }
  },
  props: {
    modelValue: {
      type: Object,
      default: null
    },
    axles: {
      type: Object, String,
      required: true
    },
    mode: {
      type: String,
      default: "new",
    },
  },
  emits: ['update:modelValue', 'validFields'],
  mixins: [ValidateMixin],
  methods: {
    feetToPixels(feetString, scaleFactor) {
      if (feetString.spacing) {

        const [feetPart, inchesPart] = feetString.spacing.split("'");

        // Convert feet to inches
        const feet = parseFloat(feetPart.trim());
        const inches = inchesPart ? parseFloat(inchesPart.trim().replace('"', '')) : 0;
        const totalInches = feet * 12 + inches;

        // Convert inches to pixels based on the scale factor
        const pixels = totalInches * scaleFactor;
        return pixels;
      }
      return 0
    },
    is_form_valid_new() {
      this.$store.dispatch("actionGenerateId", 32).then((id) => {
        this.validSw = id;
      });

      this.$emit("validFields", true);

    },

    checkValidValues(data) {
      for (const category in data) {
        if (data.hasOwnProperty(category)) {
          const errors = data[category];
          for (const error of errors) {
            if (error.error === true || error.error === null) {
              return false;
            }
          }
        }
      }
      return true; // If all errors are valid, return true
    },
    getEquipmentName(type) {
      let name = type;
      switch (type) {
        case "tractor":
          name = "Power Unit";
          break;
        case "trailer":
          name = "Trailer";
          break;
        case "sme":
          name = "SME";
          break;
        // case "helper":
        //   name = "Helper";
        //   break;
      }
      return name;
    },
    updateFormDataOnBlur() {
      this.$emit("update:modelValue", this.axles_data);
    },
  },
  watch: {
    axles: {
      handler(d) {

        const data = {
          type: [],
          spacing: [],
          axle_weight: [],
          tires_quantity: [],
          tire_width: [],
        };
        let tires_quantity;
        d.forEach((el, idx) => {
          // tires_quantity = (el.is_first && (el.type === "tractor")) ? 2 : 4;
          data.type.push({
            type: el.type
          });
          data.spacing.push({
            spacing: this.modelValue?.spacing && this.modelValue.spacing[idx]?.spacing ? this.modelValue.spacing[idx]?.spacing : null,
            error: null
          });
          data.axle_weight.push({
            axle_weight: this.modelValue?.axle_weight ? this.modelValue.axle_weight[idx]?.axle_weight : null,
            error: null
          });
          data.tires_quantity.push({
            tires_quantity: this.modelValue?.tires_quantity ? this.modelValue.tires_quantity[idx]?.tires_quantity : null,
            error: null
          });
          data.tire_width.push({
            tire_width: this.modelValue?.tire_width ? this.modelValue.tire_width[idx]?.tire_width : null,
            error: null
          });
        });
        this.axles_data = data;
        this.$emit('update:modelValue', this.axles_data)
      },
      deep: true
    },
    axles_data: {
      handler(d) {
        this.$emit('update:modelValue', d);
      },
      deep: true
    }
  }
}
</script>