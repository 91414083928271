<template>
  <div :class="{ 'profile__settings': true, 'administration': user_data.role === 'manager' || user_data.role === 'admin' }">
    <div class="profile__settings__title">
      <div>
        <img :src="password_settings_icon"/>
      </div>
      <span>Password</span>
    </div>
    <div class="profile__settings__inputs">
      <div class="form-elements">
        <div v-if="incoming_password_exist" class="form-element current">
          <label>Current password</label>
          <input-password

              v-model="passwordForm.current_password"
              placeholder="Password"
              autocomplete="new-password"
              :rules="rules.password"
          />
        </div>
        <div class="form-element">
          <label v-if="incoming_password_exist">New password</label>
          <label v-else>Create password</label>
          <input-password
              v-model="passwordForm.password"
              placeholder="Enter new password"
              :class="{ 'not-match': !passwordsMatch }"
              :rules="rules.password"
              :pass-gen="true"
              @generated="copyGeneratedPassword"
              @error="
              (err) => {
                regError('password', err);
              }
            "
              @input="
              () => {
                regError('password', false);
              }
            "
          />
        </div>
        <div class="form-element">
          <input-password
              v-model="passwordForm.confirm_password"
              placeholder="Confirm password"
              :class="{ 'not-match': !passwordsMatch }"
              :rules="rules.confirm_password"
              @input="passwordsMatch = true"
              @blur="checkPasswordIdentical"
          />
          <span
          v-if="!passwordsMatch"
          class="err not-match">Passwords do not match</span>
        </div>
      </div>
    </div>

    <div class="profile__settings__footer">
      <notification
          :is-visible="notificationShow"
          :status="true"
          text="Password changed"
      />
      <button
          :disabled="!isValidForm"
          class="button small profile__settings__button"
          @click="updatePassword"
      >Update
      </button>
    </div>
  </div>
</template>

<script>
import InputPassword from "@/components/form-elements/InputPassword.vue";
import ValidateMixin from "@/mixins/ValidateMixin";
import password_settings_icon from "@/assets/images/svgs/lock.svg";
import Notification from "@/components/helpers/Notification.vue";

export default {
  name: "ProfilePasswordForm",
  components: {
    InputPassword,
    Notification,
  },
  mixins: [ValidateMixin],
  data() {
    return {
      password_settings_icon,
      notificationShow: false,
      passwordsMatch: true,
      passwordForm: {
        current_password: null,
        password: null,
        confirm_password: null,
      },
      errors: {
        password: null,
      },
    };
  },
  computed: {
    user_data() {
      return this.$store.getters.userData;
    },

    incoming_password_exist() {
      if (!this.user_data) {
        return false;
      }
      const exist = this.user_data.password_exist;
      if(!exist){
        this.passwordForm.current_password = 'none';
      }

      return exist;
    },

    isPasswordsIdentical() {
      const form = this.passwordForm;
      if (!form.password.trim() || !form.confirm_password.trim()) {
        return false;
      }
      if (form.password.trim() !== form.confirm_password.trim()) {
        return false;
      }
      return true;
    },

    isDifferenceInPasswords() {
      if (!this.incoming_password_exist) {
        return true;
      }
      const form = this.passwordForm;
      if (!form.current_password.trim() || !form.password.trim()) {
        return false;
      }
      if (form.current_password.trim() !== form.password.trim()) {
        return true;
      }
      return false;
    },
    isValidForm() {
      const existError = Object.keys(this.errors).find((el) => this.errors[el]);
      const isEmptyField = Object.values(this.passwordForm).some(value => !value);
      return !existError && !isEmptyField && this.isPasswordsIdentical && this.isDifferenceInPasswords;
    }
  },
  methods: {
    copyGeneratedPassword(pass) {
      this.errors.password = false;
      this.passwordsMatch = true;
      this.passwordForm.confirm_password = pass;
    },
    checkPasswordIdentical() {
      setTimeout(() => {
        this.passwordsMatch = !!this.isPasswordsIdentical;
      });
    },
    resetForm() {
      const form = this.passwordForm;
      form.current_password = null;

      form.password = null;
      form.confirm_password = null;

      const errors = this.errors;
      errors.password = false;

      this.passwordsMatch = true;
    },

    updatePassword() {
      const form = this.passwordForm;
      const data = {
        current_password: form.current_password,
        password: form.password,
      }
      this.$store.dispatch('actionUserChangePassword', data)
          .then(() => {
            this.notificationShow = true;
            this.resetForm();
          })
          .catch(err => {
            console.error(err);
          });

    }
  },
};
</script>
