export default {
  state: {
    message_obj: null
  },
  getters: {
    message(state) {
      return state.message_obj;
    }
  },
  mutations: {
    mutation_alert(state, payload) {
      state.message_obj = payload;
    }
  },
  actions: {
    alert({commit}, {message, type = 'info'}) {
      commit('mutation_alert', {message, type});
    },
    hide_alert({commit}) {
      commit('mutation_alert', null);
    }
  }
};