<template>
  <div v-if="user_data.role === 'admin'" id="equipment-page">
    <div class="quote-filter__section">
      <div class="quote-filter__inputs">
        <div class="input-select">
          <select v-model="companyValue" @change="onFilter">
            <option value="all">All Companies</option>
            <option v-for="option in get_companies_list" :key="option.company_id" :value="option.company_id">
              {{ option.company_name }}
            </option>
          </select>
        </div>

      </div>
      <button class="button small button-icon reset-icon" @click="resetFilter">
        Reset
      </button>
    </div>
    <div class="bulk-info" v-if="getSelectedCheckboxItems.length">
      <div class="bulk-info-selected">
        {{ getSelectedCheckboxItems.length }} unit selected
      </div>
      <div class="bulk-info-actions">
        <delete-button @click="showConfirmDialog = true" />
      </div>
    </div>
    <div class="table-wrap">
      <div class="eq-buttons">
        <div class="tab-buttons">
          <button v-for="(tab, index) in tabs" :key="index" @click="onCLickTab(tab, index)"
            :class="{ active: activeTab === index }">
            {{ tab.title }}
          </button>
        </div>

      </div>
      <div class="tab-content">
        <div v-for="(tab, index) in tabs" :key="index" :class="{ active: activeTab === index }">
          <tractor-tab v-if="tab.refName === 'ref_tractors'" ref="ref_tractors" />
          <trailer-tab v-if="tab.refName === 'ref_trailers'" ref="ref_trailers" />
          <s-m-e-tab v-if="tab.refName === 'ref_sme'" ref="ref_sme" />
        </div>
      </div>
    </div>
  </div>
  <confirmation-dialog v-if="showConfirmDialog" :content="confirmationMessage" @cancel="() => {
    this.showConfirmDialog = false;
  }
    " @confirm="onMultiDeleteEquipment" />
</template>

<script>
import { ref } from "vue";
import ConfirmationDialog from "@/components/helpers/ConfirmationDialog.vue";
import DeleteButton from "@/components/buttons/DeleteButton";
import EquipmentForm from "@/components/equipment/EquipmentForm.vue";
import AddTractorForm from "@/components/equipment/forms/AddTractorForm.vue";
import AddTrailerForm from "@/components/equipment/forms/AddTrailerForm.vue";
import AddSMEForm from "@/components/equipment/forms/AddSMEForm.vue";
import TractorTab from "@/components/equipment/TractorTab.vue";
import TrailerTab from "@/components/equipment/TrailerTab.vue";
import SMETab from "@/components/equipment/SMETab.vue";

export default {
  name: "AdminEquipmentPage",
  components: {
    TractorTab,
    TrailerTab,
    SMETab,
    EquipmentForm,
    AddTractorForm,
    AddTrailerForm,
    AddSMEForm,
    DeleteButton,
    ConfirmationDialog,
  },
  setup() {
    const ref_tractors = ref(null);
    const ref_trailers = ref(null);
    const ref_sme = ref(null);

    return {
      ref_tractors,
      ref_trailers,
      ref_sme,
    };
  },
  computed: {
    user_data() {
      return this.$store.getters.userData;
    },
    getSelectedCheckboxItems() {
      return this.$store.getters.getSelectedCheckboxItems;
    },
    get_companies_list() {
      return this.$store.getters.companiesList;
    },
    confirmationMessage() {
      const itemCount = this.getSelectedCheckboxItems.length;
      return itemCount === 1 
        ? 'Are you sure you want to delete the equipment?' 
        : 'Are you sure you want to delete the ('+this.getSelectedCheckboxItems.length+') equipments?';
    }
  },
  data() {
    return {
      companyValue: 'all',
      selectedEquipment: [],
      showConfirmDialog: false,
      activeTab: 0,
      showAddTractorPopup: false,
      showSMEPopup: false,
      showAddTrailerPopup: false,
      tabs: [
        { title: "Power Units", refName: "ref_tractors" },
        { title: "Trailers", refName: "ref_trailers" },
        { title: "Special Mobile Equipment", refName: "ref_sme" },
      ],
    };
  },
  created() {
    this.getStatesList();
    this.getCompaniesList();
  },

  methods: {
    getCompaniesList() {

      const parameters = {
        searchValue: null,
        offset: 0,
        limit: 1200,
        orderBy: "company_name",
        order: "DESC",
      };
      this.$store
        .dispatch("actionGetCompaniesList", { parameters })
        .then((response) => {
          this.ref_tractors[0].queryParameters.company_id = this.companyValue;
          this.ref_tractors[0].getDataList();
        })
        .catch((err) => { });
    },
    getStatesList() {
      this.$store.dispatch("actionGetStates").then(() => { });
    },

    onFilter() {
      const refName = this.tabs[this.activeTab].refName;
      switch (refName) {
        case "ref_tractors":
          this.ref_tractors[0].queryParameters.company_id = this.companyValue;
          this.ref_tractors[0].getDataList();
          break;
        case "ref_sme":
          this.ref_sme[0].queryParameters.company_id = this.companyValue;
          this.ref_sme[0].getDataList();
          break;
        case "ref_trailers":
          this.ref_trailers[0].queryParameters.company_id = this.companyValue;
          this.ref_trailers[0].getDataList();
          break;
        default:
          this.ref_tractors[0].queryParameters.company_id = this.companyValue;
          this.ref_tractors[0].getDataList();
          break;
      }
    },
    resetFilter() {
      const refName = this.tabs[this.activeTab].refName;
      this.companyValue = 'all';
      switch (refName) {
        case "ref_tractors":
          this.ref_tractors[0].queryParameters.company_id = this.companyValue;
          this.ref_tractors[0].getDataList();
          break;
        case "ref_sme":
          this.ref_sme[0].queryParameters.company_id = this.companyValue;
          this.ref_sme[0].getDataList();
          break;
        case "ref_trailers":
          this.ref_trailers[0].queryParameters.company_id = this.companyValue;
          this.ref_trailers[0].getDataList();
          break;
        default:
          this.ref_tractors[0].queryParameters.company_id = this.companyValue;
          this.ref_tractors[0].getDataList();
          break;
      }
    },

    onCLickTab(tab, index) {
      this.activeTab = index;
      switch (tab.refName) {
        case "ref_tractors":
          this.ref_tractors[0].queryParameters.company_id = this.companyValue;
          this.ref_tractors[0].getDataList();
          break;
        case "ref_sme":
          this.ref_sme[0].queryParameters.company_id = this.companyValue;
          this.ref_sme[0].getDataList();
          break;
        case "ref_trailers":
          this.ref_trailers[0].queryParameters.company_id = this.companyValue;
          this.ref_trailers[0].getDataList();
          break;
        default:
          this.ref_tractors[0].queryParameters.company_id = this.companyValue;
          this.ref_tractors[0].getDataList();
          break;
      }
    },

    onMultiDeleteEquipment() {
      const ids = this.getSelectedCheckboxItems;
      this.$store.dispatch("actionMultiDeleteOwnerEquipment", { ids })
      .then(() => {

        
        this.showConfirmDialog = false;
        this.ref_tractors[0].selectedItems = {};
        this.ref_tractors[0].selectAll = false;
        this.ref_tractors[0].getDataList();
        this.ref_trailers[0].selectedItems = {};
        this.ref_trailers[0].selectAll = false;
        this.ref_trailers[0].getDataList();
        this.ref_sme[0].selectedItems = {};
        this.ref_sme[0].selectAll = false;
        this.ref_sme[0].getDataList();
        this.$store.dispatch("actionSelectedCheckboxItems", []);
      })
    },
  },
};
</script>
