<template>
  <hr>
  <div class="table-wrapper">
    <slot-table class="qoute-table qoute-table-client" :data="order_meta">
      <template #header>
        <tr>
          <th v-for="column in columns" :key="column.name">
            {{ column.label }}
          </th>
        </tr>
      </template>
      <template v-slot:body="{ item }">
        <tr :key="item.id">
          <td>{{ item.state.value }}</td>
          <td>${{ item.oversize_data.value.toFixed(2) }}</td>
          <td>${{ item.overweight_data.value.toFixed(2) }}</td>
          <td>
            {{ item.superload.selectData.value }} -
            ${{ item.superload.data.value.toFixed(2) }}
          </td>

          <td>${{ item.other_data.value.toFixed(2) }}</td>
          <td>${{ item.service_data.value.toFixed(2) }}</td>
          <td>${{ item.total.toFixed(2) }}</td>
        </tr>
      </template>
      <template #footer>
        <tr class="bg-transparent">
          <td class="bg-blue"><strong>Total</strong></td>
          <td class="bg-blue"><strong>${{ total_oversize }}</strong></td>
          <td class="bg-blue"><strong>${{ total_overweight }}</strong></td>
          <td class="bg-blue"><strong>${{ total_superload }}</strong></td>
          <td class="bg-blue"><strong>${{ total_other }}</strong></td>
          <td class="bg-blue"><strong>${{ total_service_fee }}</strong></td>
          <td class="bg-blue"><strong>${{ calculateTotalOfTotals().toFixed(2) }}</strong></td>
        </tr>
        <tr class="bg-transparent">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="bg-white">Transaction fee 3.5%</td>
          <td class="bg-white">${{ calculate3_5Percent().toFixed(2) }}</td>
        </tr>
        <tr class="bg-transparent">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="bg-white">Deposit</td>
          <td class="bg-white">-${{ Number(this.order.deposit_amount).toFixed(2) }}</td>
        </tr>
        <!-- <tr v-if="additionalDeposit > 0" class="bg-transparent">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="bg-white">Additional payment for states</td>
          <td class="bg-white">${{ additionalDeposit }}</td>
        </tr> -->
        <tr class="bg-transparent">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="bg-blue"><strong>Due Amount</strong></td>
          <td class="bg-blue">
            <strong>${{ calculateAll() }}</strong>
          </td>
        </tr>
      </template>
    </slot-table>
  </div>

</template>

<script>

import SlotTable from "@/components/SlotTable.vue";
export default {
  name: "OrderTableView",
  components: {
    SlotTable,

  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    order_meta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

      orderData: this.order_meta,

      columns: [
        { name: "state", label: "State" },
        { name: "oversize", label: "Oversize" },
        { name: "overweight", label: "Overweight" },
        { name: "superload", label: "Superload" },
        { name: "other", label: "Other" },
        { name: "service_fee", label: "Service Fee" },
        // { name: "transaction_fee", label: "Transaction fee 3.5%" },
        { name: "total", label: "Total" },
      ],
    };
  },
  computed: {
    // additionalDeposit() {
    //   const depositDifference = (+this.orderData.length * this.$DEPOSIT_RATE) - +this.order.deposit_amount;
    //   return depositDifference > 0 ? depositDifference.toFixed(2) : 0;
    // },
    total_oversize() {
      return this.orderData.reduce(
        (acc, item) => acc + Number(item.oversize_data.value),
        0
      ).toFixed(2);
    },
    total_overweight() {
      return this.orderData.reduce(
        (acc, item) => acc + Number(item.overweight_data.value),
        0
      ).toFixed(2);
    },

    total_other() {
      return this.orderData.reduce(
        (acc, item) => acc + Number(item.other_data.value),
        0
      ).toFixed(2);
    },
    total_service_fee() {
      return this.orderData.reduce(
        (acc, item) => acc + Number(item.service_data.value),
        0
      ).toFixed(2);
    },
    total_superload() {
      return this.orderData.reduce(
        (acc, item) => acc + Number(item.superload.data.value),
        0
      ).toFixed(2);
    },


  },
  methods: {

    calculateTotalOfTotals() {
      return +this.orderData.reduce((acc, item) => acc + item.total, 0).toFixed(2);
    },
    calculate3_5Percent() {
      const totalOfTotals = this.calculateTotalOfTotals();
      const result = totalOfTotals * 0.035; // 3.5% of the total
      return +result.toFixed(2);
    },
    calculateAll() {
      return (this.calculateTotalOfTotals() + this.calculate3_5Percent() - this.order.deposit_amount).toFixed(2);
      // return (this.calculateTotalOfTotals() + this.calculate3_5Percent() + Number(this.additionalDeposit) - this.order.deposit_amount).toFixed(2);
    },
  },
  watch: {
    order_meta: {
      handler() {
        this.orderData = this.order_meta;
        this.calculateAll();
      },
      deep: true,
    },
  },

};
</script>
