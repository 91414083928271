<template>
  <div class="support_tab">
    <div class="support_tab__inner">
      <h3>Support</h3>
      <p>Call Us: <a href="tel:(828) 348-6679">(828) 348-6679</a></p>
      <p>
        Email:
        <a href="mailto:contact@expresspermits.llc"
          >contact@expresspermits.llc</a
        >
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "SupportTab",
  components: {},
  data() {
    return {};
  },

  methods: {},
};
</script>
