<template>
  <div v-if="user_data.role === 'manager' || user_data.role === 'admin'" class="table-wrap quote-single">
    no data
  </div>
  <div v-else-if="!order_by_id">wrong id</div>
  <!-- <div v-else-if="order_by_id.company_id !== user_data.company_id">Permission issue.</div> -->
  <div v-else class="table-wrap quote-single">
    <div class="quote-single__row">
      <div class="quote-single__col">
        <div>

          <google-map-component ref="googleMapRef" :origin="originValue" :destination="destinationValue"
           status="new" :order_id="order_by_id.order_id"  @map-positions="mapPositions" />
        </div>
      </div>
      <div class="quote-single__col calculator">
        <!-- <div class="quote-single__history">
          <button class="button-link button-history" @click="openHistoryLogs">
            History of changes
          </button>
        </div> -->
        <calculator :mode="calculatorMode" @calc-route="runMap" :map-positions="mapPositionsData" />
      </div>
    </div>

    <template v-if="order_by_id.order_status === 'deposit_paid'">
      <div class="order-deposit">
        <div class="order-deposit__inner">

          <div>
            <p>if you have any questions:</p>
            <p>Call Us: <a href="tel:(828) 348-6679">(828) 348-6679</a></p>
            <p>
              Email:
              <a href="mailto:contact@expresspermits.llc">contact@expresspermits.llc</a>
            </p>
          </div>
        </div>
      </div>
    </template>
    <template v-if="order_by_id.order_status === 'payment_needed' ||
      order_by_id.order_status === 'payment_completed' ||
      order_by_id.order_status === 'payment_reminder'
    ">
      <order-table-view :order="order_by_id" :order_meta="order_meta_data" />
    </template>
    
    <div v-if="order_by_id.order_status === 'payment_completed'
    " class="permit-paid">
      <div>Paid!</div>
    </div>

    <template v-if="order_by_id.order_status === 'payment_completed'
    ">
      <order-permits-table-view v-if="order_by_id.order_status === 'payment_reminder' ||
        order_by_id.order_status === 'payment_needed' ||
        order_by_id.order_status === 'payment_completed' 
      " :order="order_by_id" :order_meta="order_meta_data" />
    </template>
  </div>
  <!-- <history-logs :show-popup="showPopup" @close-popup="closePopup" /> -->
</template>
<script>
import { ref } from "vue";
import Calculator from "@/components/calculator/Calculator.vue";
import GoogleMapComponent from "@/components/googleMapComponent/GoogleMapComponent.vue";
import PayPalDepositButton from "@/components/payments/PayPalDepositButton.vue";
import PayPalPaymentButton from "@/components/payments/PayPalPaymentButton.vue";
import SlotTable from "@/components/SlotTable.vue";
import print_icon from "@/assets/images/svgs/print-icon.svg";
import mail_icon from "@/assets/images/svgs/mail.svg";
import permit_img from "@/assets/images/permit.jpg";
// import HistoryLogs from "@/components/orders/HistoryLogs.vue";
import OrderTableView from "@/components/orders/owner/OrderTableView.vue";
import OrderPermitsTableView from "@/components/orders/owner/OrderPermitsTableView.vue";
export default {
  name: "OrderPageId",

  components: {
    SlotTable,
    GoogleMapComponent,
    PayPalDepositButton,
    PayPalPaymentButton,
    // HistoryLogs,
    Calculator,
    OrderTableView,
    OrderPermitsTableView,
  },
  setup() {
    const googleMapRef = ref(null);

    return {
      googleMapRef,
    };
  },

  data() {
    return {
      order_id: this.$route.params.order_id,
      // company_id: this.$route.params.company_id,
      deposit_paid: false,
      payment_paid: false,
      print_icon,
      mail_icon,
      permit_img,
      map: null,
      originValue: "",
      destinationValue: "",
      roadTypeValue: "interstate",
      showPopup: false,
      calculatorMode: "current",
      mapPositionsData: null
    };
  },
  computed: {
    order_by_id() {
      return this.$store.getters.order_by_id;
    },
    user_data() {
      return this.$store.getters.userData;
    },
    order_meta_data() {
      const data = JSON.parse(this.$store.getters.order_meta.data.meta_value);
      return data;
    },
  },
  created() {
    this.getOrderMeta();
  },

  methods: {
    runMap(data) {
      this.originValue = data.origin;
      this.destinationValue = data.destination;
      this.roadTypeValue = data.road_type;

      this.$nextTick(() => {
        this.googleMapRef.calcRoute();
      });
    },
    mapPositions(data) {
        this.mapPositionsData = data;
    },
    getOrderData() {
      const order_id = this.order_id;
      this.$store
        .dispatch("actionGetOrderbyOrderId", { order_id })
        .then((response) => {
          const order = response.order;

          this.order = order;
          this.originValue = order.origin;
          this.destinationValue = order.destination;
          this.roadTypeValue = order.road_type;
          this.$nextTick(() => {
            this.googleMapRef.calcRoute();
          });
        })
        .catch((err) => { this.$router.push('/'); });
        

    },
    getOrderMeta() {

      const order_id = this.order_id;
      const meta_key = "_data";
      this.$store
        .dispatch("actionGetOrderMeta", { order_id, meta_key })
        .then((response) => {
          this.getOrderData();
          this.getOrderLogs();
          this.orderData = JSON.parse(response.data.meta_value);
        })
        .catch((err) => { });
    },
    getOrderLogs() {
      const order_id = this.order_id;
      this.$store
        .dispatch("actionGetOrderLogs", { order_id })
        .then((response) => {
        })
        .catch((err) => { });
    },
    // openHistoryLogs() {
    //   this.showPopup = true;
    // },
    // closePopup() {
    //   this.showPopup = false;
    // },
  },
};
</script>
