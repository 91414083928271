<template>
  <div class="table-wrapper">
    <slot-table :data="equipmentPartsTractorList" class="eq-table-default">
      <template #header>
        <tr>
          <th class="checkbox-table">
            <input type="checkbox" v-model="selectAll" @change="selectAllItems" />
          </th>
          <th v-for="column in columns" :class="{ 'has-sort': column.sort }" :key="column.name" @click="sort(column)">
            {{ column.label }}
            <span v-if="column.name === queryParameters.orderBy" :class="`sorting-icon-${queryParameters.order == 'ASC' ? 'ASC' : 'DESC'
              }`">
            </span>
            <template v-else>
              <span v-if="column.sort" class="sorting-icon"> </span>
            </template>
          </th>
        </tr>
      </template>
      <template v-slot:body="{ item }">
        <tr>
          <td class="checkbox-table">
            <input type="checkbox" v-model="selectedItems[item.part_id]" @change="toggleSelection" />
          </td>
          <td class="no-wrap">{{ item.name }}</td>
          <td class="no-wrap">{{ item.trailer_type }}</td>
          <td class="no-wrap">{{ item.make }}</td>
          <td class="no-wrap">{{ item.year_model }}</td>
          <td class="no-wrap">{{ item.vin }}</td>
          <td class="no-wrap">{{ item.license }}</td>
          <td class="no-wrap">{{ item.registration_place }}</td>
          <td class="no-wrap">{{ item.registered_weight }} lbs</td>
          <td class="no-wrap">{{ item.axle_quantity }}</td>

          <td class="eq-actions-td">
            <div class="eq-actions">
              <edit-button @click="editTractorPopup(item)" />
              <delete-button title="Delete" @click="deleteTractor(item)" />
            </div>
          </td>
        </tr>
      </template>
    </slot-table>
  </div>

  <div v-if="!!equipmentPartsTractorList && equipmentPartsTractorList.length === 0">
    <div class="no-equipment">
      <div class="no-equipment__content">
        <h2>You don't have any power units</h2>
        <!-- <button @click="showEquipmentForm" class="small button">
          Add new tractor & trailer
        </button> -->
      </div>
    </div>
  </div>


  <button v-if="
    !!equipmentPartsTractorList && equipmentPartsTractorList.length < total
  " class="button small button-loadmore" @click="loadMore">
    Load More
  </button>
  <edit-tractor-form :item="activeItem" v-show="showEditTractorPopup" @close-popup="hideEditTractorPopup" />
  <confirmation-dialog v-if="showConfirmDialog" content="Are you sure want to remove this power unit?"
    @cancel="cancelRemoveTractor" @confirm="confirmRemoveTractor" />
</template>

<script>
import EditTractorForm from "@/components/equipment/forms/EditTractorForm.vue";
import SlotTable from "@/components/SlotTable.vue";
import EditButton from "@/components/buttons/EditButton";
import DeleteButton from "@/components/buttons/DeleteButton";
import { FeetMathMixin } from "@/mixins/FeetMathMixin";
import ConfirmationDialog from "@/components/helpers/ConfirmationDialog.vue";
import { nextTick } from "vue";
export default {
  name: "TractorTab",
  components: {
    SlotTable,
    EditButton,
    DeleteButton,
    EditTractorForm,
    ConfirmationDialog,
  },
  mixins: [FeetMathMixin],
  computed: {
    equipmentPartsTractorList() {
      return this.$store.getters.equipmentPartsTractorList;
    },
    user_role() {
      return this.$store.getters.userRole;
    },
  },

  data() {
    return {
      selectAll: false,
      selectedItems: {},
      showEditTractorPopup: false,
      showConfirmDialog: false,
      activeItem: {},
      total: null,
      columns: [
        { name: "name", label: "Name", sort: true },
        { name: "trailer_type", label: "Type", sort: false },
        { name: "make", label: "Make", sort: false },
        { name: "year_model", label: "Year Model", sort: false },
        { name: "vin", label: "VIN Number", sort: false },
        { name: "license", label: "License", sort: false },
        { name: "registration_place", label: "State", sort: false },
        { name: "registered_weight", label: "Registered Weight", sort: false },
        { name: "axle_quantity", label: "# Axles", sort: false },
        { name: "actions", label: "Actions", sort: false },
      ],

      queryParameters: {
        offset: 0,
        limit: 12,
        orderBy: "name",
        order: "DESC",
        type: "tractor",
        company_id: this.$store.getters.userData.company.company_id,
      },
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    showEquipmentForm() {
      this.$store.dispatch("actionToggleShowEquipmentForm");
    },
    getDataList() {
      const parameters = this.queryParameters;
      this.$store
        .dispatch("actionGetOwnerEquipmentPartsList", { parameters })
        .then((response) => {
          this.total = response.total;
        })
        .catch((err) => { });
      this.$store.dispatch("actionSelectedCheckboxItems", []);
      this.selectedItems = {};
      this.selectAll = false;
    },
    async sort(column) {
      if (!column.sort) return;

      if (this.queryParameters.orderBy === column.name) {
        this.queryParameters.order =
          this.queryParameters.order === "ASC" ? "DESC" : "ASC";
      } else {
        this.queryParameters.orderBy = column.name;
        this.queryParameters.order = "ASC";
      }
      await nextTick();
      const parameters = { ...this.queryParameters };
      parameters.limit += parameters.offset;
      parameters.offset = 0;

      this.$store
        .dispatch("actionGetOwnerEquipmentPartsList", { parameters })
        .then((response) => {
          this.total = response.total;
        })
        .catch((err) => { });
    },

    loadMore() {
      const newOffset = this.equipmentPartsTractorList.length;
      this.queryParameters.offset = newOffset;
      const parameters = this.queryParameters;

      this.$store
        .dispatch("actionGetOwnerEquipmentPartsList", {
          parameters,
          event: "pagination",
        })
        .then((response) => {
          this.total = response.total;
        })
        .catch((err) => { });
    },
    editTractorPopup(item) {
      let scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollPosition}px`;
      document.body.style.width = '100%';
      this.showEditPopup = true;
      this.showEditTractorPopup = true;
      this.activeItem = item;
    },

    hideEditTractorPopup() {
      let scrollPosition = parseInt(document.body.style.top || '0') * -1;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      window.scrollTo(0, scrollPosition);
      this.showEditTractorPopup = false;
      this.getDataList();
    },

    cancelRemoveTractor() {
      this.activeItem = {};
      this.showConfirmDialog = false;
    },
    confirmRemoveTractor() {
      const part_id = this.activeItem.part_id;
      this.$store
        .dispatch("actionDeletePartEquipment", { part_id })
        .then((response) => {
          this.showConfirmDialog = false;
          this.getDataList();
          this.$store.dispatch("alert", {
            message: "Power Unit deleted",
            type: "info",
          });
        })
        .catch((err) => { });
    },
    deleteTractor(item) {
      // if (item.used_in_equipments) {
      // this.$store.dispatch("alert", {
      //   message: "Unable to delete tractor when used in equipment.",
      //   type: "info",
      // });
      // return;
      // }
      this.activeItem = item;
      this.showConfirmDialog = true;
    },
    toggleSelection(event) {
      const itemId = event.target.value;
      this.selectedItems[itemId] = event.target.checked;
      const ids = Object.keys(this.selectedItems).filter(
        (key) => this.selectedItems[key] === true && key !== "on"
      );
     
      this.$store.dispatch("actionSelectedCheckboxItems", ids);
      this.selectAll = ids.length === this.equipmentPartsTractorList.length;
    },
    selectAllItems() {
      const selectAllChecked = this.selectAll;
      this.equipmentPartsTractorList.forEach((item) => {
        this.selectedItems[item.part_id] = selectAllChecked;
      });

      const ids = selectAllChecked
        ? this.equipmentPartsTractorList.map((item) => item.part_id)
        : [];
      this.$store.dispatch("actionSelectedCheckboxItems", ids);
    },
  },


};
</script>
