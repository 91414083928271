<template>
  <profile-data-form />
  <profile-password-form />
</template>

<script>
import ProfileDataForm from "@/components/profile/ProfileDataForm.vue";
import ProfilePasswordForm from "@/components/profile/ProfilePasswordForm.vue";

export default {
  name: "ProfileSettings",
  components: {
    ProfileDataForm,
    ProfilePasswordForm,
  },
  data() {
    return {};
  },
};
</script>
