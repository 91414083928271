export default {
  state: {
    // company_users: null,
    companies_list: null,
  },
  getters: {
    companiesList(state) {
      if (!state.companies_list) {
        return null;
      }
      return state.companies_list;
    },

  },
  mutations: {
    mutation_get_companies_list(state, payload) {
      state.companies_list = payload;
    },
    mutation_pagination_get_companies_list(state, payload) {
      state.companies_list = state.companies_list.concat(payload);
    },

  },
  actions: {
    async actionGetCompaniesList(
      { commit, dispatch, state },
      { parameters, event = "change_parameters" }
    ) {

      try {
        const request = await dispatch("actionRequest", {
          uri: "/get-companies-list",
          method: "POST",
          post_data: parameters,
        });
        const result = request.result;

        const companies_list = result.companies_list;

        if (event === "change_parameters") {
          commit("mutation_get_companies_list", companies_list);
        } else if (event === "pagination") {
          commit("mutation_pagination_get_companies_list", companies_list);
        } else if (event === "search") {
          state.companies_list = [];
          commit("mutation_get_companies_list", companies_list);
        }

        return Promise.resolve(result);
      } catch (err) {
        return Promise.reject(err);
      }
    },

  },
};
