<template>
  <!-- for manager -->
  <button
    v-if="!item.manager_id && user_data.role === 'manager'"
    class="toggle-button"
    :class="{ active: isActive }"
    @click="assignBySelf"
  >
    <div class="slider"></div>
  </button>

  <div class="manager-name" v-if="isActive">
    {{ item.manager_name }}
    <span
      v-if="user_data.role === 'admin'"
      class="unassign-manager-button"
      @click="unassign"
    >
      <svg
        width="20"
        height="20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        viewBox="0 0 100 100"
        xml:space="preserve"
      >
        <path
          fill="none"
          stroke="#e15b64"
          stroke-width="16"
          stroke-miterlimit="10"
          d="M22 78l56-56"
        />
        <path
          fill="none"
          stroke="#e15b64"
          stroke-width="16"
          stroke-miterlimit="10"
          d="M78 78L22 22"
        />
      </svg>
    </span>
  </div>

  <!-- user_data.role === 'manager' -> change to admin -->
  <button
    v-if="!isActive && user_data.role === 'admin'"
    class="button small"
    @click="onSelectManagerPopup()"
  >
    Assign Agent
  </button>
  <admin-manager-select
    v-if="showPopup && user_data.role === 'admin'"
    :item="item"
    @updated-item="updateItem"
    @close-popup="closePopup"
  />
  <spinner :loading="loading" />
</template>

<script>
import Spinner from "@/components/helpers/Spinner.vue";
import AdminManagerSelect from "@/components/AdminManagerSelect.vue";
export default {
  components: { AdminManagerSelect, Spinner },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showPopup: false,
      isActive: false,
      loading: false,
    };
  },
  computed: {
    user_data() {
      return this.$store.getters.userData;
    },
  },
  created() {
    this.checkAssign();
  },
  methods: {
    onSelectManagerPopup() {
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
    },
    checkAssign() {
      if (this.item.manager_id) {
        this.isActive = true;
      }
    },

    assignBySelf() {
      this.loading = true;
      const order_id = this.item.order_id;
      if (!this.item.manager_id) {
        this.isActive = true;

        const parameters = { order_id };

        this.$store
          .dispatch("actionAssignManagerToOrder", { parameters })
          .then((response) => {
            this.item.manager_name = response.manager_name;
            this.item.manager_id = response.manager_id;
            this.loading = false;
          });
      }
    },

    unassign() {
      const manager_id = null;
      const order_id = this.item.order_id;
      const parameters = { order_id, manager_id };
      this.$store.dispatch("actionAdminAssignManagerToOrder", { parameters });
      this.isActive = false;
    },

    updateItem() {
      this.isActive = true;
    },
  },
};
</script>

<style scoped>
.toggle-button {
  position: relative;
  width: 60px;
  height: 30px;
  border: none;
  background-color: #e0e0e0;
  border-radius: 15px;
  cursor: pointer;
  outline: none;
  padding: 3px;
  transition: background-color 0.3s;
}

.toggle-button.active {
  background-color: #6b7280;
}

.slider {
  position: absolute;
  top: 3px;
  left: 6px;
  width: 24px;
  height: 24px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s, background-color 0.3s;
}

.toggle-button.active .slider {
  transform: translateX(100%);
  background-color: #0080ea;
}

.manager-name {
  display: flex;
  align-items: center;
}
.unassign-manager-button {
  width: 15px;
  height: 15px;
  display: flex;
  margin-left: 5px;
  cursor: pointer;
}
</style>
