<template>
  <div v-if="user_data.role === 'owner' || user_data.role === 'driver'" id="equipment-page">
    <div class="bulk-info" v-if="getSelectedCheckboxItems.length">
      <div class="bulk-info-selected">
        {{ getSelectedCheckboxItems.length }} unit selected
      </div>
      <div class="bulk-info-actions">
        <delete-button @click="showConfirmDialog = true" />
      </div>
    </div>
    <div class="table-wrap">
      <div class="eq-buttons">
        <div class="tab-buttons">
          <button v-for="(tab, index) in tabs" :key="index" @click="onCLickTab(tab, index)"
            :class="{ active: activeTab === index }">
            {{ tab.title }}
          </button>
        </div>
        <div class="eq-buttons-actions">
          <button v-if="activeTab === 0" @click="showFormPopup('showAddTractorPopup')" class="button small">
            Add new power unit
          </button>
          <button v-if="activeTab === 1" @click="showFormPopup('showAddTrailerPopup')" class="button small">
            Add new trailer
          </button>
          <button v-if="activeTab === 2" @click="showFormPopup('showSMEPopup')" class="button small">
            Add new sme
          </button>

        </div>
      </div>
      <div class="tab-content">
        <div v-for="(tab, index) in tabs" :key="index" :class="{ active: activeTab === index }">
          <tractor-tab v-if="tab.refName === 'ref_tractors'" ref="ref_tractors" />
          <trailer-tab v-if="tab.refName === 'ref_trailers'" ref="ref_trailers" />
          <s-m-e-tab v-if="tab.refName === 'ref_sme'" ref="ref_sme" />

        </div>
      </div>
    </div>
  </div>

  <add-tractor-form v-if="showAddTractorPopup" @close-popup="hideFormPopup('showAddTractorPopup')"
    @update-table="updateEquipmentList" />
  <add-s-m-e-form v-if="showSMEPopup" @close-popup="hideFormPopup('showSMEPopup')" @update-table="updateEquipmentList" />
  <add-trailer-form v-if="showAddTrailerPopup" @close-popup="hideFormPopup('showAddTrailerPopup')"
    @update-table="updateEquipmentList" />

  <confirmation-dialog v-if="showConfirmDialog" :content="confirmationMessage" @cancel="() => {
    this.showConfirmDialog = false;
  }
    " @confirm="onMultiDeleteEquipment" />
</template>

<script>
import { ref } from "vue";
import ConfirmationDialog from "@/components/helpers/ConfirmationDialog.vue";
import DeleteButton from "@/components/buttons/DeleteButton";
import EquipmentForm from "@/components/equipment/EquipmentForm.vue";
import AddTractorForm from "@/components/equipment/forms/AddTractorForm.vue";
import AddTrailerForm from "@/components/equipment/forms/AddTrailerForm.vue";
import AddSMEForm from "@/components/equipment/forms/AddSMEForm.vue";
import TractorTab from "@/components/equipment/TractorTab.vue";
import TrailerTab from "@/components/equipment/TrailerTab.vue";
import SMETab from "@/components/equipment/SMETab.vue";

export default {
  name: "EquipmentPage",
  components: {
    TractorTab,
    TrailerTab,
    SMETab,
    EquipmentForm,
    AddTractorForm,
    AddTrailerForm,
    AddSMEForm,
    DeleteButton,
    ConfirmationDialog,
  },
  setup() {
    const ref_tractors = ref(null);
    const ref_trailers = ref(null);
    const ref_sme = ref(null);

    return {
      ref_tractors,
      ref_trailers,
      ref_sme,
    };
  },
  computed: {
    user_data() {
      return this.$store.getters.userData;
    },
    getSelectedCheckboxItems() {
      return this.$store.getters.getSelectedCheckboxItems;
    },
    confirmationMessage() {
      const itemCount = this.getSelectedCheckboxItems.length;
      return itemCount === 1 
        ? 'Are you sure you want to delete the equipment?' 
        : 'Are you sure you want to delete the ('+this.getSelectedCheckboxItems.length+') equipments?';
    }
  },
  data() {
    return {
      selectedEquipment: [],
      showConfirmDialogDefEquipment: false,
      showConfirmDialog: false,
      activeTab: 0,
      showAddTractorPopup: false,
      showSMEPopup: false,
      showAddTrailerPopup: false,
      showAddHelperPopup: false,
      tabs: [
        { title: "Power Units", refName: "ref_tractors" },
        { title: "Trailers", refName: "ref_trailers" },
        { title: "Special Mobile Equipment", refName: "ref_sme" },
      ],
    };
  },
  created() {
    this.getEquipmentPartsList();
    this.getStatesList();
  },

  methods: {
    getStatesList() {
      this.$store.dispatch("actionGetStates").then(() => { });
    },
    getEquipmentPartsList() {
      const parameters = {
        offset: 0,
        limit: 999,
        orderBy: "name",
        order: "DESC",
        type: "all",
        company_id: this.$store.getters.userData.company.company_id,
      };
      this.$store
        .dispatch("actionGetOwnerEquipmentPartsList", { parameters })
        .then((response) => { })
        .catch((err) => { });
    },

    onCLickTab(tab, index) {
      this.activeTab = index;
      switch (tab.refName) {
        case "ref_tractors":
          this.ref_tractors[0].getDataList();
          break;
        case "ref_sme":
          this.ref_sme[0].getDataList();
          break;
        case "ref_trailers":
          this.ref_trailers[0].getDataList();
          break;
        default:
          this.ref_tractors[0].getDataList();
          break;
      }
    },
    updateEquipmentList() {
      let scrollPosition = parseInt(document.body.style.top || '0') * -1;

      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';

      window.scrollTo(0, scrollPosition);
      const refName = this.tabs[this.activeTab].refName;
      switch (refName) {
        case "ref_tractors":
          this.ref_tractors[0].getDataList();
          this.showAddTractorPopup = false;
          break;
        case "ref_sme":
          this.ref_sme[0].getDataList();
          this.showSMEPopup = false;
          break;
        case "ref_trailers":
          this.ref_trailers[0].getDataList();
          this.showAddTrailerPopup = false;
          break;
        default:
          this.ref_tractors[0].getDataList();
          break;
      }
    },

    showFormPopup(popupName) {
      let scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollPosition}px`;
      document.body.style.width = '100%';
      this[popupName] = true;
    },

    hideFormPopup(popupName) {
      let scrollPosition = parseInt(document.body.style.top || '0') * -1;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      window.scrollTo(0, scrollPosition);
      this[popupName] = false;
    },
    onMultiDeleteEquipment() {
      const ids = this.getSelectedCheckboxItems;
      this.$store.dispatch("actionMultiDeleteOwnerEquipment", { ids })
      .then(() => {
        this.showConfirmDialog = false;
        this.ref_tractors[0].selectedItems = {};
        this.ref_tractors[0].selectAll = false;
        this.ref_tractors[0].getDataList();
        this.ref_trailers[0].selectedItems = {};
        this.ref_trailers[0].selectAll = false;
        this.ref_trailers[0].getDataList();
        this.ref_sme[0].selectedItems = {};
        this.ref_sme[0].selectAll = false;
        this.ref_sme[0].getDataList();
        this.$store.dispatch("actionSelectedCheckboxItems", []);
      })
    },


  },
  watch: {

  }
};
</script>
