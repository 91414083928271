<template>
  <div id="home-page">
    Home Page
  </div>
</template>

<script>


export default {
  name: "HomePage",

  data() {
    return {

      
    }
  },
 
}
</script>
