<template>
  <div>
    <div class="form-elements">
      <div class="form-line">

        <div class="input-box calc-form-title">Contact Information</div>
      </div>
      <div class="form-line">
        <div class="form-grid r-2-s-2">
          <div>Company</div>
          <input-fake v-model="fields.company" :copy="canIcopy" />
        </div>
      </div>
      <div class="form-line">
        <div class="form-grid r-2-s-2">
          <div>Name</div>
          <input-fake v-model="fields.name" :copy="canIcopy" />
        </div>
      </div>
      <div class="form-line">
        <div class="form-grid r-2-s-2">
          <div>Last name</div>
          <input-fake v-model="fields.lastname" :copy="canIcopy" />
        </div>
      </div>
      <div class="form-line">
        <div class="form-grid r-2-s-2">
          <div>Phone</div>
          <input-fake v-model="fields.phone" :copy="canIcopy" />
        </div>
      </div>
      <div class="form-line">
        <div class="form-grid r-2-s-2">
          <div>Email</div>
          <input-fake v-model="fields.email" :copy="canIcopy" />

        </div>
      </div>

      <div class="form-line">
        <div class="form-grid r-2-s-2">
          <div>Date</div>
          <input-fake v-model="fields.date" :copy="canIcopy" />
        </div>
      </div>

      <div class="form-line">
        <div class="form-grid center">
          <div class="form-line clc-btn__wrapper">
            <button v-if="mode === 'new'" type="button" class="button clc-button big-button primary-button button-apply"
              @click="requestPermits" :disabled="!isStepValid">
              <span>Apply</span>
            </button>
            <button v-if="((user_role === 'manager' && order_by_id.manager_id == user_data.user_id || user_role === 'admin')) || ((user_role === 'owner' || user_role === 'driver') &&
              mode === 'current' &&
              order_by_id.order_status === 'deposit_needed') ||
              (order_by_id.order_status === 'deposit_reminder' &&
                (user_role === 'owner' || user_role === 'driver'))
            " type="button" class="button clc-button big-button primary-button" :disabled="!isStepValid"
              @click="requestPermits">
              <span>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import InputFake from '@/components/form-elements/InputFake.vue';
import InputText from '@/components/form-elements/InputText.vue';
import InputPhone from '@/components/form-elements/InputPhone.vue';
import InputEmail from '@/components/form-elements/InputEmail.vue';
import InputDate from "@/components/form-elements/InputDate.vue";
import ValidateMixin from "@/mixins/ValidateMixin";

export default {
  name: "StepFormTwo",
  components: { InputText, InputFake, InputPhone, InputEmail, InputDate },
  mixins: [ValidateMixin],

  data() {
    return {
      stepForm: {

        load_time: null,
      },
      fields: {
        name: null,
        phone: null,
        email: null,
        company: null,
        date: null,
      },
      errors: {
        load_time: false,
      },

    };
  },
  props: {

    mode: {
      type: String,
      default: "new",
    },

  },
  mounted() {
    this.stepForm.load_time = this.getCurrentDate;

    this.fields.company = this.user_data.company.company_name;
    this.fields.name = this.user_data.name;
    this.fields.lastname = this.user_data.lastname;
    this.fields.phone = this.user_data.phone;
    this.fields.email = this.user_data.email;
    this.fields.date = this.getCurrentDate;
  },
  computed: {
    user_role() {
      return this.$store.getters.userRole;
    },
    canIcopy() {
      if (this.user_role === 'manager' || this.user_role === 'admin') {
        return true;
      } else {
        return false;
      }
    },
    minDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');

      return `${month}-${day}-${year}`;

    },
    maxDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear() + 3; // 3 years
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');

      return `${month}-${day}-${year}`;

    },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
      const day = String(today.getDate()).padStart(2, '0');
      const hours = String(today.getHours()).padStart(2, '0');
      const minutes = String(today.getMinutes()).padStart(2, '0');
      const seconds = String(today.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    order_by_id() {
      return this.$store.getters.order_by_id;
    },
    isStepValid() {
      const stepForm = this.stepForm;
      let valid = true;
      Object.keys(stepForm).forEach(key => {
        if (!stepForm[key]) {
          valid = false;
        }
      });

      if (!valid) {
        return false;
      }

      const exist_error = Object.keys(this.errors).find(el => this.errors[el]);
      return !exist_error;
    },
    user_data() {
      return this.$store.getters.userData;
    },
  },

  emits: ['updateStepTwo', 'formValid'],
  methods: {

    requestPermits() {
      this.$emit('formValid', this.isStepValid);
      if (this.isStepValid) {
        this.$emit('updateStepTwo', this.stepForm);
      }

    },


  },
  watch: {
    order_by_id(new_data) {
      this.stepForm.load_time = new_data.load_time;

      this.fields.company = new_data.initiator_company;
      this.fields.name = new_data.initiator_name;
      this.fields.lastname = new_data.initiator_lastname;
      this.fields.phone = new_data.initiator_phone;
      this.fields.email = new_data.initiator_email;
      this.fields.date = new_data.load_time;
    },
  }

};
</script>
