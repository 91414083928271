<template>
  <div
    :class="[
      'password-input-wrap',
      'input-box',
      'input-password',
      { 'not-valid-data': !!errorText },
    ]"
  >
  <span 
  
  :class="[
    'icon-span',
    {'lock-icon': !isFilled && !visible},
    {'eye-hidden-icon' : visible && !isFilled}
  ]
  "
  ></span>
    <div
      v-if="isFilled"
      @click="clearField"
      class="input-close-icon j-icon"
    ></div>
    <input
      v-model="inputData"
      :placeholder="placeholder"
      :type="visible ? 'text' : 'password'"
      :id="input_id ? input_id : null"
      :name="input_id ? input_id : null"
      :disabled="disabled"
      :autocomplete="autocomplete"
      @blur="eventBlur"
      @input="eventInput"
    />
    <span v-if="!!errorText" class="err">{{ errorText }}</span>

    <div class="pass-panel">
      <div
      class="password-show-button"
        v-if="isFilled"
        @click="visible = !visible"
      >
        <div v-if="visible" class="button-password eye-hidden-icon"></div>
        <div v-else class="button-password  eye-icon"></div>
      </div>

      <div
        v-if="passGen"
        @click="createPassword"
        class="button-password key-gen-icon  password-generator-password"
      >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputPassword",
  data() {
    return {
      inputData: this.modelValue,
      visible: false,
      errorText: null,
    };
  },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Boolean],
      default: null,
    },
    lazy: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    passGen: {
      type: Boolean,
      default: false,
    },
    input_id: {
      type: String,
      default: null,
    },
    validSw: {
      type: [Boolean,String],
    },
    autocomplete: {
      type: String,
      default: 'on'
    }
  },
  computed: {
    isFilled() {
      return this.inputData && !!this.inputData.length;
    },
  },
  emits: ['update:modelValue', 'blur', 'generated', 'error'],
  methods: {
    createPassword() {
      this.errorText = null;
      const new_password = this.passwordGen(14);
      this.inputData = new_password;
      this.visible = true;
      this.$emit("update:modelValue", new_password);
      this.$emit("blur", new_password);
      this.$emit("generated", new_password);
    },
    /**
     * password generator
     * @param sign_num
     * @returns {string}
     */
    passwordGen(sign_num = 9) {
      let num, s;
      const sign_arr = [];
      for (let i = 0; i < sign_num; i++) {
        num = i % 3;
        switch (num) {
          case 0:
            s = Math.round(49 + Math.random() * (57 - 49));
            break;
          case 1:
            s = Math.round(65 + Math.random() * (90 - 65));
            break;
          case 2:
            s = Math.round(97 + Math.random() * (122 - 97));
            break;
        }
        sign_arr.push(String.fromCharCode(s));
      }
      const sorted = sign_arr.sort(() => (Math.random() > 0.5 ? 1 : -1));
      return sorted.join("").toString();
    },
    eventInput(e) {
      const value = e.target.value;
      this.errorText = null;
      this.$emit("update:modelValue", value);
    },
    eventBlur(e) {
      const value = e.target.value;
      this.$emit("blur", value);
      this.validateData(value);
    },
    clearField() {
      this.inputData = null;
      this.$emit("update:modelValue", null);
      this.$emit("blur", null);
    },
    validateData(v) {
      if (!!this.rules && this.rules.length) {
        const err_txt = this.rules.map((f) => f(v)).find((itm) => itm !== true);

        if (!!err_txt) {
          this.errorText = err_txt;
          this.$emit("error", true);
          return;
        } else {
          this.errorText = null;
          this.$emit("error", false);
          return;
        }
      }
      this.$emit("error", false);
    },
  },
  watch: {
    modelValue(newData) {
      this.inputData = newData;
    },
    validSw(v) {
      this.validateData(this.inputData);
    },
    clear() {
      this.clearField();
    },
  //   errorText(newValue) {
  //   this.errorText = newValue;
  // }
  },
};
</script>
