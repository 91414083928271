<template>
  <div
    class="select-manager-popup"
    @click="closePopupOutside"
    ref="popupContainer"
  >
    <div class="popup-content">
      <button class="select-manager-popup-close" @click="closePopup">
        <svg
          width="20"
          height="20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 100 100"
          xml:space="preserve"
        >
          <path
            fill="none"
            stroke="#e15b64"
            stroke-width="16"
            stroke-miterlimit="10"
            d="M22 78l56-56"
          />
          <path
            fill="none"
            stroke="#e15b64"
            stroke-width="16"
            stroke-miterlimit="10"
            d="M78 78L22 22"
          />
        </svg>
      </button>

      <h3>Select Agent:</h3>

      <ul>
        <li
          v-if="managerList.length"
          v-for="manager in managerList"
          :key="manager.user_id"
          @click="onSelectManager(manager)"
        >
          {{ manager.name }}
        </li>
      </ul>
    </div>
  </div>
  <spinner :loading="loading"/>
</template>

<script>

import Spinner from "@/components/helpers/Spinner.vue";
export default {
  name: "AdminManagerSelect",
  components: {
    Spinner
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    showPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      managerList: [],
    };
  },
  emits:['close-popup','updated-item'],
  mounted() {
    this.assignManager();
  },

  methods: {
    assignManager() {
      this.$store
        .dispatch("actionGetUsersByRole", { parameters: { role: "manager" } })
        .then((response) => {
          this.managerList = response.users;
        });
    },
    onSelectManager(manager) {
      this.loading = true;
      const order_id = this.item.order_id;
      this.item.manager_name = manager.name;
      const manager_id = (this.item.manager_id = manager.user_id);

      const parameters = { order_id, manager_id };
      this.$store
        .dispatch("actionAdminAssignManagerToOrder", { parameters })
        .then((response) => {
          this.item.manager_name = response.manager_name;
          this.item.manager_id = response.manager_id;
          this.$emit("updated-item");
          this.closePopup();
          this.loading = false;
        });
    },
    closePopup() {
      this.$emit("close-popup");
    },
    closePopupOutside(event) {
      const popupContainer = this.$refs.popupContainer;

      if (event.target === popupContainer) {
        this.closePopup();
      }
    },
  },
};
</script>
