<template>
  <div class="company_tab">
    <div class="company_tab__items">
      <div class="company_tab__item">
        <div class="company_tab__item__left">Company Name</div>
        <div class="company_tab__item__right">
          <template v-if="!isEditing">
            {{ formData.company_name }}
          </template>
          <template v-else>

            <input-text v-if="user_data.role === 'owner'" v-model="formData.company_name" :valid-sw="validSw" :rules="rules.companyName" @error="(err) => {
              regError('companyName', err);
            }
              " />
            <input-fake v-else v-model="formData.company_name" />
          </template>
        </div>
      </div>
      <div class="company_tab__item">
        <div class="company_tab__item__left">Company Account Administrator</div>
        <div class="company_tab__item__right">
          {{ companyOwnerTitle }}
        </div>
      </div>
      <div class="company_tab__item">
        <div class="company_tab__item__left">Doing business as</div>
        <div class="company_tab__item__right">
          <template v-if="!isEditing">
            {{ formData.doing_business_as ? formData.doing_business_as : 'Please fill...' }}
          </template>
          <template v-else>
            <input-text v-model="formData.doing_business_as" maxlength="50" placeholder="Enter doing business as" />
          </template>
        </div>
      </div>
      <div class="company_tab__item">
        <div class="company_tab__item__left">Phone</div>
        <div class="company_tab__item__right">
          <template v-if="!isEditing">
            {{ formData.company_phone ? formData.company_phone : 'Please fill...' }}
          </template>
          <template v-else>
            <div class="form-element phone">
              <input-phone v-model="formData.company_phone" :valid-sw="validSw" :rules="rules.phone" :maxlength="14"
              @error="(err) => {
                regError('company_phone', err);
              }
                " />
            </div>
          </template>
        </div>
      </div>
      <div class="company_tab__item">
        <div class="company_tab__item__left">Address</div>
        <div class="company_tab__item__right">
          <template v-if="!isEditing">
            {{ formData.address ? formData.address : 'Please fill...' }}
          </template>
          <template v-else>
            <input-text v-model="formData.address" :rules="rules.address" placeholder="Enter address"
              :valid-sw="validSw" maxlength="50" @error="(err) => { regError('address', err); }" />
          </template>
        </div>
      </div>
      <div class="company_tab__item">
        <div class="company_tab__item__left">City</div>
        <div class="company_tab__item__right">
          <template v-if="!isEditing">
            {{ formData.city ? formData.city : 'Please fill...' }}
          </template>
          <template v-else>
            <input-text v-model="formData.city" :valid-sw="validSw" placeholder="Enter city" :rules="rules.requiredText"
              @error="(err) => {
                regError('city', err);
              }
                " />
          </template>
        </div>
      </div>
      <div class="company_tab__item">
        <div class="company_tab__item__left">State</div>
        <div class="company_tab__item__right">
          <template v-if="!isEditing">
            {{ formData.state ? formData.state : 'Please fill...' }}
          </template>
          <template v-else>
            <input-text v-model="formData.state" :rules="rules.requiredText" :valid-sw="validSw"
              placeholder="Enter state" @error="(err) => {
                regError('state', err);
              }
                " />
          </template>
        </div>
      </div>
      <div class="company_tab__item">
        <div class="company_tab__item__left">Zip</div>
        <div class="company_tab__item__right">
          <template v-if="!isEditing">
            {{ formData.zip_code ? formData.zip_code : 'Please fill...' }}
          </template>
          <template v-else>
            <input-text v-model="formData.zip_code" :valid-sw="validSw" placeholder="Enter zip code"
              :rules="rules.zipCode" maxlength="5" @error="(err) => {
                regError('zip_code', err);
              }
                " />
          </template>
        </div>
      </div>
      <div class="company_tab__item">
        <div class="company_tab__item__left">USDOT</div>
        <div class="company_tab__item__right">
          <template v-if="!isEditing">
            {{ formData.usdot ? formData.usdot : 'Please fill...' }}
          </template>
          <template v-else>
            <input-text v-model="formData.usdot" placeholder="Enter USDOT" maxlength="15" />
          </template>
        </div>
      </div>
      <div class="company_tab__item">
        <div class="company_tab__item__left">MC#</div>
        <div class="company_tab__item__right">
          <template v-if="!isEditing">
            {{ formData.mc ? formData.mc : 'Please fill...' }}
          </template>
          <template v-else>
            <input-text v-model="formData.mc" placeholder="Enter MC#" maxlength="15" />
          </template>
        </div>
      </div>
      <div class="company_tab__item">
        <div class="company_tab__item__left">FID</div>
        <div class="company_tab__item__right">
          <template v-if="!isEditing">
            {{ formData.fid ? formData.fid : 'Please fill...' }}
          </template>
          <template v-else>
            <input-text :rules="rules.numberTT" :valid-sw="validSw" v-model="formData.fid" placeholder="Enter FID"
              maxlength="15" />
          </template>
        </div>
      </div>
      <div class="company_tab__item">
        <div class="company_tab__item__left">IFTA</div>
        <div class="company_tab__item__right">
          <template v-if="!isEditing">
            {{ formData.ifta ? formData.ifta : 'Please fill...' }}
          </template>
          <template v-else>
            <input-text v-model="formData.ifta" placeholder="Enter IFTA" maxlength="15" />
          </template>
        </div>
      </div>
      <div class="company_tab__item">
        <div class="company_tab__item__left">IRP</div>
        <div class="company_tab__item__right">
          <template v-if="!isEditing">
            {{ formData.irp ? formData.irp : 'Please fill...' }}
          </template>
          <template v-else>
            <input-text v-model="formData.irp" placeholder="Enter IRP" maxlength="15" />
          </template>
        </div>
      </div>
      <div class="company_tab__item">
        <div class="company_tab__item__left">Days To Archive Orders</div>
        <div class="company_tab__item__right">
          <template v-if="!isEditing">
            {{ formData.days_to_archive }}
          </template>
          <template v-else>
            <input-number v-model="formData.days_to_archive" placeholder="Enter days quantity" min="2" max="180"
              maxlength="3" />
          </template>
        </div>
      </div>
    </div>

    <div class="company_tab__controls">
      <notification :is-visible="notificationShow" :status="true" text="Changes saved successfully" />

      <button v-if="isEditing" @click="eventCancelButton" class="button">Cancel
      </button>

      <button v-if="isEditing" class="button" @click="applyChanges" :disabled="!is_form_valid_new">
        Apply Changes
      </button>

      <button v-if="!isEditing" @click="isEditing = true" class="button">Edit Information
      </button>

    </div>
  </div>
</template>

<script>
import InputText from "@/components/form-elements/InputText.vue";
import InputFake from "@/components/form-elements/InputFake.vue";
import InputPhone from "@/components/form-elements/InputPhone.vue";
import ValidateMixin from "@/mixins/ValidateMixin";
import Notification from "@/components/helpers/Notification.vue";
import InputNumber from "@/components/form-elements/InputNumber.vue";

import { nextTick } from "vue";

export default {
  name: "CompanyTab",
  mixins: [ValidateMixin],
  components: {
    InputNumber,
    InputText,
    InputPhone,
    InputFake,
    Notification
  },
  data() {
    return {
      validSw: false,
      formData: {
        company_name: null,
        days_to_archive: null,
        company_phone: null,
        doing_business_as: null,
        address: null,
        city: null,
        state: null,
        zip_code: null,
        usdot: null,
        mc: null,
        fid: null,
        ifta: null,
        irp: null,
      },
      errors: {
        company_name: false,
        days_to_archive: false,
        company_phone: false,
        // doing_business_as: false,
        address: false,
        city: false,
        state: false,
        zip_code: false,
        // usdot: false,
        // mc: false,
        fid: false,
        // ifta: false,
        // irp: false,
      },
      isEditing: false,
      notificationShow: false
    };
  },
  async mounted() {
    await nextTick();
    this.copyDataToForm();
  },
  computed: {
    companyData() {
      return this.$store.getters.userCompany;
    },
    user_data() {
      return this.$store.getters.userData;
    },

    companyOwnerData() {
      if (!this.companyData) {
        return null;
      }
      return this.companyData.owner;
    },

    companyOwnerTitle() {
      if (!this.companyOwnerData) {
        return null;
      }
      const owner = this.companyOwnerData;
      const arr = [];
      arr.push(owner.name.trim());
      if (!!owner.lastname) {
        arr.push(owner.lastname.trim());
      }
      if (!!owner.email) {
        arr.push('(' + owner.email.trim() + ')');
      }
      return arr.join(' ');
    },

    areFormDataDifferences() {
      const form = { ...this.formData };
      const data = this.companyData;
      if (!data) {
        return false;
      }
      const dif = Object.keys(form)
        .find(key => {
          const val1 = form[key];
          const val2 = data[key];
          if (!val1 || !val2) {
            return false;
          }
          return val1.toString() !== val2.toString();
        });
      return dif !== undefined;
    },

  },
  methods: {
    is_form_valid_new() {
      this.$store.dispatch("actionGenerateId", 32).then((id) => {
        this.validSw = id;
      });
      const allFormDataNotEmpty = Object.entries(this.formData).every(([key, value]) => {
        const ignoredFields = ['doing_business_as', 'mc', 'usdot', 'ifta', 'irp'];
        return ignoredFields.includes(key) || (value !== null && value !== '');
      });
      const allErrorsFalse = Object.values(this.errors).every(error => error === false);
      let isValid = allFormDataNotEmpty && allErrorsFalse;

      // console.log('All form data fields are not empty:', allFormDataNotEmpty);
      // console.log('All errors are false:', allErrorsFalse);
      // console.log('Form is valid:', isValid);
      return isValid;
    },
    copyDataToForm() {
      this.formData.company_name = this.companyData.company_name;
      this.formData.company_phone = this.companyData.company_phone;
      this.formData.days_to_archive = +this.companyData.days_to_archive;
      this.formData.doing_business_as = this.companyData.doing_business_as;
      this.formData.address = this.companyData.address;
      this.formData.city = this.companyData.city;
      this.formData.state = this.companyData.state;
      this.formData.zip_code = +this.companyData.zip_code;
      this.formData.usdot = this.companyData.usdot;
      this.formData.mc = this.companyData.mc;
      this.formData.fid = this.companyData.fid;
      this.formData.ifta = this.companyData.ifta;
      this.formData.irp = this.companyData.irp;

      this.errors.company_name = false;
      this.errors.days_to_archive = false;
      this.errors.company_phone = false;
      this.errors.doing_business_as = false;
      this.errors.address = false;
      this.errors.city = false;
      this.errors.state = false;
      this.errors.zip_code = false;
      this.errors.usdot = false;
      this.errors.mc = false;
      this.errors.fid = false;
      this.errors.ifta = false;
      this.errors.irp = false;
    },

    eventCancelButton() {
      this.copyDataToForm();
      this.isEditing = false;
    },
    applyChanges() {
      if (!this.is_form_valid_new()) return;
      const data = this.formData;
      this.$store.dispatch('actionUserChangeCompanyData', data)
        .then(() => {
          this.copyDataToForm();
          this.notificationShow = true;
        });
      this.isEditing = false;


    },
  },
};
</script>
