export default {
  state: {
    order_meta: [],
    order_logs: [],
  },
  getters: {
    order_meta(state) {
      return state.order_meta;
    },
    order_logs(state) {
      return state.order_logs;
    },
  },
  mutations: {
    mutation_get_order_meta(state, payload) {
      state.order_meta = payload;
    },
    mutation_get_order_logs(state, payload) {
      state.order_logs = payload;
    },
  },
  actions: {


    async actionCreateNewOrder({ commit, dispatch, state }, { parameters }) {
      try {
        const request = await dispatch('actionRequest', {
          uri: "/create-new-order",
          method: 'POST',
          post_data: {parameters}
        });
        const result = request.result;

        return Promise.resolve(result);

      } catch (err) {
        return Promise.reject(err);
      }
    },

    async actionGetOrderMeta({ commit, dispatch, state }, { order_id, meta_key }) {

      try {
        const request = await dispatch('actionRequest', {
          uri: "/get-order-meta",
          method: 'POST',
          post_data: { order_id, meta_key }
        });
        const result = request.result;

        if(meta_key === '_data') {

          commit('mutation_get_order_meta', result);
        }
        return Promise.resolve(result);

      } catch (err) {
        return Promise.reject(err);
      }
    },
    async actionGetOrderLogs({ commit, dispatch, state }, { order_id}) {

      try {
        const request = await dispatch('actionRequest', {
          uri: "/get-order-logs",
          method: 'POST',
          post_data: { order_id }
        });
        const result = request.result;
        commit('mutation_get_order_logs', result);
        return Promise.resolve(result);

      } catch (err) {
        return Promise.reject(err);
      }
    },
    async actionUpdateMetaValueOrderMeta({ commit, dispatch, state }, { order_id, meta_key, meta_value }) {

      try {
        const request = await dispatch('actionRequest', {
          uri: "/update-order-meta-value",
          method: 'POST',
          post_data: { order_id, meta_key, meta_value }
        });
        const result = request.result;

        return Promise.resolve(result);

      } catch (err) {
        return Promise.reject(err);
      }
    },
    async actionUdpateOrderMeta({ commit, dispatch, state }, { order_id, data }) {

      try {
        const request = await dispatch('actionRequest', {
          uri: "/update-order-meta",
          method: 'POST',
          post_data: { order_id, data }
        });
        const result = request.result;

        return Promise.resolve(result);

      } catch (err) {
        return Promise.reject(err);
      }
    },
    async actionDeleteOrder({ commit, dispatch, state }, { order_id }) {

      try {
        const request = await dispatch('actionRequest', {
          uri: "/delete-order-by-order-id",
          method: 'POST',
          post_data: { order_id }
        });
        const result = request.result;

        return Promise.resolve(result);

      } catch (err) {
        return Promise.reject(err);
      }
    },



    async actionUploadPermit({ commit, dispatch, state }, formData) {
      try {
        const request = await dispatch('actionFileRequest', {
          uri: "/upload-permit-file",
          method: 'POST',
          post_data: formData
        });
        const result = request.result;

        return Promise.resolve(result);

      } catch (err) {
        return Promise.reject(err);
      }
    },
    async actionRemovePermit({ commit, dispatch, state }, permit_id) {
      try {
        const request = await dispatch('actionRequest', {
          uri: "/remove-permit-file",
          method: 'POST',
          post_data: permit_id
        });
        const result = request.result;

        return Promise.resolve(result);

      } catch (err) {
        return Promise.reject(err);
      }
    },

    async actionSendPermitToEmail({ commit, dispatch, state }, { order_id, permit_id }) {

      try {
        const request = await dispatch('actionRequest', {
          uri: "/send-permit-file",
          method: 'POST',
          post_data: { order_id, permit_id }
        });
        const result = request.result;

        return Promise.resolve(result);

      } catch (err) {
        return Promise.reject(err);
      }
    },
    async actionSendAllPermitsToEmail({ commit, dispatch, state }, { order_id }) {

      try {
        const request = await dispatch('actionRequest', {
          uri: "/send-all-permit-files",
          method: 'POST',
          post_data: { order_id }
        });
        const result = request.result;

        return Promise.resolve(result);

      } catch (err) {
        return Promise.reject(err);
      }
    },


  }
};