<template>
  <div id="profile-page" class="profile">
    <div :class="['profile__inner']">
     
      <div v-if="user_data.role === 'owner' || user_data.role === 'driver'" class="profile__left">
        <profile-tabs />
      </div>
      <div class="profile__left administration" v-if="user_data.role === 'admin' || user_data.role === 'manager'">
       
      </div>
      <div class="profile__right">
        <profile-settings  v-if="(isLargeScreen && (user_data.role == 'owner' || user_data.role == 'driver')) || (user_data.role == 'admin' || user_data.role == 'manager')" />
      </div>
    </div>
  </div>
</template>

<script>

import ProfileTabs from "@/components/profile/ProfileTabs.vue";
import ProfileSettings from "@/components/profile/ProfileSettings.vue";
import { ref, onMounted, onUnmounted, computed } from 'vue';
export default {
  name: "ProfilePage",
  components: {
    ProfileTabs,
    ProfileSettings,
  },
  setup() {
    const isLargeScreen = ref(window.innerWidth >= 1150);

    // Update the isLargeScreen value when the window is resized
    const updateScreenSize = () => {
      isLargeScreen.value = window.innerWidth >= 1150;
    };

    // Add an event listener to update the screen size when the window is resized
    onMounted(() => {
      window.addEventListener('resize', updateScreenSize);
    });

    // Remove the event listener when the component is unmounted
    onUnmounted(() => {
      window.removeEventListener('resize', updateScreenSize);
    });

    // Use a computed property to always get the latest value of isLargeScreen
    const isLargeScreenComputed = computed(() => isLargeScreen.value);

    return {
      isLargeScreen: isLargeScreenComputed,
    };
  },
  computed: {
    user_data() {
      return this.$store.getters.userData;
    },
  },
 
};
</script>
