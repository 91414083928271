<template>
  <div v-if="!success && !fail" class="reset-form">
    <div class="form-title">Reset Your Password</div>
    <div class="form-elements">
      <div class="form-element">
        <input-email
            :disabled="true"
            v-model="resetPasswordForm.email"
            placeholder="example@example.com"
            :rules="rules.requiredEmail"
            @error="
            (err) => {
              regError('email', err);
            }
          "
        />
      </div>
      <div class="form-element">
        <input-password
            v-model="resetPasswordForm.password"
            placeholder="Password"
            :class="{ 'not-match': !passwordsMatch }"
            :rules="rules.password"
            :pass-gen="true"
            @generated="copyGeneratedPassword"
            @error="err => {
              regError('password', err);
            }"
            @input="() => {
              regError('password', true);
            }"
            @blur="checkPasswordIdentical"
       
        />
      </div>
      <div class="form-element">
        <input-password
            v-model="resetPasswordForm.confirm_password"
            placeholder="Confirm password"
            :class="{ 'not-match': !passwordsMatch }"
            :rules="rules.confirm_password"
            @input="passwordsMatch = true"
            @blur="checkPasswordIdentical"
        />
        <!-- v-model="resetPasswordForm.confirm_password" -->
        <!-- <span class="err" >{{ errors.match_password }}</span> -->
        <span
          v-if="!passwordsMatch"
          class="err not-match">Passwords do not match</span>
      </div>
      
    </div>
    <button
        class="button"
        @click="updatePassword"
        :disabled="!isStepValid">
      Change password
    </button>
  </div>
  <password-reset-success v-if="success"/>
  <password-reset-failed v-if="fail"/>
</template>

<script>
import Spinner from "@/components/helpers/Spinner.vue";
import InputEmail from "@/components/form-elements/InputEmail.vue";
import InputPassword from "@/components/form-elements/InputPassword.vue";
import ValidateMixin from "@/mixins/ValidateMixin";
import PasswordResetSuccess from "@/pages/passwordReset/PasswordResetSuccess.vue";
import PasswordResetFailed from "@/pages/passwordReset/PasswordResetFailed.vue";

export default {
  name: "ResetPasswordForm",
  components: {
    InputEmail,
    InputPassword,
    PasswordResetSuccess,
    PasswordResetFailed,
    Spinner,
  },
  mixins: [ValidateMixin],
  data() {
    return {
      isLoading: false,
      success: false,
      fail: false,
      disabled: false,
      passwordsMatch: true,
      user_id: null,
      resetPasswordForm: {
        email: null,
        password: null,
        confirm_password: null,
      },
      errors: {
        email: null,
        password: null,
      },
    };
  },
  mounted() {
    const token = this.$route.params.token;
    this.$store.dispatch('actionGetUserByEmailToken', token)
        .then(user => {
          this.user_id = user.user_id;
          this.resetPasswordForm.email = user.email;
        });
  },
  computed: {
    isPasswordsIdentical() {
      if (!this.user_id) {
        return true;
      }
      const form = this.resetPasswordForm;
      if (!form.confirm_password) {
        return true;
      }

      if (!form.password || !form.confirm_password) {
        return false;
      }
      if (form.password.trim() !== form.confirm_password.trim()) {
        return false;
      }
      return true;
    },

    isStepValid() {
      const user_id = this.user_id;
      if (!user_id) {
        return false;
      }
      const existError = Object.keys(this.errors).find((el) => this.errors[el]);

      let emptyFieldsArr = Object.keys(this.resetPasswordForm).filter(key => {
        return !this.resetPasswordForm[key];
      });
      const isEmptyField = !!emptyFieldsArr.length;
      return !existError && !isEmptyField && this.isPasswordsIdentical && !this.disabled;
    },
  },
  methods: {
    checkPasswordIdentical() {
      setTimeout(() => {
        this.passwordsMatch = !!this.isPasswordsIdentical;
      });
    },

    copyGeneratedPassword(pass) {
      this.errors.password = false;
      this.passwordsMatch = true;
      this.resetPasswordForm.confirm_password = pass;
    },


    updatePassword() {
      this.disabled = true;
      const data = {
        user_id: this.user_id,
        password: this.resetPasswordForm.password.trim()
      };

      this.$store.dispatch('actionUpdateUserPassword', data)
          .then(response => {
            if (response.result) {
              this.disabled = false;
              this.fail = false;
              this.success = true;
  
              // this.$router.push('/');
            }
          });
    },
  },
};
</script>
