<template>
  <div class="input-box input-select">
    <select
      :disabled="disabled"
        @input="eventInput"
    >
      <option
          v-for="(item, idx) in options"
          :key="item.value"
          :value="item.value"
          :selected="item.value === modelValue"
      >{{ item.title }}
      </option>
    </select>
  </div>
</template>

<script>
import InputSize from "@/components/form-elements/InputSize.vue";

export default {
  name: "InputSelectWithValue",
  components: {InputSize},
  data() {
    return {}
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    eventInput(e) {
      let value = e.target.value;
      this.$emit('update:modelValue', value);
    }
  },


}
</script>