<template>
  <div>
    <div class="form-elements">
      <!--      Line-->
      <div class="form-line form-grid">
        <input-text v-model="stepForm.description" :valid-sw="validSw" label="Load description*"
          placeholder="Enter description" :disabled="canIfillForm" :rules="rules.requiredText"
          @error="regError('description', $event)" @blur="updateFormDataOnBlur" :copy="canIcopy" />
      </div>
      <div class="form-line form-grid r-3">
        <input-text v-model="stepForm.make" label="Make" placeholder="Enter make" :disabled="canIfillForm"
          maxlength="30" :copy="canIcopy" @blur="updateFormDataOnBlur" />
        <input-text v-model="stepForm.model" label="Model" placeholder="Enter model" :disabled="canIfillForm"
          maxlength="30" :copy="canIcopy" @blur="updateFormDataOnBlur" />
        <input-text v-model="stepForm.serial" label="Serial#" placeholder="Enter serial#" :disabled="canIfillForm"
          maxlength="30" :copy="canIcopy" @blur="updateFormDataOnBlur" />
      </div>
      <div class="form-line ">
        <div class="input-box calc-form-title">How Loaded</div>
        <div class="form-grid r-4">
          <template v-for="option in this.stepForm.how_loaded" :key="option.id">
            <input-checkbox :disabled="canIfillForm" v-model="option.value" :label="option.name" />
          </template>
        </div>
      </div>
      <div class="form-line">
        <div class="form-grid r-2">
          <div class="input-box calc-form-title">Load Dimensions</div>

          <div class="input-box acceptable-box">
            <span class="like-link" @click="display_acceptable_formats = !display_acceptable_formats">Acceptable
              Formats</span>

            <div :class="[
              'acceptable-win',
              { display: display_acceptable_formats },
            ]">
              <div class="acceptable-win__inner">
                <table>
                  <tbody>
                    <tr>
                      <td>Feet & Inches</td>
                      <td>13'6" or 13 - 6 or 13'6</td>
                    </tr>
                    <!-- <tr>
                      <td>Inches</td>
                      <td>300"</td>
                    </tr>
                    <tr>
                      <td>Metric</td>
                      <td>2.5m or 250cm</td>
                    </tr> -->
                    <!-- <tr> -->
                    <!-- <td>Metric Weight</td> -->
                    <!-- <td>5000kg</td> -->
                    <!-- </tr> -->
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="form-grid r-4">
          <input-size v-model="stepForm.dimensions.length" label="Length*" placeholder="30`" :copy="canIcopy"
            input-classes="input-icon length-icon" :disabled="canIfillForm"
            @error="regError('dimensions_length', $event)" :valid-sw="validSw" :rules="rules.requiredOnly"
            @blur="updateFormDataOnBlur" />

          <input-size v-model="stepForm.dimensions.width" label="Width*" placeholder="5`6”" :copy="canIcopy"
            input-classes="input-icon width-icon" :disabled="canIfillForm" @error="regError('dimensions_width', $event)"
            :valid-sw="validSw" :rules="rules.requiredOnly" @blur="updateFormDataOnBlur" />

          <input-size v-model="stepForm.dimensions.height" label="Height*" placeholder="10`6”" :copy="canIcopy"
            input-classes="input-icon height-icon" :disabled="canIfillForm"
            @error="regError('dimensions_height', $event)" :valid-sw="validSw" :rules="rules.requiredOnly"
            @blur="updateFormDataOnBlur" />

          <input-weight v-model="stepForm.dimensions.weight" label="Weight*" placeholder="500 lbs" :copy="canIcopy"
            input-classes="input-icon weight-icon" :disabled="canIfillForm"
            @error="regError('dimensions_weight', $event)" :valid-sw="validSw" :rules="rules.requiredOnly"
            @blur="updateFormDataOnBlur" />
        </div>
      </div>

      <div class="form-line">
        <div class="calc-form-title"><span>Equipment</span>
        </div>

        <div v-if="stepForm.vehicle_combination" class="vehicle-combination-name">

        </div>
        <button
          :class="['choose-vehicle-combination-button', { 'not-valid-data-box': this.errors.vehicle_combination }]"
          @click="showEquipmentForm">{{ !!stepForm.vehicle_combination ?
            'View Vehicle Combination' : 'Create Vehicle Combination' }}
          <span class="err" v-if="this.errors.vehicle_combination">Add Vehicle Combination</span>
        </button>

      </div>

      <div class="form-line">
        <div class="form-grid">
          <div class="input-box calc-form-title">Overall Dimensions</div>
        </div>

        <div class="form-grid r-4">
          <div class="form-grid__input">
            <div class="additional-info">
              <div class="additional-info-message">
                Length is measured from the front extension of the truck or load to the back extension of the trailer or
                load
                and must include all overhangs.
              </div>
            </div>

            <input-size v-model="stepForm.overall_dimensions.length" label="Length*" placeholder="30`" :copy="canIcopy"
              input-classes="input-icon length-icon" :disabled="canIfillForm"
              @error="regError('overall_dimensions_length', $event)" :valid-sw="validSw" :rules="rules.requiredOnly"
              @blur="updateFormDataOnBlur" />
          </div>

          <div class="form-grid__input">
            <div class="additional-info">
              <div class="additional-info-message">
                Width is measured from the outside points of the widest extremities of the load, excluding safety
                devices such
                as mirrors or flags.
              </div>
            </div>

            <input-size v-model="stepForm.overall_dimensions.width" label="Width*" placeholder="5`" :copy="canIcopy"
              input-classes="input-icon width-icon" :disabled="canIfillForm"
              @error="regError('overall_dimensions_width', $event)" :valid-sw="validSw" :rules="rules.requiredOnly"
              @blur="updateFormDataOnBlur" />
          </div>



          <div class="form-grid__input">
            <div class="additional-info">
              <div class="additional-info-message">
                Height refers to the loaded height, measured from the roadbed to the highest point.
              </div>
            </div>

            <input-size v-model="stepForm.overall_dimensions.height" label="Height*" placeholder="10`" :copy="canIcopy"
              input-classes="input-icon height-icon" :valid-sw="validSw" :disabled="canIfillForm"
              @error="regError('overall_dimensions_height', $event)" :rules="rules.requiredOnly"
              @blur="updateFormDataOnBlur" />
          </div>


          <div class="form-grid__input">

            <input-weight v-model="stepForm.overall_dimensions.weight" label="Weight*" placeholder="10 lbs"
              :copy="canIcopy" input-classes="input-icon weight-icon" :valid-sw="validSw" :disabled="canIfillForm"
              @error="regError('overall_dimensions_weight', $event)" :rules="rules.requiredOnly"
              @blur="updateFormDataOnBlur" />
          </div>

        </div>
      </div>
      <div class="form-line">
        <div class="form-grid r-3">

          <div class="form-grid__input">
            <div class="additional-info">
              <div class="additional-info-message">
                Front overhang is the measurement of the portion of the load or vehicle that extends beyond the front
                bumper
                of the vehicle.
              </div>
            </div>

            <input-size v-model="stepForm.overhang_front" label="Overhang front" placeholder="30`" :copy="canIcopy"
              :disabled="canIfillForm" @blur="updateFormDataOnBlur" />
          </div>

          <div class="form-grid__input">
            <div class="additional-info">
              <div class="additional-info-message">
                Rear overhang is the measurement of the portion of the load or vehicle that extends beyond the rear
                bumper of
                a single vehicle or the last vehicle in a vehicle combination.
              </div>
            </div>

            <input-size v-model="stepForm.overhang_rear" label="Overhang rear" placeholder="0" :disabled="canIfillForm"
              :copy="canIcopy" @blur="updateFormDataOnBlur" />
          </div>

          <div class="form-grid__input">
            <div class="additional-info">
              <div class="additional-info-message">
                Kingpin Distance - measured from the center of the kingpin to the center of the rear axle, or group of
                axles.
              </div>
            </div>

            <input-size v-model="stepForm.kingpin" label="Kingpin" placeholder="0" :disabled="canIfillForm"
              :copy="canIcopy" @blur="updateFormDataOnBlur" />
          </div>
        </div>
      </div>
      <div class="form-line">
        <div class="form-line-gray"></div>
      </div>
      <!-- {{ errors }} -->
      <div class="form-line">
        <div class="form-grid">
          <div class="input-box calc-form-title">Route Information</div>
        </div>

        <div class="form-grid date-route">
          <autocomplate-address-field v-model="stepForm.origin" id="origin_input" label="Origin*"
            placeholder="28715 SW 132nd Ave, Homestead, FL 33033, USA" predictions-quantity="10"
            :disabled="canIfillForm" @update-route="updateRoute" :valid-sw="validSw" @error="regError('origin', $event)"
            :rules="rules.autocomplateField" :copy="canIcopy" />

          <input-date v-model="stepForm.origin_date" label="Date*" :disabled="canIfillForm"
            @error="regError('origin_date', $event)" :valid-sw="validSw" :rules="rules.dateRules" :min="getCurrentDate"
            @update:modelValue="updateOriginDate" />
        </div>
        <div class="form-grid date-route">
          <autocomplate-address-field v-model="stepForm.destination" id="destination_input" label="Destination*"
            placeholder="28715 SW 132nd Ave, Homestead, FL 33033, USA" predictions-quantity="10"
            :disabled="canIfillForm" @update-route="updateRoute" :valid-sw="validSw"
            @error="regError('destination', $event)" :rules="rules.autocomplateField" :copy="canIcopy" />

          <input-date v-model="stepForm.destination_date" label="Date*" :valid-sw="validSw" :disabled="canIfillForm"
            @error="regError('destination_date', $event)" :rules="rules.dateRules" :min="this.destinationDateMin" />
        </div>
      </div>
      <div class="form-line">
        <choose-states :check-dates="routes_dates" :valid-sw="validSw"
          :destination-date="this.stepForm.destination_date" :origin-date="this.stepForm.origin_date"
          :states_list_data="this.stepForm.choosed_states" :disabled="canIfillStates"
          :check-states="this.errors.checkStates" @choosed-states="updateChoosedStates" />
      </div>
      <div class="form-line clc-btn__wrapper">
        <button v-if="!canIfillForm" type="button" class="button clc-button big-button primary-button button-apply"
          @click="goToNextStep">
          <span>Apply</span>
        </button>
      </div>
    </div>
  </div>
</template>


<script>
import EquipmentForm from "@/components/equipment/EquipmentForm.vue";
import AddressField from "@/components/form-elements/AddressField.vue";
import AutocomplateAddressField from "@/components/form-elements/AutocomplateAddressField.vue";
import InputText from "@/components/form-elements/InputText.vue";
import InputDate from "@/components/form-elements/InputDate.vue";
import RadioSwitcher from "@/components/form-elements/RadioSwitcher.vue";
import InputSize from "@/components/form-elements/InputSize.vue";
import InputWeight from "@/components/form-elements/InputWeight.vue";
import ChooseStates from "@/components/calculator/parts/ChooseStates.vue";
import InputCheckbox from "@/components/form-elements/InputCheckbox.vue";


import EquipmentSelector from "@/components/form-elements/EquipmentSelector.vue";
import ValidateMixin from "@/mixins/ValidateMixin";
import { FeetMathMixin } from "@/mixins/FeetMathMixin";

export default {
  //
  components: {
    AddressField,
    AutocomplateAddressField,
    InputText,
    InputDate,
    ChooseStates,
    RadioSwitcher,
    InputSize,
    InputWeight,
    EquipmentSelector,
    EquipmentForm,
    InputCheckbox,

  },
  mixins: [FeetMathMixin, ValidateMixin],
  data() {
    return {
      // selected_equipment: null,
      order_id: this.$route.params.order_id,
      stepForm: {
        origin: null,
        origin_date: null,
        destination: null,
        destination_date: null,
        description: null,
        road_type: "interstate",
        eq_id: null,
        vehicle_combination: null,
        axle_quantity: null,
        axles_values: [],
        serial: null,
        model: null,
        make: null,
        load_time: null,
        deposit_amount: 0,
        dimensions: {
          length: null,
          width: null,
          height: null,
          weight: null,
        },
        overall_dimensions: {
          length: null,
          width: null,
          height: null,
          weight: null,
        },

        overhang_front: null,
        overhang_rear: null,
        kingpin: null,
        choosed_states: [],
        how_loaded: [
          {
            id: 1,
            name: 'End-to-end',
            value: false
          },
          {
            id: 2,
            name: 'Single item',
            value: false
          },
          {
            id: 3,
            name: 'Stacked',
            value: false
          },
          {
            id: 4,
            name: 'Side-by-side',
            value: false
          }
        ]

      },
      errors: {
        description: null,
        dimensions_length: null,
        dimensions_width: null,
        dimensions_height: null,
        dimensions_weight: null,
        overall_dimensions_length: null,
        overall_dimensions_width: null,
        overall_dimensions_height: null,
        overall_dimensions_weight: null,
        origin: null,
        destination: null,
        origin_date: null,
        destination_date: null,
        vehicle_combination: false,
        statesErr: false,
        checkStates: false,
      },
      originDateMin: null,
      destinationDateMin: null,
      // statesErr: false,
      disabled: false,
      validSw: false,
      display_acceptable_formats: false,
      display_extra_fields: false,
      calcMode: {
        origin: false,
        origin_date: false,
        chooseStates: false,
        destination_date: false,
        destination: false,
        road_type: false,
        equipment: false,
        load_time: false,
        dimensions: {
          length: false,
          width: false,
          height: false,
          weight: false,
        },
        overall_dimensions: {
          length: false,
          width: false,
          height: false,
          weight: false,
        },
        overhang_front: false,
        overhang_rear: false,
        kingpin: false,

      },
    };
  },
  name: "StepFormOne",
  emits: [
    "updateOnBlur",
    "createOrder",
    "updateOrder",
    "updateRoute",
    "formValid",
    "nextStep",
    "updateStepOne",
    "updateStatesData"
  ],
  props: {
    resetForm: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: "new",
    },
    copyOrderData: {
      type: Object,
    },
    selectedNewEquipment: {
      type: Object,
    },
  },
  mounted() {
    this.stepForm.name = this.user_data.name;
    this.stepForm.lastname = this.user_data.lastname;
    this.stepForm.phone = this.user_data.phone;
    this.stepForm.email = this.user_data.email;
    this.stepForm.company = this.user_data.company.company_name;
    this.stepForm.origin_date = this.getCurrentDate;
    this.stepForm.destination_date = this.getCurrentDate;
    this.originDateMin = this.getCurrentDate;
    this.destinationDateMin = this.getCurrentDate;

  },
  computed: {
    is_test() {
      return this.stepForm.description === 'test'
    },
    user_role() {
      return this.$store.getters.userRole;
    },
    user_data() {
      return this.$store.getters.userData;
    },
    canIcopy() {
      if (this.user_role === 'manager' || this.user_role === 'admin') {
        return true;
      } else {
        return false;
      }
    },
    canIfillStates() {
      if (Object.keys(this.order_by_id).length === 0) {
        return false;
      }
      const allowedStatuses = ['deposit_needed'];
      if (['owner', 'driver'].includes(this.user_role) && allowedStatuses.includes(this.order_by_id.order_status)) {
        return false;
      }
      return true;
    },
    canIfillForm() {
      if ((this.user_role === 'manager' || this.user_role === 'admin') && this.order_by_id.manager_id == this.user_data.user_id && this.order_by_id.order_status === 'deposit_paid') {
        return false;
      }
      const allowedStatuses = ['deposit_paid', 'deposit_reminder', 'payment_needed', 'payment_reminder', 'payment_completed', 'cancelled'];
      if (['owner', 'driver', 'manager', 'admin'].includes(this.user_role) && allowedStatuses.includes(this.order_by_id.order_status)) {
        return true;
      }
      return false;
    },
    order_by_id() {
      return this.$store.getters.order_by_id;
    },
    routes_dates() {
      return !!this.stepForm.destination_date && !!this.stepForm.origin_date;
    },
    minDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");

      return `${month}-${day}-${year}`;
    },
    maxDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear() + 3; // 3 years
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");

      return `${month}-${day}-${year}`;
    },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },


  },
  methods: {
    updateFormDataOnBlur() {
      this.$emit("updateStepOne", this.stepForm);
    },
    updateOriginDate(newDate) {
      this.originDateMin = newDate;
      this.destinationDateMin = newDate;
      if (new Date(newDate) > new Date(this.stepForm.destination_date)) {
        this.stepForm.destination_date = null;
      }
    },
    showEquipmentForm() {
      this.errors.vehicle_combination = false;
      let scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollPosition}px`;
      document.body.style.width = '100%';
      this.$store.dispatch("actionToggleShowEquipmentForm");
    },
    removeCurrentEquipment() {
      this.stepForm.vehicle_combination = null;
    },
    goToNextStep() {

      if (!this.is_form_valid_new()) return;
      this.$emit('nextStep', 2);
    },
    calculatorMode() {
      let statusMode = this.mode;
      if (this.user_role === 'admin') {

        statusMode = "display_only";
      }
      else if (this.order_by_id.length === 0) {
        statusMode = "new";
      } else if (
        this.order_by_id.order_status === "deposit_needed" ||
        this.order_by_id.order_status === "deposit_reminder"
      ) {
        statusMode = "current";
      } else {
        statusMode = "display_only";
      }

      switch (statusMode) {
        case "new":
          break;

        case "current":
          break;

        case "display_only":
          this.calcMode.origin = true;
          this.calcMode.origin_date = true;
          this.calcMode.destination = true;
          this.calcMode.chooseStates = true;
          this.calcMode.destination_date = true;
          this.calcMode.description = true;
          this.calcMode.model = true;
          this.calcMode.make = true;
          this.calcMode.serial = true;
          this.calcMode.road_type = true;
          this.calcMode.load_time = true;
          this.calcMode.equipment = true;
          this.calcMode.dimensions.length = true;
          this.calcMode.dimensions.width = true;
          this.calcMode.dimensions.height = true;
          this.calcMode.dimensions.weight = true;
          this.calcMode.overall_dimensions.length = true;
          this.calcMode.overall_dimensions.width = true;
          this.calcMode.overall_dimensions.height = true;
          this.calcMode.overall_dimensions.weight = true;
          this.calcMode.overhang_front = true;
          this.calcMode.overhang_rear = true;
          this.calcMode.kingpin = true;

          break;

        default:
          "new";
          break;
      }
    },

    is_form_valid_new() {

      const allowedStatuses = ['payment_completed', 'cancelled'];
      if (['owner', 'driver', 'manager', 'admin'].includes(this.user_role) && allowedStatuses.includes(this.order_by_id.order_status)) {
        return true;
      }
      let valid = false;

      this.$store.dispatch("actionGenerateId", 32).then((id) => {
        this.validSw = id;
      });

      const vehicle_combination = { ...this.stepForm.vehicle_combination };
      if (Object.keys(vehicle_combination).length === 0) {

        this.errors.vehicle_combination = true;
      } else {
        this.errors.vehicle_combination = false;
      }

      this.errors.checkStates = true;

      if (Object.keys(this.stepForm.choosed_states).length === 0) {
        this.errors.statesErr = true;
        return false;
      } else {
        this.errors.statesErr = false;
      }
      const currentDate = new Date().toISOString().split('T')[0]

      const destinationDate = this.stepForm.destination_date ? new Date(this.stepForm.destination_date).toISOString().split('T')[0] : ''

      const validStates = this.stepForm.choosed_states.filter(item => {
        const itemDate = item.date ? item.date.split('T')[0] : ''
        return item.state && itemDate >= currentDate && itemDate <= destinationDate
      })

      if (validStates.length !== this.stepForm.choosed_states.length) {
        this.errors.checkStates = true;
        return false;
      } else {
        this.errors.checkStates = false;
      }

      const errors = { ...this.errors };
      const allErrorsValid = Object.values(errors).some(error => error === true || error === null);
      // console.log(allErrorsValid);
      if (allErrorsValid) {
        valid = false;
      } else {
        valid = true;
      }

      this.$emit("updateStepOne", this.stepForm);
      if (valid) {
        this.$emit("formValid", valid);

      } else {
        // console.log('notvalid');
      }
      return valid;
    },

    updateOrder() {
      if (this.is_form_valid()) {
        console.log(this.stepForm, 'updateOrder');
        this.$emit("updateOrder", this.stepForm);
      }
    },
    updateRoute(data) {
      if (this.stepForm.origin && this.stepForm.destination) {
        this.$emit("updateRoute", {
          origin: this.stepForm.origin,
          destination: this.stepForm.destination,
        });
      } else {
        // console.error("Both 'origin' and 'destination' fields must be filled.");
      }
    },
    updateChoosedStates(states, deposit_amount) {
      this.stepForm.choosed_states = states;
      this.stepForm.deposit_amount = deposit_amount;
      this.$emit("updateStatesData", states, deposit_amount);

    }


  },

  watch: {

    selectedNewEquipment(new_data) {
      this.$nextTick(() => {
        this.stepForm.vehicle_combination = new_data.equipment;
        this.stepForm.eq_id = new_data.eq_id;
        this.stepForm.axles_values = new_data.axles_values;
        this.stepForm.axle_quantity = new_data.axle_quantity;

      });
    },


    order_by_id(new_data) {
      this.stepForm.axles_values = JSON.parse(new_data.axles_values);
      this.stepForm.axle_quantity = new_data.axle_quantity;
      this.stepForm.origin = new_data.origin;
      this.stepForm.origin_date = new_data.origin_date;
      this.stepForm.destination_date = new_data.destination_date;
      this.stepForm.destination = new_data.destination;
      this.stepForm.eq_id = new_data.eq_id;
      this.stepForm.description = new_data.description;
      this.stepForm.load_time = new_data.load_time;
      const dimensions = JSON.parse(new_data.dimensions);
      const overall_dimensions = JSON.parse(new_data.overall_dimensions);
      const choosed_states = JSON.parse(new_data.choosed_states);
      const how_loaded = JSON.parse(new_data.how_loaded);
      const vehicle_combination = JSON.parse(new_data.vehicle_combination);
      this.stepForm.overhang_front = new_data.overhang_front;
      this.stepForm.overhang_rear = new_data.overhang_rear;
      this.stepForm.kingpin = new_data.kingpin;
      this.stepForm.make = new_data.make;
      this.stepForm.model = new_data.model;
      this.stepForm.serial = new_data.serial;
      this.stepForm.vehicle_combination = vehicle_combination;
      this.stepForm.how_loaded = how_loaded;

      Object.keys(choosed_states).forEach((key) => {
        // console.log(this.stepForm.choosed_states);
        this.stepForm.choosed_states[key] = choosed_states[key];
      });

      Object.keys(dimensions).forEach((key) => {
        this.stepForm.dimensions[key] = dimensions[key];
      });

      Object.keys(overall_dimensions).forEach((key) => {
        this.stepForm.overall_dimensions[key] = overall_dimensions[key];
      });


      this.calculatorMode();
      this.$emit("updateStepOne", this.stepForm);
      // this.is_form_valid_new();
    },


    copyOrderData: {
      deep: true,
      handler(new_data) {
        // Check if new_data is undefined
        if (!new_data) {
          // Set all fields to null or empty arrays
          this.stepForm.axles_values = [];
          this.stepForm.axle_quantity = null;
          this.stepForm.origin = null;
          this.stepForm.destination = null;
          this.stepForm.description = null;
          this.stepForm.overhang_front = null;
          this.stepForm.overhang_rear = null;
          this.stepForm.kingpin = null;
          this.stepForm.make = null;
          this.stepForm.model = null;
          this.stepForm.serial = null;
          this.stepForm.vehicle_combination = [];
          this.stepForm.how_loaded = [
            {
              id: 1,
              name: 'End-to-end',
              value: false
            },
            {
              id: 2,
              name: 'Single item',
              value: false
            },
            {
              id: 3,
              name: 'Stacked',
              value: false
            },
            {
              id: 4,
              name: 'Side-by-side',
              value: false
            }
          ];
          this.stepForm.choosed_states = {};
          this.stepForm.dimensions = {};
          this.stepForm.overall_dimensions = {};
        } else {
          // Parse and assign values if new_data is defined
          this.stepForm.axles_values = new_data.axles_values ? JSON.parse(new_data.axles_values) : [];
          this.stepForm.axle_quantity = new_data.axle_quantity || null;
          this.stepForm.origin = new_data.origin || null;
          this.stepForm.origin_date = new_data.origin_date || null;
          this.stepForm.destination = new_data.destination || null;
          this.stepForm.destination_date = new_data.destination_date || null;
          this.stepForm.description = new_data.description || null;
          this.stepForm.overhang_front = new_data.overhang_front || null;
          this.stepForm.overhang_rear = new_data.overhang_rear || null;
          this.stepForm.kingpin = new_data.kingpin || null;
          this.stepForm.make = new_data.make || null;
          this.stepForm.model = new_data.model || null;
          this.stepForm.serial = new_data.serial || null;
          this.stepForm.vehicle_combination = new_data.vehicle_combination ? JSON.parse(new_data.vehicle_combination) : [];
          this.stepForm.how_loaded = JSON.parse(new_data.how_loaded) || [];

          // Assign choosed_states, dimensions, and overall_dimensions
          this.stepForm.choosed_states = new_data.choosed_states ? JSON.parse(new_data.choosed_states) : {};
          this.stepForm.dimensions = new_data.dimensions ? JSON.parse(new_data.dimensions) : {};
          this.stepForm.overall_dimensions = new_data.overall_dimensions ? JSON.parse(new_data.overall_dimensions) : {};

          this.is_form_valid_new();
        }

        // Emit updateStepOne event and check form validity
        this.$emit("updateStepOne", this.stepForm);

      },
    },


  },
};
</script>
