<template>
  <div :class="{ 'profile__settings': true, 'administration': user_data.role === 'manager' || user_data.role === 'admin' }">
    <div
      :class="{ 'profile__settings__title': true, 'administration': user_data.role === 'manager' || user_data.role === 'admin' }">
      <div>
        <img :src="profile_settings_icon" />
      </div>
      <span>Profile</span>
    </div>

    <div class="profile__settings__header">
      <div class="profile__settings__info__wrap">

        <div class="profile__settings__name">
          <template v-if="!editMode">
            <span class="font-large">{{ profileForm.name }}</span>
            <button @click="eventEditUserName">
              <img :src="edit_icon" />
            </button>
          </template>


          <template v-else>
            <input-text v-model="profileForm.name" :rules="rules.textOnlyLetters" maxlength="62"
              @error="regError('name', $event)" />
            <button class="accept" @click="acceptName" :disabled="errors.name">
              <img :src="accept_icon" />
            </button>
            <button class="cancel" @click="eventEditUserName">
              <img :src="cancel_icon" />
            </button>
          </template>

        </div>
        <div class="profile__settings__name">
          <template v-if="!editModeName">
            <span class="font-large">{{ profileForm.lastname }}</span>
            <button @click="eventEditUserLastName">
              <img :src="edit_icon" />
            </button>
          </template>


          <template v-else>
            <input-text v-model="profileForm.lastname" :rules="rules.textOnlyLetters" maxlength="62"
              @error="regError('lastname', $event)" />
            <button class="accept" @click="acceptLastName" :disabled="errors.lastname">
              <img :src="accept_icon" />
            </button>
            <button class="cancel" @click="eventEditUserLastName">
              <img :src="cancel_icon" />
            </button>
          </template>

        </div>
      </div>

      <!--photo-->
      <div class="profile__settings__photo">
        <div class="profile__photo-info">
          <svg class="profile__photo-info-icon" style="vertical-align: middle;fill: currentColor;overflow: hidden;"
            viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M512 938.666667C276.352 938.666667 85.333333 747.648 85.333333 512S276.352 85.333333 512 85.333333s426.666667 191.018667 426.666667 426.666667-191.018667 426.666667-426.666667 426.666667z m0-85.333334a341.333333 341.333333 0 1 0 0-682.666666 341.333333 341.333333 0 0 0 0 682.666666z m-42.666667-170.666666h85.333334v85.333333h-85.333334v-85.333333z m0-84.992s85.333333-0.341333 85.333334 0C554.666667 554.922667 682.666667 512 682.666667 426.666667c0-94.293333-75.648-170.666667-170.282667-170.666667A170.666667 170.666667 0 0 0 341.333333 426.666667h85.333334c0-46.933333 38.4-85.333333 85.333333-85.333334s85.333333 38.4 85.333333 85.333334c0 38.4-128 100.992-128 171.008z"
              fill="#6B7280" />
          </svg>
          <div class="profile__photo-info-text">
            The size of the photo should not exceed 4 MB, and save them in JPG, PNG format.
          </div>
        </div>
        <div class="profile__photo-uploader">
          <input type="file" ref="fileInput" @change="handleFileUpload" accept="image/jpeg, image/png" />
          <button @click="openFileInput" class="profile__photo-upload">
            Upload Image
          </button>
          <img :src="picture_url" alt="User Profile Picture" />
        </div>
        <div class="profile__settings__photo__icon">
          <img :src="photo_icon" alt="photo-icon" />
        </div>
        <button v-if="user_data.picture_name || photoUrl" class="profile__settings__photo-remove" @click="removePhoto">
          <img :src="cancel_icon" />
        </button>
      </div>

    </div>

    <div class="profile__settings__inputs">
      <div class="form-elements">
        <div class="form-element email">
          <label>Email</label>
          <div class="block-email input-icon mail-icon">{{ profileForm.email }}</div>
        </div>
        <div class="form-element phone">
          <label>Phone</label>
          <input-phone :icon="true" v-model="profileForm.phone" :maxlength="14" :rules="rules.phone"
            @error="regError('phone', $event)" autocomplete="false" />
        </div>
      </div>
    </div>
    <div class="profile__settings__footer">
      <notification :is-visible="notificationShow" :status="true" text="Changes saved" />
      <button :disabled="!isFormValid" class="button small profile__settings__button" @click="updateProfile">
        Update
      </button>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/form-elements/InputText.vue";
import InputPhone from "@/components/form-elements/InputPhone.vue";

import ValidateMixin from "@/mixins/ValidateMixin";

import photo_icon from "@/assets/images/svgs/photo-icon.svg";
import accept_icon from "@/assets/images/svgs/accept-icon.svg";
import edit_icon from "@/assets/images/svgs/edit-icon.svg";
import cancel_icon from "@/assets/images/svgs/cancel-icon.svg";
import profile_settings_icon from "@/assets/images/svgs/profile-settings-icon.svg";
import Notification from "@/components/helpers/Notification.vue";
import profile_photo2 from "@/assets/images/profile.png";

export default {
  name: "ProfileDataForm",
  components: {
    InputPhone,
    InputText,
    Notification,
  },
  mixins: [ValidateMixin],
  data() {
    return {
      photo_icon,
      accept_icon,
      profile_settings_icon,
      edit_icon,
      cancel_icon,
      profile_photo2,
      // ------------------
      editMode: false,
      editModeName: false,
      notificationShow: false,
      photoUrl: null,
      photoImgType: null,
      profileForm: {
        name: null,
        lastname: null,
        phone: null,
        email: null,
      },
      errors: {
        name: null,
        lastname: null,
        phone: null,
        email: null,
      },
      // editedName: "",
      // touchedName: false,
      touchedPhoto: false,
    };
  },
  mounted() {
    this.profileForm.name = this.user_data.name;
    this.profileForm.lastname = this.user_data.lastname;
    this.profileForm.email = this.user_data.email;
    this.profileForm.phone = this.user_data.phone;
  },
  computed: {
    user_data() {
      return this.$store.getters.userData;
    },

    areFormDataDifferences() {
      const form = { ...this.profileForm };
      const user_data = this.user_data;
      const old_data = {};
      ['name', 'lastname', 'phone']
        .forEach(key => {
          old_data[key] = user_data[key];
        });
      const dif = Object.keys(old_data).find(key => form[key] !== old_data[key]);
      return ((dif !== undefined) || !!this.touchedPhoto);
    },

    picture_url() {
      if (this.photoUrl) {
        return this.photoUrl;
      }
      if (!this.user_data.picture_name) {
        return this.profile_photo2;
      }

      const api_url = this.$store.getters.apiUrl;
      return api_url + '/user_images/' + this.user_data.picture_name;
    },

    isFormValid() {
      const existErrors = Object.keys(this.errors).find((el) => this.errors[el]);
      const isEmptyFields = Object.keys(this.profileForm).find(k => !this.profileForm[k]);
      return !!this.areFormDataDifferences && !isEmptyFields && !existErrors;
    },
  },
  methods: {
    eventEditUserName() {
      this.editMode = !this.editMode;
      this.profileForm.name = this.user_data.name;
    },
    eventEditUserLastName() {
      this.editModeName = !this.editModeName;
      this.profileForm.lastname = this.user_data.lastname;
    },
    acceptName() {
      this.editMode = false;

    },
    acceptLastName() {
      this.editModeName = false;
    },
    handleFileUpload(e) {
      const file = e.target.files[0]
      const maxSize = 4 * 1024 * 1024 // 4 MB in bytes

      if (!file) {
        return
      }

      if (file.size > maxSize) {
        this.$store.dispatch("alert", {
            message: "File size exceeds 4 MB. Please upload a smaller file.",
            type: "info",
          });
        // Clear the input field
        this.$refs.fileInput.value = this.user_data.picture_name || this.photoUrl
        return
      }

      const type = file.type
      const reader = new FileReader()
      reader.onload = (img_event) => {
        this.photoUrl = img_event.target.result
        this.photoImgType = type
      }

      reader.readAsDataURL(file)
      this.touchedPhoto = true
    },
    removePhoto() {
      this.photoUrl = this.profile_photo2;
      this.touchedPhoto = true;
      this.user_data.picture_name = null;
    },

    openFileInput() {
      this.$refs.fileInput.click();
    },

    updateProfile() {
      const data = { ...this.profileForm }
      if (this.touchedPhoto) {
        data.photo_url = this.photoUrl;
        data.photo_type = this.photoImgType;
      }

      console.log(data);
      this.$store.dispatch('saveUserPersonalData', data)
        .then(user => {
          this.profileForm.name = this.user_data.name;
          this.profileForm.lastname = this.user_data.lastname;
          this.profileForm.phone = this.user_data.phone;
          this.touchedPhoto = false;
          this.photoImgType = null;
          this.photoUrl = null;
          this.editMode = false;
          this.editModeName = false;

        });

      // this.notificationShow = true;

    },
  },

};
</script>
