<template>
    <div v-if="user_data.role === 'admin'" id="quote-page">
        <div class="quote-filter__section">
            <div class="quote-filter__inputs">
                <select v-model="filterData.managerValue" id="status2" @change="onFilter">
                    <option value="">Select agent</option>
                    <option v-for="option in managerList" :key="option.user_id" :value="option.user_id">
                        {{ option.name }}
                    </option>
                </select>
                <input v-model="filterData.originValue" type="text" placeholder="Origin" @input="onFilter" />
                <input v-model="filterData.destinationValue" type="text" placeholder="Destination" @input="onFilter" />
               <div class="input-select">
                <select v-model="filterData.statusValue" id="status" @change="onFilter">
                    <option value="">Select status</option>
                    <option v-for="option in statusOptions" :key="option.value" :value="option.value">
                        {{ option.text }}
                    </option>
                </select>
               </div>
            </div>
            <button class="button small button-icon reset-icon" @click="resetFilter">
                Reset
            </button>
        </div>
        <div class="table-wrap">
            <div>
                <div class="tab-buttons">
                    <button v-for="(tab, index) in tabs" :key="index" @click="onCLickTab(tab, index)"
                        :class="{ active: activeTab === index }">
                        {{ tab.title }} {{ tab.total }}
                    </button>
                </div>
                <div class="tab-content">
                    <div v-for="(tab, index) in tabs" :key="index" :class="{ active: activeTab === index }">
                        <!-- Tab Content Here -->
                        <template v-if="tab.status === 'inProgress'">
                            <super-admin-order-list :filter-data="filterData" @data-total="setTabsCount($event, index)"
                                ref="admin_order_list" />
                        </template>
                        <template v-else-if="tab.status === 'archive'">
                            <super-admin-order-archive-list :filter-data="filterData"
                                @data-total="setTabsCount($event, index)" ref="admin_order_archive_list" />
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SuperAdminOrderList from "@/components/orders/super-admin/SuperAdminOrderList.vue";
import SuperAdminOrderArchiveList from "@/components/orders/super-admin/SuperAdminOrderArchiveList.vue";
import { ref } from "vue";
export default {
    name: "SuperAdminOrdersPage",
    components: {
        SuperAdminOrderList,
        SuperAdminOrderArchiveList,
    },
    setup() {
        const admin_order_list = ref(null);
        const admin_order_archive_list = ref(null);

        return {
            admin_order_list,
            admin_order_archive_list,
        };
    },
    computed: {
        user_data() {
            return this.$store.getters.userData;
        },
    },
    mounted() {
        this.assignManager();
    },
    data() {
        return {
            managerList: [],
            activeTab: 0,
            filterData: {
                originValue: null,
                destinationValue: null,
                archiveValue: 0,
                offsetValue: 0,
                statusValue: "",
                managerValue: "",
                user_id: this.$route.params.user_id,
            },

            tabs: [
                { title: "Active", status: "inProgress", total: null },
                { title: "Archived", status: "archive", total: null },
            ],
            statusOptions: [
                { text: "Deposit needed", value: "deposit_needed" },
                { text: "Deposit paid - In Progress", value: "deposit_paid" },
                { text: "Deposit reminder", value: "deposit_reminder" },
                { text: "Payment Needed", value: "payment_needed" },
                { text: "Payment reminder", value: "payment_reminder" },
                { text: "Completed", value: "payment_completed" },
                { text: "Cancelled", value: "cancelled" },
            ],
        };
    },

    methods: {
        assignManager() {
            this.$store
                .dispatch("actionGetUsersByRole", { parameters: { role: "manager" } })
                .then((response) => {
                    this.managerList = response.users;
                });
        },
        onCLickTab(tab, index) {
            this.activeTab = index;
            if (this.activeTab === 0) {
                this.filterData.archiveValue = 0;
                this.admin_order_list[0].getAdminOrdersList();
            } else {
                this.filterData.archiveValue = 1;
                this.admin_order_archive_list[0].getAdminOrdersList();
            }
        },
        setTabsCount(count, index) {
            this.tabs[0].total = `(${count.in_progress ? count.in_progress : 0})`;
            this.tabs[1].total = `(${count.archive ? count.archive : 0})`;
        },
        onFilter() {
            if (this.activeTab === 0) {
                this.filterData.archiveValue = 0;
                this.admin_order_list[0].getAdminOrdersList();
            } else {
                this.filterData.archiveValue = 1;
                this.admin_order_archive_list[0].getAdminOrdersList();
            }
        },

        resetFilter() {
            this.filterData.originValue = null;
            this.filterData.destinationValue = null;
            this.filterData.statusValue = "";
            this.filterData.managerValue = "";
            this.filterData.archiveValue = this.activeTab;
            this.filterData.offsetValue = 0;

            if (this.activeTab === 0) {
                this.filterData.archiveValue = 0;
                this.admin_order_list[0].getAdminOrdersList();
            } else {
                this.filterData.archiveValue = 1;
                this.admin_order_archive_list[0].getAdminOrdersList();
            }
        },
    },
};
</script>