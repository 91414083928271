<template>

    <div class="pdf-viewer" @click="printPdf">
        <div class="pdf-viewer-inner">
            <div class="pdf-viewer-header"><button @click="closePrintPdf" class="pdf-viewer-close">&times;</button></div>
            <embed :src="pdfUrl" type="application/pdf" width="100%" height="600px" />
        </div>
    </div>
</template>
  
<script>
export default {
    name: "PdfViewer",
    props: {
        pdfUrl: {
            type: String,
            required: true,
        },
    },
    emits:['close'],
    methods: {
        printPdf() {
            window.print();
        },
        closePrintPdf() {
            this.$emit("close");
    }
    },
};
</script>