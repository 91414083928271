<template>
  <div class="ef-form--button-box">
    <div class="ef-form--button-box__inner">
      <!-- <div v-if="!!pText" v-html="pText"></div> -->
      <div>
        <button :disabled="!formFiled" class="button" @click.prevent="eventClickSubmit">
          <span>{{ buttonName }}</span>
        </button>
      </div>

    </div>
  </div>
</template>
<script>

import InputCheckbox from "@/components/form-elements/InputCheckbox.vue";

export default {
  name: "EquipmentButtons",
  components: { InputCheckbox },
  computed: {
    order_by_id() {
      return this.$store.getters.order_by_id;
    },
    user_role() {
      return this.$store.getters.userRole;
    },
    buttonName() {
      const allowedStatuses = ['deposit_paid', 'deposit_reminder', 'payment_needed', 'payment_reminder', 'payment_completed', 'cancelled'];
      if (['owner', 'driver'].includes(this.user_role) && allowedStatuses.includes(this.order_by_id.order_status)) {
        return 'Close Vehicle combination';
      }
      if (['admin', 'manager'].includes(this.user_role) && allowedStatuses.includes(this.order_by_id.order_status)) {
        return 'Close Vehicle combination';
      }
      return 'Create Vehicle combination';
    },
  },
  data() {
    return {}
  },
  props: {
    formFiled: {
      type: Boolean,
      default: false
    },
    pText: {
      type: String,
      default: null
    },
    privateText: {
      type: String,
      default: null
    },
  },
  methods: {
    eventClickSubmit(e) {
      let scrollPosition = parseInt(document.body.style.top || '0') * -1;

      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';

      window.scrollTo(0, scrollPosition);
      this.$emit('submit');
    },


  },
}
</script>
