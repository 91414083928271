import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { DEPOSIT_RATE, VUE_APP_PAYPAL_CLIENT_ID_TEST, VUE_APP_PAYPAL_CLIENT_ID_REAL, PHONE_NUM } from './config';

const app = createApp(App);
app.config.globalProperties.$DEPOSIT_RATE = DEPOSIT_RATE;
app.config.globalProperties.$PAYPAL_TEST = VUE_APP_PAYPAL_CLIENT_ID_TEST;
app.config.globalProperties.$PAYPAL_REAL = VUE_APP_PAYPAL_CLIENT_ID_REAL;
app.config.globalProperties.$PHONE_NUM = PHONE_NUM;
// createApp(App).use(store).use(router).mount('#app')
app.use(store).use(router).mount('#shell')
