<template>
  <div id="buttonGoogleAuth"></div>
</template>

<script>
export default {
  name: "ButtonGoogleAuth",
  async mounted() {
    await this.$nextTick();
    setTimeout(() => {
      this.initializeGoogleSignIn();
    }, 100);
  },
  methods: {
    handleCredentialResponse(response) {
      const token = response.credential;

      // ----------------- jwt parser ----------------
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      // user data
      const incoming_google_user_data = JSON.parse(jsonPayload);

      const out_google_data = {
        google_id: incoming_google_user_data.sub,
        email: incoming_google_user_data.email,
        email_verified: incoming_google_user_data.email_verified,
        name: incoming_google_user_data.name,
        given_name: incoming_google_user_data.given_name,
        family_name: incoming_google_user_data.family_name,
        picture_url: incoming_google_user_data.picture,
        locale: incoming_google_user_data.locale,
        all_json_data: jsonPayload
      };

      this.$store.dispatch('actionSignInByGoogle', out_google_data);
    },

    initializeGoogleSignIn() {
      google.accounts.id.initialize({
        client_id: "340745215228-8khqs9kgpl1dorfdl7j4o4t8lcpbd8mf.apps.googleusercontent.com",
        callback: this.handleCredentialResponse,
      });
      let offwetWidth = document.getElementById("buttonGoogleAuth")?.offsetWidth;
      let width = offwetWidth ? offwetWidth : 320;
      google.accounts.id.renderButton(document.getElementById("buttonGoogleAuth"), {
        theme: "outline",
        size: "large",
        logo_alignment: "left",
        width: width,
        height: 48
      });
      const ls_token = localStorage.getItem('token');
      if (!ls_token) {
        google.accounts.id.prompt();
      }
    },
  },
};
</script>
