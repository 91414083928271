<template>
  <div>
    <transition name="slide-in-from-right">
      <div v-show="showNotifications" class="user-notifications">
        <div class="user-notifications__inner">
          <div class="user-notifications__header">
            <div>Notifications</div>
            <div :class="{ active: checkNotifications }" @click="readAllNotifications">Mark all as read</div>
          </div>
          <div v-if="!user_notifications.length" class="user-notifications__items no-items">
            <p>No notifications yet <br>
              Messages and alerts about your account will show up here.</p>
          </div>
          <ul v-else class="user-notifications__items">
            <!-- <li @click="markReadNotification(index)" :class="{ readed: notification.readed }"
              v-for="(notification, index) in user_notifications" :key="index">
              {{ notification.event }}
            </li> -->
            <li v-for="(notification,index) in sortedNotifications" :key="notification.index" :class="{ readed: notification.readed }"
              @click="markReadNotification(index)">
              {{ notification.event }}
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "HeaderNotifications",

  props: {
    user_notifications: {
      type: [Object, Array],
      default: []
    },
    showNotifications: {
      type: Boolean,
      default: [],
    }
  },

  data() {
    return {
      // Additional data if needed
    };
  },
  computed: {
    sortedNotifications() {
      // console.log(this.user_notifications);
      return this.user_notifications;
    },
    checkNotifications() {
      return this.user_notifications.some(notification => !notification.readed);
    }
  },
  methods: {
    readAllNotifications() {
      this.user_notifications.forEach(notification => {
        notification.readed = true;
      });
      this.updateNotifications();
    },
    markReadNotification(index) {

      this.user_notifications[index].readed = true;
      this.updateNotifications();
    },
    updateNotifications() {
      const notifications = this.user_notifications;
      const parameters = { notifications };
      this.$store.dispatch("actionUpdateUserNotifications", { parameters });
    }
  },

  watch: {
    user_notifications: function (newNotifications, oldNotifications) {
    }
  }
};
</script>
