<template>
  <div v-if="user_data.role === 'owner' || user_data.role === 'driver'" id="new-quote-page" class="new-quote wrap">
    <div class="new-quote">
      <div class="new-quote__inner">
        <google-map-component
          ref="googleMapRef"
          :origin="originValue"
          :destination="destinationValue"
          @map-positions="mapPositions"
          status="new"
        />
        <div class="new-quote__calc">
          <calculator
          @calc-route="runMap"
          mode="new"
          :map-positions="mapPositionsData"
          />
        </div> 
      </div>
    </div>
  </div>
 
</template>

<script>
import { ref } from "vue";
import Calculator from "@/components/calculator/Calculator.vue";
import Spinner from "@/components/helpers/Spinner.vue";
import GoogleMapComponent from "@/components/googleMapComponent/GoogleMapComponent.vue";
export default {
  name: "NewOrderPage",
  components: { Spinner, GoogleMapComponent, Calculator },
  setup() {
    const googleMapRef = ref(null);

    return {
      googleMapRef,
    };
  },
 
  computed: {
    user_data() {
      return this.$store.getters.userData;
    },
  },
  data() {
    return {
      isLoading: false,
      map: null,
      originValue: "",
      destinationValue: "",
      roadTypeValue: 'interstate',
      mapPositionsData: null
     
    };
  },
  beforeMount() {
    this.clearOrderByIdGetter();
  },
  methods: {

    runMap(data) {
      this.originValue = data.origin;
      this.destinationValue = data.destination;
      this.roadTypeValue = data.road_type;
      
      this.$nextTick(() => {
        this.googleMapRef.calcRoute();
      });
    },
    mapPositions(data) {
        this.mapPositionsData = data;
    },
    clearOrderByIdGetter() {
      this.$store.commit('mutation_order_by_id',{});
    },
  },
};
</script>
