<template>
  <div class="input-box input-checkbox">
    <span class="checkbox-wrapper" @click="eventClick">
      <span :class="['checkbox-sign', { 'disabled' : disabled, 'active': !!modelValue }]"></span>
      <span class="checkbox-label" v-html="label"></span>
    </span>
  </div>
</template>

<script>
export default {
  name: "InputCheckbox",
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },

  },
  methods: {
    eventClick() {
      if(this.disabled) return;
      const new_value = !this.modelValue;
      this.$emit('update:modelValue', new_value);
    }
  }


}
</script>
