<template>
 
  <div v-if="user_data.role == 'manager'" id="activities-page">
    <div class="quote-filter__section">
      <div class="quote-filter__inputs">
        <input
          v-model="filterData.originValue"
          type="text"
          placeholder="Origin"
          @input="onFilter"
        />
        <input
          v-model="filterData.destinationValue"
          type="text"
          placeholder="Destination"
          @input="onFilter"
        />
       <div class="input-select">
        <select v-model="filterData.statusValue" id="status" @change="onFilter">
          <option value="">Select status</option>
          <option
            v-for="option in statusOptions"
            :key="option.value"
            :value="option.value"
          >
            {{ option.text }}
          </option>
        </select>
       </div>
      </div>
      <button class="button small button-icon reset-icon" @click="resetFilter">
        Reset
      </button>
    </div>
    <div class="table-wrap">
      <back-button link-url="/clients/" />
      <div>
        <div class="tab-buttons">
          <button
            v-for="(tab, index) in tabs"
            :key="index"
            @click="onCLickTab(tab, index)"
            :class="{ active: activeTab === index }"
          >
            {{ tab.title }} {{ tab.total }}
          </button>
        </div>
        <div class="tab-content">
          <div
            v-for="(tab, index) in tabs"
            :key="index"
            :class="{ active: activeTab === index }"
          >
            <!-- Tab Content Here -->
            <template v-if="tab.status === 'inProgress'">
              <clients-order-list
                :filter-data="filterData"
                @data-total="setTabsCount($event, index)"
                ref="admin_order_list"
              />
            </template>
            <template v-else-if="tab.status === 'archive'">
              <clients-order-archive-list
                :filter-data="filterData"
                @data-total="setTabsCount($event, index)"
                ref="admin_order_archive_list"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/buttons/BackButton";
import ClientsOrderList from "@/components/orders/admin/ClientsOrderList.vue";
import ClientsOrderArchiveList from "@/components/orders/admin/ClientsOrderArchiveList.vue";
// import AdminOrderArchiveList from "@/components/orders/admin/AdminOrderArchiveList.vue";
// import AdminOrderList from "@/components/orders/admin/AdminOrderList.vue";
import { ref } from "vue";
export default {
  name: "ClientActivities",
  components: {
    ClientsOrderList,
    // AdminOrderArchiveList,
    // AdminOrderList,
    ClientsOrderArchiveList,
    BackButton
  },
  setup() {
    const admin_order_list = ref(null);
    const admin_order_archive_list = ref(null);

    return {
      admin_order_list,
      admin_order_archive_list,
    };
  },
  computed: {
    user_data() {
      return this.$store.getters.userData;
    },
  },
  data() {
    return {
      activeTab: 0,
      filterData: {
        originValue: null,
        destinationValue: null,
        archiveValue: 0,
        statusValue: "",
        user_id: this.$route.params.user_id,
        // company_id:   this.$route.params.company_id,
        
      },

      tabs: [
        { title: "Active", status: "inProgress", total: null },
        { title: "Archived", status: "archive", total: null },
      ],
      statusOptions: [
      { text: "Deposit needed", value: "deposit_needed" },
        { text: "Deposit paid - In Progress", value: "deposit_paid" },
        { text: "Deposit reminder", value: "deposit_reminder" },
        { text: "Payment needed", value: "payment_needed" },
        { text: "Payment reminder", value: "payment_reminder" },
        { text: "Completed", value: "payment_completed" },
        { text: "Cancelled", value: "cancelled" },
      ],
    };
  },

  methods: {
    onCLickTab(tab, index) {
      this.activeTab = index;
      if (this.activeTab === 0) {
        this.filterData.archiveValue = 0;
        this.admin_order_list[0].getAdminOrdersList();
      } else {
        this.filterData.archiveValue = 1;
        this.admin_order_archive_list[0].getAdminOrdersList();
      }
    },
    setTabsCount(count, index) {
      this.tabs[0].total = `(${count.in_progress ? count.in_progress : 0})`;
      this.tabs[1].total = `(${count.archive ? count.archive : 0})`;
    },
    onFilter() {
      if (this.activeTab === 0) {
        this.filterData.archiveValue = 0;
        this.admin_order_list[0].getAdminOrdersList();
      } else {
        this.filterData.archiveValue = 1;
        this.admin_order_archive_list[0].getAdminOrdersList();
      }
    },

    resetFilter() {
      this.filterData.originValue = null;
      this.filterData.destinationValue = null;
      this.filterData.statusValue = "";
      this.filterData.archiveValue = this.activeTab;

      if (this.activeTab === 0) {
        this.filterData.archiveValue = 0;
        this.admin_order_list[0].getAdminOrdersList();
      } else {
        this.filterData.archiveValue = 1;
        this.admin_order_archive_list[0].getAdminOrdersList();
      }
    },
  },
};
</script>
