<template>
  <div v-if="!disabled" :class="['quote-delete']">
    <div class="delete-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.10096 3.20964C8.04715 3.20964 7.99939 3.24406 7.98237 3.29511L7.64975 4.29297H12.3509L12.0183 3.29511C12.0013 3.24406 11.9535 3.20964 11.8997 3.20964H8.10096ZM13.8442 4.29297L13.3622 2.84712C13.1524 2.21759 12.5633 1.79297 11.8997 1.79297H8.10096C7.43738 1.79297 6.84825 2.21759 6.6384 2.84712L6.15645 4.29297H4.16699H2.50033C2.10912 4.29297 1.79199 4.6101 1.79199 5.0013C1.79199 5.3925 2.10912 5.70964 2.50033 5.70964H3.50431L4.13798 15.2147C4.25034 16.9001 5.65014 18.2096 7.33921 18.2096H12.6614C14.3505 18.2096 15.7503 16.9 15.8627 15.2147L16.4963 5.70964H17.5003C17.8915 5.70964 18.2087 5.3925 18.2087 5.0013C18.2087 4.6101 17.8915 4.29297 17.5003 4.29297H15.8337H13.8442ZM15.0765 5.70964H13.3337H6.66699H4.92412L5.55151 15.1205C5.61425 16.0616 6.39596 16.793 7.33921 16.793H12.6614C13.6047 16.793 14.3864 16.0616 14.4491 15.1205L15.0765 5.70964Z"
          fill="#EE7373"
        />
      </svg>
    </div>
    Delete
  </div>
  <div v-else :class="['quote-delete', { 'quote-delete-disabled': disabled }]">
    <div class="delete-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.10096 3.20964C8.04715 3.20964 7.99939 3.24406 7.98237 3.29511L7.64975 4.29297H12.3509L12.0183 3.29511C12.0013 3.24406 11.9535 3.20964 11.8997 3.20964H8.10096ZM13.8442 4.29297L13.3622 2.84712C13.1524 2.21759 12.5633 1.79297 11.8997 1.79297H8.10096C7.43738 1.79297 6.84825 2.21759 6.6384 2.84712L6.15645 4.29297H4.16699H2.50033C2.10912 4.29297 1.79199 4.6101 1.79199 5.0013C1.79199 5.3925 2.10912 5.70964 2.50033 5.70964H3.50431L4.13798 15.2147C4.25034 16.9001 5.65014 18.2096 7.33921 18.2096H12.6614C14.3505 18.2096 15.7503 16.9 15.8627 15.2147L16.4963 5.70964H17.5003C17.8915 5.70964 18.2087 5.3925 18.2087 5.0013C18.2087 4.6101 17.8915 4.29297 17.5003 4.29297H15.8337H13.8442ZM15.0765 5.70964H13.3337H6.66699H4.92412L5.55151 15.1205C5.61425 16.0616 6.39596 16.793 7.33921 16.793H12.6614C13.6047 16.793 14.3864 16.0616 14.4491 15.1205L15.0765 5.70964Z"
          fill="#EE7373"
        />
      </svg>
    </div>
    Delete
  </div>
</template>

<script>
export default {
  name: "DeleteButton",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
