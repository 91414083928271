<template>
  <div
    :class="'clc-steps active-step-' + step">
    <div class="clc-steps__indicator"></div>
    <div
      v-for="(num, ind) in [1,2,3]"
      :class="'clc-step-tab clc-step-tab__step' + num"
      @click.prevent="eventStepTabClick(num)"
    >Step {{ num }}
    </div>
  </div>
</template>

<script>
export default {
  name: "StepTabs",
  props: {
    step: {
      type: [Number, String],
      default: 1
    }
  },
  emits: ['change-step'],
  methods: {
    eventStepTabClick(num) {
      this.$emit('change-step', num);
    }
  }
}
</script>
