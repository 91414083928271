<template>
  <div id="login-form" class="accounting">
    <div class="accounting__inner">
      <div class="accounting__left">
        <a href="https://expresspermits.llc/" class="logo">
          <img :src="logo" alt="Express Permits LLC"/>
        </a>
        <img class="background" :src="bg" alt="truck"/>
      </div>
      <div class="accounting__right">
        <sign-in-form/>
      </div>
    </div>
  </div>
  <spinner :loading="isLoading"/>
</template>

<script>
import Spinner from "@/components/helpers/Spinner.vue";
import SignInForm from "@/components/authorization/SignIn.vue";
import logo from "@/assets/images/logo_white.svg";
import bg from "@/assets/images/bg.jpg";

export default {
  name: "LoginForm",
  components: {SignInForm, Spinner},
  data() {
    return {
      bg,
      logo,
      isLoading: false,
      showingSignUp: false,
      showingSignIn: true,
      showingRecoverPassword: false,
    };
  },
};
</script>
