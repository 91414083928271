<template>
    <transition name="slide-in-from-top">
        <div v-if="showPopup" class="contact-withus-popup" @click="closePopupOutside" ref="popupContainer">
            <div class="popup-content">
                <button class="contact-withus-popup-close" @click="closePopup">
                    <svg width="20" height="20" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0"
                        viewBox="0 0 100 100" xml:space="preserve">
                        <path fill="none" stroke="#e15b64" stroke-width="16" stroke-miterlimit="10" d="M22 78l56-56" />
                        <path fill="none" stroke="#e15b64" stroke-width="16" stroke-miterlimit="10" d="M78 78L22 22" />
                    </svg>
                </button>
                <h3>Contact with Us</h3>
                <p>Please if you have any questions please contact with us.</p>
                <div class="form-elements">
                    <div class="form-line form-grid">
                        <input-textarea v-model="contactForm.message" placeholder="Message" :disabled="disabled" :valid-sw="validSw"
                            :rules="rules.longText" @error="regError('message', $event)" />
                    </div>
                    <div class="form-line">
                        <button type="button" :disabled="disabled"
                            class="button clc-button big-button primary-button button-apply" @click="submit">
                            <span>Send</span>
                        </button>

                    </div>
                </div>

            </div>
        </div>
    </transition>
</template>

<script>
import InputTextarea from "@/components/form-elements/InputTextarea.vue";
import ValidateMixin from "@/mixins/ValidateMixin";
export default {
    name: "PopupContactUs",
    components: {
        InputTextarea,

    },
    mixins: [ValidateMixin],
    data() {
        return {
            disabled: false,
            validSw: false,
            contactForm: {
                message: null,
            },
            errors: {
                message: null,
            }
        };
    },

    props: {
        showPopup: {
            type: Boolean,
            default: false,
        },
    },

    computed: {

    },
    emits: [
        "close-popup",
    ],
    methods: {
        submit() {
            if (this.is_form_valid_new()) return;
            this.disabled = true;
            const formData = { ...this.contactForm };
            this.$store
                .dispatch("actionSentPopupMessage", { formData })
                .then(() => {
                    this.contactForm.message = null;
                    this.closePopup();
                    this.disabled = false;
                });

        },
        is_form_valid_new() {
            let valid = false;
            const allErrorsValid = Object.values(this.errors).some(error => error === true || error === null);
            this.$store.dispatch("actionGenerateId", 32).then((id) => {
                this.validSw = id;
            });
            valid = allErrorsValid;
            return valid;
        },

        closePopup() {
            this.$emit("close-popup");
        },
        closePopupOutside(event) {
            const popupContainer = this.$refs.popupContainer;

            if (event.target === popupContainer) {
                this.$emit("close-popup");
            }
        },

    },
};
</script>