<template>
  <div class="payment_tab">
    <div class="payment_tab__inner">
      <h3>Paypal method of payment</h3>
      <p>
        PayPal allows you to make payments using a variety of methods including:
        PayPal balance, a bank account, PayPal Credit, debit or credit cards,
        and rewards balance.
      </p>
      <p>This page does not address unauthorized transactions.</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "PaymentsTab",
  components: {},
  data() {
    return {};
  },

  methods: {},
};
</script>
