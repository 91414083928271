/*
Math for feets and inches
 */

export const FeetMathMixin = {
  methods: {

    calculate_overall_length(eq_data, params) {
      // find max
      const max_dec = params.reduce((accum, item) => {
        const dec_data = this.feet_to_dec(item);
        return Math.max(accum, dec_data);
      }, 0);

      const total_dec = this.feet_to_dec(eq_data.total);
      const trailer_dec = this.feet_to_dec(eq_data.trailer);
      // ---
      const result_dec = total_dec - trailer_dec + max_dec;
      return this.dec_to_feet(result_dec);
    },

    /**
     *
     * @param params
     * @returns {string}
     */
    feet_sum(params) {
      const summ_dec = params.reduce((accum, item) => {
        const dec_data = this.feet_to_dec(item);
        return +accum + +dec_data;
      }, 0);
      return this.dec_to_feet(summ_dec);
    },

    feet_max(params) {
      const max_dec = params.reduce((accum, item) => {
        const dec_data = this.feet_to_dec(item);
        // console.log('params', accum, dec_data);
        return Math.max(accum, dec_data);
      }, 0);
      return this.dec_to_feet(max_dec);
    },

    /**
     *
     * @param feet_data
     * @returns {*|number}
     */
    feet_to_dec(feet_data) {
      feet_data = feet_data.toString();
      const regex = /^((\d+)')?((\d+)")?$/;
      const match = feet_data.match(regex);
      if (!match) {
        return +feet_data;
      }
      const feets = (match[2] !== undefined) ? +match[2] : 0;
      const inches = (match[4] !== undefined) ? +match[4] : 0;
      return (feets * 12) + inches;

    },

    /**
     *
     * @param dec_data
     * @returns {string}
     */
    dec_to_feet(dec_data) {
      dec_data = +dec_data;
      const feets = Math.floor(dec_data / 12);
      const inches = Math.floor(dec_data % 12);

      const res_arr = [];
      if (!!feets) {
        res_arr.push(feets.toString() + "'");
      }
      if (!!inches) {
        res_arr.push(inches.toString() + '"');
      }
      return res_arr.join('');
    }

  }
};