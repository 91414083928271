<template>
  <div class="part-form--vehicle-button">
    <button @click.prevent="openMenu" type="button" class=" button">
      <!-- <span>
        <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M6.00008 0.291672C6.39128 0.291672 6.70841 0.608803 6.70841 1.00001V5.29167H11.0001C11.3913 5.29167 11.7084 5.6088 11.7084 6C11.7084 6.39121 11.3913 6.70834 11.0001 6.70834H6.70841V11C6.70841 11.3912 6.39128 11.7083 6.00008 11.7083C5.60888 11.7083 5.29175 11.3912 5.29175 11V6.70834H1.00008C0.60888 6.70834 0.291748 6.39121 0.291748 6C0.291748 5.6088 0.60888 5.29167 1.00008 5.29167H5.29175V1.00001C5.29175 0.608803 5.60888 0.291672 6.00008 0.291672Z"
            fill="#00101E" />
        </svg>
      </span> -->
      Add Power Unit/SME
    </button>
    <div :class="['add-button--type-menu', { 'open': menu_open }]">
      <div @click.prevent="createNewPart('tractor', $event); createNewPart('trailer', $event) ">Power Unit</div>
      <div @click.prevent="createNewPart('sme', $event)">SME</div>
    </div>

  </div>
</template>


<script>

export default {
  name: "PartAddVehicleButton",
  data() {
    return {
      menu_open: false
    }
  },
  methods: {
    openMenu() {
      this.menu_open = !this.menu_open;
    },
    createNewPart(type) {
      this.$emit('createNewPart', type);
      this.menu_open = false;
    }
  }

}
</script>