<template>
  <div class="alert-notification">
    <div
        :class="['alert-content', 'alert-info', {'display': display}]"
        v-html="content"
    ></div>
  </div>
</template>

<script>

export default {
  name: "AlertNotification",
  data() {
    return {
      display: false,
      content: null,
      type: null,
    }
  },

  computed: {
    message_obj() {
      return this.$store.getters.message;
    }
  },

  watch: {
    message_obj(data) {
      if (data && data.message) {
        if(data.message.includes("failed")) { // fix bug
          this.content = null;
          return;
        } else {
          this.content = data.message;
        }
        this.type = data.type;
        this.display = true;
        //1
        setTimeout(() => {
          this.display = false;
        }, 2500);
        //2
        setTimeout(() => {
          this.$store.dispatch('hide_alert')
        }, 3000);

      } else if (!data) {
        this.content = null;
      }
    }
  }


}
</script>