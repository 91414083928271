<template>
  <div class="tab-buttons-icons">
    <button v-for="(tab, index) in tabs" :key="index" @click="activeTab = index"
      :class="{ active: activeTab === index, [tab.icon]: true }">
      {{ tab.title }}
    </button>
  </div>
  <div class="tab-content">
    <div v-for="(tab, index) in tabs" :key="index" :class="{ active: activeTab === index }">

      <div class="tab-content__icon" 
        :class="{ [tab.icon]: true, active: activeTab === index, }"
        v-for="(tab, index) in tabs" :key="index">
        <div v-if="activeTab === index">
          {{ tab.title }}
        </div>
      </div>

      <div class="tab-content__wrap">
        <template v-if="tab.tab_name === 'company_tab'">
          <company-tab />
        </template>
        <template v-else-if="tab.tab_name === 'company_users_tab'">
          <company-users-tab />
        </template>
        <!-- <template v-else-if="tab.tab_name === 'payment_tab'">
          <payments-tab />
        </template> -->
        <template v-else-if="tab.tab_name === 'support_tab'">
          <support-tab />
        </template>
        <template v-else-if="tab.tab_name === 'profile_tab' && !isLargeScreen">
          <ProfileSettings />
          <!-- <support-tab /> -->
        </template>
      </div>
    </div>

  </div>
</template>
<script>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import CompanyTab from "@/components/profile/tabs/CompanyTab.vue";
import CompanyUsersTab from "@/components/profile/tabs/CompanyUsersTab.vue";
import SupportTab from "@/components/profile/tabs/SupportTab.vue";
import PaymentsTab from "@/components/profile/tabs/PaymentsTab.vue";
import ProfileSettings from "@/components/profile/ProfileSettings.vue";
export default {
  name: "ProfileTabs",
  components: {
    CompanyTab,
    CompanyUsersTab,
    PaymentsTab,
    SupportTab,
    ProfileSettings
  },
  setup() {
    const isLargeScreen = ref(window.innerWidth >= 1150);

    // Update the isLargeScreen value when the window is resized
    const updateScreenSize = () => {
      isLargeScreen.value = window.innerWidth >= 1150;
    };

    // Add an event listener to update the screen size when the window is resized
    onMounted(() => {
      window.addEventListener('resize', updateScreenSize);
    });

    // Remove the event listener when the component is unmounted
    onUnmounted(() => {
      window.removeEventListener('resize', updateScreenSize);
    });

    // Use a computed property to always get the latest value of isLargeScreen
    const isLargeScreenComputed = computed(() => isLargeScreen.value);

    return {
      isLargeScreen: isLargeScreenComputed,
    };
  },
  computed: {
    user_data() {
      return this.$store.getters.userData;
    },
  },
  data() {
    return {
      activeTab: null,
      tabs: [
        { title: "Company", icon: "company-icon", tab_name: "company_tab" },
        {
          title: "Company Users",
          icon: "users-icon",
          tab_name: "company_users_tab",
        },
        // {
        //   title: "Payment method",
        //   icon: "payment-icon",
        //   tab_name: "payment_tab",
        // },
        { title: "Support", icon: "support-icon", tab_name: "support_tab" },
        { title: "Profile", icon: "profile-icon", tab_name: "profile_tab" },
      ],
    };
  },

  methods: {},
  mounted() {
    this.activeTab = 0; 
    if(this.user_data.role !== 'owner' && this.user_data.role !== 'driver') {
      this.activeTab = 4
    }
  },

};
</script>
