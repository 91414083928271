<template>
  <div id="login-form" class="accounting">
    <div class="accounting__inner">
      <div class="accounting__left">
        <a href="https://expresspermits.llc/" class="logo">
          <img :src="logo" alt="Express Permits LLC"/>
        </a>
        <img class="background" :src="bg" alt="truck"/>
      </div>
      <div class="accounting__right">
        <recover-password-form v-if="$route.name === 'password_recover'"/>
        <reset-password-form v-else-if="$route.name === 'password_reset'"/>
      </div>
    </div>
  </div>
  <spinner :loading="isLoading"/>
</template>

<script>
import Spinner from "@/components/helpers/Spinner.vue";
import ResetPasswordForm from "@/pages/passwordReset/PasswordResetForm";
import logo from "@/assets/images/logo_white.svg";
import bg from "@/assets/images/bg.jpg";
import RecoverPasswordForm from "@/components/authorization/RecoverPassword.vue";

export default {
  name: "LoginForm",
  components: {RecoverPasswordForm, ResetPasswordForm, Spinner},
  data() {
    return {
      bg,
      logo,
      isLoading: false,

    };
  },
};
</script>
  