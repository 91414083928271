import { createStore } from "vuex";

const API_URL = calc_config.api_url;
const LOCAL_API_URL = calc_config.local_api_url;

// Modules
import StoreModuleEquipment from "@/store/StoreModuleEquipment";
import StoreModuleUser from "@/store/StoreModuleUser";
import StoreModuleCompanies from "@/store/StoreModuleCompanies";
import StoreModuleAlertNotification from "@/store/StoreModuleAlertNotification";
import StoreModuleLoginPageServices from "@/store/StoreModuleLoginPageServices";
import StoreModuleInviteUser from "@/store/StoreModuleInviteUser";
import StoreModuleOrders from "@/store/StoreModuleOrders";

export default createStore({
  modules: {
    StoreModuleEquipment,
    StoreModuleUser,
    StoreModuleCompanies,
    StoreModuleAlertNotification,
    StoreModuleLoginPageServices,
    StoreModuleInviteUser,
    StoreModuleOrders,
  },
  state: {
    state_list: null,
    order_by_id: [],
    client_list: [],
    admin_order_list: [],
    order_list_by_user_id: [],
    order_client_list_by_user_id: [],
  },
  getters: {
    apiUrl() {
      let protocol = window.location.protocol;
      protocol = protocol.substring(0, protocol.length - 1);
      if (
        protocol === "http" ||
        window.location.hostname.toString() === "localhost"
      ) {
        return LOCAL_API_URL;
      }
      return API_URL;
    },
    clientList(state) {
      return state.client_list;
    },
    admin_order_list(state) {
      return state.admin_order_list;
    },
    order_list_by_user_id(state) {
      return state.order_list_by_user_id;
    },
    order_by_id(state) {
      return state.order_by_id;
    },
    order_client_list_by_user_id(state) {
      return state.order_client_list_by_user_id;
    },
    state_list(state) {
      if (!state.state_list) {
        return [];
      }

      // const listNames = state.state_list.map((state) => state.name);

      // console.table(state.state_list);
      // return listNames;

      const newList = state.state_list.map((state) => state);

      // console.table(newList);
      return newList;
    },
  },
  mutations: {
    mutation_add_client_list(state, payload) {
      state.client_list = payload;
    },
    mutation_order_by_id(state, payload) {
      state.order_by_id = payload;
    },

    mutation_admin_orders_list(state, payload) {
      state.admin_order_list = payload;
    },

    mutation_pagination_admin_orders_list(state, payload) {
      state.admin_order_list = state.admin_order_list.concat(payload);
    },
    mutation_pagination_admin_client_list(state, payload) {
      state.client_list = state.client_list.concat(payload);
    },

    mutation_client_orders_list_by_user_id(state, payload) {
      state.order_client_list_by_user_id = payload;
    },
    mutation_pagination_client_orders_list_by_user_id(state, payload) {
      state.order_client_list_by_user_id =
        state.order_client_list_by_user_id.concat(payload);
    },

    // for client order list
    mutation_orders_list_by_user_id(state, payload) {
      state.order_list_by_user_id = payload;
    },
    mutation_pagination_orders_list_by_user_id(state, payload) {
      state.order_list_by_user_id = state.order_list_by_user_id.concat(payload);
    },
    mutation_get_state_list(state, payload) {
      state.state_list = payload;
    },
  },
  actions: {
    async actionRequest(
      { rootGetters, commit, dispatch },
      { uri, authorization = true, method = "GET", post_data = null }
    ) {
      let token;
      let headers = { "Content-Type": "application/json" };

      const apiUrl = await rootGetters.apiUrl;

      const arg = {
        method: method,
        mode: "cors",
        cache: "no-cache",
        redirect: "follow",
        referrerPolicy: "no-referrer",
      };

      if (authorization) {
        token = localStorage.getItem("token");

        if (!token) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          if (process.env.NODE_ENV === "development") {
            console.error(' --- Request "' + uri + '" error | No token');
          }
          return Promise.reject("No token");
        }
        headers["Authorization"] = "Bearer " + token;
        // headers["test"] = true;
        arg.credentials = "include";
      }

      arg.headers = headers;

      if (method.toUpperCase() === "POST") {
        arg.body = JSON.stringify(post_data);
      }

      try {
        const response = await fetch(apiUrl + uri, arg);

        const status = response.status;
        const ok = response.ok;
        const result = await response.json();

        // if (!!authorization && (status === 401)) {
        //   dispatch('actionLogOut')
        //     .then(() => {
        //       window.location.reload();
        //     });
        // }

        // commit('set_loading', false);

        if (result.message !== undefined) {
          let message = result.message;
          dispatch("alert", { message, type: "info" });
        }

        if (ok) {
          return Promise.resolve({ result, ok, status });
        } else {
          return Promise.reject(result);
        }
      } catch (err) {
        if (err.message !== undefined) {
          let message = err.message;
          dispatch("alert", { message, type: "error" });
        }
        return Promise.reject(err);
      }
    },
    async actionFileRequest(
      { rootGetters, commit, dispatch },
      { uri, authorization = true, method = "POST", post_data = null }
    ) {
      let token;
      let headers = {};

      const apiUrl = await rootGetters.apiUrl;

      const arg = {
        method: method,
        mode: "cors",
        cache: "no-cache",
        redirect: "follow",
        referrerPolicy: "no-referrer",
      };

      if (authorization) {
        token = localStorage.getItem("token");

        if (!token) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          if (process.env.NODE_ENV === "development") {
            console.error(' --- Request "' + uri + '" error | No token');
          }
          return Promise.reject("No token");
        }
        headers["Authorization"] = "Bearer " + token;
        arg.credentials = "include";
      }

      arg.headers = headers;

      if (method.toUpperCase() === "POST") {
        arg.body = post_data;
      }

      try {
        const response = await fetch(apiUrl + uri, arg);

        const status = response.status;
        const ok = response.ok;
        const result = await response.json();

        // if (!!authorization && (status === 401)) {
        //   dispatch('actionLogOut')
        //     .then(() => {
        //       window.location.reload();
        //     });
        // }

        // commit('set_loading', false);

        if (result.message !== undefined) {
          let message = result.message;
          console.log(message);
          // dispatch("alert", { message, type: "info" });
        }

        if (ok) {
          return Promise.resolve({ result, ok, status });
        } else {
          return Promise.reject(result);
        }
      } catch (err) {
        if (err.message !== undefined) {
          let message = err.message;
          console.log(message);
          // dispatch("alert", { message, type: "error" });
        }
        return Promise.reject(err);
      }
    },

    /**
     * GENERATOR
     * @param sign_num
     * @returns {Promise<string>}
     */
    async actionGenerateId({}, sign_num = 9) {
      let num, s;
      const sign_arr = [];
      for (let i = 0; i < sign_num; i++) {
        num = i % 3;
        switch (num) {
          case 0:
            s = Math.round(49 + Math.random() * (57 - 49));
            break;
          case 1:
            s = Math.round(65 + Math.random() * (90 - 65));
            break;
          case 2:
            s = Math.round(97 + Math.random() * (122 - 97));
            break;
        }
        sign_arr.push(String.fromCharCode(s));
      }
      const sorted = sign_arr.sort(() => (Math.random() > 0.5 ? 1 : -1));
      const out = sorted.join("").toString();
      return Promise.resolve(out);
    },

    // ------------- For manager

    /**
     *
     * @param commit
     * @param dispatch
     * @param parameters
     * @returns {Promise<unknown>}
     */
    async actionGetClientList(
      { commit, dispatch, state },
      { parameters, event = "change_parameters" }
    ) {
      // localStorage.setItem("clientListParameters", JSON.stringify(parameters));

      try {
        const request = await dispatch("actionRequest", {
          uri: "/manager-get-client-list",
          method: "POST",
          post_data: parameters,
        });
        const result = request.result;

        const client_list = result.client_list;

        if (event === "change_parameters") {
          commit("mutation_add_client_list", client_list);
        } else if (event === "pagination") {
          commit("mutation_pagination_admin_client_list", client_list);
        } else if (event === "search") {
          state.client_list = [];
          commit("mutation_add_client_list", client_list);
        }

        return Promise.resolve(result);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async actionGetClientListByUserId(
      { commit, dispatch, state },
      { parameters, event = "change_parameters" }
    ) {
      // localStorage.setItem('clientListParameters', JSON.stringify(parameters));
      try {
        const request = await dispatch("actionRequest", {
          uri: "/admin-get-client-list-by-user-id",
          method: "POST",
          post_data: { parameters },
        });
        const result = request.result;

        const orders_list = result.orders_list;

        if (event === "change_parameters") {
          commit("mutation_client_orders_list_by_user_id", orders_list);
        } else if (event === "pagination") {
          commit(
            "mutation_pagination_client_orders_list_by_user_id",
            orders_list
          );
        }

        return Promise.resolve(result);
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async actionGetAdminOrdersList(
      { commit, dispatch, state },
      { parameters, event = "change_parameters" }
    ) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/admin-get-orders-list",
          method: "POST",
          post_data: { parameters },
        });
        const result = request.result;
        const orders_list = result.orders_list;
        if (event === "change_parameters") {
          commit("mutation_admin_orders_list", orders_list);
        } else if (event === "pagination") {
          commit("mutation_pagination_admin_orders_list", orders_list);
        }

        return Promise.resolve(result);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async actionAssignManagerToOrder({ dispatch }, { parameters }) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/assign-manager-to-order",
          method: "POST",
          post_data: { parameters },
        });
        const result = request.result;

        return Promise.resolve(result);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async actionAdminAssignManagerToOrder({ dispatch }, { parameters }) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/admin-assign-manager-to-order",
          method: "POST",
          post_data: { parameters },
        });
        const result = request.result;

        return Promise.resolve(result);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async actionGetOrdersListByCompanyId(
      { commit, dispatch, state },
      { parameters, event = "change_parameters" }
    ) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/get-order-list-by-company-id",
          method: "POST",
          post_data: { parameters },
        });
        const result = request.result;
        const orders_list = result.orders_list;

        if (event === "change_parameters") {
          commit("mutation_orders_list_by_user_id", orders_list);
        } else if (event === "pagination") {
          commit("mutation_pagination_orders_list_by_user_id", orders_list);
        }

        return Promise.resolve(result);
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async actionGetOrderbyOrderId({ dispatch, commit }, { order_id }) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/get-order-by-order-id",
          method: "POST",
          post_data: { order_id },
        });
        const result = request.result;
        const order = result.order;

        commit("mutation_order_by_id", order);

        return Promise.resolve(result);
      } catch (err) {
        // return Promise.reject(err);
      }
    },

    async actionUpdateOrderByOrderId(
      { commit, dispatch, state },
      { order_id, parameters }
    ) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/update-order-by-order-id",
          method: "POST",
          post_data: { order_id, parameters },
        });
        const result = request.result;

        console.log(result);
        return Promise.resolve(result);
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async actionGetPrediction({ rootGetters, dispatch }, { value, quantity }) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/get-prediction/" + value + "/" + quantity,
          authorization: false,
        });

        const result = request.result;
        const ok = request.ok;

        if (ok) {
          return Promise.resolve(result);
        } else {
          return Promise.reject(result);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async actionGetStates({ dispatch, commit }) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/get-states/",
        });

        const result = request.result;
        const ok = request.ok;

        commit("mutation_get_state_list", result.state_list);

        if (ok) {
          return Promise.resolve(result);
        } else {
          return Promise.reject(result);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async actionGetBtSettings({ commit, dispatch, state }) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/get-bt-settings",
          method: "GET",
        });
        const result = request.result;

        return Promise.resolve(result);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async actionUpdateBtSettings({ dispatch }, { parameters }) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/update-bt-settings",
          method: "POST",
          post_data: { parameters },
        });
        const result = request.result;

        return Promise.resolve(result);
      } catch (err) {
        return Promise.reject(err);
      }
    },
  },
});
