<template>
  <div v-if="!success && !fail">
    <div class="recover-form">
      <div class="form-title">
        Lost your password? Enter your details to recover
      </div>
      <div class="form-elements">
        <div class="form-element">
          <input-email
              v-model="recoverPasswordForm.email"
              placeholder="example@example.com"
              :rules="rules.requiredEmail"
              @error="
            (err) => {
              regError('email', err);
            }
          "
          />
        </div>
      </div>
      <button
          class="button"
          @click="recoverPassword"
          :disabled="!isStepValid"
      >
        Recover
      </button>
    </div>
    <div class="check-account">
      <span>Don't have an account?</span>
      <button class="button-link" @click="onSignUp()">Sign Up</button>
    </div>
  </div>
  <recover-password-send-success v-if="success"/>
  <recover-password-send-failed v-if="fail" @back="() => {
    this.fail = false;
    this.success = false;
  }" />

</template>

<script>
import Spinner from "@/components/helpers/Spinner.vue";
import InputEmail from "@/components/form-elements/InputEmail.vue";
import ValidateMixin from "@/mixins/ValidateMixin";
import RecoverPasswordSendSuccess from "@/components/authorization/RecoverPasswordSendSuccess.vue";
import RecoverPasswordSendFailed from "@/components/authorization/RecoverPasswordSendFailed.vue";

export default {
  name: "RecoverPasswordForm",
  components: {InputEmail, RecoverPasswordSendSuccess, RecoverPasswordSendFailed, Spinner},
  mixins: [ValidateMixin],
  data() {
    return {
      isLoading: false,
      success: false,
      fail: false,
      disabled: false,
      recoverPasswordForm: {
        email: null,
      },
      errors: {
        email: null,
      },
    };
  },
  computed: {
    isStepValid() {
      const existError = Object.keys(this.errors).find((el) => this.errors[el]);
      const isEmptyField = Object.values(this.recoverPasswordForm).some(
          (value) => !value
      );
      return !existError && !isEmptyField && !this.disabled;
    },
  },
  emits: ['updateSignUp'],
  methods: {
    recoverPassword() {
      this.disabled = true;
      this.$store.dispatch("actionRecoverPassword", {
        email: this.recoverPasswordForm.email
      })
          .then(response => {
            if (response.result) {
              this.disabled = false;
              
              this.success = true;
              this.fail = false;
              // setTimeout(() => {
              //   this.$router.push('/');
              // }, 10000);
            }
          })
          .catch(err => {
          });
      // this.success = true;
      // this.fail = true
    },
    onSignUp() {
      this.$router.push({name: 'sign_up'});
    },
    getAgreementLabel() {
      return 'I agree to the <a target="_blank" href="https://expresspermits.llc/service-agreement/">Service Agreement</a> and <a target="_blank" href="https://expresspermits.llc/privacy-policy/">Privacy Policy.</a>.</a>';
    },
  },
};
</script>
