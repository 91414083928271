<template>
  <div class="input-wrap">
    <div
      :class="['input-box', 'calc__address-input-box', 'calc__autocomplate-address--input-box ', { 'not-valid-data-box': !!errorText }, {'disabled': !!disabled}]">
      <label :for="id">{{ label }}</label>
      <input v-model="formInput" :class="['input-icon', 'map-icon', { 'not-valid-data': !!errorText }]" :id="id"
        :placeholder="placeholder" type="text" :maxlength="maxlength" :disabled="disabled" :minlength="minlength" autocomplete="off"
        @keyup="eventKeyUp" @input="eventInput" @blur="eventBlur" />
      <span v-if="!!errorText" class="err">{{ errorText }}</span>
      <button v-if="copy && formInput" class="copy-link" @click="copyLink">
        <svg width="64px" height="64px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" stroke-width="3"
          stroke="#6B7280" fill="none">
          <rect x="11.13" y="17.72" width="33.92" height="36.85" rx="2.5" />
          <path
            d="M19.35,14.23V13.09a3.51,3.51,0,0,1,3.33-3.66H49.54a3.51,3.51,0,0,1,3.33,3.66V42.62a3.51,3.51,0,0,1-3.33,3.66H48.39" />
        </svg>
      </button>
      <div :class="['prediction-panel', { 'panel-display': !!prediction.length }]">
        <div v-for="(item, ind) in prediction" :class="['prediction-item', { 'active': ind === currentPrediction }]"
          v-html="item.html" @click="setPredictionToCurrent(item.text)"></div>
      </div>

    </div>
    <span v-if="!!showCopied"  class="show-copied">Copied!</span>
  </div>
</template>

<script>
export default {
  name: "AddressField",
  data() {
    return {
      formInput: this.modelValue,
      timeout: null,
      prediction: [],
      currentPrediction: 0,
      errorText: null,
      showCopied: false
    }
  },
  props: {
    modelValue: {
      type: String,
      default: null
    },
    validSw: {
      type: [Boolean, String],
    },
    disabled: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => []
    },
    predictionsQuantity: {
      type: [Number, String],
      default: 5
    },
    minlength: {
      type: [String, Number],
      default: 0
    },
    maxlength: {
      type: [String, Number],
      default: 32
    },
    copy: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'error', 'updateRoute'],
  methods: {
    copyLink() {
      const input = document.createElement("input");
      input.value = this.formInput;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);

      this.showCopied = true;
      setTimeout(() => {
        this.showCopied = false;
      }, 3000);
    },


    eventInput(e) {
      const value = e.target.value;
      this.$emit('update:modelValue', value);
      this.getPrediction(value);
    },

    eventKeyUp(e) {
      const length = this.prediction.length;
      const currentPrediction = +this.currentPrediction;

      const code = e.code;
      const service_code = [
        "ArrowUp",
        "ArrowDown",
        "ArrowRight",
        "Enter",
        "NumpadEnter"
      ];

      const is_service_code = service_code.indexOf(code) !== -1;

      if (is_service_code) {
        if (!!length) {

          // key up
          if (e.code === "ArrowUp") {
            if ((currentPrediction - 1) < 0) {
              this.currentPrediction = length - 1;
            } else {
              this.currentPrediction--;
            }
          }

          // key down
          if (e.code === "ArrowDown") {
            if ((currentPrediction + 1) >= length) {
              this.currentPrediction = 0;
            } else {
              this.currentPrediction++;
            }
          }

          if (e.code === "Enter" || e.code === "NumpadEnter" || e.code === "ArrowRight") {
            const curValue = this.prediction[currentPrediction];
            this.setPredictionToCurrent(curValue.text);
          }
        } else {
          this.currentPrediction = 0;
        }
      } else {
        const value = e.target.value;
        if (!value.trim()) {
          this.prediction = [];
        } else {
          if (!this.disabled) {
            this.getPrediction(value);
          }
        }
      }
    },

    /**
     *
     * @param value
     */
    getPrediction(value) {

      if (!value.trim()) {
        this.prediction = [];
        return;
      }

      const autocompleteService = new google.maps.places.AutocompleteService();
      const usBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(24.396308, -125.0),
        new google.maps.LatLng(49.384358, -66.93457)
      );

      autocompleteService.getPlacePredictions(
        { input: value, bounds: usBounds, componentRestrictions: { country: 'us' } },
        (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            this.prediction = predictions.map(prediction => ({
              text: prediction.description,
              html: prediction.description
            }));
          } else {
            this.prediction = [];
          }
        }
      );
    },


    setPredictionToCurrent(word) {
      this.formInput = word;
      this.prediction = [];
      this.currentPrediction = 0;
      this.$emit('update:modelValue', word);
      this.$emit('updateRoute', word.trim());
    },
    eventBlur() {
      this.validateData(this.formInput);
    },
    validateData(v) {
      if(v) {
        v = v.trim(); // Trim the value before validation
      }

      if (v === '') {
        this.errorText = "This field cannot be empty.";
        this.$emit('error', true);
        return;
      }

      if (!!this.rules && this.rules.length) {
        const err_txt = this.rules
          .map(f => f(v))
          .find(itm => itm !== true);

        if (!!err_txt) {
          this.errorText = err_txt;
          this.$emit('error', true);
          return;
        } else {
          this.errorText = null;
          this.$emit('error', false);
          return;
        }
      }
      this.$emit('error', false);
    },

  },
  watch: {
    validSw() {
      this.validateData(this.formInput);
    },
    modelValue(new_value, old_value) {
      this.formInput = new_value;
    }
  }
}
</script>

