<template>
  <div class="template">
    <accounting-panel />
    <main class="main">
      <div class="container">
        <router-view />
      </div>
    </main>
  </div>
</template>

<script>
import AccountingPanel from "@/components/header/AccountingPanel.vue";
export default {
  components: { AccountingPanel },
  name: "adminTemplate",
};
</script>
