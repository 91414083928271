<template>
  <div class="reset-form-success">
    <div class="success-icon">
      <svg
        width="72"
        height="72"
        viewBox="0 0 72 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M33.1213 58.9436C32.7135 60.2089 32.0105 61.359 31.0705 62.2991C30.1304 63.2391 28.9803 63.9421 27.715 64.3499C28.9803 64.7577 30.1304 65.4607 31.0705 66.4007C32.0105 67.3408 32.7135 68.4909 33.1213 69.7562C33.529 68.491 34.2318 67.3409 35.1717 66.4008C36.1116 65.4608 37.2617 64.7578 38.5269 64.3499C37.2616 63.9422 36.1114 63.2393 35.1715 62.2992C34.2316 61.3591 33.5288 60.2089 33.1213 58.9436ZM47.8668 3.60449C46.0172 13.897 37.8815 22.0334 27.5906 23.8838C37.8815 25.7335 46.0172 33.8707 47.8668 44.1624C49.7164 33.8699 57.8521 25.7335 68.143 23.8838C57.8521 22.0334 49.7164 13.897 47.8668 3.60449ZM13.7612 31.6743C12.4999 36.9397 8.34695 41.0934 3.08154 42.3547C8.34695 43.6161 12.5007 47.7698 13.7612 53.0352C15.0225 47.7698 19.1755 43.6161 24.4409 42.3547C19.1755 41.0934 15.0225 36.9397 13.7612 31.6743Z"
          fill="#0080EA"
        />
      </svg>
    </div>

    <div class="form-title">Your password has been changed</div>
    <router-link class="button" to="/">Back to login</router-link>
  </div>
</template>

<script>
export default {
  name: "PasswordResetSuccess",
};
</script>
