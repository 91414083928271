<template>
  <div class="table-wrapper">
    <slot-table :data="company_users">
      <template #header>
        <tr>
          <th v-for="column in columns" :key="column.name">
            {{ column.label }}
          </th>
        </tr>
      </template>
      <template v-slot:body="{ item }">
        <tr :key="item.id">
          <td>{{ (item.vereficated_email) ? 'Verified' : 'Unverified' }}</td>
          <td class="quote-route">{{ item.email }}</td>
          <td>
            {{ item.name }}
          </td>
          <td>{{ item.role }}</td>
        </tr>
      </template>
    </slot-table>
    <invite-popup
        :show-popup="showPopup"
        @close-popup="closePopup"/>

  </div>

  <div class="invite-people" v-if="user_role === 'owner'">
    <button class="button small invite-people-button" @click="onInvitePeople">
      Invite more people
    </button>
  </div>
</template>


<script>
import SlotTable from "@/components/SlotTable.vue";
import InvitePopup from "@/components/profile/InvitePopup.vue";

export default {
  name: "CompanyUsersTab",

  components: {
    SlotTable,
    InvitePopup

  },

  data() {
    return {
      columns: [
        {name: "status", label: "Status"},
        {name: "email", label: "Email"},
        {name: "name", label: "Name"},
        {name: "role", label: "Role"},
      ],
      showPopup: false,
    };
  },

  mounted() {
    this.$store.dispatch('actionGetCompanyUserList');
  },
  computed: {
    company_users() {
      return this.$store.getters.getCompanyUsers;
    },
    user_role() {
      return this.$store.getters.userRole;
    },
  },
  methods: {
    onInvitePeople() {
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
    },


  },
};
</script>
