export default {
  data() {
    return {
      password1: null,
      password2: null,
      rules: {
        text: [
          this.moreThenNoRequiredValidate(2),
          this.lessThenNoRequiredValidate(48),
          this.textNoRequiredValidate
        ],
        numbers: [
          this.textValidateNumbers,
          this.textNoRequiredValidate
        ],
        textOnlyLetters: [
          this.requiredValidate,
          this.moreThenValidate(2),
          this.lessThenValidate(48),
          this.textValidate,
          this.textOnlyLettersandMinusValidate,
          this.textNoRequiredValidate
        ],
        vin_code: [
          
          this.requiredValidate,
          this.textValidate,
          this.moreThenValidate(15),
        ],
        license_plate: [
          this.requiredValidate,
          this.textValidate,
          this.moreThenValidate(5),
        ],
        year: [
          this.requiredValidate,
          this.year
        ],
        requiredText: [
          this.requiredValidate,
          this.moreThenValidate(2),
          this.lessThenValidate(48),
          this.textValidate
        ],
        address: [
          this.requiredValidate,
          this.moreThenValidate(2),
          this.lessThenValidate(60),
          this.addressValidate
        ],
        USDOT: [
          this.requiredValidate,
          this.moreThenValidate(2),
          this.lessThenValidate(15),
        ],
        zipCode: [
          this.requiredValidate,
          this.zipCode
        ],
        numberTT: [
          this.requiredValidate,
          this.moreThenValidate(4),
          this.latinValidate,
          this.textValidate,
          // this.lessThenValidate(15),
          // this.numberTTValidate
        ],
        companyName: [
          this.requiredValidate,
          this.moreThenValidate(2),
          this.lessThenValidate(60),
        ],
        longText: [
          this.requiredValidate,
          // this.textValidate,
          this.moreThenNoRequiredValidate(4),
          this.lessThenNoRequiredValidate(160),
        ],
        longRequiredText: [
          this.requiredValidate,
          this.moreThenValidate(4),
          this.lessThenValidate(160),
          this.textValidate
        ],
        login: [
          this.requiredValidate,
          this.moreThenValidate(3),
          this.lessThenValidate(48),
          this.latinValidate
        ],
        autocomplateField: [ 
          this.requiredValidate, 
          this.latinValidate
        ],
        password: [
          this.requiredValidate,
          this.latinValidate,
          this.moreThenValidate(8),
          this.lessThenValidate(25),
          this.upperCaseValidate,
          this.lowerCaseValidate,
          this.numberValidate,
          // this.passwordValidate,
          v => this.conf_password_val(v),
          
        ],
        // confirm_password: [
        //   this.requiredValidate,
        //   this.moreThenValidate(8),
        //   this.lessThenValidate(25),
        //   this.passwordValidate,
        //   v => this.password_val(v),
        // ],
        email: [
          this.moreThenNoRequiredValidate(5),
          this.lessThenNoRequiredValidate(48),
          this.emailNoRequiredValidate
        ],
        requiredEmail: [
          this.requiredValidate,
          this.moreThenValidate(5),
          this.lessThenValidate(48),
          this.emailValidate
        ],
        url: [
          (v) => !v || /^(https?\:\/\/|\/\/)(www\.)?([a-z0-9\.\-]+)\.([a-z]{2,4})+([a-z0-9%\/.-_?&=\[\]#:]+)?$/i.test(v) || 'Error in URL'
        ],
        phone: [
          this.requiredValidate,
          this.phoneValidate(13),
          this.phoneValidateDigits
        ],
        requiredOnly: [
          this.requiredValidate,
        ],
        dateRules: [
          this.requiredValidate,
          this.checkPastDate
        ],
        
      }
    }
  },
  methods: {
    regError(obj, err) {
      this.errors[obj] = err;
      // this.form_valid();
      // запустить is_valid для всей формы
    },

    /////////////////////////////////
    ////// Validation templates ////////
    /////////////////////////////////
    requiredValidate: (() => {
      return v => !!v || 'Required field'
    })(),
    checkPastDate: (() => {
      const today = new Date().toISOString().split('T')[0];
        return v => v >= today || 'Past Date is not allowed'
    })(),
    // Латиница
    latinValidate: (() => {
      return v => !!v && /^[a-z.]+$/.test(v) || 'Requires Latin without symbols, spaces and numbers, only lowercase'
    })(),
    // Text
    textValidate: (() => {
      return v => !!v && /^[a-zA-Z0-9а-яА-ЯёЁ\s\.\,\!\?\-\:\r\n]+$/ig.test(v) || 'The text should not contain characters'
    })(),
    textValidateNumbers: (() => {
      return v => !!v && /^\d+$/.test(v) || 'The text should contain only numbers';
    })(),
    //
    textNoRequiredValidate: (() => {
      return v => !v || /^[a-zA-Z0-9а-яА-ЯёЁ\s\.\,\!\?\-\:\r\n]+$/ig.test(v) || 'The text should not contain characters';
    })(),
    textOnlyLettersValidate: (() => {
      return v => !v || /^[a-zA-Zа-яА-ЯёЁ\s]+$/g.test(v) || 'The text should contain only letters';
    })(),
    textOnlyLettersandMinusValidate: (() => {
      return v => !v || /^[a-zA-Zа-яА-ЯёЁ\s-]+$/g.test(v) || 'The text should contain only letters';
    })(),
    // passwordValidate
    passwordValidate: (() => {
      return v => !!v && /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(v) || 'Use latin, uppercase and lowercase letters and numbers';
    })(),
    latinValidate: (() =>{
      return v => !!v && /^[a-zA-Z0-9!@#$%^&*()\-_=~`[\]{}|:;"'<>,.?/ ]+$/.test(v) || 'Use only Latin characters';
    })(),
     // address
    addressValidate: (() => {
      return v => !!v && /^[A-Za-z0-9'\.\-\s\,\//\\]+$/.test(v) || 'Not a valid address';
    })(),
    year: (() => {
      return v => !!v && /^\d{4}$/.test(v) || 'Enter a valid 4-digit Year';
    })(),
    zipCode: (() => {
      return v => !!v && /^\d{5,}$/.test(v) || 'Enter a valid 5-digit Zip Code';
    })(),
    upperCaseValidate: (() => {
      return v => !!v && /[A-Z]/.test(v) || 'Use at least one uppercase letter';
    })(),
    lowerCaseValidate: (() =>{
      return v => !!v && /[a-z]/.test(v) || 'Use at least one lowercase letter';
    })(),
    numberValidate: (() => {
      return v => !!v && /[0-9]/.test(v) || 'Use at least one number';
    })(),
    // Valid MC#, FID, IFTA и IRP.
    numberTTValidate: (() => {
      return v => !!v && /\d/.test(v) || 'Use at least one number or letter';
    })(),
    // Text
    emailValidate: (() => {
      return v => !!v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(v) || 'Error found in E-mail'
    })(),
    //
    emailNoRequiredValidate: (() => {
      return v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(v) || 'Error found in E-mail'
    })(),
    //
    moreThenValidate: (cnt) => {
      return v => !!v && v.length >= cnt || 'More than ' + cnt + ' characters required'
    },
    //
    moreThenNoRequiredValidate: (cnt) => {
      return v => !v || v.length > cnt || 'More than ' + cnt + ' characters required'
    },
    //
    lessThenValidate: (cnt) => {
      return v => !!v && v.length < cnt || 'Less than ' + cnt + ' characters required'
    },
    //
    lessThenNoRequiredValidate: (cnt) => {
      return v => !v || v.length < cnt || 'Less than ' + cnt + ' characters required'
    },
    phoneValidate: (cnt) => {
      return v => !!v && v.length > cnt || 'Phone must contain 10 digits'
    },
    phoneValidateDigits: (() => {
      return v => !v || /^\(?\d{3}\)?[ .-]?\d{3}[ .-]?\d{4}$/.test(v) || 'Please enter valid phone number'
    })(),
    password_val(v) {
      this.password1 = v;
      return this.password1 === this.password2 || 'Passwords do not match';
    },
    conf_password_val(v) {
      this.password2 = v;
      // return this.password1 === this.password2 || 'Passwords do not match';
    },

   

  }
};