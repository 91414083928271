<template>
  <transition name="slide-in-from-right">
    <div v-if="showPopup" class="history-logs-popup" @click="closePopupOutside" ref="historyContainer">
      <div class="popup-content">
        <!-- <div class="history-logs-close">
          <button class="button-link button-history close" @click="closePopup">
            History of changes
          </button>
        </div> -->
        <div class="history-logs-search">
          <input type="text" class="history-logs-search__input" placeholder="Search" />
          <button class="button-search"></button>
        </div>
        <div class="history-logs-items">

          <div v-for="(log, index) in order_logs" :key="index" class="history-logs-item">
            <div class="history-logs-date">
              <div class="history-logs-time">
                <span> {{ log.created_at }}</span>
              </div>
              <div class="history-logs-item-close"></div>
            </div>
            <div class="history-logs-item__events">
              <div v-if="log.text" v-html="log.text" class="history-logs-item__event">

              </div>
              <!-- <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div> -->
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="485" height="2" viewBox="0 0 485 2" fill="none">
              <path d="M485 1L-1.52588e-05 1" stroke="#DFE1E4" stroke-linecap="square" stroke-dasharray="8 8" />
            </svg>
          </div>
          <!-- <div class="history-logs-item">
            <div class="history-logs-date">
              <div class="history-logs-time">
                <span> 20.03.23 13:25 </span>
              </div>
              <div class="history-logs-item-close"></div>
            </div>
            <div class="history-logs-item__events">
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="485" height="2" viewBox="0 0 485 2" fill="none">
              <path d="M485 1L-1.52588e-05 1" stroke="#DFE1E4" stroke-linecap="square" stroke-dasharray="8 8" />
            </svg>
          </div>
          <div class="history-logs-item">
            <div class="history-logs-date">
              <div class="history-logs-time">
                <span> 19.03.23 11:25 </span>
              </div>
              <div class="history-logs-item-close"></div>
            </div>
            <div class="history-logs-item__events">
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="485" height="2" viewBox="0 0 485 2" fill="none">
              <path d="M485 1L-1.52588e-05 1" stroke="#DFE1E4" stroke-linecap="square" stroke-dasharray="8 8" />
            </svg>
          </div>
          <div class="history-logs-item">
            <div class="history-logs-date">
              <div class="history-logs-time">
                <span> 18.03.23 13:25 </span>
              </div>
              <div class="history-logs-item-close"></div>
            </div>
            <div class="history-logs-item__events">
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="485" height="2" viewBox="0 0 485 2" fill="none">
              <path d="M485 1L-1.52588e-05 1" stroke="#DFE1E4" stroke-linecap="square" stroke-dasharray="8 8" />
            </svg>
          </div>
          <div class="history-logs-item">
            <div class="history-logs-date">
              <div class="history-logs-time">
                <span> 19.03.23 19:55 </span>
              </div>
              <div class="history-logs-item-close"></div>
            </div>
            <div class="history-logs-item__events">
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="485" height="2" viewBox="0 0 485 2" fill="none">
              <path d="M485 1L-1.52588e-05 1" stroke="#DFE1E4" stroke-linecap="square" stroke-dasharray="8 8" />
            </svg>
          </div>
          <div class="history-logs-item">
            <div class="history-logs-date">
              <div class="history-logs-time">
                <span> 16.03.23 17:35 </span>
              </div>
              <div class="history-logs-item-close"></div>
            </div>
            <div class="history-logs-item__events">
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
              <div class="history-logs-item__event">
                The value <strong>"field name"</strong> has been changed to
                <strong>"value"</strong>.
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="485" height="2" viewBox="0 0 485 2" fill="none">
              <path d="M485 1L-1.52588e-05 1" stroke="#DFE1E4" stroke-linecap="square" stroke-dasharray="8 8" />
            </svg>
          </div> -->
        </div>

        <div class="history-logs__download">
          <button class="button small download">
            Download history of changes
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "HistoryLogs",

  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    order_logs() {

      const data = this.$store.getters.order_logs.data;
      const findMetaValueByMetaKey = (metaKey) => {
        return data.find(obj => {
          const metaValue = JSON.parse(obj.meta_value);
          return metaValue.hasOwnProperty(metaKey);
        });
      };
      
      data.forEach(currentObj => {
        const meta_value = JSON.parse(currentObj.meta_value);
        const meta_key = currentObj.meta_key;

        if (meta_value.deposit_needed) {
          currentObj.text = `The order was created and set to status <strong>"${meta_value.deposit_needed}"</strong>.`;
        }

        if (meta_value.deposit_paid) {
          const statusObject = findMetaValueByMetaKey("deposit_needed");
          const prevStatus = Object.values(JSON.parse(statusObject.meta_value))[0];
          currentObj.text = `The order status was changed from <strong>"${prevStatus}"</strong> to <strong>"${meta_value.deposit_paid}"</strong>.`;
        }

        if (meta_value.payment_needed) {
          const statusObject = findMetaValueByMetaKey("deposit_paid");
          const prevStatus = Object.values(JSON.parse(statusObject.meta_value))[0];
          currentObj.text = `The order status was changed from <strong>"${prevStatus}"</strong> to <strong>"${meta_value.payment_needed}"</strong>.`;
        }
        if (meta_value.payment_reminder) {
          const statusObject = findMetaValueByMetaKey("payment_needed");
          const prevStatus = Object.values(JSON.parse(statusObject.meta_value))[0];
          currentObj.text = `The order status was changed from <strong>"${prevStatus}"</strong> to <strong>"${meta_value.payment_reminder}"</strong>.`;
        }
        if (meta_value.payment_completed && findMetaValueByMetaKey("payment_reminder")) {
          const statusObject = findMetaValueByMetaKey("payment_reminder");
          const prevStatus = Object.values(JSON.parse(statusObject.meta_value))[0];
          currentObj.text = `The order status was changed from <strong>"${prevStatus}"</strong> to <strong>"${meta_value.payment_completed}"</strong>.`;
        }
        if (meta_value.payment_completed && !findMetaValueByMetaKey("payment_reminder")) {
          const statusObject = findMetaValueByMetaKey("payment_needed");
          const prevStatus = Object.values(JSON.parse(statusObject.meta_value))[0];
          currentObj.text = `The order status was changed from <strong>"${prevStatus}"</strong> to <strong>"${meta_value.payment_completed}"</strong>.`;
        }


        if (meta_key === '_deposit') {
          currentObj.text = meta_value.text;
        }
        if (meta_key === '_full') {
          currentObj.text = meta_value.text;
        }
        if (meta_key === '_add_manager') {
          currentObj.text = meta_value.text;
        }
      });


      return this.$store.getters.order_logs.data;
    },
  },
  data() {
    return {
      logs: this.order_logs
    };
  },


  emits: ['close-popup'],
  methods: {
    closePopup() {
      this.$emit("close-popup");
    },
    closePopupOutside(event) {
      const popupContainer = this.$refs.historyContainer;

      if (event.target === popupContainer) {
        this.$emit("close-popup");
      }
    },
    addBodyOverflowHidden() {
      document.body.classList.add("popup-open");
    },
    removeBodyOverflowHidden() {
      document.body.classList.remove("popup-open");
    },

  },
  watch: {

    showPopup(newVal) {
      if (newVal) {
        this.addBodyOverflowHidden();
      } else {
        this.removeBodyOverflowHidden();
      }
    },
  },
};
</script>
