<template>
     <div class="input-wrap">
  <div :class="['input-box', 'input-select', 'input-select-group', { 'not-valid-data-box': !!errorText }, {'disabled': !!disabled}]">
    <select @input="eventInput" :disabled="disabled" name="states" id="states">
      <option :value="null">{{ modelValue ? modelValue : '—' }}</option>
      <optgroup v-for="(states, label) in groupedStates" :label="label" :key="label">
        <option v-for="state in states" :value="state.id">{{ state.name }}</option>
      </optgroup>
    </select>
    <span v-if="!!errorText" class="err">{{ errorText }}</span>
    <button v-if="copy && modelValue" class="copy-link" @click="copyLink">
      <svg width="64px" height="64px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" stroke-width="3"
      stroke="#6B7280" fill="none">
      <rect x="11.13" y="17.72" width="33.92" height="36.85" rx="2.5" />
      <path
      d="M19.35,14.23V13.09a3.51,3.51,0,0,1,3.33-3.66H49.54a3.51,3.51,0,0,1,3.33,3.66V42.62a3.51,3.51,0,0,1-3.33,3.66H48.39" />
    </svg>
  </button>
  </div>
  <span v-if="!!showCopied"  class="show-copied">Copied!</span>
</div>
</template>

<script>
export default {
  name: "InputSelectGroup",
  data() {
    return {
      errorText: null,
      showCopied: false
    }
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: null
    },
    validSw: {
      type: [Boolean, String],
    },
    rules: {
      type: Array,
      default: () => []
    },
    groupedStates: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean
    },
    copy: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    copyLink() {
      const input = document.createElement("input");
      input.value = this.modelValue;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);

      this.showCopied = true;
      setTimeout(() => {
        this.showCopied = false;
      }, 3000);
    },
    eventInput(e) {
      let value = e.target.value;
      if (value === null || value === '---') {
        value = null;
      }
      this.validateData(value);
      this.$emit('update:modelValue', value);
    },
    validateData(v) {
      if (!!this.rules && this.rules.length) {
        const err_txt = this.rules
          .map(f => f(v))
          .find(itm => itm !== true);

        if (!!err_txt) {
          this.errorText = err_txt;
          this.$emit('error', true);
          return;
        } else {
          this.errorText = null;
          this.$emit('error', false);
          return;
        }
      }
      this.$emit('error', false);
    },

  },
  watch: {
    validSw() {
      this.validateData(this.modelValue);
    },

  }


}
</script>
