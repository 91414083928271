<template>
  <!-- {{ queryParameters }} -->
  <div v-if="user_data.role === 'admin'">
    <div v-if="!!admin_order_list && totalItems.in_progress !== 0" class="quotes table-wrapper">
      <slot-table :data="admin_order_list">
        <template #header>
          <tr>
            <th v-for="column in columns" :class="{ 'has-sort': column.sort }" :key="column.name" @click="sort(column)">
              {{ column.label }}
              <span v-if="column.name === queryParameters.orderBy" :class="`sorting-icon-${queryParameters.order == 'ASC' ? 'ASC' : 'DESC'
                }`">
              </span>
              <template v-else>
                <span v-if="column.sort" class="sorting-icon"> </span>
              </template>
            </th>
          </tr>
        </template>
        <template v-slot:body="{ item }">
          <tr :key="item.order_id">
            <td class="no-wrap">
              {{ formatDateToReadableText(item.created_at) }}
            </td>
            <td class="no-wrap">
              <div>
                <toggle-assign-button :item="item" />
              </div>
            </td>
            <td >{{ item.is_test == 1 ? "TEST" : '' }} {{  item.name_field }}  {{  item.lastname_field }} {{ item.order_name }}</td>
            <td> <div class="company-show-popup" @click="showCompanyPopup(item.company_id)">{{ item.company_name }}</div></td>

            <td>
              <div class="quote-route" :title="item.origin + ' - ' +  item.destination">
                <div @click="copyLink(item)" class="quote-route-btn">{{ item.origin }} - {{ item.destination }}</div>
              </div>
              <div>{{ prettifyDimensions(item.dimensions) }}</div>
            </td>

            <td>
              <status-select
                :options="statusOptions"
                :item_details="item"
                :value="item.order_status"
                @input="updateStatus(item, $event)"
              ></status-select>
            </td>

            <td>
              <div style="display: flex; gap: 10px;"><router-link :to="'/super-order/' + item.order_id" :data-id="item.order_id">
                Show
              </router-link>
              |
              <delete-button  title="Delete"  @click="deleteOrder(item)" /></div>
            </td>
          </tr>
        </template>
      </slot-table>
    </div>
    <p v-else>Not found</p>
    <button v-if="!!admin_order_list && admin_order_list.length < admin_list_length" class="button small button-loadmore"
      @click="loadMore">
      Load More
    </button>
  </div>
  <spinner :loading="loading" />
  <company-view ref="companyViewRef" :show-popup="showCompanyPopupWindow" :company_id="activeCompanyId" @close-popup="closeCompanyPopup"  />
  <confirmation-dialog v-if="showConfirmDialog" content="Are you sure you want to delete this order?"
        @cancel="cancelDeleteOrder" @confirm="confirmDeleteOrder" />  
</template>

<script>
import DeleteButton from "@/components/buttons/DeleteButton";
import { ref } from "vue";
import CompanyView from "@/components/company/CompanyView.vue";
import SlotTable from "@/components/SlotTable.vue";
import StatusSelect from "@/components/StatusSelect.vue";
import { nextTick } from "vue";
import { FormatterMixin } from "@/mixins/FormatterMixin";
import EditButton from "@/components/buttons/EditButton";
import ToggleAssignButton from "@/components/buttons/ToggleAssignButton";
import Spinner from "@/components/helpers/Spinner.vue";
import ConfirmationDialog from "@/components/helpers/ConfirmationDialog.vue";
export default {
  name: "SuperAdminOrderList",
  props: {
    filterData: {
      type: Object,
    },
  },
  components: {
    SlotTable,
    StatusSelect,
    EditButton,
    ToggleAssignButton,
    Spinner,
    CompanyView,
    DeleteButton,
    ConfirmationDialog
  },
  setup() {
    const companyViewRef = ref(null);
    return {
      companyViewRef,
    };
  },
  computed: {
    user_data() {
      return this.$store.getters.userData;
    },
    admin_order_list() {
      return this.$store.getters.admin_order_list;
    },
    admin_list_length() {
      if (this.queryParameters.archive === 0) {
        return this.totalItems.in_progress;
      } else {
        return this.totalItems.archive;
      }
    },
  },
  async created() {
    this.getAdminOrdersList();
  },
  mixins: [FormatterMixin],
  emits: ["data-total", "close-popup"],
  data() {
    return {
      activeCompanyId: '',
      showCompanyPopupWindow: false,
      showConfirmDialog: false,
      activeOrder: null,
      loading: false,
      totalItems: {
        in_progress: null,
        archive: null,
      },
      columns: [
        { name: "created_at", label: "Created", sort: true },
        { name: "assigned", label: "Assigned Agent", sort: false },
        { name: "order_name", label: "Order Name", sort: true },
        { name: "company_name", label: "Company", sort: false },
        { name: "route", label: "Origin / Destination", sort: false },
        { name: "status", label: "Status", sort: false },
        { name: "edit", label: "", sort: false },
      ],
      tabs: [
      { title: "Active", status: "inProgress" },
        { title: "Archived", status: "archive" },
      ],

      statusOptions: [
      { text: "Deposit needed", value: "deposit_needed" },
        { text: "Deposit paid - In Progress", value: "deposit_paid" },
        // { text: "Deposit reminder", value: "deposit_reminder" },
        { text: "Payment needed", value: "payment_needed" },
        { text: "Payment reminder", value: "payment_reminder" },
        { text: "Completed", value: "payment_completed" },
        { text: "Cancelled", value: "cancelled" },
      ],
      queryParameters: {
        offset: 0,
        limit: 12,
        orderBy: "created_at",
        order: "DESC",
        archive: 0,
        origin: null,
        destination: null,
        status: "",
        // user_id: this.$store.getters.userData.user_id,
      },
    };
  },

  methods: {
    copyLink(item) {
      const input = document.createElement("input");
      input.value = item.origin + ' - ' + item.destination;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
    },
    showCompanyPopup(company_id) {
      this.activeCompanyId = company_id;
      this.companyViewRef.getCompaniesList()
      this.showCompanyPopupWindow = true;
    },
    closeCompanyPopup() {
      this.activeCompanyId = null;
      this.showCompanyPopupWindow = false;
    },
    getAdminOrdersList() {
      const parameters = this.queryParameters;
      this.$store
        .dispatch("actionGetAdminOrdersList", { parameters })
        .then((response) => {
          this.totalItems.in_progress = response.total;
          this.totalItems.archive = response.total_archive;
          this.$emit("data-total", this.totalItems);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateStatus(order,status) {
      this.loading = true;
      const order_id = order.order_id;
      const parameters = {
        order_status: status,
        is_test: order.is_test,
      };
      this.$store
        .dispatch("actionUpdateOrderByOrderId", { order_id, parameters })
        .then((response) => {
          this.loading = false;
          this.getAdminOrdersList();
        })
        .catch((err) => {});
    },
    loadMore() {
      const newOffset = this.admin_order_list.length;
      this.queryParameters.offset = newOffset;
      const parameters = this.queryParameters;

      this.$store
        .dispatch("actionGetAdminOrdersList", {
          parameters,
          event: "pagination",
        })
        .then((response) => {
          this.totalItems.in_progress = response.total;
          this.totalItems.archive = response.total_archive;
          this.$emit("data-total", this.totalItems);
        })
        .catch((err) => { });
    },

    getStatusDisplay(statusValue) {
      const foundStatus = this.statusOptions.find(
        (option) => option.value === statusValue
      );
      return foundStatus ? foundStatus.text : "Unknown Status";
    },
    async sort(column) {
      if (!column.sort) return;

      if (this.queryParameters.orderBy === column.name) {
        this.queryParameters.order =
          this.queryParameters.order === "ASC" ? "DESC" : "ASC";
      } else {
        this.queryParameters.orderBy = column.name;
        this.queryParameters.order = "ASC";
      }
      await nextTick();
      const parameters = { ...this.queryParameters };
      parameters.limit += parameters.offset;
      parameters.offset = 0;

      this.$store
        .dispatch("actionGetAdminOrdersList", { parameters })
        .then((response) => {
          this.totalItems.in_progress = response.total;
          this.totalItems.archive = response.total_archive;
          this.$emit("data-total", this.totalItems);
        })
        .catch((err) => { });
    },


    deleteOrder(order) {
      this.activeOrder = order;
      this.showConfirmDialog = true;
    },
    cancelDeleteOrder() {
      this.activeOrder = null;
      this.showConfirmDialog = false;
    },
    confirmDeleteOrder() {
      const order_id = this.activeOrder.order_id;
      this.$store
          .dispatch("actionDeleteOrder", { order_id })
          .then((response) => {
            console.log(response);
            this.showConfirmDialog = false;
            this.getAdminOrdersList();
          })
          .catch((err) => { });
    }

  },

  watch: {
    filterData: {
      deep: true, // This watches for changes within nested properties of the object
      handler(newData) {
        this.queryParameters.archive = newData.archiveValue;
        this.queryParameters.origin = newData.originValue;
        this.queryParameters.offset = newData.offsetValue;
        this.queryParameters.destination = newData.destinationValue;
        this.queryParameters.status = newData.statusValue;
        this.queryParameters.manager_id = newData.managerValue;
      },
    },
  },
};
</script>
