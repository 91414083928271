<template>
  <div class="edit-equipment-popup tractor-popup" ref="popupContainer" @click="closePopupOutside">
    <div id="equipment-form" class="equipment-form-edit">
      <div class="ef__wrapper">
        <button @click="closePopup" class="close-eqipment">&#x2715</button>
        <div class="ef__window">
          <div class="ef-container">
            <div class="ef-form-title">Add Trailer</div>
            <div class="ef-form">
              <div class="ef-form-box">
                <div class="ef-form-box ef-form-type3">
                  <div class="part-form ef-form__simple-window form-elements">
                    <div class="form-line part-form__add-fields">
                      <div class="input-box acceptable-box">
                        <span class="like-link" @click="additional = !additional">{{
                          additional
                            ? "Hide additional fields"
                            : "Show additional fields"
                        }}</span>
                      </div>
                    </div>
                    <div class="form-line form-grid r-2">
                      <div class="additional-info">Unit number/Name
                        <div class="additional-info-message">
                          The nickname/company specific number/internal tracking number
                        </div>
                      </div>
                      <input-text v-model="formData.unit_number" placeholder="W335" :valid-sw="validSw"
                        :rules="rules.requiredText" maxlength="32" @error="regError('unit_number', $event)" />
                    </div>
                    <div class="form-line form-grid r-2">
                      <div>Type</div>
                      <input-select v-model="formData.trailer_type" :options="type_items" :valid-sw="validSw"
                        :rules="rules.requiredOnly" @error="regError('trailer_type', $event)" />
                    </div>
                    <div class="form-line form-grid r-2">
                      <div class="additional-info make">Make*
                        <div class="additional-info-message">
                          Kenworth,
                          Peterbilt, etc.
                        </div>
                      </div>
                      <input-text v-model="formData.make" placeholder="Peterbilt" :valid-sw="validSw"
                        :rules="rules.requiredText" maxlength="32" @error="regError('make', $event)" />
                    </div>
                    <div class="form-line form-grid r-2">
                      <div>Year Model*</div>
                      <input-text v-model="formData.year_model" placeholder="2014" :valid-sw="validSw"
                        :rules="rules.year" maxlength="4" @error="regError('year_model', $event)" />
                    </div>
                    <div class="form-line form-grid r-2 vin-code">
                      <div class="vin-code">VIN Number*</div>
                      <input-text v-model="formData.vin" placeholder="VHR151EKNFE2223" :valid-sw="validSw"
                        :rules="rules.vin_code" maxlength="17" @error="regError('vin', $event)" />
                    </div>
                    <div class="form-line form-grid r-2">
                      <div >License Plate Number*</div>
                      <input-text class="license-plate" v-model="formData.license" placeholder="P56231" :valid-sw="validSw"
                        :rules="rules.license_plate" maxlength="20" @error="regError('license', $event)" />
                    </div>
                    <div class="form-line form-grid r-2">
                      <div>State Registered*</div>
                      <input-select-group v-model="formData.registration_place"
                        :grouped-states="{ 'US States': usStates, 'Mexican States': mxStates, 'Canadian Provinces': caProvinces }"
                        :valid-sw="validSw" :rules="rules.requiredOnly"
                        @error="regError('registration_place', $event)" />
                    </div>
                    <div class="form-line form-grid r-2">
                      <div># Axles*</div>
                      <input-select v-model="formData.axle_quantity" :options="axles_options" :valid-sw="validSw"
                        :rules="rules.requiredOnly" @error="regError('axle_quantity', $event)" />
                    </div>
                    <div class="form-line form-grid r-2">
                      <div>Length*</div>
                      <input-size v-model="formData.length" placeholder="25’" :valid-sw="validSw"
                      :rules="rules.requiredOnly" @error="regError('length', $event)" maxlength="8" />
                    </div>
                    <div v-show="additional" class="form-line form-grid r-2">
                      <div>Axle Width</div>
                      <input-size v-model="formData.axle_width" placeholder="8’6”" maxlength="8" />
                    </div>
                    <div v-show="additional" class="form-line form-grid r-2">
                      <div>Deck Height</div>
                      <input-size v-model="formData.deck_height" placeholder="5’" maxlength="8" />
                    </div>

                    <div v-show="additional" class="form-line form-grid r-2">
                      <div>Empty weight</div>
                      <input-weight v-model="formData.empty_weight" placeholder="45,000 lbs" maxlength="8" />
                    </div>
                  </div>
                </div>
                <button @click="eventFormSubmit" class="button small update-eq-button">
                  Add Trailer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputSize from "@/components/form-elements/InputSize.vue";
import InputText from "@/components/form-elements/InputText.vue";
import InputSelect from "@/components/form-elements/InputSelect.vue";
import InputSelectGroup from "@/components/form-elements/InputSelectGroup.vue";
import InputWeight from "@/components/form-elements/InputWeight.vue";
import AxlesTable from "@/components/equipment/parts/AxlesTable.vue";
import ValidateMixin from "@/mixins/ValidateMixin";
import { FeetMathMixin } from "@/mixins/FeetMathMixin";
export default {
  name: "AddTrailerForm",
  components: {
    InputWeight,
    InputSelect,
    InputSelectGroup,
    InputText,
    InputSize,
    AxlesTable,
  },

  emits: ["update-table", "close-popup"],
  mixins: [FeetMathMixin, ValidateMixin],
  mounted() {
    this.resetForm();
  },
  data() {
    return {
      formData: {
        part_id: null,
        type: "trailer",
        unit_number: null,
        length: null,
        trailer_type: null,
        deck_height: null,
        make: null,
        year_model: null,
        vin: null,
        license: null,
        registration_place: null,
        empty_weight: null,
        axle_width: null,
        axle_quantity: 3,
      },
      errors: {
        unit_number: null,
        make: null,
        year_model: null,
        vin: null,
        license: null,
        registration_place: null,
        axle_quantity: null,
        length: null,
        trailer_type: null,
      },
      validSw: false,
      additional: false,
      type_items: [
        "---",
        "Stepdeck",
        "Flatbed",
        "Rgn",
        "Double drop",
        "Auto/Boat Transporter",
        "Full Trailer",
        "Jeep",
        "Booster",
        "Dolly",
        "Other",
      ],
    };
  },

  computed: {
    axles_options() {
      const min = 1;
      const max = 30;
      let out = ["---"];
      for (let i = min; i <= max; i++) {
        out.push(i);
      }
      return out;
    },
    state_list() {
      let out = ["---"];
      const state_list = this.$store.getters.state_list;
      return out.concat(state_list);
    },
    usStates() {
      return this.state_list.filter(state => state.country_id === "US");
    },
    mxStates() {
      return this.state_list.filter(state => state.country_id === "MX");
    },
    caProvinces() {
      return this.state_list.filter(state => state.country_id === "CA");
    }
  },

  methods: {
    is_form_valid_new() {
      let valid = false;
      const allErrorsValid = Object.values(this.errors).some(error => error === true || error === null);
      this.$store.dispatch("actionGenerateId", 32).then((id) => {
        this.validSw = id;
      });
      valid = allErrorsValid;
      return valid;
    },
    closePopup() {
      this.$emit("close-popup");
    },
    closePopupOutside(event) {
      const popupContainer = this.$refs.popupContainer;
      if (event.target === popupContainer) {
        this.closePopup();
      }
    },
    resetForm() {
      const form = this.formData;
      form.unit_number = null;
      form.part_id = null;
      form.length = null;
      form.make = null;
      form.year_model = null;
      form.license = null;
      form.registration_place = null;
      form.empty_weight = null;
      form.axle_width = null;
      form.axle_quantity = 3;
      form.trailer_type = null;
      form.deck_height = null;
      form.vin = null;
    },
    resetErrors() {
      const errors = this.errors;
      errors.unit_number = null;
      errors.trailer_type = null;
      errors.make = null;
      errors.year_model = null;
      errors.vin = null;
      errors.license = null;
      errors.axle_quantity = 3;
      errors.length = null;
      errors.registration_place = null;
    },
    eventFormSubmit() {

      if (this.is_form_valid_new()) return;
      this.$store.dispatch("actionGenerateId", 32).then((equipment_part_id) => {
        const formData = { ...this.formData };
        formData.part_id = equipment_part_id.toLowerCase();
        this.$store
          .dispatch("actionAddNewPartEquipment", { formData })
          .then(() => {
            this.resetForm();
            this.resetErrors();
            this.$emit("update-table");
            this.$store.dispatch("alert", {
              message: "Trailer added",
              type: "info",
            });
          });
      });
    },
  },
};
</script>
