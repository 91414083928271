<template>
  <div class="form-grid equipment-selector-box">
    <div class="select-form-container">
      <input-select-with-value :disabled="disabled" v-model="inputValue" :options="items" />
    </div>
    <button @click="showEquipmentForm" :disabled="disabled" class="clc-button clc-button-add">
      <span class="clc-button-sign">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M10.0003 5.29199C10.3915 5.29199 10.7087 5.60912 10.7087 6.00033V10.292H15.0003C15.3915 10.292 15.7087 10.6091 15.7087 11.0003C15.7087 11.3915 15.3915 11.7087 15.0003 11.7087H10.7087V16.0003C10.7087 16.3915 10.3915 16.7087 10.0003 16.7087C9.60912 16.7087 9.29199 16.3915 9.29199 16.0003V11.7087H5.00033C4.60912 11.7087 4.29199 11.3915 4.29199 11.0003C4.29199 10.6091 4.60912 10.292 5.00033 10.292H9.29199V6.00033C9.29199 5.60912 9.60912 5.29199 10.0003 5.29199Z" />
        </svg>
      </span>
      <span class="clc-button-title">New</span>
    </button>
  </div>
</template>

<script>
import InputSelectWithValue from "@/components/form-elements/InputSelectWithValue.vue";
import { FeetMathMixin } from "@/mixins/FeetMathMixin";
export default {
  name: "EquipmentSelector",
  components: { InputSelectWithValue },
  data() {
    return {
      inputValue: this.modelValue,
    };
  },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "update"],
  mixins: [FeetMathMixin],
  computed: {
    raw_items() {
      const eq = this.$store.getters.equipmentList;
      return !!eq ? eq : [];
    },
    current_equipment() {
      const current = !!this.$store.getters.equipment_by_id
        ? this.$store.getters.equipment_by_id
        : {
          "name": "Undefined",
          "eq_id": "null",
        };
      return current
    },
    all_eqs() {
      const rawItems = !!this.$store.getters.equipmentList
        ? this.$store.getters.equipmentList
        : [];

      return rawItems;
      // return rawItems.concat(equipmentItems);
    },
    user_role() {
      return this.$store.getters.userRole;
    },
    items() {
      const out = [{ value: "none", title: "---" }];
      if (this.user_role == 'manager' || this.user_role == 'admin') {
        let arr = this.raw_items
        arr.push(this.current_equipment)
      }

      const raw_items = this.raw_items.map((el) => ({
        value: el.eq_id,
        title: "*" + this.generateTitle(el),
      }));


      return out.concat(raw_items);
    },
  },

  methods: {
    generateTitle(item) {
      const title_arr = [
        item.name,
        "(",
        "total: " + this.dec_to_feet(item.total_length) + ",",
        "trailer: " + this.dec_to_feet(item.trailer_length) + ",",
        item.axle_quantity + " axles,",
        item.deck_height + " tall,",
        item.empty_weight + " lbs weight",
        ")",
      ];

      return title_arr.join(" ");
    },
    showEquipmentForm() {
      if (!this.disabled) {
        this.$store.dispatch("actionToggleShowEquipmentForm");
      }
    },
    getCurrentEquipment() {
      const equipment_id = this.inputValue;
      this.$store
        .dispatch("actionGetEquipmentByEqId", { equipment_id })
        .then((response) => {
        })
        .catch((err) => { });
    },
  },

  watch: {
    modelValue(d) {
      this.inputValue = d;
      if(d) {
        this.getCurrentEquipment();
      }
    },
    inputValue(d) {
      if (!this.disabled) {
        const selected_eq = this.all_eqs.find((el) => el.eq_id === d);
        const val = d === "none" ? null : d;
        this.$emit("update:modelValue", val);
        this.$emit("update", val, selected_eq);
      }
    },
  },
};
</script>
