<template>

  <div v-if="user_data.role === 'owner' || user_data.role === 'driver'" id="quote-page">
    <div class="quote-filter__section">
      <div class="quote-filter__inputs">
        <div class="input-select">
          <select v-model="filterData.ownOrdersValue" id="status" @change="onFilter">
            <option value="">All Orders</option>
            <option :value="user_data.user_id">
              My Orders
            </option>
          </select>
        </div>
        <input v-model="filterData.originValue" type="text" placeholder="Origin" @input="onFilter" />
        <input v-model="filterData.destinationValue" type="text" placeholder="Destination" @input="onFilter" />
        <div class="input-select">
          <select v-model="filterData.statusValue" id="status" @change="onFilter">
            <option value="">Select status</option>
            <option v-for="option in statusOptions" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
        </div>
     
      </div>
      <button class="button small button-icon reset-icon" @click="resetFilter">
        Reset
      </button>
      
    </div>
    <div class="table-wrap">
      <div>
        <div class="tab-buttons">
          <button v-for="(tab, index) in tabs" :key="index" @click="onCLickTab(tab, index)"
            :class="{ active: activeTab === index }">
            {{ tab.title }} {{ tab.total }}
          </button>
        </div>
        <div class="tab-content">
          <div v-for="(tab, index) in tabs" :key="index" :class="{ active: activeTab === index }">
            <template v-if="tab.status === 'inProgress'">
              <owner-order-list :filter-data="filterData" @data-total="setTabsCount($event, index)"
                ref="owner_order_list" />
            </template>
            <template v-else-if="tab.status === 'archive'">
              <owner-order-archive-list :filter-data="filterData" @data-total="setTabsCount($event, index)"
                ref="owner_order_archive_list" />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
 
</template>

<script>
import SlotTable from "@/components/SlotTable.vue";

import OwnerOrderList from "@/components/orders/owner/OwnerOrderList.vue";
import OwnerOrderArchiveList from "@/components/orders/owner/OwnerOrderArchiveList.vue";
import { ref } from "vue";
export default {
  name: "OrderPage",
  components: {
    SlotTable,
    OwnerOrderList,
    OwnerOrderArchiveList,
   
  },
  setup() {
    const owner_order_list = ref(null);
    const owner_order_archive_list = ref(null);

    return {
      owner_order_list,
      owner_order_archive_list,
    };
  },
  computed: {
    user_data() {
      return this.$store.getters.userData;
    },
  },
  data() {
    return {
     
      activeTab: 0,
      filterData: {
        originValue: null,
        destinationValue: null,
        archiveValue: 0,
        statusValue: "",
        offsetValue: 0,
        ownOrdersValue: "",
        user_id: this.$route.params.user_id,
      },
      tabs: [
        { title: "Active", status: "inProgress", total: null },
        { title: "Archived", status: "archive", total: null },
      ],
      statusOptions: [
        { text: "Deposit needed", value: "deposit_needed" },
        { text: "Deposit paid - In Progress", value: "deposit_paid" },
        { text: "Deposit reminder", value: "deposit_reminder" },
        { text: "Payment Needed", value: "payment_needed" },
        { text: "Payment reminder", value: "payment_reminder" },
        { text: "Completed", value: "payment_completed" },
       
        { text: "Cancelled", value: "cancelled" },
      ],
    };
  },

  methods: {
   
    onCLickTab(tab, index) {
      this.activeTab = index;
      if (this.activeTab === 0) {
        this.filterData.archiveValue = 0;
        this.owner_order_list[0].getOrdersList();
      } else {
        this.filterData.archiveValue = 1;
        this.owner_order_archive_list[0].getOrdersList();
      }
    },
    setTabsCount(count, index) {
      this.tabs[0].total = `(${count.in_progress ? count.in_progress : 0})`;
      this.tabs[1].total = `(${count.archive ? count.archive : 0})`;
    },
    onFilter() {
      if (this.activeTab === 0) {
        this.filterData.archiveValue = 0;
        this.owner_order_list[0].getOrdersList();
      } else {
        this.filterData.archiveValue = 1;
        this.owner_order_archive_list[0].getOrdersList();
      }
    },

    resetFilter() {
      this.filterData.originValue = null;
      this.filterData.destinationValue = null;
      this.filterData.statusValue = "";
      this.filterData.ownOrdersValue = "";
      this.filterData.archiveValue = this.activeTab;
      this.filterData.offsetValue = 0;

      if (this.activeTab === 0) {
        this.filterData.archiveValue = 0;
        this.owner_order_list[0].getOrdersList();
      } else {
        this.filterData.archiveValue = 1;
        this.owner_order_archive_list[0].getOrdersList();
      }
    },
  },
};
</script>
