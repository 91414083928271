<template>
    <div v-if="user_role === 'admin'" id="users-page">
        <div class="table-search">
            <div class="table-search__title"> {{ total }} Users</div>
            <div class="table-search__actions">
                <div class="table-search__input__wrapper">
                    <input v-model="queryParameters.searchValue" type="text" placeholder="Search"
                        @input="searchEventInput" />
                    <button @click="getUsersList" class="button-search"></button>
                </div>
                <button class="button small button-icon reset-icon" @click="resetRequest">
                    Reset
                </button>
            </div>
        </div>
        <div v-if="total !== 0" class="table-clients-wrapper">
            <slot-table :data="users_list">
                <template #header>
                    <tr>
                        <th v-for="column in columns" :class="{ 'has-sort': column.sort }" :key="column.name"
                            @click="sort(column)">
                            {{ column.label }}
                            <span v-if="column.name === queryParameters.orderBy" :class="`sorting-icon-${queryParameters.order == 'ASC' ? 'ASC' : 'DESC'
                                }`">
                            </span>
                            <template v-else>
                                <span v-if="column.sort" class="sorting-icon"> </span>
                            </template>
                        </th>
                    </tr>
                </template>
                <template v-slot:body="{ item }">
                    <tr :key="item.user_id">

                        <td>
                            {{ item.name }}
                        </td>
                        <td class="quote-route">
                            {{ item.email }}
                        </td>
                        <td >
                            <div class="company-show-popup" @click="showCompanyPopup(item.company_id)">{{
                                item.company_name }}</div>
                        </td>
                        <td>
                            {{ item.phone ? item.phone : '-' }}
                        </td>
                        <td>
                            <input-select v-if="item.user_id !== user_data.user_id" v-model="item.role"
                                :options="userRoles" @change="changeUserRole(item.user_id, $event)" />
                            <span v-else>You, Admin</span>
                        </td>
                        <td>
                            {{ item.created_at }}
                        </td>
                        <td>
                            <div style="display: flex; gap: 10px;">
                                <edit-button @click="editUser(item)" />
                                <span v-if="item.role !== 'admin'">|</span>
                                <delete-button v-if="item.role !== 'admin'" @click="deleteUser(item)" />
                            </div>
                        </td>
                    </tr>
                </template>
            </slot-table>
        </div>
        <p v-else>Not found</p>
        <button v-if="!!users_list && users_list.length < total" @click="loadMore"
            class="button small table-clients-loadmore">
            Load more
        </button>
    </div>
    <company-view ref="companyViewRef" :show-popup="showCompanyPopupWindow" :company_id="activeCompanyId"
        @close-popup="closeCompanyPopup" />
    <user-edit ref="userEditRef" :show-popup="showUserPopupWindow" :user_id="activeUser"
        @close-popup="closeUserPopup" />
    <confirmation-dialog v-if="showConfirmDialog"
        content="Are you sure you want to delete this user? In this case that delete all user orders."
        @cancel="cancelDeleteUser" @confirm="confirmDeleteUser" />
</template>

<script>
import EditButton from "@/components/buttons/EditButton";
import DeleteButton from "@/components/buttons/DeleteButton";
import { ref } from "vue";
import CompanyView from "@/components/company/CompanyView.vue";
import UserEdit from "@/components/users/UserEdit.vue";
import { nextTick } from "vue";
import SlotTable from "@/components/SlotTable.vue";
import InputSelect from "@/components/form-elements/InputSelect.vue";
import ConfirmationDialog from "@/components/helpers/ConfirmationDialog.vue";
export default {
    name: "UsersPage",
    components: {
        SlotTable,
        InputSelect,
        CompanyView,
        EditButton,
        UserEdit,
        DeleteButton,
        ConfirmationDialog
    },
    setup() {
        const companyViewRef = ref(null);
        const userEditRef = ref(null);
        return {
            companyViewRef,
            userEditRef,
        };
    },
    created() {
        this.getUsersList()
    },
    computed: {
        user_data() {
            return this.$store.getters.userData;
        },
        user_role() {
            return this.$store.getters.userRole;
        },
        users_list() {
            return this.$store.getters.usersList;
        },
    },

    data() {
        return {
            showConfirmDialog: false,
            activeCompanyId: null,
            activeUser: null,
            showCompanyPopupWindow: false,
            showUserPopupWindow: false,
            total: null,
            userRoles: ['owner', 'manager', 'admin', 'driver'],
            columns: [
                { name: "name", label: "Name", sort: true },
                { name: "email", label: "Email", sort: false },
                { name: "company", label: "Company", sort: false },
                { name: "phone", label: "Phone", sort: false },
                { name: "role", label: "Role", sort: false },
                { name: "created_at", label: "Created", sort: true },
                { name: "actions", label: "Actions", sort: false },

            ],
            queryParameters: {
                searchValue: null,
                offset: 0,
                limit: 12,
                orderBy: "name",
                order: "DESC",
            },

        }
    },
    methods: {
        deleteUser(user) {
            this.activeUser = user.user_id;
            this.showConfirmDialog = true;
        },
        cancelDeleteUser() {
            this.activeUser = null;
            this.showConfirmDialog = false;
        },
        confirmDeleteUser() {
            this.showConfirmDialog = false;

            const user_id = this.activeUser;
            const parameters = { user_id };
            this.$store
                .dispatch("actionDeleteUser", { parameters })
                .then(user => {
                    this.getUsersList();
                });
        },
        editUser(user) {
            this.activeUser = user.user_id;
            this.userEditRef.getUsersList();
            this.showUserPopupWindow = true;
        },
        closeUserPopup() {
            this.activeUser = null;
            this.showUserPopupWindow = false;
        },
        showCompanyPopup(company_id) {
            this.activeCompanyId = company_id;
            this.companyViewRef.getCompaniesList();
            this.showCompanyPopupWindow = true;
        },
        closeCompanyPopup() {
            this.activeCompanyId = null;
            this.showCompanyPopupWindow = false;
        },
        getUsersList() {

            const parameters = this.queryParameters;
            this.$store
                .dispatch("actionGetUsersList", { parameters })
                .then((response) => {
                    this.total = response.total;
                })
                .catch((err) => { });
        },
        changeUserRole(user_id, event) {

            const role = event.target.value;

            const parameters = { user_id, role };
            this.$store
                .dispatch("actionSuperAdminChangeUserRole", { parameters })
                .then((response) => {

                });

        },
        async searchEventInput(e) {
            this.queryParameters.offset = 0;
            this.queryParameters.limit = 12;
            this.queryParameters.orderBy = "name";
            this.queryParameters.order = "ASC";
            await nextTick();
            this.getUsersList();
        },
        async resetRequest(e) {
            this.queryParameters.searchValue = null;
            this.queryParameters.offset = 0;
            this.queryParameters.limit = 12;
            this.queryParameters.orderBy = "name";
            this.queryParameters.order = "ASC";
            await nextTick();
            this.getUsersList();
        },
        async sort(column) {
            if (!column.sort) return;

            if (this.queryParameters.orderBy === column.name) {
                this.queryParameters.order =
                    this.queryParameters.order === "ASC" ? "DESC" : "ASC";
            } else {
                this.queryParameters.orderBy = column.name;
                this.queryParameters.order = "ASC";
            }

            await nextTick();
            const parameters = { ...this.queryParameters };
            parameters.limit += parameters.offset;
            parameters.offset = 0;

            this.$store
                .dispatch("actionGetUsersList", { parameters })
                .then((response) => {
                    this.total = response.total;
                })
                .catch((err) => { });
        },
        loadMore() {
            const newOffset = this.users_list.length;
            this.queryParameters.offset = newOffset;
            const parameters = this.queryParameters;

            this.$store
                .dispatch("actionGetUsersList", { parameters, event: "pagination" })
                .then((response) => {
                    this.total = response.total;
                })
                .catch((err) => { });
        },


    }

}
</script>