<template>

    <transition name="slide-in-from-top">
        <div v-if="showPopup" class="company-popup" @click="closePopupOutside" ref="popupContainer">
            <div class="popup-content">
                <button class="contact-withus-popup-close" @click="closePopup">
                    <svg width="20" height="20" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0"
                        viewBox="0 0 100 100" xml:space="preserve">
                        <path fill="none" stroke="#e15b64" stroke-width="16" stroke-miterlimit="10" d="M22 78l56-56" />
                        <path fill="none" stroke="#e15b64" stroke-width="16" stroke-miterlimit="10" d="M78 78L22 22" />
                    </svg>
                </button>
                <div class="settings-accounting-text">
                    <h3>Company Info, {{ formData.company_name }}</h3>

                </div>
                <div class="form-element-wrap">
                    <div class="form-element phone">
                        <label>Company Name*</label>
                        <input-text v-model="formData.company_name" placeholder="Enter name" :valid-sw="validSw"
                            :rules="rules.companyName" @error="(err) => {
                                regError('company_name', err);
                            }
                                " />
                    </div>
                    <div class="form-element phone">
                        <label>Company Phone*</label>
                        <input-phone v-model="formData.company_phone" :valid-sw="validSw" :rules="rules.phone"
                            placeholder="Enter phone" @error="(err) => { regError('company_phone', err); }" />
                    </div>
                    <div class="form-element ">
                        <label>Doing business as</label>
                        <input-text v-model="formData.doing_business_as" placeholder="Enter doing business as" />
                    </div>

                    <div class="form-element ">
                        <label>Address*</label>
                        <input-text v-model="formData.address" :valid-sw="validSw" :rules="rules.address"
                            placeholder="Enter address" @error="(err) => { regError('address', err); }" />
                    </div>
                    <div class="form-element phone">
                        <label>City*</label>
                        <input-text v-model="formData.city" :valid-sw="validSw" placeholder="Enter city"
                            :rules="rules.requiredText" @error="(err) => {
                                regError('city', err);
                            }
                                " />
                    </div>
                    <div class="form-element phone">
                        <label>State*</label>
                        <input-text v-model="formData.state" :rules="rules.requiredText" :valid-sw="validSw"
                            placeholder="Enter state" @error="(err) => {
                                regError('state', err);
                            }
                                " />
                    </div>
                    <div class="form-element phone">
                        <label>Zip*</label>
                        <input-text v-model="formData.zip_code" :rules="rules.zipCode" :valid-sw="validSw"
                            placeholder="Enter zip code" maxlength="5" @error="(err) => {
                                regError('zip_code', err);
                            }
                                " />
                    </div>
                    <div class="form-element phone">
                        <label>FID*</label>
                        <input-text :rules="rules.numberTT" v-model="formData.fid" :valid-sw="validSw" maxlength="15"
                            placeholder="Enter FID" @error="(err) => {
                                regError('fid', err);
                            }
                                " />
                    </div>
                    <div class="form-element phone">
                        <label>MC#</label>
                        <input-text v-model="formData.mc" maxlength="15" placeholder="Enter MC#" />
                    </div>
                    <div class="form-element phone">
                        <label>USDOT</label>
                        <input-text v-model="formData.usdot" maxlength="15" placeholder="Enter USDOT" />
                    </div>
                    <div class="form-element phone">
                        <label>IFTA</label>
                        <input-text v-model="formData.ifta" maxlength="15" placeholder="Enter IFTA" />
                    </div>
                    <div class="form-element phone">
                        <label>IRP</label>
                        <input-text v-model="formData.irp" maxlength="15" placeholder="Enter IRP" />
                    </div>

                </div>
                <div class="company-popup-submit">
                    <button @click="updateInfo" class="button small">Update</button>
                </div>
            </div>

        </div>
    </transition>


</template>

<script>
import ValidateMixin from "@/mixins/ValidateMixin";
import InputPhone from "@/components/form-elements/InputPhone.vue";
import InputPassword from "@/components/form-elements/InputPassword.vue";
import InputText from "@/components/form-elements/InputText.vue";
import InputNumber from "@/components/form-elements/InputNumber.vue";
export default {
    components: {
        InputPhone,
        InputPassword,
        InputNumber,
        InputText,
    },
    name: "CompanyEdit",
    computed: {
        user_data() {
            return this.$store.getters.userData;
        },
    },
    mixins: [ValidateMixin],
    props: {
        showPopup: {
            type: Boolean,
            default: false,
        },
        company_id: {
            type: String,
        },
    },

    data() {
        return {
            validSw: false,
            formData: {
                company_name: null,
                company_phone: null,
                doing_business_as: null,
                address: null,
                city: null,
                state: null,
                zip_code: null,
                usdot: null,
                mc: null,
                fid: null,
                ifta: null,
                irp: null,
                days_to_archive: 10,
            },
            errors: {
                company_name: false,
                company_phone: false,
                doing_business_as: false,
                address: false,
                city: false,
                state: false,
                zip_code: false,
                usdot: false,
                mc: false,
                fid: false,
                ifta: false,
                irp: false,
            },

        }
    },
    methods: {
        getCompaniesList() {

            const parameters = {
                searchValue: null,
                offset: 0,
                limit: 9999,
                orderBy: "company_name",
                order: "DESC",
            };
            this.$store
                .dispatch("actionGetCompaniesList", { parameters })
                .then((response) => {
                    const foundCompany = response.companies_list.find(company => company.company_id === this.company_id);
                    this.formData.company_name = foundCompany.company_name;
                    this.formData.company_phone = foundCompany.company_phone;
                    this.formData.doing_business_as = foundCompany.doing_business_as;
                    this.formData.address = foundCompany.address;
                    this.formData.city = foundCompany.city;
                    this.formData.state = foundCompany.state;
                    this.formData.zip_code = foundCompany.zip_code;
                    this.formData.usdot = foundCompany.usdot;
                    this.formData.mc = foundCompany.mc;
                    this.formData.ifta = foundCompany.ifta;
                    this.formData.irp = foundCompany.irp;
                    this.formData.fid = foundCompany.fid;
                })
                .catch((err) => { });
        },
        closePopup() {
            this.removeBodyOverflowHidden()
            this.$emit("close-popup");
        },
        addBodyOverflowHidden() {
            document.body.classList.add("popup-open");
        },
        removeBodyOverflowHidden() {
            document.body.classList.remove("popup-open");
        },
        closePopupOutside(event) {
            const popupContainer = this.$refs.popupContainer;

            if (event.target === popupContainer) {
                this.$emit("close-popup");
            }
        },
        updateInfo() {
            if (!this.is_form_valid_new()) return;
            const data = { ...this.formData };
            const company_id = this.company_id;

            const parameters = { company_id, data };
            this.$store
                .dispatch("actionUpdateCompany", { parameters })
                .then(company => {
                    this.getCompaniesList();
                    this.closePopup();

                });

        },
        is_form_valid_new() {
            this.$store.dispatch("actionGenerateId", 32).then((id) => {
                this.validSw = id;
            });
            const allFormDataNotEmpty = Object.entries(this.formData).every(([key, value]) => {
                const ignoredFields = ['doing_business_as', 'mc', 'usdot', 'ifta', 'irp'];
                return ignoredFields.includes(key) || (value !== null && value !== '');
            });
            const allErrorsFalse = Object.values(this.errors).every(error => error === false);
            let isValid = allFormDataNotEmpty && allErrorsFalse;

            if (!this.user_data.password_exist) {
                isValid = allFormDataNotEmpty && allErrorsFalse && !this.formError;
            }

            // console.log('All form data fields are not empty:', allFormDataNotEmpty);
            // console.log('All errors are false:', allErrorsFalse);
            // console.log('Form is valid:', isValid);
            return isValid;
        },
    }
};
</script>