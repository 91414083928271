<template>
  <hr />
  <div v-if="permitsData.length" class="permits-data">
    <h3 class="permit-title">Permit Managment</h3>
    <table v-if="permitsData" class="permits-quote-table permit-table">
      <thead>
        <tr>
          <th>State</th>
          <th>Status</th>
          <th>Permits</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in permitsData" :key="row.id">
          <td>
            <div class="permits-states">
              {{ row.state.value }}
            </div>
          </td>
          <td>
            <status-select v-if="row.state.value" :options="statusOptions" :item_details="order" :value="row.status.value"
              @input="updateStatus(row, $event)"></status-select>
          </td>
          <td>
            <div v-if="row.state.value" class="permits-info">
              <div v-if="!row.state_permit_link == ''" class="permits-info__image">
                <img :src="permit_img" :alt="row.state_permit.name" :title="row.state_permit.name" />
              </div>
              <div class="permits-info__name">
                <span>{{ row.state_permit.name }}</span>
              </div>
              <div class="permits-info__links">
                <button class="quotes-button-remove" :disabled="!row.state_permit_link"
                  @click="showConfirmationDialog(row)"></button>
              </div>
            </div>
          </td>
          <td v-if="row.state.value">
            <input type="file" :ref="'fileInput_' + index" @change="handleFileUpload(index, $event)" accept=".pdf"
              style="display: none" />
            <button title="Max upload 5MB" style="width: 200px; max-width: 175px;" :disabled="row.state_permit_link !== ''"
              class="button small download" @click="openFileInput(index)">
              {{ row.state_permit_link !== '' ? 'Permit attached ' : 'Upload Permit (Pdf)' }}
            </button>

          </td>
        </tr>
      </tbody>
    </table>

  </div>
  <ConfirmationDialog v-if="showConfirmDialog" content="Are you sure you want to remove the file?"
    @cancel="cancelRemovePermit" @confirm="confirmRemovePermit" />
</template>

<script>
import mail_icon from "@/assets/images/svgs/mail.svg";
import permit_img from "@/assets/images/permit.jpg";
import StatusSelect from "@/components/StatusSelect.vue";
import ConfirmationDialog from "@/components/helpers/ConfirmationDialog.vue";
export default {
  name: "AdminEditPermit",
  props: ["tableData", "order_id"],
  components: {
    StatusSelect,
    ConfirmationDialog,
  },
  data() {
    return {
      mail_icon,
      permit_img,
      permitsData: this.tableData,
      showConfirmDialog: false,
      rowToRemove: null,
      statusOptions: [
        { text: "In progress", value: "in_progress" },
        { text: "Awaiting state response", value: "awaiting" },
        { text: "Completed", value: "complete" },
        { text: "Cancelled", value: "cancelled" },
        { text: "Not requested", value: "not_requested" },
        { text: "Has annual", value: "has_annual" },
      ],
    };
  },
  computed: {
    order() {
      return this.$store.getters.order_by_id;
    },
    api_url() {
      return this.$store.getters.apiUrl;
    },
    user_data() {
      return this.$store.getters.userData;
    },
    order_meta_data() {
      const data = JSON.parse(this.$store.getters.order_meta.data.meta_value);
      return data;
    },
  },
  emits: ["update-table"],
  methods: {
    updateStatus(item, value) {
      item.status.value = value;
      this.$emit("update-table", this.permitsData);
    },
    openFileInput(index) {
      // Use the dynamic ref to access the file input of the specific row
      this.$refs["fileInput_" + index][0].click();
    },
    handleFileUpload(index) {
      const formData = new FormData();

      const stateName = this.permitsData[index].state.value;

      const fileField = this.$refs['fileInput_' + index][0];

      const file = fileField.files[0];

      formData.append("order_id", this.order_id);
      formData.append("state_name", stateName);
      formData.append("permitFile", file);
      this.$store
        .dispatch("actionUploadPermit", formData)
        .then((response) => {
          console.log(response);
          this.permitsData[index].state_permit_link = this.api_url + response.file_url;
          this.permitsData[index].state_permit.name = response.file_name;
          this.permitsData[index].state_permit.file_id = response.file_id;
          this.$emit("update-table", this.permitsData);
        })
        .catch((err) => {
          console.log(err);
        });

    },

    showConfirmationDialog(row) {
      this.rowToRemove = row;
      this.showConfirmDialog = true;
    },
    cancelRemovePermit() {
      this.rowToRemove = null;
      this.showConfirmDialog = false;
    },
    confirmRemovePermit() {
      if (this.rowToRemove) {
        this.removePermitFile(this.rowToRemove);
        this.rowToRemove = null;
        this.showConfirmDialog = false;

      }
    },
    removePermitFile(item) {
      const permit_id = item.state_permit.file_id;
      console.log(permit_id);

      this.$store
        .dispatch("actionRemovePermit", permit_id)
        .then((response) => {
          item.state_permit.name = "";
          item.state_permit_link = "";
          item.state_permit.file_id = "";

          // Clear the file input value
          const fileInput =
            this.$refs["fileInput_" + this.permitsData.indexOf(item)][0];
          if (fileInput) {
            fileInput.value = "";
          }
          this.$emit("update-table", this.permitsData);
        })
        .catch((err) => {
          console.log(err);
        });

    },
  },
};
</script>
