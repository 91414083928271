<template>
  <div v-if="user_role === 'owner'" id="quote-page">
    <div v-if="!!order_list_by_user_id && totalItems.archive !== 0" class="quotes table-wrapper">
      <slot-table :data="order_list_by_user_id">
        <template #header>
          <tr>
            <th
              v-for="column in columns"
              :class="{ 'has-sort': column.sort }"
              :key="column.name"
              @click="sort(column)"
            >
              {{ column.label }}
              <span
                v-if="column.name === queryParameters.orderBy"
                :class="`sorting-icon-${
                  queryParameters.order == 'ASC' ? 'ASC' : 'DESC'
                }`"
              >
              </span>
              <template v-else>
                <span v-if="column.sort" class="sorting-icon"> </span>
              </template>
            </th>
          </tr>
        </template>
        <template v-slot:body="{ item }">
          <tr :class="[{'own-order': user_data.user_id === item.initiator_id}]" :key="item.order_id">
            <td class="no-wrap">
              <span v-if="user_data.user_id === item.initiator_id"><svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <rect width="16" height="16" id="icon-bound" fill="none" />
  <polygon points="8,5 13,10 3,10" fill="#0080EA" />
</svg></span>
              {{ formatDateToReadableText(item.created_at) }}
            </td>
            <td class="no-wrap">{{ item.manager_name ?? '-' }}</td>
            <td class="no-wrap">{{ item.company_name }}</td>
            <td>{{ item.is_test == 1 ? "TEST" : '' }} {{  item.name_field }} {{  item.lastname_field }} {{ item.order_name }}</td>


            <td>
              <div class="quote-route" :title="item.origin + ' - ' +  item.destination">
                {{ item.origin }} - {{ item.destination }}
              </div>
            </td>
            <td class="qoute-dimensions">
              {{ prettifyDimensions(item.dimensions) }}
            </td>

            <td>
              <div class="status-select">
                <div :class="['quote-status', item.order_status]">
                  {{ getStatusDisplay(item.order_status) }}
                </div>
              </div>
            </td>

            <td></td>
            <td>
              <router-link
                :to="'/order/' + item.order_id"
                :data-id="item.order_id"
              >
                Show
              </router-link>
            </td>
          </tr>
        </template>
      </slot-table>
    </div>
    <div v-else>
      <div>
        <div class="no-equipment">
          <div class="no-equipment__content">
            <h2>You don't have any archive orders</h2>
          </div>
        </div>
      </div>
    </div>

    <button
      v-if="
        !!order_list_by_user_id &&
        order_list_by_user_id.length < order_list_length
      "
      class="button small button-loadmore"
      @click="loadMore"
    >
      Load More
    </button>
  </div>
</template>

<script>
import SlotTable from "@/components/SlotTable.vue";
import { nextTick } from "vue";
import { FormatterMixin } from "@/mixins/FormatterMixin";
export default {
  name: "OwnerOrderList",
  props: {
    filterData: {
      type: Object,
    },
  },
  components: {
    SlotTable,
  },
  computed: {
    user_data() {
      return this.$store.getters.userData;
    },
    user_role() {
      return this.$store.getters.userRole;
    },
    order_list_by_user_id() {
      return this.$store.getters.order_list_by_user_id;
    },
    order_list_length() {
      if (this.queryParameters.archive === 0) {
        return this.totalItems.in_progress;
      } else {
        return this.totalItems.archive;
      }
    },
  },
  data() {
    return {
      totalItems: {
        in_progress: null,
        archive: null,
      },
      columns: [
        { name: "created_at", label: "Created", sort: true },
        { name: "manager", label: "Assigned Agent", sort: false },
        { name: "company_field", label: "Company", sort: false },
        { name: "order_name", label: "Order Name", sort: true },
        { name: "route", label: "Origin / Destination", sort: false },
        { name: "load", label: "Load (L x W x H x W)", sort: false },
        { name: "status", label: "Status", sort: false },
        { name: "buttons", label: "", sort: false },
        { name: "show", label: "", sort: false },
      ],
      queryParameters: {
        offset: 0,
        limit: 12,
        orderBy: "created_at",
        order: "DESC",
        archive: 1,
        origin: null,
        destination: null,
        status: "",
        company_id: this.$store.getters.userData.company_id,
      },

      statusOptions: [
      { text: "Deposit needed", value: "deposit_needed" },
        { text: "Deposit paid - In Progress", value: "deposit_paid" },
        { text: "Deposit reminder", value: "deposit_reminder" },
        { text: "Payment needed", value: "payment_needed" },
        { text: "Payment reminder", value: "payment_reminder" },
        { text: "Completed", value: "payment_completed" },
        { text: "Cancelled", value: "cancelled" },
      ],
    };
  },

  mixins: [FormatterMixin],
  emits: ["data-total"],
  methods: {
    getOrdersList() {
      const parameters = this.queryParameters;
      this.$store
        .dispatch("actionGetOrdersListByCompanyId", { parameters })
        .then((response) => {
          this.totalItems.in_progress = response.total;
          this.totalItems.archive = response.total_archive;
          this.$emit("data-total", this.totalItems);
        })
        .catch((err) => {});
    },
    getStatusDisplay(statusValue) {
      const foundStatus = this.statusOptions.find(
        (option) => option.value === statusValue
      );
      return foundStatus ? foundStatus.text : "Unknown Status";
    },
    loadMore() {
      const newOffset = this.order_list_by_user_id.length;
      this.queryParameters.offset = newOffset;
      const parameters = this.queryParameters;

      this.$store
        .dispatch("actionGetOrdersListByCompanyId", {
          parameters,
          event: "pagination",
        })
        .then((response) => {
          this.totalItems.in_progress = response.total;
          this.totalItems.archive = response.total_archive;
          this.$emit("data-total", this.totalItems);
        })
        .catch((err) => {});
    },
    async sort(column) {
      if (!column.sort) return;

      if (this.queryParameters.orderBy === column.name) {
        this.queryParameters.order =
          this.queryParameters.order === "ASC" ? "DESC" : "ASC";
      } else {
        this.queryParameters.orderBy = column.name;
        this.queryParameters.order = "ASC";
      }
      await nextTick();
      const parameters = { ...this.queryParameters };
      parameters.limit += parameters.offset;
      parameters.offset = 0;

      this.$store
        .dispatch("actionGetOrdersListByCompanyId", { parameters })
        .then((response) => {
          this.totalItems.in_progress = response.total;
          this.totalItems.archive = response.total_archive;
          this.$emit("data-total", this.totalItems);
        })
        .catch((err) => {});
    },
  },
  watch: {
    filterData: {
      deep: true, // This watches for changes within nested properties of the object
      handler(newData) {
        this.queryParameters.archive = newData.archiveValue;
        this.queryParameters.offset = newData.offsetValue;
        this.queryParameters.origin = newData.originValue;
        this.queryParameters.destination = newData.destinationValue;
        this.queryParameters.status = newData.statusValue;
        this.queryParameters.user_id = newData.ownOrdersValue;
      },
    },
  },
};
</script>
