<template>
  <div id="invite-form" class="accounting">
    <div class="accounting__inner">
      <div class="accounting__left">
        <a href="https://expresspermits.llc/" class="logo">
          <img :src="logo" alt="Express Permits LLC" />
        </a>
        <img class="background" :src="bg" alt="truck" />
      </div>
      <div class="accounting__right">

        <div v-if="formData.type === 'multiple'" class="invite-form form-wrapper">
          <!--          <div class="form-title">You have been invited</div>-->
          <p v-html="invitedTitle" class="invite-page__description"></p>

          <!--          Company users-->
          <div v-if="!!companyUsers && !!companyUsers.length">
            <p class="invite-page__description">Already in the company:</p>

            <div class="invite-page--company-users-list">
              <div v-for="(u, i) in companyUsers" class="invite-page--company-user">
                <div class="invite-page--round-avatar">
                  <img v-if="!!u.picture_name" :src="apiUrl + '/user_images/' + u.picture_name" :alt="u.name + ' ' + u.lastname"
                    class="user-avatar-img" :title="u.name + ' ' + u.lastname" />

                  <img v-else :src="defAvatar" class="user-avatar-img" :alt="u.name + ' ' + u.lastname" title="u.name" />

                </div>
                <div class="invite-page--user-title">{{ u.name }} {{ u.lastname }}</div>
              </div>
            </div>
          </div>


          <div v-if="!inviteType" class="invite-page--sign-in-variants">
            <div>
              <button type="button" class="button" @click="inviteType = 'email'">
                <span>Sign In via email</span>
              </button>
            </div>

            <div>
              <button type="button" class="button" @click="activateSignInViaGoogle">
                <span>Sign In via Google</span>
              </button>
            </div>
            <div>
              <button type="button" class="button" @click="activateSignInViaLinkedin">
                <span>Sign In via Linkedin</span>
              </button>
            </div>
          </div>

          <div v-if="inviteType === 'email'" class="invite-page--sign-in-email">
            <div class="form-elements">
              <div class="form-element">
                <input-email v-model="formData.email" placeholder="Add you email" :rules="rules.requiredEmail" @error="(err) => {
                  regError('email', err);
                }" />
              </div>
            </div>

            <div class="buttons-two-columns">
              <div>
                <button class="button" @click="eventMultiAddEmail" :disabled="disabled || multipleButtonDisabled">Add to system
                </button>
              </div>
              <div>
                <span class="like-link" @click="inviteType = null">Go back to another way</span>
              </div>

            </div>

          </div>

          <div v-if="inviteType === 'google'" class="invite-page--sign-in-google">
            <div class="buttons-two-columns">
              <div>
                <div id="button-google-auth"></div>
              </div>

              <div>
                <span class="like-link" @click="inviteType = null">Go back to another way</span>
              </div>

            </div>
          </div>
          <div v-if="inviteType === 'linkedin'"  class="invite-page--sign-in-google">
            <div class="buttons-two-columns">
              <button class="button-auth button-linkedin" @click="signInViaLinkedin">
                <div class="button-icon">
                  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.19995 17.9999H0.199951V5.9999H4.19995V17.9999ZM2.20075 4.3999C1.09435 4.3999 0.199951 3.5031 0.199951 2.3991C0.199951 1.2951 1.09595 0.399902 2.20075 0.399902C3.30315 0.399902 4.19995 1.2967 4.19995 2.3991C4.19995 3.5031 3.30315 4.3999 2.20075 4.3999ZM18.6 17.9999H14.7544V12.1599C14.7544 10.7671 14.728 8.9759 12.7552 8.9759C10.7528 8.9759 10.4448 10.4927 10.4448 12.0591V17.9999H6.59995V5.9911H10.2912V7.6319H10.3432C10.8568 6.6879 12.112 5.6927 13.984 5.6927C17.88 5.6927 18.6 8.1799 18.6 11.4135V17.9999Z"
                      fill="#6B7280" />
                  </svg>

                </div>
                <span class="button-text">Sign in with LinkedIn</span>
              </button>

              <div>
                <span class="like-link" @click="inviteType = null">Go back to another way</span>
              </div>

            </div>
          </div>
        </div>

        <div v-else-if="formData.type === 'email'" class="invite-form form-wrapper">
          <div class="form-title">
            Registration new invited user
          </div>

          <p>You have been invited by email <br /><strong>{{ formData.email }}</strong></p><br />
          <div class="form-elements">

            <div class="form-element">
              <input-text v-model="formData.name" placeholder="Enter your first name" :rules="rules.textOnlyLetters" @error="(err) => {
                regError('name', err);
              }
                " />
            </div>
            <div class="form-element">
              <input-text v-model="formData.lastname" placeholder="Enter your last name" :rules="rules.textOnlyLetters" @error="(err) => {
                regError('lastname', err);
              }
                " />
            </div>

            <div class="form-element">
              <input-phone v-model="formData.phone" placeholder="Enter your phone number" autocomplete="off"
                :rules="rules.phone" maxlength="14" minlength="14" @error="err => {
                  regError('phone', err);
                }" @input="() => {
                  regError('phone', false);
                }" />
            </div>

            <div class="form-element">
              <input-password 
                v-model="formData.password" 
                placeholder="Password" 
                :class="{ 'not-match': !passwordsMatch }"
                :rules="rules.password"
                :pass-gen="true" 
                @generated="copyGeneratedPassword" 
                @blur="checkPasswordIdentical" @error="err => {
                  regError('password', err);
                }" @input="() => {
                  regError('password', false);
                }" />
            </div>

            <div class="form-element">
              <input-password 
                v-model="formData.confirm_password" 
                placeholder="Confirm password"
                :class="{ 'not-match': !passwordsMatch }"
                :rules="rules.confirm_password"
                @input="passwordsMatch = true"
                @blur="checkPasswordIdentical"
              />
              <span v-if="!passwordsMatch" class="err not-match">Passwords do not match</span>
            </div>

          </div>
          <button class="button" @click="eventSignInInvitedUser" :disabled="invitedUserFormHasErrors">Sign In
          </button>
        </div>


        <div v-else class="invite-form form-wrapper">
          <div class="form-title">
            Blank invitation information

            <button class="button smaill" @click="this.$router.push('/')">Homepage</button>
          </div>
        </div>


      </div>
    </div>
  </div>
  <spinner :loading="isLoading" />
</template>

<script>

import logo from "@/assets/images/logo_white.svg";
import bg from "@/assets/images/bg.jpg";
import profile_photo2 from "@/assets/images/profile.png";

import Spinner from "@/components/helpers/Spinner.vue";
import InputEmail from "@/components/form-elements/InputEmail.vue";

import ValidateMixin from "@/mixins/ValidateMixin";
import InputText from "@/components/form-elements/InputText.vue";
import InputPhone from "@/components/form-elements/InputPhone.vue";
import InputPassword from "@/components/form-elements/InputPassword.vue";

export default {
  components: { InputPassword, InputPhone, InputText, InputEmail, Spinner },
  mixins: [ValidateMixin],
  data() {
    return {
      titlePattern: "You were invited by <strong>{initiator}</strong> to the <strong>{company}</strong> company.",
      bg,
      logo,
      defAvatar: profile_photo2,
      isLoading: false,
      passwordsMatch: true,
      initiator: null,
      company: null,
      companyUsers: null,
      inviteType: null,
      codeLinkedin: false,
      disabled: false,
      formData: {
        token: null,
        type: null,
        name: null,
        lastname: null,
        email: null,
        phone: null,
        password: null,
        confirm_password: null,
      },
      errors: {
        email: false,
        phone: false,
        name: false,
        lastname: false,
        password: false,
      }
    }
  },


  mounted() {
    const token = this.$route.params.token;
    this.$store.dispatch('actionGetDataByInvitedToken', token)
      .then(data => {

        const tokenData = data.token_data;
        this.formData.type = tokenData.type;
        this.formData.token = tokenData.token;
        this.formData.email = tokenData.email;
        // --------------

        this.initiator = data.initiator;
        this.company = data.company;
        this.companyUsers = data.company_users;

      });

    const code = new URLSearchParams(window.location.search).get('code');
    const currentUrl = window.location.href;
    const parsedUrl = new URL(currentUrl);
    const domain = parsedUrl.origin;
    const callbackUrl = domain + '/invite-user/callback/';
    if (code) {
      this.codeLinkedin = true;
      this.sendCodeToApi(code, callbackUrl);
      const newUrl = window.location.href.split('?')[0];
      history.replaceState({}, document.title, newUrl);
    }
  },

  computed: {
    /**
     *
     * @returns {string}
     */
    invitedTitle() {
      if (!this.initiator) {
        return '';
      }
      let title = this.titlePattern;
      title = title.replace(/\{\s?initiator\s?\}/ig, this.initiator.name);
      title = title.replace(/\{\s?company\s?\}/ig, this.company.company_name);
      return title;
    },


    isPasswordsIdentical() {
      const form = this.formData;
      if (!form.password || !form.confirm_password) {
        return false;
      }
      if (form.password.trim() !== form.confirm_password.trim()) {
        return false;
      }
      return true;
    },

    multipleButtonDisabled() {
      if (this.formData.type !== 'multiple') {
        return true;
      }
      const email = this.formData.email;
      if (!email) {
        return true;
      }
      const exist_error_email = !!this.errors.email;
      if (exist_error_email) {
        return true;
      }
      return false;
    },

    invitedUserFormHasErrors() {
      if (this.formData.type !== 'email') {
        return true;
      }
      const fields = ['name','lastname', 'email', 'phone', 'password'];
      const form = this.formData;
      const errors = this.errors;
      const not_valid_form = fields.find(key => {
        if (!form[key]) {
          return true;
        }
        return (form[key].trim().length <= 2) || errors[key]
      });
      return (not_valid_form !== undefined) || !this.passwordsMatch;
    },
    apiUrl() {
      return this.$store.getters.apiUrl;
    }

  },

  methods: {
    eventMultiAddEmail() {
      this.disabled = true;
      const data = {
        email: this.formData.email.trim(),
        token: this.formData.token.trim(),
        type: this.formData.type
      };
      this.$store.dispatch('inviteNoPrivateOneMember', data)
        .then(() => {
         
        });
    },
    eventSignInInvitedUser() {
      const form = this.formData;
      const data = {
        token: form.token,
        name: form.name.trim(),
        lastname: form.lastname.trim(),
        email: form.email.trim(),
        phone: form.phone.trim(),
        password: form.password.trim(),
      };
      this.$store.dispatch('actionRegistrationOneInvitedMember', data)
        .then(() => {
          this.$router.push('/');
        });
    },
    copyGeneratedPassword(pass) {
      this.errors.password = false;
      this.passwordsMatch = true;
      this.formData.confirm_password = pass;
    },

    checkPasswordIdentical() {
      setTimeout(() => {
        this.passwordsMatch = !!this.isPasswordsIdentical;
      });
    },

    // ------------------ GOOGLE -----------------

    handleCredentialResponse(response) {
      const token = response.credential;

      // ----------------- jwt parser ----------------
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      // user data
      const incoming_google_user_data = JSON.parse(jsonPayload);

      const url_token = this.$route.params.token;

      const out_google_data = {
        google_id: incoming_google_user_data.sub,
        email: incoming_google_user_data.email,
        email_verified: incoming_google_user_data.email_verified,
        name: incoming_google_user_data.name,
        given_name: incoming_google_user_data.given_name,
        family_name: incoming_google_user_data.family_name,
        picture_url: incoming_google_user_data.picture,
        locale: incoming_google_user_data.locale,
        all_json_data: jsonPayload,
        url_token
      };

      this.$store.dispatch('actionAddGoogleUserByInviteToken', out_google_data)
        .then(() => {
          this.$router.push('/');
          // window.location.href = "/";
        });

      // console.log('-=> ', out_google_data);

      // this.$store.dispatch('actionSignInByGoogle', out_google_data);
    },


    async activateSignInViaGoogle() {
      this.inviteType = 'google';
      await this.$nextTick();

      google.accounts.id.initialize({
        client_id: "340745215228-8khqs9kgpl1dorfdl7j4o4t8lcpbd8mf.apps.googleusercontent.com",
        callback: this.handleCredentialResponse,
      });
      google.accounts.id.renderButton(document.getElementById("button-google-auth"), {
        theme: "outline",
        size: "large",
      });
      // const ls_token = localStorage.getItem('token');
      // if (!ls_token) {
      //   google.accounts.id.prompt();
      // }


    },
    activateSignInViaLinkedin() {
      this.inviteType = 'linkedin';
    },
    signInViaLinkedin() {
      const currentUrl = window.location.href;
      const parsedUrl = new URL(currentUrl);
      const domain = parsedUrl.origin;
      const clientId = '77djmc10uywfqs';
      const callbackUrl = domain + '/invite-user/callback/';
      const timestamp = Date.now();
      const state = `foobar_${timestamp}`;
      const scope = 'openid profile email';
      const url_token = this.$route.params.token;
      localStorage.setItem('url_token', url_token);
      const authorizationUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${callbackUrl}&state=${state}&scope=${scope}`;
      window.location.href = authorizationUrl;
    },
    sendCodeToApi(code, callbackUrl) {
      const data = { data: code, callbackUrl: callbackUrl };
      this.$store.dispatch('actionGetTokenFromLinkedin', data)
        .then(response => {
          this.handleCredentialResponseLinkedin(response);
        })
    },
    handleCredentialResponseLinkedin(response) {
      const token = response.id_token;

      // ----------------- jwt parser ----------------
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      // user data

      const url_token = localStorage.getItem('url_token');
      const incoming_linkedin_user_data = JSON.parse(jsonPayload);
      const out_data = {
        linkedin_id: incoming_linkedin_user_data.sub,
        email: incoming_linkedin_user_data.email,
        email_verified: incoming_linkedin_user_data.email_verified,
        name: incoming_linkedin_user_data.name,
        given_name: incoming_linkedin_user_data.given_name,
        family_name: incoming_linkedin_user_data.family_name,
        picture_url: incoming_linkedin_user_data.picture,
        locale: incoming_linkedin_user_data.locale,
        all_json_data: jsonPayload,
        url_token
      };

      // this.$store.dispatch('actionSignInByLinkedin', out_data);

      this.$store.dispatch('actionAddLinkedinUserByInviteToken', out_data)
        .then(() => {
          localStorage.removeItem('url_token');
          this.$router.push('/');
          // window.location.href = "/";
        });
    },

  }

}
</script>