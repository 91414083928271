<template>
  <div class="new-quote__map">
    <div id="quote-map"></div>
    <div id="quote-nout" class="new-quote__nout">
      <p>The map serves for visualization purposes. Please ALWAYS check your permits for the permitted route.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "GoogleMapComponent",
  props: {
    origin: {
      type: String,
      required: true,
    },
    destination: {
      type: String,
      required: true,
    },

    order_id: {
      type: String,
    },
    status: {
      type: String,
    },
  },
  data() {
    return {
      map: null,
      directionsService: null,
      directionsRenderer: null,
      draggablePolyline: null,
    };
  },
  mounted() {
    this.initMap();
  },
  emits: ['map-positions'],
  methods: {
    initMap() {
      const styles = [
        {
          featureType: "administrative",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#6b6b6b",
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [
            {
              color: "#e4f3ff",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [
            {
              color: "#7cbcf8",
            },
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [
            {
              color: "#7cbcf8",
            },
          ],
        },
      ];

      const mapOptions = {
        center: { lat: 37.7749, lng: -102.4194 },
        zoom: 4.3,
        styles,
      };

      this.map = new google.maps.Map(
        document.getElementById("quote-map"),
        mapOptions
      );

      this.directionsService = new google.maps.DirectionsService();
      this.directionsRenderer = new google.maps.DirectionsRenderer({
        map: this.map,
        draggable: true, // Allow dragging the route
      });

      google.maps.event.addListener(this.directionsRenderer, 'directions_changed', () => {
        // This event is triggered when the route is dragged and changed
        const updatedDirections = this.directionsRenderer.getDirections().routes[0].overview_polyline;
        // this.$emit('map-positions', updatedDirections);
      });


    },

    calcRoute() {
      const origin = this.origin;
      const destination = this.destination;
      // const roadType = this.roadType;
      const status = this.status;
      const order_id = this.order_id;

      // if (status === 'new') {
      const req = {
        origin,
        destination,
        travelMode: "DRIVING",
      };

      this.directionsService.route(req, (response, status) => {
        if (status === "OK") {

          this.directionsRenderer.setDirections(response);
          this.directionsRenderer.setOptions({
            polylineOptions: {
              strokeColor: "#EF4444",
            },
          });
        }
      });

      // } else if (status === 'show') {
      //   this.$store
      //     .dispatch("actionGetOrderMeta", { order_id, meta_key: '_map_positions' })
      //     .then((response) => {
      //       const polyline = response.data.meta_value;
      //       if (polyline) {
      //         const decodedPath = google.maps.geometry.encoding.decodePath(JSON.parse(polyline));
      //         new google.maps.Polyline({
      //           path: decodedPath,
      //           strokeColor: "#EF4444",
      //           map: this.map,
      //         });

      //         const bounds = new google.maps.LatLngBounds();
      //         decodedPath.forEach(point => bounds.extend(point));
      //         this.map.fitBounds(bounds);
      //       }

      //     })
      //     .catch((err) => { });

      // }

    },

  },
};
</script>
