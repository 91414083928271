<template>
  <div>
    <div class="step-form-3__wrap">
      <div class="step-form-3__inner">
        <template v-if="order_by_id.order_status == 'deposit_reminder' || order_by_id.order_status == 'deposit_needed'">
          <h3>THANK YOU FOR YOUR REQUEST.</h3>
          <h3>Please pay a ${{ depositAmount }} deposit to complete the order</h3>

          <PayPalDepositButton  ref="paypalButton" :amount="depositAmount" :order="order_by_id ? order_by_id : ''"
            :order_id="order_by_id.order_id ? order_by_id.order_id : ''" />
        </template>
        <template v-else-if="order_by_id.order_status == 'deposit_paid'">
          <h3>The deposit of ${{ order_by_id.deposit_amount }} has been paid!</h3>
          <div>
            <p>if you have any questions:</p>
            <p>Call Us: <a href="tel:(828) 348-6679">(828) 348-6679</a></p>
            <p>
              Email:
              <a href="mailto:contact@expresspermits.llc">contact@expresspermits.llc</a>
            </p>
          </div>
        </template>
        <template v-else-if="user_role == 'manager' && order_by_id.order_status == 'payment_needed'">
          <h3>Waiting for client payment</h3>
        </template>
        <template
          v-if="user_role !== 'manager' && order_by_id.order_status == 'payment_reminder' || user_role !== 'manager' && order_by_id.order_status == 'payment_needed'">
          <h3>Please pay a ${{ order_by_id.cost }} to complete the order</h3>
          <div v-if="order_by_id.order_status == 'payment_reminder' || order_by_id.order_status == 'payment_needed'">
            <PayPalPaymentButton v-if="+order_by_id.cost > 0" :amount="+order_by_id.cost" :order="order_by_id"
              :order_id="order_by_id.order_id" />
            <span v-else style="text-align: right;" class="err">Total {{ +order_by_id.cost }}$ is lower than 0$</span>
          </div>
        </template>

        <template v-else-if="user_role !== 'manager' && order_by_id.order_status == 'payment_completed'">
          <h3>Thank you! The order has been paid.</h3>
          <div>
            <p>if you have any questions:</p>
            <p>Call Us: <a href="tel:(828) 348-6679">(828) 348-6679</a></p>
            <p>
              Email:
              <a href="mailto:contact@expresspermits.llc">contact@expresspermits.llc</a>
            </p>
          </div>
        </template>
        <template v-else-if="user_role == 'manager' && order_by_id.order_status == 'payment_completed'">
          <h3>Payment completed</h3>
        </template>

      </div>
      <p v-if="user_role !== 'manager'">Check out our <a
          href="https://www.instagram.com/express.permits/?igsh=M284dzNoaWcwczFj" target="_blank">instagram</a> and <a
          target="_blank" href="https://expresspermits.llc/wide-load-regulation/">free resources</a> regarding
        OSOW
        regulations.</p>
    </div>
  </div>
</template>

<script>
import PayPalDepositButton from "@/components/payments/PayPalDepositButton.vue";
import PayPalPaymentButton from "@/components/payments/PayPalPaymentButton.vue";
export default {
  name: "StepFormThree",

  props: {
    incomingData: {
      type: Object,
      default: null
    },
    // depositAmount: {
    //   type: Number,
    //   default: 0
    // },
  },
  components: {
    PayPalDepositButton,
    PayPalPaymentButton

  },
  computed: {
    user_role() {
      return this.$store.getters.userRole;
    },

    order_by_id() {
      return this.$store.getters.order_by_id;
    },

  },
  data() {
    return {
      depositAmount: 0
    }
  },
  methods: {
    runPayPalScript() {
      this.$refs.paypalButton.loadPayPalScript();
    }
  },
  watch: {
    incomingData: {
      deep: true,
      handler(new_data) {
        this.depositAmount = new_data.deposit_amount;
      }
    }
  }

};
</script>
