<template>
  <div v-if="visibility" class="notification">
    <div v-if="status" class="notification__inner success">
      <div class="notification__icon">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.20835 10.0013C3.20835 6.25037 6.24909 3.20964 10 3.20964C13.751 3.20964 16.7917 6.25037 16.7917 10.0013C16.7917 13.7522 13.751 16.793 10 16.793C6.24909 16.793 3.20835 13.7522 3.20835 10.0013ZM10 1.79297C5.46668 1.79297 1.79169 5.46797 1.79169 10.0013C1.79169 14.5346 5.46668 18.2096 10 18.2096C14.5334 18.2096 18.2084 14.5346 18.2084 10.0013C18.2084 5.46797 14.5334 1.79297 10 1.79297ZM13.8342 8.00217C14.1108 7.72555 14.1108 7.27706 13.8342 7.00043C13.5576 6.72381 13.1091 6.72381 12.8325 7.00043L9.16669 10.6662L8.00089 9.50044C7.72427 9.22381 7.27577 9.22381 6.99915 9.50044C6.72253 9.77706 6.72253 10.2255 6.99915 10.5022L8.66582 12.1688C8.79866 12.3017 8.97883 12.3763 9.16669 12.3763C9.35455 12.3763 9.53472 12.3017 9.66755 12.1688L13.8342 8.00217Z"
            fill="#23B45D"
          />
        </svg>
      </div>
      <div class="notification__text">{{ text }}</div>
    </div>
    <div v-else class="notification__inner fail">
      <div class="notification__icon">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="0 0 100 100"
          xml:space="preserve"
        >
          <path
            fill="none"
            stroke="#e15b64"
            stroke-width="16"
            stroke-miterlimit="10"
            d="M22 78l56-56"
          />
          <path
            fill="none"
            stroke="#e15b64"
            stroke-width="16"
            stroke-miterlimit="10"
            d="M78 78L22 22"
          />
        </svg>
      </div>
      <div class="notification__text">{{ text }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Notification",
  data() {
    return {
      visibility: this.isVisible,
    };
  },
  props: {
    isVisible: {
      type: Boolean,
    },
    status: {
      type: Boolean,
    },
    text: {
      type: String,
    },
  },
  watch: {
    isVisible(data) {
      if (!!data) {
        this.visibility = true;
        setTimeout(() => {
          this.visibility = false;
        }, 3000);
      }
    },
  },
};
</script>
