<template>
  <div class="order-deposit" v-if="order.order_status === 'deposit_needed'">
    <div class="order-deposit__inner">
      <h3>Order Status - Deposit needed</h3>
      <p>
        You can send a notification to remind the client to pay the deposit.
      </p>
      <button @click="changeStatus('deposit_reminder')" class="button button-deposit-needed">
        Change status to "Deposit Reminder"
      </button>
    </div>
  </div>
  <div class="order-deposit" v-if="order.order_status === 'deposit_reminder'">
    <div class="order-deposit__inner">
      <h3>Order Status - Deposit reminder</h3>
      <p>Waiting for a payment.</p>
      <button @click="changeStatus('deposit_reminder')" class="button button-deposit-needed">
        Send another reminder
      </button>
    </div>
  </div>
  <div v-if="
    order.order_status === 'payment_completed' ||
    order.order_status === 'payment_needed' ||
    order.order_status === 'payment_reminder'
  ">
    <order-table-view :order="order" :order_meta="order_meta" />

    <button v-if="order.order_status === 'payment_reminder'" @click="changeStatus('payment_reminder')"
      class="button small send-to-client">
      Send another payment reminder
    </button>

  </div>

  <button v-if="order.order_status === 'deposit_paid' && order.manager_id === user_data.user_id" @click="changeStatus('payment_needed')"
    class="button small send-to-client" :disabled="!checkValid || total < 0">
    Change status to "Payment needed"
  </button>
  <button v-if="order.total !== 0 && order.total > 0 && order.order_status === 'payment_needed'"
    @click="changeStatus('payment_reminder')" class="button small send-to-client">
    Change status to "Payment reminder"
  </button>
  <div v-if="
      order.order_status === 'payment_completed' 
    " class="permit-paid">
      <div>Paid!</div>
    </div>
  <ConfirmationDialog v-if="showConfirmStatusDialog" :content="statusContent" @cancel="cancelChangeStatus"
    @confirm="confirmChangeStatus" />
  <spinner :loading="loading" />
</template>

<script>
import ConfirmationDialog from "@/components/helpers/ConfirmationDialog.vue";
import Spinner from "@/components/helpers/Spinner.vue";
import OrderTableView from "@/components/orders/owner/OrderTableView.vue";
import OrderPermitsTableView from "@/components/orders/owner/OrderPermitsTableView.vue";

export default {
  name: "AdminOrderPageStatuses",
  components: {
    ConfirmationDialog,
    OrderTableView,
    OrderPermitsTableView,

    Spinner,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    // additionalDeposit: {
    //   type: Number,
    //   default: 0
    // },
    checkValid: {
      type: Boolean,
      default: false
      // required: true,
    },
    order_meta: {
      type: Object,
      required: true,
    },
    total: {
      type: [Number, String],
    },
  },
  emits: ['update-data'],
  data() {
    return {
      loading: false,
      showConfirmStatusDialog: false,
      showConfirmDialog: false,
      statusContent: "",
      setStatus: "",
    };
  },
  computed: {
    user_data() {
      return this.$store.getters.userData;
    },
  },

  methods: {
    changeStatus(status) {
      this.showConfirmStatusDialog = true;
      this.setStatus = status;
      if (this.order.order_status === "payment_needed") {
        this.statusContent =
          "Are you sure you want to change status to - Payment Reminder ?";
      } else if (
        status === "deposit_reminder" &&
        this.order.order_status === "deposit_reminder"
      ) {
        this.statusContent = "Are you sure you want send another reminder ?";
      } else if (
        status === "payment_reminder" &&
        this.order.order_status === "payment_reminder"
      ) {
        this.statusContent = "Are you sure you want send another reminder ?";
      } else if (status === "deposit_reminder") {
        this.statusContent =
          "Are you sure you want to change status to - Deposit Reminder ?";
      } else if (status === "payment_needed") {
        this.statusContent =
          "Are you sure you want to change status to - Payment needed ?";
      }
    },
    cancelChangeStatus() {
      this.showConfirmStatusDialog = false;
      this.setStatus = "";
    },
    confirmChangeStatus() {
      this.loading = true;
      this.showConfirmStatusDialog = false;
      const order_id = this.order.order_id;
      const parameters = {
        order_status: this.setStatus,
        is_test: this.order.is_test,
        // additionalDeposit: this.additionalDeposit,

      };
      this.$store
        .dispatch("actionUpdateOrderByOrderId", { order_id, parameters })
        .then((response) => {
          this.loading = false;
          this.setStatus = "";
          this.$emit('update-data');
        })
        .catch((err) => { });
    },
  },
};
</script>
