<template>
  <div class="template">
    <accounting-panel />
    <main class="main">
      <div class="container">

        <div class="table-wrap settings-accounting">
          <h2 class="settings-accounting-heading">Account Information</h2>
          <div class="settings-accounting-text">
            <h3>Dear, {{ formData.name }} {{ formData.lastname }}</h3>
            <p>Please fill up the information below to continue with ordering permits.</p>
          </div>
          <div class="form-element-wrap ">
            <div class="form-element ">
              <label>Name*</label>
              <input-text v-model="formData.name" placeholder="Enter name" :valid-sw="validSw"
                :rules="rules.textOnlyLetters" @error="(err) => {
                  regError('name', err);
                }
                  " />
            </div>
            <div class="form-element ">
              <label>Last Name*</label>
              <input-text v-model="formData.lastname" placeholder="Enter name" :valid-sw="validSw"
                :rules="rules.textOnlyLetters" @error="(err) => {
                  regError('lastname', err);
                }
                  " />
            </div>
            <div class="form-element phone">
              <label>Personal Phone*</label>
              <input-phone v-model="formData.phone" :valid-sw="validSw" :rules="rules.phone" placeholder="Enter phone"
                @error="(err) => { regError('phone', err); }" />
            </div>
            <div class="form-element ">
              <label>Company Name*</label>

              <input-text v-if="user_data.role !== 'driver'" v-model="formData.company_name" placeholder="Enter name"
                :valid-sw="validSw" :rules="rules.companyName" @error="(err) => {
                  regError('company_name', err);
                }
                  " />
              <input-fake v-else v-model="formData.company_name" placeholder="Enter name" />
            </div>
            <div class="form-element phone">
              <label>Company Phone*</label>
              <input-phone v-if="user_data.role !== 'driver'" v-model="formData.company_phone" :valid-sw="validSw"
                :rules="rules.phone" placeholder="Enter phone" @error="(err) => { regError('company_phone', err); }" />
              <input-fake v-else v-model="formData.company_phone" placeholder="Enter phone" />
            </div>
            <div class="form-element ">
              <label>Doing business as</label>
              <input-text v-if="user_data.role !== 'driver'" v-model="formData.doing_business_as" maxlength="50"
                placeholder="Enter doing business as" />
              <input-fake v-else v-model="formData.doing_business_as" placeholder="Enter doing business as" />
            </div>

            <div class="form-element ">
              <label>Address*</label>
              <input-text v-if="user_data.role !== 'driver'" v-model="formData.address" :valid-sw="validSw"
                :rules="rules.address" placeholder="Enter address" @error="(err) => { regError('address', err); }" />
              <input-fake v-else v-model="formData.address" placeholder="Enter address" />
            </div>
            <div class="form-element city">
              <label>City*</label>
              <input-text v-if="user_data.role !== 'driver'" v-model="formData.city" :valid-sw="validSw"
                placeholder="Enter city" :rules="rules.textOnlyLetters" @error="(err) => {
                  regError('city', err);
                }
                  " />
              <input-fake v-else v-model="formData.city" placeholder="Enter city" />
            </div>
            <div class="form-element states">
              <label>State*</label>
              <input-text v-if="user_data.role !== 'driver'" v-model="formData.state" :rules="rules.textOnlyLetters"
                :valid-sw="validSw" maxlength="2" placeholder="Enter state id" @error="(err) => {
                  regError('state', err);
                }
                  " />
              <input-fake v-else v-model="formData.state" placeholder="Enter state id" />
            </div>
            <div class="form-element phone">
              <label>Zip*</label>
              <input-text v-if="user_data.role !== 'driver'" v-model="formData.zip_code" :rules="rules.zipCode"
                :valid-sw="validSw" placeholder="Enter zip code" maxlength="5" @error="(err) => {
                  regError('zip_code', err);
                }
                  " />
              <input-fake v-else v-model="formData.zip_code" placeholder="Enter zip code" />
            </div>
            <div class="form-element phone">
              <label>FID*</label>
              <input-text v-if="user_data.role !== 'driver'" :rules="rules.numberTT" v-model="formData.fid"
                :valid-sw="validSw" maxlength="15" placeholder="Enter FID" @error="(err) => {
                  regError('fid', err);
                }
                  " />
              <input-fake v-else v-model="formData.fid" placeholder="Enter FID" />
            </div>

            <div class="form-element phone">
              <label>MC#</label>
              <input-text v-if="user_data.role !== 'driver'" v-model="formData.mc" maxlength="15"
                placeholder="Enter MC#" />
              <input-fake v-else v-model="formData.mc" placeholder="Enter MC#" />
            </div>
            <div class="form-element phone">
              <label>USDOT</label>
              <input-text v-if="user_data.role !== 'driver'" v-model="formData.usdot" maxlength="15"
                placeholder="Enter USDOT" />
              <input-fake v-else v-model="formData.usdot" placeholder="Enter USDOT" />
            </div>
            <div class="form-element phone">
              <label>IFTA</label>
              <input-text v-if="user_data.role !== 'driver'" v-model="formData.ifta" maxlength="15"
                placeholder="Enter IFTA" />
              <input-fake v-else v-model="formData.ifta" placeholder="Enter IFTA" />
            </div>
            <div class="form-element phone">
              <label>IRP</label>
              <input-text v-if="user_data.role !== 'driver'" v-model="formData.irp" maxlength="15"
                placeholder="Enter IRP" />
              <input-fake v-else v-model="formData.irp" placeholder="Enter IRP" />
            </div>

          </div>
          <div v-if="!user_data.password_exist" class="settings-accounting-password">
            <h2 class="settings-accounting-heading">Security</h2>
            <div class="form-element row agreement">
              <div class="settings-accounting-text">
                <p>For your account security, please set password.</p>
              </div>
              <div class="settings-accounting-password-wrap">
                <InputPassword v-model="password" placeholder="Enter your password" :valid-sw="validSw"
                  :rules="rules.password" @blur="validateForm" />
                <InputPassword v-model="confirmPassword" placeholder="Confirm your password" :valid-sw="validSw"
                  :pass-gen="true" @generated="copyGeneratedPassword" :rules="rules.password" @blur="validateForm" />
              </div>
              <span v-if="formError" class="error">{{ formError }}</span>
            </div>

          </div>
          <div class="settings-accounting-agreement">
            <h2 class="settings-accounting-heading">Service Agreement</h2>
            <div id="content" class="agreement-wrapper" style="color: #000;">

              <p>THIS SERVICE AGREEMENT (Agreement) is made effective by and between <b>EXPRESS PERMITS LLC</b>, a
                North Carolina limited liability company with office at 120 Dogwood Rd, Candler, NC 28715 (EP) and
                Customer</p>

              <p>WHEREAS, EP is engaged in the business of providing over-dimensional and special cargo licensing and
                permit services to motor carriers; and WHEREAS, Customer wishes to hire EP to provide certain
                licensing, permits, and related services necessary to conduct its business in particular forums.</p>

              <p>NOW, THEREFORE, in consideration of the mutual promises contained herein and other good and valuable
                consideration, the receipt and sufficiency of which is hereby acknowledged, the parties agree as
                follows:</p>

              <p><b>1. Services Provided.</b> EP agrees to assist with procurement of transportation permits and
                related services from Governing Agencies as requested by Customer, using information provided by
                Customer. EP reports information to Governing Agencies as requested and it is given to EP, and
                Customer authorizes EP to transmit information to Governing Agencies on Customer's behalf. Governing
                Agencies may be, but not limited to, Federal Motor Carrier Administration, Departments of
                Transportation, Departments of Motor Vehicles, state, province entities etc.</p>

              <p><b>2. Rates.</b> All necessary or recommended governmental authority filing fees, permit fees,
                transaction fees, escort fees, and similar payments will be advanced by EP and invoiced to Customer
                for payment. Services and prices are subject to change without notice to Customer.</p>

              <p><b>3. Collection.</b> Customer agrees to pay EP all costs arising out of and relating to EP's
                collection of all invoices and billings by EP to Customer. Customers are encouraged to use
                www.expresspermits.llc for all permit advances and payments. Issued permits may not be send to the
                Customer until EP can ensure payment will be made for all applicable fees.</p>

              <p><b>4. Customer Obligations.</b> Customer must do the following:</p>

              <ul>
                <li>provide accurate and timely information to allow EP to obtain necessary permits and
                  authorizations;</li>
                <li>timely respond to all questions from EP;</li>
                <li>provide truckers liability insurance and cargo damage insurance to protect its cargo, property,
                  and equipment;</li>
                <li>comply with all Governing agencies rules and regulations;</li>
                <li>understand that Governing Agencies are not required to accept requested routing and may amend
                  requested routes to issue a permit;</li>
                <li>carefully read and follow all the regulations, provisions, safety requirements as well as approved
                  route listed in permit;</li>
                <li>state permits may not cover city streets and/or county roads. It is the responsibility of Customer
                  to ensure that local permits are obtained.</li>
              </ul>

              <p><b>EP Obligations.</b> EP shall take all actions reasonably necessary to obtain the necessary
                licenses and permits from Governing Agencies.</p>
              <p><b>6. Certificate of Insurance.</b> It is the policy of EP to retain updated insurance information
                for all of our customers that order permits and the permits will be processed only for the customers
                with valid insurance information.</p>

              <p><b>7. Disclaimer Policy.</b> EP shall not be liable, for any reason for any indirect, special,
                incidental, consequential, punitive, exemplary damages. Customer are responsible for permit accuracy.
                EP is not responsible for fines, delays, claims, down time, lost revenue etc. due to inaccurate
                information, Customer's violation of rules or requirements, delays or turnaround time from Governing
                Agency issuing a permit, or any other circumstance within or beyond EP's control. <br>Any issues with
                permits are to be reported directly to EP to rectify the situation whether it is EP's error or
                Customer's.</p>

              <p><b>8. Confidential Information.</b> Each party to this agreement agrees they will not disclose,
                divulge, reveal, report or use, for any purpose, any confidential information with respect to the
                business of the other party, which has been obtained, except as may be necessary or desirable to
                further the business interests of the other party.</p>

            </div>
            <div class="form-element row agreement">
              <input-checkbox v-model="formData.agreement"
                label='Yes, I have read and agreed to the Service Agreement.' />
            </div>

          </div>
          <div class="settings-accounting-footer">
            <button :disabled="!formData.agreement || disabledButton" @click="updateInfo"
              class="button small">Apply</button>
          </div>
        </div>
      </div>
    </main>
  </div>

</template>

<script>

import InputPhone from "@/components/form-elements/InputPhone.vue";
import InputFake from "@/components/form-elements/InputFake.vue";
import InputPassword from "@/components/form-elements/InputPassword.vue";
import InputText from "@/components/form-elements/InputText.vue";
import ValidateMixin from "@/mixins/ValidateMixin";
import InputNumber from "@/components/form-elements/InputNumber.vue";
import AccountingPanel from "@/components/header/AccountingPanel.vue";
import InputCheckbox from "@/components/form-elements/InputCheckbox.vue";
export default {
  components: {
    AccountingPanel,
    InputPhone,
    InputPassword,
    InputNumber,
    InputText,
    InputCheckbox,
    InputFake
  },
  name: "settingsTemplate",
  mixins: [ValidateMixin],
  computed: {
    user_data() {
      return this.$store.getters.userData;
    },
  },
  mounted() {
    this.formData.lastname = this.user_data.lastname;
    this.formData.name = this.user_data.name;
    this.formData.phone = this.user_data.phone;
    if (this.user_data.role === 'driver' || this.user_data.role === 'owner') {
      this.formData.company_name = this.user_data.company.company_name;
      this.formData.company_phone = this.user_data.company.company_phone;
      this.formData.address = this.user_data.company.address;
      this.formData.city = this.user_data.company.city;
      this.formData.state = this.user_data.company.state;
      this.formData.zip_code = this.user_data.company.zip_code;
      this.formData.fid = this.user_data.company.fid;
      this.formData.doing_business_as = this.user_data.company.doing_business_as;
      this.formData.mc = this.user_data.company.mc;
      this.formData.usdot = this.user_data.company.usdot;
      this.formData.ifta = this.user_data.company.ifta;
      this.formData.irp = this.user_data.company.irp;
    }
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      formError: null,
      validSw: false,
      disabledButton: false,
      formData: {
        name: null,
        lastname: null,
        phone: null,
        company_name: null,
        company_phone: null,
        address: null,
        city: null,
        state: null,
        zip_code: null,
        fid: null,
        doing_business_as: null,
        mc: null,
        usdot: null,
        ifta: null,
        irp: null,
        days_to_archive: 10,
        first_login: 1,
        agreement: false,
      },
      errors: {
        company_name: false,
        company_phone: false,
        doing_business_as: false,
        address: false,
        city: false,
        state: false,
        zip_code: false,
        usdot: false,
        mc: false,
        fid: false,
        ifta: false,
        irp: false,
        name: false,
        lastname: false,
        phone: false,
        password: false,
        confirmPassword: false,
      },

    }
  },
  methods: {
    validateForm() {
      this.formError = null;
      if (!this.password) {
        this.formError = 'Password is required';
        return;
      }
      if (!this.confirmPassword) {
        this.formError = 'Confirm password is required';
        return;
      }
      if (this.password !== this.confirmPassword) {
        this.formError = 'Passwords do not match';
        return;
      }
    },
    copyGeneratedPassword(pass) {
      this.password = pass;
      this.validateForm()
      this.$store.dispatch("actionGenerateId", 32).then((id) => {
        this.validSw = id;
      });
    },
    updatePassword() {
      const password = this.password;
      this.$store.dispatch('actionUserChangePassword', {
        password: password
      })
        .then(() => {
          this.password = null;
        })
        .catch(err => {
          console.error(err);
        });
    },
    updatePersonalData() {
      const name = this.formData.name;
      const lastname = this.formData.lastname;
      const phone = this.formData.phone;
      this.$store.dispatch('saveUserPersonalData', {
        name: name,
        lastname: lastname,
        phone: phone,
      })
    },
    updateInfo() {
      if (!this.is_form_valid_new()) return;

      this.disabledButton = true;

      if (!this.user_data.password_exist) {
        this.updatePassword();
      }

      this.updatePersonalData();

      const data = this.formData;
      this.$store.dispatch('actionUserChangeCompanyData', data)
        .then(() => {
          this.disabledButton = false;
          window.location.reload();
        });
    },
    is_form_valid_new() {
      this.$store.dispatch("actionGenerateId", 32).then((id) => {
        this.validSw = id;
      });
      const allFormDataNotEmpty = Object.entries(this.formData).every(([key, value]) => {
        const ignoredFields = ['doing_business_as', 'mc', 'usdot', 'ifta', 'irp'];
        return ignoredFields.includes(key) || (value !== null && value !== '');
      });
      const allErrorsFalse = Object.values(this.errors).every(error => error === false);
      let isValid = allFormDataNotEmpty && allErrorsFalse;

      if (!this.user_data.password_exist) {
        isValid = allFormDataNotEmpty && allErrorsFalse && !this.formError;
      }

      // console.log('All form data fields are not empty:', allFormDataNotEmpty);
      // console.log('All errors are false:', allErrorsFalse);
      // console.log('Form is valid:', isValid);
      return isValid;
    },
  }
};
</script>