<template>
  <div 
  :class="['phone-input-wrap','input-box', 'input-phone', {'not-valid-data-box': !!errorText}, {'disabled': !!disabled}]"
  >
  <span class="input-icon phone-icon"></span>
    <input
      v-model="inputValue"
      :class="[{'not-valid-data': !!errorText}]"
      type="tel"
      placeholder="(___) ___ - ____"
      @input="eventInput"
      @blur="eventBlur"
      :autocomplete="autocomplete"
      :minlength="minlength"
      :maxlength="maxlength"
      :disabled="disabled"
    />
    <span v-if="!!errorText" class="err">{{ errorText }}</span>
  </div>
</template>

<script>
export default {


  data() {
    return {
      inputValue: this.modelValue,
      isInvalid: false,
      errorMessage: "",
      errorText: null
    };
  },
  props: {
    modelValue: {
        type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    validSw: {
      type: [Boolean,String],
    },
    icon: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    minlength: {
      type: [Number, String],
      default: null
    },
    maxlength: {
      type: [Number, String],
      default: null
    },
    autocomplete: {
      type: String,
      default: 'on'
    }
  },
  emits: ["update:modelValue", 'error', 'blur'],

  methods: {
    eventInput(e) {
      const value = this.fvFormatMaskedPhoneNumber(e.target.value);
      this.errorText = null;
      this.$emit('update:modelValue', value);

      // this.$emit("update:modelValue", this.inputValue);
    },
    eventBlur(e) {
      const value = this.fvFormatMaskedPhoneNumber(e.target.value);
      this.$emit('blur', value);
      this.validateData(value);
    },
    validateData(v) {
      if (!!this.rules && this.rules.length) {
        const err_txt = this.rules
          .map(f => f(v))
          .find(itm => itm !== true);

        if (!!err_txt) {
          this.errorText = err_txt;
          this.$emit('error', true);
          return;
        } else {
          this.errorText = null;
          this.$emit('error', false);
          return;
        }
      }
      this.$emit('error', false); 
    },
    fvFormatMaskedPhoneNumber(val) {
    val = val.replace(/[^\d]/gi, "");
    let phone_mask = "(xxx) xxx-xxxx";
    let phone_arr = phone_mask.split("");

    const digits_arr = val.split("");
    let digit_count = digits_arr.length;
    if (!digit_count) {
      return "";
    }
    let ind = 0;
    phone_arr = phone_arr.map((el) => {
      if (ind >= digit_count) {
        return "";
      }
      if (el === "x") {
        const val = digits_arr[ind];
        ind++;
        return val;
      }
      return el;
    });
    return phone_arr.join("").trim();
  },
    modifyValue(val) {
      //mask
      let x = val.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      val = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? "-" + x[3] : ""}`;
      
      return val;
    },
  },
  watch: {
    inputValue(new_val) {
     this.inputValue = this.fvFormatMaskedPhoneNumber(new_val);
    },
    modelValue(new_val) {
      this.inputValue = new_val;
    },
    validSw(v) {
      this.validateData(this.inputValue);
    },
  }
};
</script>
