export default {
  state: {
    user_data: null,
    company_users: null,
    users_list: null,
  },
  getters: {
    userData(state) {
      if (!state.user_data) {
        return null;
      }
      return state.user_data;
    },
    userRole(state) {
      if (!state.user_data) {
        return null;
      }
      return state.user_data.role;
    },
    usersList(state) {
      if (!state.users_list) {
        return null;
      }
      return state.users_list;
    },
    userCompany(state) {
      if (!state.user_data) {
        return null;
      }
      if (!state.user_data.company) {
        return null;
      }
      return state.user_data.company;
    },
    getCompanyUsers(state) {
      if (!state.company_users) {
        return [];
      }
      return state.company_users;
    },
  },
  mutations: {
    mutation_store_user(state, payload) {
      state.user_data = payload;
    },
    mutation_company_users(state, payload) {
      state.company_users = payload;
    },
    mutation_get_users_list(state, payload) {
      state.users_list = payload;
    },
    mutation_pagination_get_users_list(state, payload) {
      state.users_list = state.users_list.concat(payload);
    },

    
  },
  actions: {
    async actionGetUserByToken({ rootGetters, commit, dispatch }) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/get-user-data",
          method: "GET",
        });

        const result = request.result;
        const ok = request.ok;

        if (ok) {
          localStorage.setItem("user", JSON.stringify(result.user));
          commit("mutation_store_user", result.user);

          const api_url = rootGetters.apiUrl;
          const user_id = result.user.user_id;

          const protocol = window.location.protocol + '//';

          const d = new Date();
          // Set the cookie to expire in 7 days
          d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
          const expires = "expires=" + d.toUTCString();
          const domain = "domain=.expresspermits.llc"; // temporary
          document.cookie = "user_id=" + user_id + ";" + expires + ";path=/;" + domain;
  
          // let message = 'Cookie has been set for .expresspermits.llc';

            // console.log(result.user);
            // console.log(message);


          return Promise.resolve(result.user);
        } else {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          return Promise.reject(result);
        }
      } catch (err) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        return Promise.reject(err);
      }
    },

    // ------------ Personal panel

    async actionUserChangePassword({ dispatch, commit }, data) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/acc-personal-password-changing",
          method: "POST",
          post_data: data,
        });
        const result = request.result;
        // const ok = request.ok;

        localStorage.setItem("user", JSON.stringify(result.user));
        commit("mutation_store_user", result.user);

        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async saveUserPersonalData({ dispatch, commit }, data) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/acc-save-personal-user-data",
          method: "POST",
          post_data: data,
        });

        const result = request.result;
        localStorage.setItem("user", JSON.stringify(result.user));
        commit("mutation_store_user", result.user);
        return Promise.resolve(result.user);
      } catch (err) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        return Promise.reject(err);
      }
    },

    async actionUserChangeCompanyData({ dispatch, commit }, data) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/acc-save-personal-user-company-data",
          method: "POST",
          post_data: data,
        });

        const result = request.result;
        const user = result.user;

        localStorage.setItem("user", JSON.stringify(user));
        commit("mutation_store_user", user);
        return Promise.resolve(user);
      } catch (err) {
        // return Promise.reject(err);
      }
    },

    async actionGetCompanyUserList({ dispatch, commit }) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/acc-get-company-user-list",
        });
        const result = request.result;
        commit("mutation_company_users", result);
        return Promise.resolve(result);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async actionUpdateCompany({ dispatch }, { parameters }) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/update-company",
          method: "POST",
          post_data: { parameters },
        });
        const result = request.result;

        return Promise.resolve(result);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async actionDeleteCompany({ dispatch }, { parameters }) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/delete-company",
          method: "POST",
          post_data: { parameters },
        });
        const result = request.result;

        return Promise.resolve(result);
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async actionGetUsersByRole({ dispatch }, { parameters }) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/get-users-by-role",
          method: "POST",
          post_data: { parameters },
        });
        const result = request.result;
        // const users = result.users;

        return Promise.resolve(result);
      } catch (err) {
        // return Promise.reject(err);
      }
    },
    async actionGetUsersByid({ dispatch }, { parameters }) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/get-user-by-id",
          method: "POST",
          post_data: { parameters },
        });
        const result = request.result;
        // const users = result.users;

        return Promise.resolve(result);
      } catch (err) {
        // return Promise.reject(err);
      }
    },
    //superadmin

    async actionGetUsersList(
      { commit, dispatch, state },
      { parameters, event = "change_parameters" }
    ) {
      // localStorage.setItem("clientListParameters", JSON.stringify(parameters));

      try {
        const request = await dispatch("actionRequest", {
          uri: "/get-users-list",
          method: "POST",
          post_data: parameters,
        });
        const result = request.result;

        const users_list = result.users_list;

        if (event === "change_parameters") {
          commit("mutation_get_users_list", users_list);
        } else if (event === "pagination") {
          commit("mutation_pagination_get_users_list", users_list);
        } else if (event === "search") {
          state.users_list = [];
          commit("mutation_get_users_list", users_list);
        }

        return Promise.resolve(result);
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async actionSuperAdminChangeUserRole({ dispatch }, { parameters }) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/super-admin-change-user-role",
          method: "POST",
          post_data: { parameters },
        });
        const result = request.result;

        return Promise.resolve(result);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async actionUpdateUserNotifications({ dispatch }, { parameters }) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/update-user-notifications",
          method: "POST",
          post_data: { parameters },
        });
        const result = request.result;

        return Promise.resolve(result);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async actionUpdateUser({ dispatch }, { parameters }) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/update-user",
          method: "POST",
          post_data: { parameters },
        });
        const result = request.result;

        return Promise.resolve(result);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async actionDeleteUser({ dispatch }, { parameters }) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/delete-user",
          method: "POST",
          post_data: { parameters },
        });
        const result = request.result;

        return Promise.resolve(result);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async actionSentPopupMessage({ dispatch }, { formData }) {
      try {
        console.log(formData);
        const request = await dispatch("actionRequest", {
          uri: "/sent-popup-message",
          method: "POST",
          post_data: { formData },
        });
        const result = request.result;

        return Promise.resolve(result);
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async actionGetUserNotifications({ rootGetters, commit, dispatch }) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/get-user-notifications",
          method: "GET",
        });

        const result = request.result;
        const ok = request.ok;

        if (ok) {
         
          return Promise.resolve(result);
        } else {
          
          return Promise.reject(result);
        }
      } catch (err) {
      
        return Promise.reject(err);
      }
    },
    async actionClearUserNotifications({ rootGetters, commit, dispatch }) {
      try {
        const request = await dispatch("actionRequest", {
          uri: "/clear-user-notifications",
          method: "GET",
        });

        const result = request.result;
        const ok = request.ok;

        if (ok) {
         
          return Promise.resolve(result);
        } else {
          
          return Promise.reject(result);
        }
      } catch (err) {
      
        return Promise.reject(err);
      }
    },
  },
};
