<template>
  <div class="recover-form-success">
    <div class="success-icon">
      <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 51.3337C40.887 51.3337 51.3333 40.8873 51.3333 28.0003C51.3333 15.1133 40.887 4.66699 28 4.66699C15.113 4.66699 4.66663 15.1133 4.66663 28.0003C4.66663 40.8873 15.113 51.3337 28 51.3337ZM19.3503 26.3507C19.7879 25.9132 20.3812 25.6675 21 25.6675C21.6187 25.6675 22.2121 25.9132 22.6496 26.3507L25.6666 29.3677L33.3503 21.684C33.5655 21.4611 33.823 21.2834 34.1077 21.1611C34.3924 21.0388 34.6985 20.9744 35.0084 20.9717C35.3182 20.969 35.6254 21.0281 35.9122 21.1454C36.1989 21.2627 36.4595 21.436 36.6785 21.6551C36.8976 21.8742 37.0709 22.1347 37.1882 22.4214C37.3055 22.7082 37.3646 23.0154 37.3619 23.3253C37.3592 23.6351 37.2948 23.9413 37.1725 24.2259C37.0502 24.5106 36.8725 24.7681 36.6496 24.9833L27.3163 34.3167C27.1001 34.5341 26.8431 34.7066 26.5599 34.8244C26.2768 34.9421 25.9732 35.0027 25.6666 35.0027C25.36 35.0027 25.0564 34.9421 24.7733 34.8244C24.4902 34.7066 24.2332 34.5341 24.017 34.3167L19.3503 29.65C18.9129 29.2124 18.6671 28.619 18.6671 28.0003C18.6671 27.3816 18.9129 26.7882 19.3503 26.3507Z"
        fill="#D5ECFF"
      />
      <path
        d="M19.3503 26.3507C19.7878 25.9133 20.3812 25.6675 20.9999 25.6675C21.6187 25.6675 22.2121 25.9133 22.6496 26.3507L25.6666 29.3677L33.3503 21.684C33.5655 21.4612 33.823 21.2834 34.1077 21.1611C34.3923 21.0388 34.6985 20.9745 35.0083 20.9718C35.3182 20.9691 35.6254 21.0281 35.9122 21.1454C36.1989 21.2628 36.4595 21.436 36.6785 21.6551C36.8976 21.8742 37.0709 22.1347 37.1882 22.4215C37.3055 22.7082 37.3646 23.0155 37.3619 23.3253C37.3592 23.6351 37.2948 23.9413 37.1725 24.226C37.0502 24.5106 36.8725 24.7681 36.6496 24.9834L27.3163 34.3167C27.1001 34.5341 26.843 34.7067 26.5599 34.8244C26.2768 34.9422 25.9732 35.0028 25.6666 35.0028C25.36 35.0028 25.0564 34.9422 24.7733 34.8244C24.4902 34.7067 24.2331 34.5341 24.0169 34.3167L19.3503 29.65C18.9129 29.2125 18.6671 28.6191 18.6671 28.0004C18.6671 27.3816 18.9129 26.7883 19.3503 26.3507Z"
        fill="#0080EA"
      />
    </svg>
    </div>

    <div class="form-title">
      Your request has been accepted, please check your email
    </div>
  </div>
</template>

<script>
export default {
  name: "RecoverPasswordSendSuccess",
};
</script>
