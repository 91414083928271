<template>
  <div class="confirmation-dialog">
    <div class="dialog-container">
      <h3>{{ content }}</h3>
      <div class="dialog-buttons">
        <button  class="confirm" @click="cancel">No</button>
        <button @click="confirm">Yes</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["cancel", "confirm"],
  props: {
    content: {
      type: String,
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>

<style>
 .confirmation-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.dialog-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  color: #6B7280;
  padding: 32px 48px;
  max-width: 372px;
  width: 100%;
  text-align: center;
  
}
@media screen and (max-width: 767px) {
  .dialog-container {
    padding: 24px;
  }
}
.dialog-buttons {

  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.dialog-buttons button {
  margin-left: 10px;
  border: unset;
  background: unset;
  font-family: Barlow;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  color: #6B7280;
  min-width: 120px;
  border-radius: 4px;
  transition: background 0.3s ;
 
}
.dialog-buttons button:hover {
  background: #ee7373;
  color: white;
}
.dialog-buttons button.confirm {

  background: #ee7373;
  display: inline-flex;
  padding: 12px 24px;
  justify-content: center;
  color: white;
  align-items: center;
  border: unset;
}
</style>
