<template>
  <div class="part-form--add-button">
    <button @click.prevent="openMenu" type="button" class="button small">Add<br>Trailer</button>
    <div :class="['add-button--type-menu', { 'open': menu_open }]">
      <!-- <div @click.prevent="createNewPart('tractor')">Tractor</div> -->
      <div @click.prevent="createNewPart('trailer')">Trailer</div>
      <!-- <div @click.prevent="createNewPart('helper')">Helper</div> -->
    </div>

  </div>
</template>


<script>

export default {
  name: "PartAddButton",
  data() {
    return {
      menu_open: false
    }
  },
  methods: {
    openMenu() {
      this.menu_open = !this.menu_open;
    },
    createNewPart(type) {
      // console.log(type);
      this.$emit('createNewPart', type);
      this.menu_open = false;
    }
  }

}
</script>