<template>
  <hr />
  <div class="permits-header">
    <h3 class="permit-title">Permit Managment</h3>
    <div class="permits-buttons">
      <a v-if="download_all" :href="api_url + '/download.php?download_all=' + order.order_id"
        class="button small download no-wrap download-all">Download all permits</a>
    </div>
  </div>
  <div class="table-wrapper">
    <slot-table class="permit-table" :data="order_meta">
      <template #header>
        <tr>
          <th v-for="column in permit_columns" :key="column.name">
            {{ column.label }}
          </th>
        </tr>
      </template>
      <template v-slot:body="{ item }">
        <tr :key="item.id">
          <td>{{ item.state.value }}</td>
          <td>
            <div :class="['permit-status', item.status.value]">
              {{ getStatusDisplay(item.status.value) }}
            </div>
          </td>
          <td>
            <div class="permits-info">
              <div v-if="!item.state_permit_link == ''" class="permits-info__image">
                <img :src="permit_img" :alt="item.state_permit.name" :title="item.state_permit.name" />
              </div>
              <div class="permits-info__name">
                <span>{{ item.state_permit.name }}</span>
              </div>
              <div class="permits-info__links">
                <button @click="printPdf(item.state_permit_link, item.state_permit.name)"
                  :disabled="!item.state_permit_link">
                  <img :src="print_icon" alt="print_icon" />
                </button>
              </div>
            </div>
          </td>
          <td>

            <a v-if="item.state_permit.file_id" class="button small download"
              :href="api_url + '/download.php?file_id=' + item.state_permit.file_id">Download Permit</a>
            <button v-else class="button small download no-wrap" disabled="true">Download Permit</button>
          </td>
        </tr>
      </template>
    </slot-table>
  </div>
  <pdf-viewer :pdfUrl="showPrintUrl" @close="closePrintPdf" v-if="showPrint" />
</template>

<script>
import PdfViewer from '@/components/helpers/PdfViewer.vue';
import SlotTable from "@/components/SlotTable.vue";
import print_icon from "@/assets/images/svgs/print-icon.svg";
import mail_icon from "@/assets/images/svgs/mail.svg";
import permit_img from "@/assets/images/permit.jpg";
export default {
  name: "OrderPermitsTableView",
  components: {
    SlotTable,
    PdfViewer
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    order_meta: {
      type: Object,
      required: true,
    },
  },
  computed: {
    api_url() {
      return this.$store.getters.apiUrl;
    },
    download_all() {
      // Check if all state_permit.file_id values are not empty
      var allFileIdsPresent = this.order_meta.some(function (permit) {
        return permit.state_permit && permit.state_permit.file_id && permit.state_permit.file_id.trim() !== '';
      });
      return allFileIdsPresent;

    },

  },
  data() {
    return {
      activeButton: false,
      disableButton: false,
      print_icon,
      mail_icon,
      permit_img,
      showPrint: false,
      showPrintUrl: '',
      showPrintTitle: '',
      permit_columns: [
        { name: "state", label: "State" },
        { name: "status", label: "Status" },
        { name: "permits", label: "Permits" },
        { name: "actions", label: "Actions" },
      ],
      statusOptions: [
        { text: "In progress", value: "in_progress" },
        { text: "Awaiting state response", value: "awaiting" },
        { text: "Completed", value: "complete" },
        { text: "Cancelled", value: "cancelled" },
        { text: "Not requested", value: "not_requested" },
        { text: "Has annual", value: "has_annual" },
      ],
    };
  },
  methods: {
    getStatusDisplay(statusValue) {
      const foundStatus = this.statusOptions.find(
        (option) => option.value === statusValue
      );
      return foundStatus ? foundStatus.text : "Unknown Status";
    },
    printPdf(url, title) {
      this.showPrintUrl = url;
      this.showPrintTitle = title;
      this.showPrint = true;
    },
    closePrintPdf() {
      this.showPrintUrl = '';
      this.showPrintTitle = '';
      this.showPrint = false;
    },
    downloadAllPermits() {
      console.log('downloadAllPermits');
    },
    sendPermitFileToEmail(permit_id) {
     
      const order_id = this.order.order_id;
      this.$store
        .dispatch("actionSendPermitToEmail", { order_id, permit_id })
        .then((response) => {
        
        })
        .catch((err) => { });
    },

    // sendAllPermitsToEmail() {
    //   this.disableButton = true;
    //   const order_id = this.order.order_id;
    //   this.$store
    //     .dispatch("actionSendAllPermitsToEmail", { order_id })
    //     .then((response) => {
    //       setTimeout(() => {
    //         this.disableButton = false;
    //       }, 0);
    //     })
    //     .catch((err) => { });
    // }

  },
};
</script>
