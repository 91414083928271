<template>
   <div class="input-wrap">
  <div :class="['input-box', 'input-weight', {'not-valid-data-box': !!errorText}, {'disabled': !!disabled}]">
    <span class="label">{{ label }}</span>
    <input
      v-model="inputData"
      type="text"
      :class="[inputClasses,{'not-valid-data': !!errorText}]"
      :placeholder="placeholder"
      :maxlength="maxLength"
      :disabled="disabled"
      @input="eventInput"
      @blur="eventBlur"
      @keyup.enter="eventKeyUp"
    />
    <span v-if="!!errorText" class="err">{{ errorText }}</span>
    <button v-if="copy && inputData" class="copy-link" @click="copyLink">
      <svg width="64px" height="64px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" stroke-width="3"
      stroke="#6B7280" fill="none">
      <rect x="11.13" y="17.72" width="33.92" height="36.85" rx="2.5" />
      <path
      d="M19.35,14.23V13.09a3.51,3.51,0,0,1,3.33-3.66H49.54a3.51,3.51,0,0,1,3.33,3.66V42.62a3.51,3.51,0,0,1-3.33,3.66H48.39" />
    </svg>
  </button>
  </div>
  <span v-if="!!showCopied"  class="show-copied">Copied!</span>
</div>
</template>

<script>
export default {
  name: "InputWeight",
  data() {
    return {
      inputData: this.modelValue,
      errorText: null,
      showCopied: false
    }
  },
  props: {
    modelValue: {
      type: [Number, String],
      default: null
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    inputClasses: {
      type: String,
      default: null
    },
    validSw: {
      type: [Boolean,String],
    },
    rules: {
      type: Array,
      default: () => []
    },
    maxLength: {
      type: Number,
      default: 6
    },
    disabled: {
      type: Boolean,
      default: false
    },
    step: {
      type: Number,
      default: 1
    },
    copy: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue','error', 'update'],
  methods: {
    copyLink() {
      const input = document.createElement("input");
      input.value =  this.inputData;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);

      this.showCopied = true;
      setTimeout(() => {
        this.showCopied = false;
      }, 3000);
    },
    eventKeyUp(e) {
      this.eventInput(e);
      this.eventBlur(e);
      e.target.blur();
    },
    /**
     *
     * @param e
     */
    eventInput(e) {
      let v = e.target.value;
      v = v.replace(/[^0-9\slbskg]/ig, '');
      if (!!v) {
        const new_v = v.trim().substr(0, 1);
        if (!/[0-9]/g.test(new_v)) {
          this.inputData = '';
          return;
        }
        v = v.replace(/\s+/ig, ' ');
      }
      this.inputData = v;
    },
    /**
     *
     * @param e
     */
    eventBlur(e) {
      let value = e.target.value;
      value = value.replace(/[^0-9lbskg]/ig, '').trim();
      value = this.calculateValue(value);
      this.$emit('update:modelValue', value);
      this.$emit('update', value);
      this.inputData = value;
      this.validateData(this.inputData);
    },
    /**
     *
     * @param v
     * @returns {string|null}
     */
    calculateValue(v) {
      v = v.replace(/[^0-9\slbskg]/ig, '').trim();

      // if empty
      if (/^0+$/g.test(v) || v.length === 0) {
        return null;
      }

      const digits = v.match(/^\d+/ig);

      let data = digits + ' lbs';

      if (v.substr(-2, 2).toLowerCase() === 'kg') {
        data = (Math.round(+digits * 2.20462 * 10)) / 10;
        data += ' lbs';
      }
      return data;
    },
    validateData(v) {
        if (!!this.rules && this.rules.length) {
          const err_txt = this.rules
            .map(f => f(v))
            .find(itm => itm !== true);
  
          if (!!err_txt) {
            this.errorText = err_txt;
            this.$emit('error', true);
            return;
          } else {
            this.errorText = null;
            this.$emit('error', false);
            return;
          }
        }
        this.$emit('error', false);
      },
  },
  watch: {
    validSw() {
      this.validateData(this.inputData);
    },
    modelValue(new_data) {
      this.inputData = new_data;
    }
  }
}
</script>
