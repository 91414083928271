<template>
  <div class="input-box radio-switcher" :class="{ disabled: disabled }">
    <div v-if="!!items.length" class="radio-box">
      <div
        v-for="item in items"
        :class="['radio-item', { checked: item.value === modelValue }]"
        @click="eventClick(item.value)"
      >
        <i></i><span>{{ item.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RadioSwitcher",
  props: {
    modelValue: {
      type: [String, Object, Boolean],
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],

  methods: {
    eventClick(val) {
      if (!this.disabled) {
        this.$emit("update:modelValue", val);
      }
    },
  },
};
</script>

<style scoped>
.disabled {
  opacity: 0.9;
  pointer-events: none;
}
</style>
