<template>
  <div class="ef__part-form ef__part-sme">
    <!-- <part-add-button @create-new-part="createNewPart" /> -->
    <div class="part-form">
      <div class="form-line form-grid r-2">
        <div class="part-form-title">Special Mobile Equipment</div>
        <input-select-with-value v-model="formData.part_id" :disabled="canIfillForm" :options="smeList"
          @change="setSMEFromList" />
        <button @click.prevent="eventDeleteForm" type="button" class="delete-button">
          <span>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M7.10047 2.20835C7.04667 2.20835 6.9989 2.24278 6.98188 2.29383L6.64926 3.29169H11.3504L11.0178 2.29383C11.0008 2.24278 10.953 2.20835 10.8992 2.20835H7.10047ZM12.8437 3.29169L12.3618 1.84584C12.1519 1.21631 11.5628 0.791687 10.8992 0.791687H7.10047C6.43689 0.791687 5.84776 1.21631 5.63792 1.84584L5.15597 3.29169H3.1665H1.49984C1.10864 3.29169 0.791504 3.60882 0.791504 4.00002C0.791504 4.39122 1.10864 4.70835 1.49984 4.70835H2.50382L3.13749 14.2134C3.24985 15.8988 4.64965 17.2084 6.33872 17.2084H11.661C13.35 17.2084 14.7498 15.8988 14.8622 14.2134L15.4959 4.70835H16.4998C16.891 4.70835 17.2082 4.39122 17.2082 4.00002C17.2082 3.60882 16.891 3.29169 16.4998 3.29169H14.8332H12.8437ZM14.076 4.70835H12.3332H5.6665H3.92363L4.55102 14.1192C4.61377 15.0604 5.39547 15.7917 6.33872 15.7917H11.661C12.6042 15.7917 13.3859 15.0604 13.4487 14.1192L14.076 4.70835Z" />
            </svg>
          </span>
        </button>
      </div>
      <div class="form-line part-form__add-fields">
        <div class="input-box">{{ equipmentName }}</div>
        <div class="input-box acceptable-box">
          <span class="like-link" @click="additional = !additional">{{
            additional ? "Hide additional fields" : "Show additional fields"
          }}</span>
        </div>
      </div>

      <div class="form-line form-grid r-2">
        <div class="additional-info">Unit number/Name
          <div class="additional-info-message">
            The nickname/company specific number/internal tracking number
          </div>
        </div>
        <input-text v-model="formData.unit_number" :disabled="canIfillForm" @blur="updateFormDataOnBlur" :copy="canIcopy"
          placeholder="W335" :valid-sw="validSw" :rules="rules.requiredText" maxlength="32"
          @error="regError('unit_number', $event)" />
      </div>
      <div class="form-line form-grid r-2">
        <div class="additional-info make">Make*
          <div class="additional-info-message">
            Kenworth,
            Peterbilt, etc.
          </div>
        </div>
        <input-text v-model="formData.make" :disabled="canIfillForm" @blur="updateFormDataOnBlur" :copy="canIcopy"
          placeholder="Peterbilt" :valid-sw="validSw" :rules="rules.requiredText" maxlength="32"
          @error="regError('make', $event)" />
      </div>
      <div class="form-line form-grid r-2">
        <div>Year Model*</div>
        <input-text v-model="formData.year_model" :disabled="canIfillForm" @blur="updateFormDataOnBlur" :copy="canIcopy"
          placeholder="2014" :valid-sw="validSw" :rules="rules.year" maxlength="4"
          @error="regError('year_model', $event)" />
      </div>
      <div class="form-line form-grid r-2 vin-code">
        <div class="vin-code">VIN Number*</div>
        <input-text v-model="formData.vin" :disabled="canIfillForm" @blur="updateFormDataOnBlur" :copy="canIcopy"
          placeholder="VHR151EKNFE2223" :valid-sw="validSw" :rules="rules.vin_code" maxlength="17"
          @error="regError('vin', $event)" />
      </div>
      <div class="form-line form-grid r-2">
        <div >License Plate Number*</div>
        <input-text class="license-plate" v-model="formData.license" :disabled="canIfillForm" @blur="updateFormDataOnBlur" :copy="canIcopy"
          placeholder="P56231" :valid-sw="validSw" :rules="rules.license_plate" maxlength="20"
          @error="regError('license', $event)" />
      </div>

      <div class="form-line form-grid r-2">
        <div>State Registered*</div>
        <input-select-group v-model="formData.registration_place" :disabled="canIfillForm"
          @change="updateFormDataOnBlur"
          :grouped-states="{ 'US States': usStates, 'Mexican States': mxStates, 'Canadian Provinces': caProvinces }"
          :options="stateList" :valid-sw="validSw" :rules="rules.requiredOnly"
          @error="regError('registration_place', $event)" />
      </div>

      <div class="form-line form-grid r-2">
        <div># Axles*</div>
        <input-select v-model="formData.axle_quantity" :disabled="canIfillForm" @change="updateFormDataOnBlur" 
          :options="axles_options" :valid-sw="validSw" :rules="rules.requiredOnly"
          @error="regError('axle_quantity', $event)" />
      </div>
      <div v-show="additional" class="form-line form-grid r-2">
        <div>Axle Width</div> 
        <input-size v-model="formData.axle_width" :disabled="canIfillForm" @blur="updateFormDataOnBlur" :copy="canIcopy"
          placeholder="8’6”" maxlength="8" />
      </div>
      <div v-show="additional" class="form-line form-grid r-2">
        <div>Length</div>
        <input-size v-model="formData.length" :disabled="!newEquipment" @blur="updateFormDataOnBlur" placeholder="25’" :copy="canIcopy"
          maxlength="8" />
      </div>
      <div v-show="additional" class="form-line form-grid r-2">
        <div>Empty weight</div>
        <input-weight v-model="formData.empty_weight" :disabled="canIfillForm" @blur="updateFormDataOnBlur" :copy="canIcopy"
          placeholder="45,000 lbs" maxlength="8" />
      </div>
<!-- 
      <div v-show="additional" class="form-line form-grid r-2">
        <div>Length</div>
        <input-size v-model="formData.length" :disabled="canIfillForm" @blur="updateFormDataOnBlur" placeholder="25’"
          maxlength="8" />
      </div> -->

    </div>
  </div>
</template>
<script>
import InputText from "@/components/form-elements/InputText.vue";
import InputSize from "@/components/form-elements/InputSize.vue";
import InputSelectWithValue from "@/components/form-elements/InputSelectWithValue.vue";
import InputSelect from "@/components/form-elements/InputSelect.vue";
import InputSelectGroup from "@/components/form-elements/InputSelectGroup.vue";
import InputWeight from "@/components/form-elements/InputWeight.vue";
import EquipmentButtons from "@/components/equipment/EquipmentButtons.vue";
import PartAddButton from "@/components/equipment/parts/PartAddButton.vue";
import { FeetMathMixin } from "@/mixins/FeetMathMixin";
import ValidateMixin from "@/mixins/ValidateMixin";


export default {
  name: "SMEForm",
  components: {
    PartAddButton,
    EquipmentButtons,
    InputWeight,
    InputSelect,
    InputSelectGroup,
    InputSelectWithValue,
    InputText,
    InputSize,
  },
  data() {
    return {
      formData: {
        part_id: null,
        type: "sme",
        unit_number: null,

        length: null,
        make: null,
        year_model: null,
        vin: null,
        license: null,
        registration_place: null,

        empty_weight: null,
        axle_width: null,
        axle_quantity: 3,
      },
      errors: {
        unit_number: null,
        make: null,
        year_model: null,
        vin: null,
        license: null,
        registration_place: null,
        axle_quantity: null,
      },
      newEquipment: true,
      additional: false,
      validSw: false,
    };
  },
  mixins: [FeetMathMixin, ValidateMixin],
  created() {
    this.updateBaseForm(this.modelValue);
    if (this.modelValue.unit_number) {
      this.newEquipment = false
    }
  },
  props: {
    modelValue: {
      type: Object,
      default: null,
    },


    stateList: {
      type: Array,
      default: () => [],
    },
    smeList: {
      type: Array,
      default: () => [],
    },
    smeData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    order_by_id() {
      return this.$store.getters.order_by_id;
    },
    user_role() {
      return this.$store.getters.userRole;
    },
    user_data() {
      return this.$store.getters.userData;
    },
    canIcopy() {
      if(this.user_role === 'manager' || this.user_role === 'admin') {
        return true;
      } else {
        return false;
      }
    },
    canIfillForm() {
      if(this.user_role === 'manager' && this.order_by_id.manager_id !== this.user_data.user_id) {
        return true;
      }
      const allowedStatuses = ['deposit_paid', 'deposit_reminder', 'payment_needed', 'payment_reminder', 'payment_completed', 'cancelled'];
      if (['owner', 'driver'].includes(this.user_role) && allowedStatuses.includes(this.order_by_id.order_status)) {
        return true;
      }
      return false;
    },
    equipmentName() {
      const arr = [];
      const name = !!this.formData.unit_number
        ? this.formData.unit_number
        : "No name";
      arr.push(name);

      if (!!this.formData.axle_quantity) {
        let axles =
          +this.formData.axle_quantity > 1
            ? this.formData.axle_quantity + " axles"
            : this.formData.axle_quantity + " axle";
        arr.push(axles);
      }
      return arr.join(", ");
    },

    axles_options() {
      const min = 2;
      const max = 30;
      let out = ["---"];
      for (let i = min; i <= max; i++) {
        out.push(i);
      }
      return out;
    },
    state_list() {
      let out = ["---"];
      const state_list = this.$store.getters.state_list;
      return out.concat(state_list);
    },
    usStates() {
      return this.state_list.filter(state => state.country_id === "US");
    },
    mxStates() {
      return this.state_list.filter(state => state.country_id === "MX");
    },
    caProvinces() {
      return this.state_list.filter(state => state.country_id === "CA");
    }
  },
  emits: ["removePart", "createNewPart", "update:modelValue", "validFields"],
  methods: {
    is_form_valid_new() {
      const allErrorsValid = Object.values(this.errors).some(error => error === true || error === null);
      this.$store.dispatch("actionGenerateId", 32).then((id) => {
        this.validSw = id;
      });
      this.$emit("validFields", !allErrorsValid);
    },
    updateBaseForm(incoming) {
      if (!incoming) {
        return;
      }
      Object.keys({ ...incoming }).forEach((key) => {
        this.formData[key] = incoming[key];
      });
    },
    createNewPart(type) {
      this.$emit("createNewPart", type);
    },
    eventDeleteForm() {
      this.$emit("removePart", this.formIndex);
    },
    setSMEFromList(event) {
      const part_id = event.target.value;
      const newSMEData = this.smeData.find(
        (obj) => obj.part_id === part_id
      );
      if (newSMEData) {
        this.newEquipment = false;
        this.formData.part_id = newSMEData.part_id;
        this.formData.unit_number = newSMEData.unit_number;
        this.formData.length = this.dec_to_feet(newSMEData.length);
        this.formData.empty_weight = newSMEData.empty_weight + " lbs";
        this.formData.make = newSMEData.make;
        this.formData.year_model = newSMEData.year_model;
        this.formData.vin = newSMEData.vin;
        this.formData.license = newSMEData.license;
        this.formData.registration_place = newSMEData.registration_place;
        this.formData.axle_width = this.dec_to_feet(newSMEData.axle_width);
        this.formData.axle_quantity = newSMEData.axle_quantity;
        this.$emit("update:modelValue", this.formData);
        this.is_form_valid_new();
      } else {

        this.$store.dispatch("actionGenerateId", 32).then((id) => {
          this.formData.part_id = id;
          this.formData.unit_number = null;
          this.formData.length = null;
          this.formData.empty_weight = null;
          this.formData.make = null;
          this.formData.year_model = null;
          this.formData.vin = null;
          this.formData.license = null;
          this.formData.registration_place = null;
          this.formData.axle_width = null;
          this.formData.axle_quantity = null;
          this.newEquipment = true;
        });
        this.$emit("update:modelValue", this.formData);
      }
     
    },

    updateFormDataOnBlur() {
      this.$emit("update:modelValue", this.formData);
    },
  },


};
</script>
